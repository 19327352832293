<div class="container">
  <div (elementResize)="resizeArea($event)"></div>

  <ng-container *ngIf="builder">
    <chart-builder
      *zefPortal
      class="z-fx-col builder"
      (builder)="showBuilder($event)"
      [dimensionInput]="builderDimensions"
    ></chart-builder>
  </ng-container>

  <div class="z-fx z-fx-between-center grid-toolbar">
    <div class="z-fx-col z-fx-gap-8 search-box">
      <zef-searchbox (input)="searchUpdate.next($event)">
        <span i18n>Find...</span>
      </zef-searchbox>
    </div>
    <button
      *ngIf="
        (Rights.hasRights(Rights.EDIT, surveyRights) || Rights.hasRights(Rights.ADMIN, userRights)) && !isSharedReport
      "
      color="ink"
      class="zef-combo-button zef-ghost-button"
      mat-raised-button
      (click)="showHidePanel.openPanel()"
    >
      <mat-icon class="z-fx-none">menu</mat-icon>
      <span i18n>Show / Hide</span>
    </button>
  </div>
  <div *ngIf="searchTerm" class="search-results">
    <div *ngFor="let result of searchList" class="search-result" (click)="goTo(result.key)">{{ result.title }}</div>
    <div *ngIf="searchList.length === 0" i18n>No results...</div>
  </div>

  <div class="grid" id="report-grid-container">
    <mat-grid-list [cols]="mobileView ? 1 : 2" rowHeight="56px" gutterSize="24px">
      <mat-grid-tile
        *ngFor="let item of grid; trackBy: trackByKey; let i = index"
        class="z-fx-col z-fx-none tile"
        [id]="'grid-item-' + item.key"
        [colspan]="mobileView ? 1 : item.gridSettings.sizex"
        [rowspan]="dragOn !== item.key ? item.gridSettings.sizey : placeholderSizeY || item.gridSettings.sizey"
      >
        <div
          class="z-fx"
          [class.activedrag]="dragOn === item.key"
          cdkDropList
          [cdkDropListConnectedTo]="drops"
          [cdkDropListData]="i"
          (cdkDropListEntered)="dropListEnter($event)"
          (cdkDropListDropped)="resetMouseEvents($event)"
        >
          <chart-card
            *ngIf="!item.isNotesCard && !item.isSubheader && !item.isInsightsCard"
            class="z-fx-col z-fx-auto grid-item"
            cdkDrag
            [cdkDragData]="i"
            [settings]="item"
            [data]="item.data"
            [height]="item.gridSettings.sizey"
            [width]="item.gridSettings.sizex"
            [row]="item.gridSettings.row"
            [update]="item.data.timestamp"
            [visibleArea]="visibleArea"
            [comparison]="item.data.comparison"
            [surveyRights]="surveyRights"
            [userRights]="userRights"
            [sampleData]="sampleData"
            [onResize]="onResize"
            [explorable]="explorable"
            [mobileView]="mobileView"
            [touchDevice]="touchDevice"
            [isSharedReport]="isSharedReport"
            [filtersDemo]="filtersDemo"
            [trendHoverInput]="trendHoverValue"
            (openBuilder)="openBuilder($event)"
            (duplicateChart)="duplicateChart($event)"
            (pinnedChart)="pin($event)"
            (removedChart)="removeChart($event)"
            (hidedChart)="hideChart($event)"
            (toggleFullscreen)="openFullscreenDialog($event)"
            (cdkDragStarted)="dragStarted(item.key)"
            (mousedown)="resizeStartedMousePosition($event, item.key)"
            (trendHoverOutput)="trendHover($event)"
          >
            <span class="drag-handle" cdkDragHandle>{{ item.title }}</span>
          </chart-card>
          <div *ngIf="item.isSubheader" class="z-fx-col z-fx-auto subheader" cdkDrag cdkDragDisabled [cdkDragData]="i">
            <mat-divider class="z-fx-none divider"></mat-divider>
            <div class="z-fx z-fx-between-center subheader-text">
              <ng-container *ngIf="item.key === 'Contacts_header'" i18n>Contacts</ng-container>
              <ng-container *ngIf="item.key === 'Answers_header'" i18n>Responses</ng-container>
              <chart-menu
                *ngIf="!isSharedReport"
                [isSubheader]="true"
                [editRights]="
                  (Rights.hasRights(Rights.EDIT, surveyRights) || Rights.hasRights(Rights.ADMIN, userRights)) &&
                  !sampleData
                "
                [isCustomCard]="item.isCustomCard"
                [isSharedReport]="isSharedReport"
                (hideChart)="hideChart(item.gridId)"
              ></chart-menu>
            </div>
          </div>
          <div
            *ngIf="item.isInsightsCard"
            class="z-fx-col z-fx-auto insights-card"
            cdkDrag
            cdkDragDisabled
            [cdkDragData]="i"
          >
            <div class="z-fx z-fx-between-center subheader-text">
              <div *ngIf="item.key === 'Insights_header'" class="z-fx-col z-fx-start-start z-fx-gap-8 insights">
                <div class="z-fx z-fx-start-center z-fx-gap-8">
                  <div class="zef-text-b2" i18n>Insights</div>
                  <zef-chip class="zef-chip-beta">Beta</zef-chip>
                </div>
                <div class="zef-text-p2-muted description" i18n>Verify the facts. AI can make mistakes.</div>
              </div>
              <chart-menu
                *ngIf="!isSharedReport"
                [isSubheader]="true"
                [editRights]="
                  (Rights.hasRights(Rights.EDIT, surveyRights) || Rights.hasRights(Rights.ADMIN, userRights)) &&
                  !sampleData
                "
                [isCustomCard]="item.isCustomCard"
                [isSharedReport]="isSharedReport"
                [showRegenerate]="true"
                (regenerateChart)="regenerateInsights()"
                (hideChart)="hideChart(item.gridId)"
              ></chart-menu>
            </div>
            <div class="chart-discover-container">
              <chart-discover
                class="z-fx-col z-fx-auto chart-discover-component"
                (hidedChart)="hideChart($event)"
              ></chart-discover>
            </div>
          </div>
          <notes-card
            *ngIf="item.isNotesCard"
            class="z-fx-col z-fx-auto grid-item"
            cdkDrag
            cdkDragDisabled
            [cdkDragData]="i"
            [settings]="item"
            [data]="item.notesCardData"
            [isSharedReport]="isSharedReport"
            [surveyRights]="surveyRights"
            [userRights]="userRights"
            [mobileView]="mobileView"
            (toggleFullscreen)="openFullscreenDialog($event)"
            (hidedChart)="hideChart($event)"
          ></notes-card>
        </div>
        <mat-icon *ngIf="!touchDevice && !item.isSubheader" class="resizer" (mousedown)="resizerOn = item.key">
          expand
        </mat-icon>
        <div *ngIf="resizerOn === item.key" class="resizer-viewer-container">
          <div
            *ngIf="resizerOn === item.key"
            class="resizer-viewer-box"
            [style.width]="resizedWidth"
            [style.height]="resizedHeight"
            (document:mouseup)="resetMouseEvents()"
            (document:mousemove)="resizeGridItem($event)"
          ></div>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
  <button
    *ngIf="
      !mobileView &&
      explorable &&
      (grid.length > 0 || cm.blankForReason || toggleUsed) &&
      (Rights.hasRights(Rights.EDIT, surveyRights) || Rights.hasRights(Rights.ADMIN, userRights)) &&
      !isSharedReport
    "
    mat-raised-button
    class="zef-placeholder-button center add-new-card inline"
    color="primary"
    (click)="showBuilder(true)"
  >
    <mat-icon>plus</mat-icon>
    <span i18n>Add new card</span>
  </button>

  <zef-spinner
    *ngIf="grid.length === 0 && !cm.blankForReason && !toggleUsed"
    class="spinner"
    mode="indeterminate"
    size="huge"
  ></zef-spinner>
</div>

<zef-panel #showHidePanel [align]="'right'" [width]="width * 0.8 > 650 ? '600px' : width * 0.8 + 'px'" [scrim]="true">
  <div *ngIf="showHidePanel?.isOpen()" class="z-fx-col z-fx-auto">
    <div class="z-fx z-fx-start-center show-hide-panel-top-bar">
      <div class="z-fx z-fx-flex z-fx-between-center z-fx-gap-4">
        <p class="z-fx-auto zef-text-h2" i18n>Charts</p>
        <div class="z-fx z-fx-start-center z-fx-gap-8 button-container">
          <button
            mat-raised-button
            class="zef-combo-button"
            color="secondary"
            (click)="showHidePanel.closePanel(); showBuilder(true)"
          >
            <mat-icon>plus</mat-icon>
            <span i18n>New chart</span>
          </button>
          <button mat-icon-button color="secondary" [mat-menu-trigger-for]="selectionsMenu">
            <mat-icon>more</mat-icon>
          </button>
          <button mat-icon-button color="secondary" (click)="showHidePanel.closePanel()">
            <mat-icon>close</mat-icon>
          </button>
        </div>

        <mat-menu #selectionsMenu class="zef-menu-combo menu-dropdown" x-position="before" y-position="below">
          <button mat-menu-item (click)="showAllCharts()">
            <mat-icon>visible_on</mat-icon>
            <span i18n>Show all</span>
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="showCompactView()" [disabled]="hasGroups()">
            <mat-icon>highlight_ai</mat-icon>
            <span i18n>Summary view</span>
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="hideEmptyCharts()">
            <mat-icon>visible_off</mat-icon>
            <span i18n>Hide empty</span>
          </button>
          <button mat-menu-item (click)="hideContactCharts()">
            <mat-icon>visible_off</mat-icon>
            <span i18n>Hide contacts</span>
          </button>
          <button mat-menu-item (click)="hideAllCharts()">
            <mat-icon>visible_off</mat-icon>
            <span i18n>Hide all</span>
          </button>
        </mat-menu>
      </div>
    </div>
    <ng-scrollbar class="z-fx-col z-fx-auto">
      <div
        cdkDropList
        (cdkDropListDropped)="updateQuestionOrder($event)"
        class="z-fx-col z-fx-auto z-fx-gap-8 drag-boundary show-hide-panel-content"
      >
        <div
          *ngFor="let item of visibilityGrid; trackBy: trackByKey"
          cdkDrag
          cdkDragBoundary=".drag-boundary"
          [cdkDragData]="item"
          class="z-fx z-fx-start-center show-hide-panel-item-container"
          [class.zef-list-item-hidden]="item.hided"
        >
          <mat-icon *ngIf="belongsGroup(item)" class="z-fx z-fx-center-center subdirectory-icon">child</mat-icon>
          <div
            class="z-fx z-fx-start-center z-fx-gap-8 show-hide-panel-item"
            [class.contacts-header]="item?.key === 'Contacts_header'"
            [class.answers-header]="item?.key === 'Answers_header'"
            [class.in-group]="belongsGroup(item)"
            (click)="goTo(item.key)"
          >
            <div>
              <mat-icon class="z-fx z-fx-center-center question-icon" [ngClass]="itemClass(item)">
                {{ getQuestionIcon(item) }}
              </mat-icon>
              <mat-icon class="z-fx z-fx-center-center drag-handle-icon" cdkDragHandle>drag_handle</mat-icon>
            </div>
            <div *ngIf="!item.isSubheader" class="zef-text-ellipsis zef-text-h4 text" [class.hided]="item.hided">
              {{ item.title }}
            </div>
            <div *ngIf="item.isSubheader" class="zef-text-ellipsis zef-text-h3 text" [class.hided]="item.hided">
              <ng-container *ngIf="item.key === 'Contacts_header'" i18n>Contacts</ng-container>
              <ng-container *ngIf="item.key === 'Answers_header'" i18n>Responses</ng-container>
            </div>
            <button
              mat-icon-button
              class="zef-button-icon-transparent toggle"
              [class.hided]="item.hided"
              matTooltip="Show/Hide"
              i18n-matTooltip="tooltip|chart visibility"
              matTooltipPosition="above"
              (click)="$event.stopPropagation(); onToggleChart(item)"
            >
              <mat-icon>
                {{ item.hided ? 'visible_off' : 'visible_on' }}
              </mat-icon>
            </button>
          </div>
        </div>
      </div>
    </ng-scrollbar>
  </div>
</zef-panel>
