<div class="z-fx-col container">
  <div class="z-fx-col min-h-0 min-w-0" [ngStyle]="stickyStyles">
    <ng-scrollbar [autoHeightDisabled]="false" class="z-fx-col z-fx-auto">
      <div class="content z-fx-col z-fx-auto">
        <div *ngIf="(stats && !stats.comparison) || cropFilters?.length" class="z-fx-col content-box">
          <stats-view
            class="z-fx-col z-fx-auto stats-view"
            [stats]="stats"
            [filters]="filters"
            [filtersDemo]="filtersDemo"
            [defaultSurvey]="defaultSurvey"
            [includedSurveys]="includedSurveys"
            [userRights]="userRights"
            [surveyRights]="surveyRights"
            [isSharedReport]="isSharedReport"
            [anonymityTreshold]="anonymityTreshold"
            (closeComparison)="closeComparison()"
            (removeSurvey)="removeSurvey($event)"
          ></stats-view>
          <filter-view
            class="z-fx-col z-fx-none filter-view"
            [filters]="cropFilters"
            [dimensions]="dimensions"
            [userRights]="userRights"
            [surveyRights]="surveyRights"
            [isSharedReport]="isSharedReport"
            (removeCropFilters)="removeCropFilters($event)"
          ></filter-view>
        </div>
        <div class="z-fx-col content-box">
          <trend-view
            class="z-fx-col z-fx-auto"
            [trendAnalysisOn]="trendAnalysisOn"
            [activeTrend]="activeTrend"
            (changeTrend)="changeTrend($event)"
            (trendSwitched)="trendSwitched($event)"
          ></trend-view>
        </div>
        <div class="z-fx-col content-box">
          <tools-view
            class="z-fx-col z-fx-auto"
            [userSegments]="userSegments"
            [filters]="filters"
            [anonymityTreshold]="anonymityTreshold"
          ></tools-view>
        </div>
        <div *ngIf="showLanguages" class="z-fx-col z-fx-start-end">
          <language-view
            class="z-fx-col z-fx-auto"
            [languages]="languages"
            [activeLanguage]="activeLanguage"
            (changeLanguage)="changeLanguage($event)"
          ></language-view>
        </div>
      </div>
    </ng-scrollbar>
  </div>
</div>
