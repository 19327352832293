import { fromEvent, Observable, of, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, filter, first, map, switchMap, takeUntil } from 'rxjs/operators';

import { Store } from '@ngxs/store';

import { ActivatedRoute, NavigationStart, Router } from '@angular/router';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import { LocalStorage } from 'ngx-webstorage';

import { Crossfilter } from '@report/shared/services/crossfilter.service';
import { ReportSave } from '@report/shared/services/report-save.service';
import { InfoBanner } from '@report/shared/services/info-banner.service';

import { ChartsManager } from '@report/shared/services/charts-manager.service';

import { AuthManager } from '@shared/services/auth-manager.service';
import { AccountManager } from '@shared/services/account-manager.service';

import { ReportData, SurveyData } from '@shared/models/survey.model';

import { SurveysManager } from '@shared/services/surveys-manager.service';
import { ReportManager } from '@shared/services/report-manager.service';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';
import { DialogControl } from '@shared/services/dialog-control.service';

import { Rights } from '@shared/enums/rights.enum';
import { AccountState } from '@shared/states/account.state';
import { SurveyState } from '@shared/states/survey.state';
import { SignOutWithoutRedirect } from '@shared/states/auth.actions';
import { AuthState } from '@shared/states/auth.state';
import { NgScrollbar } from 'ngx-scrollbar';
import { MediaMonitor } from '@shared/modules/media-monitor.module';
import { ViewSize } from '@shared/enums/view-size.enum';
import { isShallowEqual } from '@shared/utilities/object.utilities';
import { ReportCommon } from './shared/services/report-common.service';

@Component({
  selector: 'zef-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  providers: [LifecycleHooks],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportApp implements OnInit, OnDestroy, AfterViewInit {
  private surveySub: any;
  private amSub: any;
  private cfSub: any;
  private touchDeviceSub: Subscription;

  public surveyData: SurveyData | null = null;
  public reportsData: ReportData[] | null = null;
  public id: string = '';

  public loaded: boolean = false;
  public sampleData: boolean = false;

  public scrollState: number[] | null = null;

  public userRights: number = 0;
  public surveyRights: number = 0;

  public width: number = 0;

  public isSharedReport: boolean = false;
  public isTemplateReport: boolean = false;
  public isPollReport: boolean = false;
  public isAuthorUnsavedChanges: boolean = false;
  public reportInfo: any = {};
  public filtersDemo: boolean = false;
  public filtersApplied: boolean = false;

  public touchDevice: boolean = false;

  public lastOpenReport: string | null = null;
  public showLastReportBanner: boolean = true;

  public sampleDataRemovalBanner: boolean = false;
  public sampleDataRemovalBannerClosed: boolean = false;

  public sampleDataBanner: boolean = true;
  public sampleDataBannerClosed: boolean = false;

  readonly isDesktop$ = this.mm.media$.pipe(
    map((viewSize) => ViewSize.isDesktop(viewSize)),
    distinctUntilChanged(),
  );
  readonly showSidePane$ = this.isDesktop$.pipe(
    map((isDesktop) => isDesktop && !this.isPollReport),
    distinctUntilChanged(),
  );

  @Input() templateKey: string | null = null;

  @ViewChild(NgScrollbar, { static: true })
  sb: NgScrollbar;

  @LocalStorage('remember') remember: boolean | undefined;

  @HostListener('window:unload') onUnload(): void {
    this.closeReport();
  }

  @HostListener('window:pagehide') onPageHide(): void {
    this.closeReport();
  }

  @HostListener('window:beforeunload') onBeforeUnload(): void {
    this.closeReport();
  }

  constructor(
    private route: ActivatedRoute,
    public cf: Crossfilter,
    private cm: ChartsManager,
    public rs: ReportSave,
    readonly sm: SurveysManager,
    readonly router: Router,
    readonly auth: AuthManager,
    readonly hooks: LifecycleHooks,
    readonly dc: DialogControl,
    private cdRef: ChangeDetectorRef,
    private rm: ReportManager,
    readonly store: Store,
    public am: AccountManager,
    private _element: ElementRef,
    private ib: InfoBanner,
    private mm: MediaMonitor,
    private rc: ReportCommon,
  ) {
    // use mouseover event here and set touchdevice true by default
    this.touchDeviceSub = fromEvent(window, 'touchstart')
      .pipe(
        catchError(() => of(null)),
        first(),
      )
      .subscribe(() => {
        this.touchDevice = true;
        this.cdRef.detectChanges();
      });
  }

  ngOnInit() {
    if (this.templateKey) {
      this.isTemplateReport = true;
      this.loadTemplateReport('templates/' + this.templateKey);
    } else {
      this.amSub = this.store.select(AccountState.userRole).subscribe((rights: number) => {
        this.userRights = rights;
        this.cdRef.markForCheck();
      });

      this.router.events
        .pipe(
          takeUntil(this.hooks.destroy),
          filter((event) => event instanceof NavigationStart),
          catchError(() => of(null)),
        )
        .subscribe((event: NavigationStart) => {
          const current: string[] = this.router.url.split('/');
          const target: string[] = event.url.split('/');

          for (let i = 0; i < 4; i++) {
            if (current[i] !== target[i]) {
              this.ib.resetParams();
            }
          }
        });

      this.route.params
        .pipe(distinctUntilChanged(isShallowEqual), takeUntil(this.hooks.destroy))
        .subscribe((params) => {
          this.ib.setParams(params);

          if (params.poll && params.session) {
            this.loadPollReport(params.poll, params.session);
          } else if (params.public || params.template) {
            this.sampleData = params.public === 'demo';
            this.isTemplateReport = params.template != null;

            this.loadPublicReport(params.public || 'templates/' + params.template);
          } else {
            if (this.rs.autoSaveOn) {
              this.rs.disconnectAutoSave();
            }
            this.loadReport(params);
          }
        });
    }
  }

  ngAfterViewInit() {
    this.sb.verticalScrolled.pipe(takeUntil(this.hooks.destroy)).subscribe(($event) => this.scroll($event));
  }

  private loadPollReport(linkKey: string, sessionKey: string) {
    const pollData = this.route.snapshot.data?.pollData;
    const surveyKey = pollData?.surveyKey;
    const teamKey = pollData?.teamKey;

    this.isSharedReport = true;
    this.isPollReport = true;

    of(this.store.selectSnapshot(AuthState.authClaims))
      .pipe(
        switchMap((claims) => {
          if (
            !claims ||
            claims.scope !== 'livepoll' ||
            claims.survey !== surveyKey ||
            claims.team !== teamKey ||
            claims.linkKey !== linkKey ||
            claims.sessionKey !== sessionKey
          ) {
            console.log({ claims: JSON.parse(JSON.stringify(claims)), surveyKey, teamKey, linkKey, sessionKey });
            return this.auth
              .getPollToken(surveyKey, teamKey, linkKey, sessionKey)
              .pipe(switchMap((result) => this.auth.signInWithToken(result.token)));
          } else {
            return of(void 0);
          }
        }),
      )
      .subscribe(() => this.initDefaulReport(surveyKey, teamKey, pollData));
  }

  private loadPublicReport(publicKey: string) {
    this.rm.getPublicReport(publicKey).subscribe(
      (report) => this.processReport(report),
      () => {
        this.router.navigate(['/offline']);
      },
      () => {},
    );
  }

  private loadTemplateReport(templateKey: string) {
    this.rm.getPublicReport(templateKey, false).subscribe((report) => this.processReport(report));
  }

  private processReport(report: any) {
    if (report) {
      if (this.cf.baseExists()) {
        this.cf.reset();
        this.cm.reset();
      }

      this.reportInfo = report.info;
      this.isSharedReport = true;
      this.cm.connect(report.charts);
      this.cf.connectPublic(report, this.isSharedReport);
      this.loaded = true;
      this.cdRef.markForCheck();
      this.cdRef.detectChanges();

      if (this.cfSub) {
        this.cfSub.unsubscribe();
      }

      let tracked: boolean = false;
      this.cfSub = this.cf.crossfilter.subscribe((crossfilter) => {
        if (crossfilter && crossfilter.other && crossfilter.other.filtersDemo) {
          this.filtersDemo = true;
        } else {
          this.filtersDemo = false;
        }

        this.filtersApplied = crossfilter && crossfilter.filters && Object.keys(crossfilter.filters).length > 0;
        this.cdRef.detectChanges();

        if (!this.isTemplateReport && !tracked && crossfilter && crossfilter.stats) {
          this.trackReportOpen(
            report.respondents?.answerers?.length || 0,
            report.getAnswers?.survey?.key,
            '',
            report.info?.teamKey,
            'open_shared_public',
            !this.cf.latestTrackSurvey ||
              !this.cf.latestTrackTime ||
              this.cf.latestTrackSurvey !== report.getAnswers?.survey?.key ||
              new Date(this.cf.latestTrackTime).setHours(0, 0, 0, 0) !== new Date().setHours(0, 0, 0, 0),
          );
          tracked = true;
        }
      });
    }
  }

  private loadReport(params) {
    const data = this.route.snapshot.data;

    this.isSharedReport = data.shared || false;

    if (data.survey) {
      this.surveySub = data.survey.subscribe((snapshot) => {
        this.surveyData = snapshot;
      });
    }

    this.store
      .select(SurveyState.activeSurvey())
      .pipe(
        filter((model) => !!model),
        takeUntil(this.hooks.destroy),
      )
      .subscribe((model) => {
        // console.warn(model);

        const surveyRights = model.rights || 0;
        const surveyData: SurveyData = model.survey;

        if (!surveyData) {
          const team = this.store.selectSnapshot(AccountState.team);

          this.dc
            .surveyAccessDeniedDialog(team)
            .afterClosed()
            .subscribe(() => {
              if (this.isSharedReport) {
                window.location.reload();
              } else {
                this.router.navigate(['/surveys']);
              }
            });
        } else {
          this.surveyRights = surveyRights;
          this.sm.setSurveyKeyForManagers(model.survey?.$key || null);
        }
      });

    const surveyKey = params['survey'];
    const reportKey = params['report'] || '';
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const isSharedReport = this.isSharedReport;

    this.rc.update({ surveyKey, reportKey, teamKey, isSharedReport });

    if (reportKey) {
      this.lastOpenReport = null;
      if (this.cf.baseExists()) {
        this.cf.reset();
        this.cm.reset();
      }
      this.rm.getStoredReportData(surveyKey, reportKey).subscribe((report) => {
        this.reportInfo = report && report.info;

        this.cm.connect((report && report.charts) || []);
        // this.cm.connectPinnings(data.pinned, surveyKey, reportKey);
        if (report) {
          this.cf.connect(surveyKey, reportKey, report, this.isSharedReport);
          this.trackReportOpen(
            report.respondents?.answerers?.length || 0,
            surveyKey,
            reportKey,
            teamKey,
            this.isSharedReport ? 'open_shared_private' : 'open',
            !this.cf.latestTrackSurvey ||
              !this.cf.latestTrackTime ||
              this.cf.latestTrackSurvey !== surveyKey ||
              new Date(this.cf.latestTrackTime).setHours(0, 0, 0, 0) !== new Date().setHours(0, 0, 0, 0),
          );
        } else {
          this.cf.initialize(surveyKey);
        }

        this.loaded = true;
        if (
          report &&
          !this.route.snapshot.data.shared &&
          (Rights.hasRights(Rights.ADMIN, this.userRights) || Rights.hasRights(Rights.EDIT, this.surveyRights))
        ) {
          this.rm.saveOpenedAtTime(surveyKey, reportKey);
          this.rs.initAutoSave(this.cf, this.cm, surveyKey, reportKey);
        }

        if (this.cfSub) {
          this.cfSub.unsubscribe();
        }

        this.cfSub = this.cf.crossfilter.subscribe((crossfilter) => {
          this.filtersApplied = crossfilter && crossfilter.filters && Object.keys(crossfilter.filters).length > 0;

          if (crossfilter && crossfilter.other && crossfilter.other.filtersDemo) {
            this.filtersDemo = true;
          } else {
            this.filtersDemo = false;
          }
          this.cdRef.detectChanges();
        });

        this.cdRef.markForCheck();
        this.cdRef.detectChanges();
      });
    } else {
      this.initDefaulReport(surveyKey, teamKey);
    }
  }

  private initDefaulReport(surveyKey?: string, teamKey?: string, pollData?: any) {
    if (this.ib.showOpenLastReportBanner) {
      this.route.data.pipe(takeUntil(this.hooks.destroy)).subscribe((routeData) => {
        if (routeData.reports) {
          const userKey = this.store.selectSnapshot(AccountState.userKey);

          let lastReport: number = 0;
          let lastReportKey: string | null = null;

          routeData.reports.pipe(takeUntil(this.hooks.destroy)).subscribe((reports: ReportData[]) => {
            if (reports !== null) {
              for (let s = 0, len = reports.length; s < len; s++) {
                if (reports[s]['isAuthorUnsavedChanges'] && reports[s]['owner'] === userKey) {
                  this.isAuthorUnsavedChanges = true;
                  lastReportKey = `/surveys/${surveyKey}/analyze/${reports[s]['$key']}`;
                  break;
                } else if (reports[s]['lastOpened'] && reports[s]['lastOpened'][userKey]) {
                  if (reports[s]['lastOpened'][userKey] > lastReport) {
                    lastReport = reports[s]['lastOpened'][userKey];
                    lastReportKey = `/surveys/${surveyKey}/analyze/${reports[s]['$key']}`;
                  }
                }
              }
            }
          });

          this.lastOpenReport = lastReportKey;
        }
      });
    }
    if (this.cf.baseExists()) {
      this.cf.reset();
      this.cm.reset();
    } else {
      this.cm.connect([]);
    }
    this.cf.initialize(surveyKey, teamKey, pollData);

    if (this.cfSub) {
      this.cfSub.unsubscribe();
    }

    let tracked: boolean = false;

    this.cfSub = this.cf.crossfilter.subscribe((crossfilter) => {
      if (crossfilter && crossfilter.stats && crossfilter.stats.sampleData) {
        this.sampleData = true;
        if (
          crossfilter &&
          crossfilter.other &&
          crossfilter.other.newAnswersAvailable &&
          !this.sampleDataRemovalBannerClosed
        ) {
          this.sampleDataRemovalBanner = true;
        } else {
          this.sampleDataBanner = !this.sampleDataBannerClosed;
        }
      } else {
        this.sampleData = false;
        if (
          (Rights.hasRights(Rights.ADMIN, this.userRights) || Rights.hasRights(Rights.EDIT, this.surveyRights)) &&
          crossfilter &&
          crossfilter.stats &&
          !crossfilter.stats.sampleData &&
          !this.rs.preAutoSaveOn &&
          !this.rs.autoSaveOn
        ) {
          this.rs.initPreAutoSave(this.cf, this.cm, surveyKey);
        }
        if (crossfilter && crossfilter.stats && !tracked) {
          this.trackReportOpen(
            crossfilter?.stats?.respondentsAll || 0,
            surveyKey,
            '',
            teamKey,
            'open',
            !this.cf.latestTrackSurvey ||
              !this.cf.latestTrackTime ||
              this.cf.latestTrackSurvey !== surveyKey ||
              new Date(this.cf.latestTrackTime).setHours(0, 0, 0, 0) !== new Date().setHours(0, 0, 0, 0),
          );
          tracked = true;
        }
      }
      if (crossfilter && crossfilter.other && crossfilter.other.filtersDemo) {
        this.filtersDemo = true;
      } else {
        this.filtersDemo = false;
      }
      this.filtersApplied = crossfilter && crossfilter.filters && Object.keys(crossfilter.filters).length > 0;
      this.cdRef.detectChanges();
    });
    this.loaded = true;
    this.cdRef.markForCheck();
    this.cdRef.detectChanges();
  }

  private trackReportOpen(
    respondentCount: number,
    surveyKey: string,
    reportKey: string,
    teamKey: string,
    type: 'open' | 'open_shared_private' | 'open_shared_public' | 'update' | 'update_shared_private',
    initial: boolean,
  ) {
    this.rm.trackReportOpen(respondentCount, surveyKey, reportKey, teamKey, type, initial);

    if (this.cf) {
      this.cf.latestTrackSurvey = surveyKey;
      this.cf.latestTrackTime = new Date().valueOf();
    }
  }

  public closeReport(): void {
    const authScope = this.store.selectSnapshot(AuthState.authScope);

    if (authScope && !this.remember && !this.isPollReport) {
      console.log('Signing out');

      this.store.dispatch(new SignOutWithoutRedirect());
    }
  }

  ngOnDestroy() {
    this.rs.disconnectPreAutoSave();

    if (this.surveySub) {
      this.surveySub.unsubscribe();
    }

    if (this.amSub) {
      this.amSub.unsubscribe();
    }

    if (this.cfSub) {
      this.cfSub.unsubscribe();
    }

    if (this.touchDeviceSub) {
      this.touchDeviceSub.unsubscribe();
    }

    this.loaded = false;
    this.lastOpenReport = null;

    this.cf.reset();
    this.cm.reset();
  }

  saveRequired() {
    return new Observable<boolean>((observer) => {
      if (this.rs.autoSaveOn) {
        this.lastOpenReport = null;
        this.rs.disconnectAutoSave();
        this.rs.saveReady.subscribe((ready) => {
          if (ready) {
            observer.next(true);
            observer.complete();
          }
        });
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }

  public onViewResize($event) {
    this.width = $event.dimensions.width;
    this.scrollState = [
      0,
      $event.dimensions.height,
      this._element.nativeElement.getBoundingClientRect().top +
        (this.isSharedReport && !this.isTemplateReport ? 80 : 0),
      this.sb.viewport.scrollTop,
    ];
    this.cdRef.detectChanges();
  }

  public scroll($event) {
    this.scrollState = [
      0,
      $event.target.offsetHeight,
      this._element.nativeElement.getBoundingClientRect().top +
        (this.isSharedReport && !this.isTemplateReport ? 80 : 0),
      this.sb.viewport.scrollTop,
    ];
    this.cdRef.detectChanges();
  }

  public scrollTo($event) {
    this.sb.scrollTo({ top: $event, duration: 40 });
  }

  public openLastReport() {
    if (this.lastOpenReport) {
      this.router.navigate([this.lastOpenReport]);
    }
  }

  public closeLastReportBanner() {
    this.showLastReportBanner = false;

    if (this.isAuthorUnsavedChanges) {
      this.rm.clearUnsavedChangesReports(this.surveyData['$key']);
    }
  }

  public closeSampleDataBanner() {
    this.sampleDataBanner = false;
    this.sampleDataBannerClosed = true;
  }

  public closeSampleDataRemovalBanner() {
    this.sampleDataRemovalBanner = false;
    this.sampleDataRemovalBannerClosed = true;
    this.sampleDataBannerClosed = true;
  }

  public goToShareView() {
    if (!this.isPollReport) {
      const url = `/surveys/${this.surveyData['$key']}/share/`;
      this.router.navigate([url]);
    }
  }

  public showRealResponses() {
    this.sampleDataRemovalBanner = false;
    this.sampleData = false;
    this.cf.changeSampleDataToRealOne();
    if (!this.rs.autoSaveOn && !this.rs.preAutoSaveOn) {
      this.rs.initPreAutoSave(this.cf, this.cm, this.surveyData['$key']);
    }
  }
}
