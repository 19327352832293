export function drawContactIcon(
  context: CanvasRenderingContext2D,
  size: number,
  x: number,
  y: number,
  color: string,
): void {
  context.save();
  context.translate(x, y - size / 2);
  context.scale(size / 1024, -size / 1024);
  context.translate(0, -1024 / 1.25);
  context.lineWidth = size * 5;
  context.strokeStyle = color;

  context.beginPath();
  context.moveTo(511.877, 704);
  context.bezierCurveTo(391.64300000000003, 704, 294.648, 603.46, 294.648, 480);
  context.bezierCurveTo(
    294.648,
    431.66700000000003,
    318.994,
    380.98699999999997,
    365.90700000000004,
    327.18899999999996,
  );
  context.lineTo(373.50200000000007, 318.669);
  context.lineTo(361.2990000000001, 312.534);
  context.bezierCurveTo(303.2170000000001, 282.793, 258.6460000000001, 252.207, 227.3170000000001, 220.216);
  context.lineTo(218.24300000000008, 210.75);
  context.lineTo(213.63100000000009, 205.47);
  context.bezierCurveTo(183.10500000000008, 166.987, 185.18200000000007, 112.503, 218.00500000000008, 77.501);
  context.bezierCurveTo(305.89200000000005, -16.21799999999999, 404.2750000000001, -64, 511.87700000000007, -64);
  context.bezierCurveTo(615.6690000000001, -64, 710.9370000000001, -19.524, 796.4370000000001, 67.78299999999999);
  context.lineTo(805.5110000000001, 77.24899999999998);
  context.lineTo(810.123, 82.52899999999998);
  context.bezierCurveTo(840.649, 121.01199999999997, 838.572, 175.49599999999998, 805.749, 210.498);
  context.bezierCurveTo(772.717, 245.721, 725.311, 279.238, 663.494, 311.49);
  context.lineTo(650.027, 318.382);
  context.lineTo(657.9100000000001, 327.228);
  context.bezierCurveTo(704.8270000000001, 381.15, 729.1070000000001, 431.749, 729.1070000000001, 479.999);
  context.bezierCurveTo(729.1070000000001, 603.4590000000001, 632.113, 703.999, 511.87800000000004, 703.999);
  context.closePath();

  context.clip();
  context.lineWidth *= 2;
  context.stroke();

  context.restore();
}

export function drawIntelligenceIcon(context: CanvasRenderingContext2D, size: number, x: number, y: number): void {
  context.save();
  context.translate(x, y);

  /* eslint-disable max-len */
  const path: string =
    'M28 22.5V23C28 24.1046 27.1046 25 26 25C24.8954 25 24 24.1046 24 23V12C24 8.68629 21.3137 6 18 6C14.6863 6 12 8.68629 12 12C12 12.004 12 12.0081 12 12.0121M13 25H6V20.5C6 16.5813 8.50443 13.2476 12 12.0121M13 25H20V18H18C14.6903 18 12.0066 15.3203 12 12.0121M13 25V22';
  /* eslint-enable max-len */

  const p = new Path2D(
    path
      .split(/([a-zA-Z ]+)/u)
      .map((s) => (!isNaN(Number(s)) && !/^\s*$/.test(s) ? (size / 32) * Number(s) : s))
      .join(''),
  );

  context.strokeStyle = '#ef5d65';
  context.lineWidth = 2 * (size / 32);
  context.stroke(p);

  context.fillStyle = '#ef5d65';
  context.beginPath();
  context.arc((size / 32) * 18, (size / 32) * 12, 1 * (size / 32), 0, 2 * Math.PI);
  context.closePath();
  context.fill();

  context.restore();
}

export function drawConsensusIcon(
  context: CanvasRenderingContext2D,
  size: number,
  x: number,
  y: number,
  color: string,
): void {
  context.save();
  context.translate(x + size * 0.0625, y - size / 2);
  context.beginPath();
  context.strokeStyle = color;
  const s = size * 0.875;
  context.lineWidth = 0.0725 * size;

  context.arc(s / 2, s / 2, s / 2.5, -0.5 * Math.PI, 1.5 * Math.PI);
  context.stroke();

  context.lineTo(s / 2, s);
  context.moveTo(s / 2, s / 2);
  context.lineTo(0, s);
  context.moveTo(s / 2, s / 2);
  context.lineTo(s, s);
  context.clip();
  context.stroke();
  context.restore();
}

export function drawRoundRect(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: { tl: number; tr: number; bl: number; br: number } | number,
  fill: boolean,
  stroke: boolean,
) {
  const smallestRadius = Math.min(width / 2, height / 2);

  if (typeof stroke === 'undefined') {
    stroke = false;
  }
  if (typeof radius === 'undefined') {
    radius = smallestRadius > 5 ? 5 : smallestRadius;
  }
  if (typeof radius === 'number') {
    radius = {
      tl: smallestRadius > radius ? radius : smallestRadius,
      tr: smallestRadius > radius ? radius : smallestRadius,
      br: smallestRadius > radius ? radius : smallestRadius,
      bl: smallestRadius > radius ? radius : smallestRadius,
    };
  } else {
    const defaultRadius = { tl: 0, tr: 0, br: 0, bl: 0 };
    for (const side in defaultRadius) {
      radius[side] = (smallestRadius > radius[side] ? radius[side] : smallestRadius) || defaultRadius[side];
    }
  }
  if (width !== 0 && height !== 0) {
    ctx.beginPath();
    ctx.moveTo(x + radius.tl, y);
    ctx.lineTo(x + width - radius.tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
    ctx.lineTo(x + width, y + height - radius.br);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
    ctx.lineTo(x + radius.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
    ctx.lineTo(x, y + radius.tl);
    ctx.quadraticCurveTo(x, y, x + radius.tl, y);
    ctx.closePath();

    if (fill) {
      ctx.fill();
    }
    if (stroke) {
      ctx.stroke();
    }
  } else if (stroke && ((height !== 0 && width === 0) || (height === 0 && width !== 0))) {
    ctx.strokeRect(x, y, width, height);
  }
}

export function drawChartLegends(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  fontSize: number,
  fillColor: string,
  textColor: string,
  label: string,
  respondents: string,
  center?: boolean,
) {
  ctx.font = 10 + 'px Inter';

  const wrap = (context, text = '', w, padding) => {
    const textLength = context.measureText(text).width;

    if (textLength > w - 2 * padding && text && text.length > 0) {
      const availableSpace = (w - 2 * padding) / (textLength / text.length);
      const firstPartClip = Math.floor(availableSpace / 2 - text.length - 1);
      const secondPartClip = -Math.floor(availableSpace / 2 - 1);
      const firstPart = text.slice(0, firstPartClip);
      const secondPart = secondPartClip < 0 ? text.slice(secondPartClip) : '';

      return firstPart + ' ... ' + secondPart;
    } else {
      return text;
    }
  };
  const wNumber = respondents ? ctx.measureText(respondents || '0').width + 8 : 0;
  const spaceForText = width - 28 - 16 - (12 + 4) * (respondents ? 1 : 0) - wNumber;
  const wText = (label && ctx.measureText(label).width) || 0;
  const middleY = y + height / 2;
  let currentX = center ? x - (wText < spaceForText ? 20 + wText : spaceForText) / 2 : x + 8;

  ctx.beginPath();
  ctx.arc(currentX, middleY, 4, 0, 2 * Math.PI);
  ctx.closePath();
  ctx.fillStyle = fillColor;
  ctx.fill();
  currentX += 12;

  ctx.textBaseline = 'middle';
  ctx.fillStyle = textColor;
  ctx.fillText(wText > spaceForText ? wrap(ctx, label, spaceForText, 4) : label, currentX, middleY);
  currentX += Math.min(wText, spaceForText) + 16;

  if (respondents) {
    drawContactIcon(ctx, 12, currentX, middleY, textColor);
    currentX += 12 + 4;

    ctx.font = 10 + 'px Inter';
    ctx.fillText(respondents, currentX, middleY);
    currentX += wNumber + 8;
  }
}

export function shortenText(context, text = '', width, padding): string {
  const textLength = context.measureText(text).width;

  if (textLength > width - 2 * padding && text && text.length > 0) {
    const availableSpace = (width - 2 * padding) / (textLength / text.length);
    const firstPartClip = Math.floor(availableSpace / 2 - text.length - 1);
    const secondPartClip = -Math.floor(availableSpace / 2 - 1);
    const firstPart = text.slice(0, firstPartClip);
    const secondPart = secondPartClip < 0 ? text.slice(secondPartClip) : '';

    return firstPart + ' ... ' + secondPart;
  } else {
    return text;
  }
}
