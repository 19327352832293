import * as d3 from 'd3';

import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

/**
 * This is a Z-scoring tutorial.
 */
@Directive({
  selector: '[Standardize1DTutorial]',
})
export class Standardize1DTutorial implements OnChanges {
  @Input() show: boolean = false;

  private data: any;

  private width: number = 0;
  private height: number = 0;
  private margin: any = { top: 40, right: 40, bottom: 40, left: 40 };
  private sliderHeight: number = 0;

  private base: any;

  private axis: any;
  private enterAxis: any;

  private currentValueX: string = '';

  private enterAverageLine: any;
  private averageLine: any;

  private slider: any;
  private ball: any;
  private distance: any;

  private transition: any;
  private transitionDuration: number = 2000;

  private first: any;
  private second: any;
  private third: any;
  private fourth: any;

  constructor(private _element: ElementRef) {
    this.base = d3.select(this._element.nativeElement).append('svg').attr('height', '300px');
  }

  ngOnChanges() {
    this.data = [
      { id: 1, x0: 191, x1: 168 },
      { id: 2, x0: 147, x1: 28 },
      { id: 3, x0: 180, x1: 134 },
    ];
    if (this.show) {
      this.transitionDuration = 2000;
      this.setEnvironment();
      this.setTransitions();
      this.setDataAxis();
      this.setData();
    } else {
      clearTimeout(this.first);
      clearTimeout(this.second);
      clearTimeout(this.third);
      clearTimeout(this.fourth);
      this.transitionDuration = 0;
      this.data = [];
      this.setDataSlider();
      this.setDataAverages();
      this.setDataDistance();
      this.setDataBall();
    }
  }

  setData() {
    this.data = [
      { id: 1, x0: 191, x1: 168 },
      { id: 2, x0: 147, x1: 28 },
      { id: 3, x0: 180, x1: 134 },
    ];
    this.currentValueX = 'x0';
    this.setDataSlider();
    this.setDataAverages();
    this.setDataDistance();
    this.setDataBall();

    this.first = setTimeout(() => {
      if (this.show) {
        this.currentValueX = 'x1';
        this.setDataSlider();
        this.setDataAverages();
        this.setDataDistance();
        this.setDataBall();

        this.second = setTimeout(() => {
          if (this.show) {
            this.data = [
              { id: 4, x0: 91, x1: 168 },
              { id: 5, x0: 47, x1: 28 },
              { id: 6, x0: 80, x1: 134 },
            ];
            this.currentValueX = 'x0';
            this.setDataSlider();
            this.setDataAverages();
            this.setDataDistance();
            this.setDataBall();

            this.third = setTimeout(() => {
              if (this.show) {
                this.currentValueX = 'x1';
                this.setDataSlider();
                this.setDataAverages();
                this.setDataDistance();
                this.setDataBall();

                this.fourth = setTimeout(() => {
                  if (this.show) {
                    this.setData();
                  }
                }, 4000);
              }
            }, 4000);
          }
        }, 4000);
      }
    }, 4000);
  }

  setEnvironment() {
    this.width = 300 - this.margin.left - this.margin.right;
    this.height = 300 - this.margin.top - this.margin.bottom;
    this.sliderHeight = this.height / (this.data.length + 1);
  }

  setTransitions() {
    this.transition = d3.transition().duration(this.transitionDuration);
  }

  setDataAxis() {
    // JOIN new data
    this.axis = this.base.selectAll('.summary1d-axis').data([this.data]);

    // Exit old elements not present in new data
    this.axis.exit().transition(this.transition).remove();

    // Update
    this.axis
      .select('.summary1d-line.axis.horizontal')
      .transition(this.transition)
      .attr('transform', () => `translate(0,${this.data.length * this.sliderHeight})`)
      .attr('x1', 0)
      .attr('x2', this.width)
      .attr('y1', this.sliderHeight / 4)
      .attr('y2', this.sliderHeight / 4);

    this.axis
      .select('.summary1d-line.axis.vertical')
      .transition(this.transition)
      .attr('x1', this.width / 2)
      .attr('x2', this.width / 2)
      .attr('y1', () => this.data.length * this.sliderHeight + this.sliderHeight / 4)
      .attr('y2', 0);

    this.axis
      .select('.summary1d-middle-dot')
      .transition(this.transition)
      .attr('transform', () => `translate(0,${this.data.length * this.sliderHeight})`)
      .attr('cx', this.width / 2)
      .attr('cy', this.sliderHeight / 4)
      .attr('r', this.sliderHeight / 15);

    // Enter new elements
    this.enterAxis = this.axis
      .enter()
      .append('g')
      .attr('class', 'summary1d-axis')
      .attr('transform', () => `translate(${this.margin.left},${this.margin.top})`);

    this.enterAxis
      .append('line')
      .attr('class', 'summary1d-line axis horizontal')
      .style('stroke', '#e1e8ea')
      .attr('transform', () => `translate(0,${this.data.length * this.sliderHeight})`)
      .attr('x1', 0)
      .attr('x2', this.width)
      .attr('y1', this.sliderHeight / 4)
      .attr('y2', this.sliderHeight / 4);

    this.enterAxis
      .append('line')
      .attr('class', 'summary1d-line axis vertical')
      .style('stroke', '#e1e8ea')
      .attr('x1', this.width / 2)
      .attr('x2', this.width / 2)
      .attr('y1', () => this.data.length * this.sliderHeight + this.sliderHeight / 4)
      .attr('y2', 0);

    this.enterAxis
      .append('circle')
      .attr('class', 'summary1d-middle-dot')
      .style('fill', 'white')
      .style('stroke', '#e1e8ea')
      .style('stroke-width', '1px')
      .attr('transform', () => `translate(0,${this.data.length * this.sliderHeight})`)
      .attr('cx', this.width / 2)
      .attr('cy', this.sliderHeight / 4)
      .attr('r', this.sliderHeight / 15);
  }

  setDataSlider() {
    this.base.selectAll('.summary1d-slider').interrupt();

    // JOIN new data
    this.slider = this.base.selectAll('.summary1d-slider').data(this.data, (d) => d.id);

    // Exit old elemets not present in new data
    this.slider.exit().remove();

    // Update
    this.slider.attr(
      'transform',
      (d, i) => `translate(${this.margin.left},${i * this.sliderHeight + this.margin.top})`,
    );

    this.slider
      .select('.summary1d-line')
      .attr('x1', 0)
      .attr('x2', this.width)
      .attr('y1', this.sliderHeight / 1.5)
      .attr('y2', this.sliderHeight / 1.5);

    // Enter new elements
    this.slider
      .enter()
      .append('g')
      .attr('class', 'summary1d-slider')
      .attr('transform', (d, i) => `translate(${this.margin.left},${i * this.sliderHeight + this.margin.top})`)
      .append('line')
      .attr('class', 'summary1d-line')
      .style('stroke', '#e1e8ea')
      .attr('x1', 0)
      .attr('x2', this.width)
      .attr('y1', this.sliderHeight / 1.5)
      .attr('y2', this.sliderHeight / 1.5);
  }

  setDataDistance() {
    this.base.selectAll('.summary1d-distance').interrupt();

    // JOIN new data
    this.distance = this.base
      .selectAll('.summary1d-slider')
      .selectAll('.summary1d-distance')
      .data((d) => [d]);

    // Exit old elemets not present in new data
    this.distance.exit().transition(this.transition).remove();

    // Update
    this.distance
      .transition(this.transition)
      .style('stroke', '#a4b0b9')
      .style('stroke-dasharray', '5, 10')
      .style('stroke-width', '3px')
      .attr('x1', (d) => d[this.currentValueX])
      .attr('y1', this.sliderHeight / 1.5)
      .attr('x2', () => d3.mean(this.data, (c) => c[this.currentValueX]))
      .attr('y2', this.sliderHeight / 1.5);

    // Enter new elements
    this.distance
      .enter()
      .append('line')
      .attr('class', 'summary1d-distance')
      .style('stroke', '#a4b0b9')
      .style('stroke-dasharray', '5, 10')
      .style('stroke-width', '3px')
      .attr('x1', (d) => d[this.currentValueX])
      .attr('y1', this.sliderHeight / 1.5)
      .attr('x2', () => d3.mean(this.data, (c) => c[this.currentValueX]))
      .attr('y2', this.sliderHeight / 1.5);
  }

  setDataBall() {
    this.base.selectAll('.summary1d-answer').interrupt();

    // JOIN new data
    this.ball = this.base
      .selectAll('.summary1d-slider')
      .selectAll('.summary1d-answer')
      .data((d) => [d]);

    // Exit old elemets not present in new data
    this.ball.exit().transition(this.transition).attr('r', 0).remove();

    // Update
    this.ball
      .transition(this.transition)
      .style('fill', '#a4b0b9')
      .attr('cx', (d) => d[this.currentValueX])
      .attr('cy', this.sliderHeight / 1.5)
      .attr('r', 12.5);

    // Enter new elements
    this.ball
      .enter()
      .append('circle')
      .attr('class', 'summary1d-answer')
      .style('fill', '#a4b0b9')
      .attr('cx', (d) => d[this.currentValueX])
      .attr('cy', this.sliderHeight / 1.5)
      .attr('r', 0)
      .transition(this.transition)
      .attr('r', 12.5);
  }

  setDataAverages() {
    this.base.selectAll('.summary1d-average').interrupt();

    // JOIN new data
    this.averageLine = this.base.selectAll('.summary1d-average').data(this.data.length > 0 ? [this.data] : []);

    // Exit old elemets not present in new data
    this.averageLine.exit().transition(this.transition).remove();

    // Update
    this.averageLine
      .select('.summary1d-average-ball')
      .transition(this.transition)
      .style('fill', 'red')
      .attr('cx', (d) => d3.mean(d, (c) => c[this.currentValueX]))
      .attr('cy', this.data.length * this.sliderHeight + this.sliderHeight / 4)
      .attr('r', 5);

    this.averageLine
      .select('.summary1d-average-line')
      .transition(this.transition)
      .attr('x1', (d) => d3.mean(d, (c) => c[this.currentValueX]))
      .attr('x2', (d) => d3.mean(d, (c) => c[this.currentValueX]))
      .attr('y1', () => this.data.length * this.sliderHeight + this.sliderHeight / 4)
      .attr('y2', 0);

    // Enter new elements
    this.enterAverageLine = this.averageLine
      .enter()
      .append('g')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`)
      .attr('class', 'summary1d-average');

    this.enterAverageLine
      .append('circle')
      .attr('class', 'summary1d-average-ball')
      .style('fill', 'red')
      .attr('cx', (d) => d3.mean(d, (c) => c[this.currentValueX]))
      .attr('cy', this.data.length * this.sliderHeight + this.sliderHeight / 4)
      .attr('r', 0)
      .transition(this.transition)
      .attr('r', 5);

    this.enterAverageLine
      .append('line')
      .attr('class', 'summary1d-average-line')
      .style('stroke', 'red')
      .attr('x1', (d) => d3.mean(d, (c) => c[this.currentValueX]))
      .attr('x2', (d) => d3.mean(d, (c) => c[this.currentValueX]))
      .attr('y1', () => this.data.length * this.sliderHeight + this.sliderHeight / 4)
      .attr('y2', 0);
  }
}
