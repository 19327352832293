import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';
import { LindaModule } from '@shared/modules/linda.module';
import { CdkTableModule } from '@angular/cdk/table';

/* COMPONENTS */
import { Chart } from '@report/+charts/chart-card/chart/chart.component';

import { BarChartTable } from '@report/+charts/chart-card/chart/bar-chart-table/bar-chart-table.component';
import { InterviewerSummary } from '@report/+charts/chart-card/chart/interviewer-summary/interviewer-summary.component';
import { NPSChart } from '@report/+charts/chart-card/chart/nps-chart/nps-chart.component';
import { ResponseRates } from '@report/+charts/chart-card/chart/response-rates/response-rates.component';
import { TextSentimentAnalysis } from '@report/+charts/chart-card/chart/text-sentiment-analysis/text-sentiment-analysis.component';
import { TextEmotions } from '@report/+charts/chart-card/chart/text-emotions/text-emotions.component';
import { TextAnswerTable } from '@report/+charts/chart-card/chart/text-answer-table/text-answer-table.component';
import { WhyFinderSummary } from '@report/+charts/chart-card/chart/why-finder-summary/why-finder-summary.component';
import { WordAnalysis } from '@report/+charts/chart-card/chart/word-analysis/word-analysis.component';
import { Summary2D } from '@report/+charts/chart-card/chart/summary-charts/summary-2d.component';
import { Summary1D } from '@report/+charts/chart-card/chart/summary-charts/summary-1d.component';
import { SummaryChoice } from '@report/+charts/chart-card/chart/summary-charts/summary-choice.component';

/* DIRECTIVES */
import { ChartDataTable } from '@report/+charts/chart-card/chart/chart-types/chart-data-table.directive';
import { NPSGauge } from '@report/+charts/chart-card/chart/chart-types/nps-gauge.directive';
import { NPSBarChart } from '@report/+charts/chart-card/chart/chart-types/nps-bar-chart.directive';
import { RadarChart } from '@report/+charts/chart-card/chart/chart-types/radar-chart.directive';
import { Heatmap } from '@report/+charts/chart-card/chart/chart-types/heatmap.directive';
import { PieChart } from '@report/+charts/chart-card/chart/chart-types/pie-chart.directive';
import { LineChart } from '@report/+charts/chart-card/chart/chart-types/line-chart.directive';
import { AreaChart } from '@report/+charts/chart-card/chart/chart-types/area-chart.directive';
import { BarChartH } from '@report/+charts/chart-card/chart/chart-types/bar-chart-h.directive';
import { BarChartV } from '@report/+charts/chart-card/chart/chart-types/bar-chart-v.directive';
import { BubbleChart } from '@report/+charts/chart-card/chart/chart-types/bubble-chart.directive';
import { Summary1DChart } from '@report/+charts/chart-card/chart/chart-types/summary-1d.directive';
import { Summary2DChart } from '@report/+charts/chart-card/chart/chart-types/summary-2d.directive';
import { TopicBubbleChart } from '@report/+charts/chart-card/chart/chart-types/topic-bubble.directive';
import { DonutChart } from '@report/+charts/chart-card/chart/chart-types/donut-chart.directive';
import { MultiDonutChart } from '@report/+charts/chart-card/chart/chart-types/multi-donut-chart.directive';
import { MultiBarChartH } from '@report/+charts/chart-card/chart/chart-types/multi-bar-chart-h.directive';
import { MultiBarChartV } from '@report/+charts/chart-card/chart/chart-types/multi-bar-chart-v.directive';
import { MultiLineChart } from '@report/+charts/chart-card/chart/chart-types/multi-line-chart.directive';
import { StackedPieChart } from '@report/+charts/chart-card/chart/chart-types/stacked-pie-chart.directive';
import { SingleBarChartH } from '@report/+charts/chart-card/chart/chart-types/single-bar-chart-h.directive';
import { SingleGauge } from '@report/+charts/chart-card/chart/chart-types/single-gauge.directive';
import { StackedBarChartH } from '@report/+charts/chart-card/chart/chart-types/stacked-bar-chart-h.directive';
import { StackedBarChartV } from '@report/+charts/chart-card/chart/chart-types/stacked-bar-chart-v.directive';
import { WordCloud } from '@report/+charts/chart-card/chart/chart-types/word-cloud.directive';
import { TimelineChart } from '@report/+charts/chart-card/chart/chart-types/timeline-chart.directive';
import { TrendCategoricalChart } from '@report/+charts/chart-card/chart/chart-types/trend-categorical-chart.directive';
import { TrendNPSChart } from '@report/+charts/chart-card/chart/chart-types/trend-nps-chart.directive';
import { TrendLinear1DChart } from '@report/+charts/chart-card/chart/chart-types/trend-linear-1d-chart.directive';
import { TrendLinear2DChart } from '@report/+charts/chart-card/chart/chart-types/trend-linear-2d-chart.directive';
import { ScaleX } from '@report/+charts/chart-card/chart/chart-types/scale-x.directive';
import { FunnelChartH } from '@report/+charts/chart-card/chart/chart-types/funnel-chart-h.directive';

import { Standardize2DTutorial } from '@report/shared/directives/standardize-2d-tutorial.directive';
import { Standardize1DTutorial } from '@report/shared/directives/standardize-1d-tutorial.directive';

/* SERVICES */
import { Crossfilter } from '@report/shared/services/crossfilter.service';
import { DataGenerator } from '@report/shared/services/data-generator.service';
import { DataPreparation } from '@report/shared/services/data-preparation.service';
import { FilterConverter } from '@report/shared/services/filter-converter.service';
import { WordCounter } from '@report/shared/services/word-counter.service';

/* UPDATING REPORTS */
import { AnonymityChecker } from '@report/shared/services/anonymity-checker.service';
import { ChartsManager } from '@report/shared/services/charts-manager.service';
import { DataConverter } from '@report/shared/services/data-converter.service';
import { ReportUpdate } from '@report/shared/services/report-update.service';

@NgModule({
  bootstrap: [],
  declarations: [
    Chart,

    BarChartH,
    BarChartV,
    BubbleChart,
    ChartDataTable,
    BarChartTable,
    InterviewerSummary,
    NPSChart,
    ResponseRates,
    TextSentimentAnalysis,
    TextEmotions,
    WhyFinderSummary,
    WordAnalysis,
    TextAnswerTable,
    PieChart,
    DonutChart,
    MultiDonutChart,
    RadarChart,
    NPSGauge,
    NPSBarChart,
    Heatmap,
    MultiBarChartH,
    MultiBarChartV,
    MultiLineChart,
    SingleBarChartH,
    SingleGauge,
    StackedBarChartH,
    StackedBarChartV,
    StackedPieChart,
    LineChart,
    AreaChart,
    Summary1D,
    Summary1DChart,
    Summary2D,
    Summary2DChart,
    SummaryChoice,
    TopicBubbleChart,
    WordCloud,
    TimelineChart,
    TrendCategoricalChart,
    TrendNPSChart,
    TrendLinear1DChart,
    TrendLinear2DChart,
    ScaleX,
    FunnelChartH,

    Standardize2DTutorial,
    Standardize1DTutorial,
  ],
  exports: [
    Chart,

    BarChartH,
    BarChartV,
    BubbleChart,
    ChartDataTable,
    BarChartTable,
    InterviewerSummary,
    NPSChart,
    TextSentimentAnalysis,
    TextEmotions,
    WhyFinderSummary,
    WordAnalysis,
    ResponseRates,
    TextAnswerTable,
    PieChart,
    DonutChart,
    MultiDonutChart,
    NPSGauge,
    NPSBarChart,
    Heatmap,
    MultiBarChartH,
    MultiBarChartV,
    MultiLineChart,
    SingleBarChartH,
    SingleGauge,
    StackedBarChartH,
    StackedBarChartV,
    StackedPieChart,
    LineChart,
    AreaChart,
    Summary1D,
    Summary1DChart,
    Summary2D,
    Summary2DChart,
    SummaryChoice,
    TopicBubbleChart,
    WordCloud,
    TimelineChart,
    TrendCategoricalChart,
    TrendNPSChart,
    TrendLinear1DChart,
    TrendLinear2DChart,
    ScaleX,
    FunnelChartH,

    Standardize2DTutorial,
    Standardize1DTutorial,
  ],
  imports: [SharedModule, LindaModule, CdkTableModule],
  providers: [
    AnonymityChecker,
    Crossfilter,
    DataGenerator,
    DataPreparation,
    FilterConverter,
    WordCounter,

    ReportUpdate,
    ChartsManager,
    DataConverter,
  ],
})
export class ChartModule {}
