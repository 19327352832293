<div class="z-fx-col z-fx-auto z-fx-start-start notes-card-container">
  <div class="z-fx z-fx-gap-8 notes-card-header">
    <button
      *ngIf="!fullscreen && !mobileView"
      color="secondary"
      mat-icon-button
      class="zef-icon-button"
      (click)="toggleFullScreen()"
      [matTooltip]="tooltip1"
    >
      <mat-icon>fullscreen</mat-icon>
    </button>
    <button
      *ngIf="fullscreen"
      color="secondary"
      mat-icon-button
      class="zef-icon-button"
      (click)="toggleFullScreen()"
      [matTooltip]="tooltip2"
    >
      <mat-icon>fullscreen_exit</mat-icon>
    </button>
    <chart-menu
      *ngIf="!isSharedReport"
      [isSubheader]="true"
      [editRights]="Rights.hasRights(Rights.EDIT, surveyRights) || Rights.hasRights(Rights.ADMIN, userRights)"
      [isCustomCard]="settings.isCustomCard"
      [isSharedReport]="isSharedReport"
      (hideChart)="hideChart($event)"
    ></chart-menu>
  </div>
  <div
    class="notes-card-content"
    [innerHtml]="(data?.content != null ? data.content : '') | safeHtml"
    (elementResize)="onResize($event)"
  ></div>
</div>
