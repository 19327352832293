import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { Questions } from '@shared/enums/questions.enum';
import { rotateAnimation } from '@shared/animations/rotate.anim';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';

import { Colors } from '@report/shared/enums/colors.enum';
import {
  ChartDistribution,
  ChartDomain,
  ChartSettings,
  ChartStats,
  DimensionDataItem,
  SummaryChartData,
  SummaryChartDataChild,
} from '@shared/models/report.model';

import { AnonymityChecker } from '@report/shared/services/anonymity-checker.service';
import { Crossfilter } from '@report/shared/services/crossfilter.service';

import * as d3 from 'd3';
import { assertArray } from '@shared/utilities/array.utilities';

/**
 * This is a Summary 2d chart component.
 */
/* eslint-disable @angular-eslint/component-selector */
@Component({
  selector: 'summary-2d',
  templateUrl: './summary-2d.component.html',
  styleUrls: ['./summary-2d.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    rotateAnimation,
    trigger('expand', [
      state('void', style({ width: '0' })),
      state('*', style({ width: '*' })),
      transition(':enter, :leave', animate('200ms ease-in-out')),
    ]),
    trigger('parent', [transition(':enter', [])]),
  ],
})
export class Summary2D implements OnChanges, OnInit {
  @Input() distribution: ChartDistribution[] = [];
  @Input() stats: ChartStats[] = [];
  @Input() domain: ChartDomain[] = [];
  @Input() details: DimensionDataItem[] = [];
  @Input() filterInput: any;
  @Input() zvalues: boolean = false;
  @Input() showAverages: boolean = false;
  @Input() showZoomText: boolean = false;
  @Input() transitionDuration: number = 0;
  @Input() update: Date = new Date();
  @Input() comparison: any;
  @Input() comparisonMode: string = 'grouped';
  @Input() selectionExists: boolean = false;
  @Input() filtersDemo: boolean = false;
  @Input() anonymityTreshold: number = null;

  @Input() chartSettings: ChartSettings = {} as ChartSettings;

  @Output() settingsChange: EventEmitter<any> = new EventEmitter<any>();

  public updateQuestionList$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public searchTerms: { [s: string]: string } = {};
  public sortKeys: { [s: string]: string } = {};
  public sortDirections: { [s: string]: string } = {};
  public selectedQuestions: { [s: string]: string[] } = {};
  public hoveredFromChart: { [s: string]: string[] } = {};
  public showGroupAverages: { [s: string]: boolean } = {};
  public questionGroups: { [s: string]: { [s: string]: SummaryChartDataChild[] } } = {};
  public questionCount: { [s: string]: number } = {};

  public chartData: SummaryChartData[] = [];
  public highlighted: string[] = [];
  public size: number = 0;
  public flexMode: string = 'row';

  private margin: any = { top: 50, right: 50, bottom: 50, left: 50 };
  private width: number = 0;
  private height: number = 0;
  private fontSize: number = 0;
  private newSize: number = 0;

  private axisGroups: any;

  @HostListener('window:resize') resize() {
    this.updateChart();
  }

  constructor(
    private ac: AnonymityChecker,
    private _element: ElementRef,
    private cdRef: ChangeDetectorRef,
    private cf: Crossfilter,
    readonly hooks: LifecycleHooks,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.chartSettings && changes.chartSettings.firstChange && this.chartSettings?.summaryChartQuestionList) {
      this.sortKeys = this.chartSettings.summaryChartQuestionList.sortKeys;
      this.sortDirections = this.chartSettings.summaryChartQuestionList.sortDirections;
      this.selectedQuestions = this.chartSettings.summaryChartQuestionList.selectedQuestions;
      this.showGroupAverages = this.chartSettings.summaryChartQuestionList.showGroupAverages;
    }

    if (
      changes.distribution ||
      changes.stats ||
      changes.domain ||
      changes.details ||
      changes.filterInput ||
      changes.zvalues ||
      changes.update ||
      changes.comparison ||
      changes.comparisonMode ||
      changes.filtersDemo
    ) {
      this.updateChart();
    }
  }

  ngOnInit() {
    this.updateQuestionList$.pipe(takeUntil(this.hooks.destroy)).subscribe(() => {
      const rawArray = this.chartData.slice();

      for (const chart of rawArray) {
        if (!this.sortKeys[chart.id]) {
          this.sortKeys[chart.id] = 'survey';
        }
        if (!this.sortDirections[chart.id]) {
          this.sortDirections[chart.id] = this.sortKeys[chart.id] === 'survey' ? 'asc' : 'desc';
        }
        if (!this.hoveredFromChart[chart.id]) {
          this.hoveredFromChart[chart.id] = [];
        }

        const sortKey = this.sortKeys[chart.id];
        const sortVal =
          sortKey === 'average'
            ? this.zvalues
              ? 'zAverage'
              : 'average'
            : sortKey === 'averageY'
              ? this.zvalues
                ? 'zAverageY'
                : 'averageY'
              : '';
        const sortDirection = this.sortDirections[chart.id];
        const searchTerm = this.searchTerms[chart.id];
        const hoveredFromChart = this.hoveredFromChart[chart.id];
        const selectedQuestions =
          this.selectedQuestions[chart.id] && this.selectedQuestions[chart.id].slice().reverse();
        const rawList = chart.children.slice();

        let filteredList: SummaryChartDataChild[] =
          hoveredFromChart.length === 0 ? rawList : rawList.filter((item) => hoveredFromChart.indexOf(item.key) >= 0);

        if (hoveredFromChart.length === 0 && searchTerm) {
          const filteredResults = filteredList.filter((col) =>
            col.title.toLowerCase().includes(searchTerm.toLowerCase()),
          );
          filteredList = filteredResults;
        }

        if (this.showGroupAverages[chart.id]) {
          filteredList = filteredList.filter((item) => !item.questionGroup || item.isGroupAverage);
        }

        if (
          !this.showGroupAverages[chart.id] &&
          this.comparisonMode !== 'joined' &&
          (this.sortKeys[chart.id] !== 'survey' || this.sortDirections[chart.id] !== 'asc')
        ) {
          filteredList = filteredList.filter((item) => !item.isGroupAverage);
        }

        let sortedList =
          this.comparisonMode !== 'joined'
            ? sortKey === 'survey'
              ? sortDirection === 'desc'
                ? filteredList.reverse()
                : filteredList
              : filteredList.sort((a, b) => {
                  if (a[sortVal] > b[sortVal]) {
                    return sortDirection === 'asc' ? 1 : -1;
                  }
                  if (a[sortVal] < b[sortVal]) {
                    return sortDirection === 'asc' ? -1 : 1;
                  }

                  return 0;
                })
            : filteredList;

        if (selectedQuestions && selectedQuestions.length) {
          sortedList = sortedList.sort((a, b) =>
            selectedQuestions.indexOf(a.key) >= 0 && selectedQuestions.indexOf(b.key) < 0 ? -1 : 1,
          );
        }

        const groupSeparator: string = '\u001D';
        const finalList = [];
        const idList = [];

        for (let i = 0, len = sortedList.length; i < len; i++) {
          const key = !sortedList[i]['isGroupAverage']
            ? sortedList[i]['key']
            : sortedList[i]['key'].split(groupSeparator)[1];
          const index = idList.indexOf(key);
          if (index < 0) {
            finalList.push([sortedList[i]]);
            idList.push(key);
          } else {
            finalList[index].push(sortedList[i]);
          }
        }

        chart.visibleQuestionList = finalList;
      }
    });
  }

  updateChart() {
    this.setQuestions();
    this.setSizes();
    this.setData();
    this.setQuestionGroups();
    this.size = this.newSize;
    this.updateQuestionList$.next('update');
  }

  /**
   * Parsing questions from data by connecting dimension pairs together.
   */
  setQuestions() {
    this.axisGroups = {};

    const sliders: string[] = [];
    const naturalPairs = {};
    const xIndexes = {};
    for (let i = 0; i < this.details.length; i++) {
      const item = i.toString();
      if (
        this.details[item].originalType === Questions.INPUT_NUMERIC ||
        this.details[item].originalType === Questions.SLIDER_1D ||
        this.details[item].originalType === Questions.SLIDER_1V ||
        this.details[item].originalType === Questions.SLIDER_NPS ||
        this.details[item].originalType === Questions.SLIDER_E_NPS ||
        this.details[item].originalType === Questions.SLIDER_2D
      ) {
        sliders.push(item);
      }
      if (this.details[item].key.split(':')[1] === '0') {
        naturalPairs[this.details[item].key] = null;
        xIndexes[this.details[item].key] = item;
      } else if (this.details[item].key.split(':')[1] === '1') {
        const x = this.details[item].key.split(':')[0] + ':0';
        naturalPairs[x] = [xIndexes[x], item];
      }
    }
    Object.keys(naturalPairs).forEach((key) => naturalPairs[key] == null && delete naturalPairs[key]);
    for (const pair in naturalPairs) {
      if (!naturalPairs[pair][0] || !naturalPairs[pair][1]) {
        delete naturalPairs[pair];
      }
    }

    if (Object.keys(naturalPairs).length < 1) {
      if (sliders.length > 1) {
        const pairsLen = sliders.length > 2 ? Math.floor(sliders.length / 2) : 1;

        for (let i = 0; i < pairsLen; i++) {
          const x = sliders[2 * i].toString();
          const y = sliders[2 * i + 1].toString();
          const group: string = this.details[x].group === this.details[y].group ? this.details[x].group : '';
          const groupId: string = this.parseGroupId(this.details[x], this.details[y]);
          const item = [x, y, group, groupId];

          if (!this.axisGroups[groupId]) {
            this.axisGroups[groupId] = { groups: new Set(), questions: [] };
          }

          this.axisGroups[groupId]['groups'].add(group);
          this.axisGroups[groupId]['questions'].push(item);
        }
      } else {
      }
    } else {
      Object.keys(naturalPairs).forEach((key) => {
        const group: string = this.details[naturalPairs[key][0]].group;
        const groupId: string = this.parseGroupId(
          this.details[naturalPairs[key][0]],
          this.details[naturalPairs[key][1]],
        );

        if (!this.axisGroups[groupId]) {
          this.axisGroups[groupId] = { groups: new Set(), questions: [] };
        }

        naturalPairs[key].push(group, groupId);

        this.axisGroups[groupId]['groups'].add(group);
        this.axisGroups[groupId]['questions'].push(naturalPairs[key]);
      });
    }
  }

  /**
   * Setting sizes for 2Ds.
   */
  setSizes() {
    const countCharts = () => {
      const axisGroups = Object.keys(this.axisGroups).length;
      const comparisonFactor =
        this.comparison && this.comparison.values.length > 0 && this.comparisonMode !== 'joined'
          ? this.comparison.values.length
          : 1;
      return comparisonFactor * axisGroups;
    };

    const aW = this._element.nativeElement.clientWidth;
    const aH = this._element.nativeElement.clientHeight;
    const chartCount: number = countCharts();

    if (aW < 400) {
      this.flexMode = 'column';
    } else {
      this.flexMode = 'row';
    }

    const size = Math.min(
      this.flexMode === 'row' ? aW - 240 : Infinity,
      aW / (this.flexMode === 'row' ? 1.5 : 1),
      (aH * (this.flexMode === 'row' ? 1 : 0.7)) / chartCount,
    );

    this.newSize = size;

    this.fontSize = parseFloat(window.getComputedStyle(this._element.nativeElement).fontSize);
    this.margin = {
      top: this.fontSize * 3,
      right: this.fontSize * 3,
      bottom: this.fontSize * 3,
      left: this.fontSize * 3,
    };

    this.width = size - this.margin.left - this.margin.right > 0 ? size - this.margin.left - this.margin.right : 0;
    this.height = size - this.margin.bottom - this.margin.top > 0 ? size - this.margin.bottom - this.margin.top : 0;
  }

  /**
   * Setting data for 2Ds.
   */
  setData() {
    const setData = (axisGroup, comparisonGroup = '', comparisonItem = '', cidx = 0) => {
      const children: SummaryChartDataChild[] = [];
      const questions = new Set();
      let id;
      let sliderDetails;
      let group;
      const comparisonDomain = this.domain.find((dom) => dom.key === comparisonGroup);
      const cdi = this.domain.findIndex((dom) => dom.key === comparisonGroup);

      let number = 0;

      for (const pair of axisGroup['questions']) {
        const x = pair[0];
        const y = pair[1];
        const groupId =
          (comparisonItem && this.comparisonMode !== 'joined' ? `${comparisonItem}: ` : ``) + `${pair[3]}`;

        if (this.domain[x] && this.domain[y] && this.details[x] && this.details[y]) {
          const sliderLabels = this.domain[x].labelsLinear;
          const sliderLabelsY = this.domain[y].labelsLinear;
          const sliderValues = this.details[x].valueScaleLinear;
          const sliderValuesY = this.details[y].valueScaleLinear;

          const zMethod = axisGroup.groups.size > 1 ? 'globalZ' : 'z';
          const key = this.details[x].key;
          const domIndex = this.domain[x].index;
          const domIndexY = this.domain[y].index;
          const questionGroup = this.details[x].group;
          const questionGroupLabel = this.domain[x].groupLabel;
          const title =
            this.domain[x].title && this.domain[y].title && this.domain[x].title !== this.domain[y].title
              ? this.domain[x].title + ' - ' + this.domain[y].title
              : this.domain[x].title
                ? this.domain[x].title
                : this.details[x].title || '';

          const n = comparisonDomain
            ? this.stats[cdi] &&
              this.stats[cdi]['children'] &&
              this.stats[cdi]['children'][cidx] &&
              this.stats[cdi]['children'][cidx]['children'] &&
              this.stats[cdi]['children'][cidx]['children'][domIndex] &&
              this.stats[cdi]['children'][cidx]['children'][domIndex]['count'] != null
              ? this.stats[cdi]['children'][cidx]['children'][domIndex]['count']
              : null
            : this.stats[x] && this.stats[x]['count'];

          const isUnderAnonymityTreshold: boolean = this.anonymityTreshold && this.anonymityTreshold > n;

          const average = !isUnderAnonymityTreshold
            ? comparisonDomain
              ? this.stats[cdi] &&
                this.stats[cdi]['children'] &&
                this.stats[cdi]['children'][cidx] &&
                this.stats[cdi]['children'][cidx]['children'] &&
                this.stats[cdi]['children'][cidx]['children'][domIndex] &&
                this.stats[cdi]['children'][cidx]['children'][domIndex]['average'] != null
                ? this.stats[cdi]['children'][cidx]['children'][domIndex]['average']
                : null
              : this.stats[x] && this.stats[x]['average']
            : null;
          const std = !isUnderAnonymityTreshold
            ? comparisonDomain
              ? this.stats[cdi] &&
                this.stats[cdi]['children'] &&
                this.stats[cdi]['children'][cidx] &&
                this.stats[cdi]['children'][cidx]['children'] &&
                this.stats[cdi]['children'][cidx]['children'][domIndex] &&
                this.stats[cdi]['children'][cidx]['children'][domIndex]['std'] != null
                ? this.stats[cdi]['children'][cidx]['children'][domIndex]['std']
                : null
              : this.stats[x] && this.stats[x]['std']
            : null;
          const averageY = !isUnderAnonymityTreshold
            ? comparisonDomain
              ? this.stats[cdi] &&
                this.stats[cdi]['children'] &&
                this.stats[cdi]['children'][cidx] &&
                this.stats[cdi]['children'][cidx]['children'] &&
                this.stats[cdi]['children'][cidx]['children'][domIndexY] &&
                this.stats[cdi]['children'][cidx]['children'][domIndexY]['average'] != null
                ? this.stats[cdi]['children'][cidx]['children'][domIndexY]['average']
                : null
              : this.stats[y] && this.stats[y]['average']
            : null;
          const stdY = !isUnderAnonymityTreshold
            ? comparisonDomain
              ? this.stats[cdi] &&
                this.stats[cdi]['children'] &&
                this.stats[cdi]['children'][cidx] &&
                this.stats[cdi]['children'][cidx]['children'] &&
                this.stats[cdi]['children'][cidx]['children'][domIndexY] &&
                this.stats[cdi]['children'][cidx]['children'][domIndexY]['std'] != null
                ? this.stats[cdi]['children'][cidx]['children'][domIndexY]['std']
                : null
              : this.stats[y] && this.stats[y]['std']
            : null;
          const zAverage = !isUnderAnonymityTreshold
            ? comparisonDomain
              ? this.stats[cdi] &&
                this.stats[cdi]['children'] &&
                this.stats[cdi]['children'][cidx] &&
                this.stats[cdi]['children'][cidx]['children'] &&
                this.stats[cdi]['children'][cidx]['children'][domIndex] &&
                this.stats[cdi]['children'][cidx]['children'][domIndex][`${zMethod}Average`] != null
                ? this.stats[cdi]['children'][cidx]['children'][domIndex][`${zMethod}Average`]
                : null
              : this.stats[x] && this.stats[x][`${zMethod}Average`]
            : null;
          const zStd = !isUnderAnonymityTreshold
            ? comparisonDomain
              ? this.stats[cdi] &&
                this.stats[cdi]['children'] &&
                this.stats[cdi]['children'][cidx] &&
                this.stats[cdi]['children'][cidx]['children'] &&
                this.stats[cdi]['children'][cidx]['children'][domIndex] &&
                this.stats[cdi]['children'][cidx]['children'][domIndex][`${zMethod}Std`] != null
                ? this.stats[cdi]['children'][cidx]['children'][domIndex][`${zMethod}Std`]
                : null
              : this.stats[x] && this.stats[x][`${zMethod}Std`]
            : null;
          const zAverageY = !isUnderAnonymityTreshold
            ? comparisonDomain
              ? this.stats[cdi] &&
                this.stats[cdi]['children'] &&
                this.stats[cdi]['children'][cidx] &&
                this.stats[cdi]['children'][cidx]['children'] &&
                this.stats[cdi]['children'][cidx]['children'][domIndexY] &&
                this.stats[cdi]['children'][cidx]['children'][domIndexY][`${zMethod}Average`] != null
                ? this.stats[cdi]['children'][cidx]['children'][domIndexY][`${zMethod}Average`]
                : null
              : this.stats[y] && this.stats[y][`${zMethod}Average`]
            : null;
          const zStdY = !isUnderAnonymityTreshold
            ? comparisonDomain
              ? this.stats[cdi] &&
                this.stats[cdi]['children'] &&
                this.stats[cdi]['children'][cidx] &&
                this.stats[cdi]['children'][cidx]['children'] &&
                this.stats[cdi]['children'][cidx]['children'][domIndexY] &&
                this.stats[cdi]['children'][cidx]['children'][domIndexY][`${zMethod}Std`] != null
                ? this.stats[cdi]['children'][cidx]['children'][domIndexY][`${zMethod}Std`]
                : null
              : this.stats[y] && this.stats[y][`${zMethod}Std`]
            : null;

          /* const percentage = comparisonGroup
                          ? this.stats[comparisonGroup][comparisonItem][key]['percentage']
                          : this.stats['aggregate'][key]['percentage']; */

          const dom = [sliderValues.min, sliderValues.max];
          const domY = [sliderValuesY.min, sliderValuesY.max];
          const cx = !isUnderAnonymityTreshold
            ? d3.scaleLinear().rangeRound([0, this.width]).domain(dom)(
                this.zvalues ? (zAverage != null ? zAverage : dom[0] + (dom[1] - dom[0]) / 2) : average,
              )
            : null;
          const cy = !isUnderAnonymityTreshold
            ? d3.scaleLinear().rangeRound([this.height, 0]).domain(domY)(
                this.zvalues ? (zAverageY != null ? zAverageY : domY[0] + (domY[1] - domY[0]) / 2) : averageY,
              )
            : null;
          const rx = !isUnderAnonymityTreshold
            ? d3
                .scaleLinear()
                .rangeRound([0, this.width])
                .domain([0, dom[1] - dom[0]])(this.zvalues ? zStd : std) / 2
            : null;
          const ry = !isUnderAnonymityTreshold
            ? d3
                .scaleLinear()
                .rangeRound([0, this.height])
                .domain([0, domY[1] - domY[0]])(this.zvalues ? zStdY : stdY) / 2
            : null;

          if (!id) {
            id = (this.comparisonMode === 'joined' ? 'joined' : '') + groupId;
          }
          if (!sliderDetails) {
            sliderDetails = {
              labels: sliderLabels,
              labelsY: sliderLabelsY,
              values: sliderValues,
              valuesY: sliderValuesY,
            };
          }
          if (!group) {
            group = {
              key: comparisonItem ? comparisonItem : 'aggregate',
              title:
                comparisonItem && this.comparisonMode !== 'joined'
                  ? `${comparisonDomain ? comparisonDomain['labels'][comparisonItem] : ''}`
                  : ``,
            };
          }

          number += 1;

          if (n > 0 && (!this.zvalues || (zAverage != null && zAverageY != null))) {
            const child: SummaryChartDataChild = {
              key,
              title,
              n: !isUnderAnonymityTreshold ? n : null,
              cx,
              cy,
              rx,
              ry,
              domain: dom,
              domainY: domY,
              average,
              std,
              averageY,
              stdY,
              zAverage,
              zStd,
              zAverageY,
              zStdY,
              number,
              group: comparisonItem ? comparisonItem : 'aggregate',
              groupLabel: comparisonDomain ? comparisonDomain['labels'][comparisonItem] : '',
              index: comparisonDomain && comparisonDomain['colors'] ? comparisonDomain['colors'][comparisonItem] : null,
              questionGroup,
              questionGroupLabel,
              isUnderAnonymityTreshold,
            };
            children.push(child);
            questions.add(number + '. ' + title);
          }
        }
      }
      return {
        children,
        id,
        sliderDetails,
        group,
        questions: Array.from(questions) as string[],
      };
    };

    const chartData: any[] = [];

    for (const group in this.axisGroups) {
      const axisGroup = this.axisGroups[group];

      if (
        this.comparison &&
        this.comparison.values.length > 0 &&
        this.details.find((details) => details.key === this.comparison.key) // ensuring details' existence
      ) {
        const comparisonDetails = this.details.find((details) => details.key === this.comparison.key);
        const joinedData = {
          children: [] as SummaryChartDataChild[],
          id: '',
          sliderDetails: {},
          group: {},
          questions: [] as string[],
        };
        const joinedQuestions = new Set();

        if (comparisonDetails) {
          for (let c = 0, len = this.comparison.values.length; c < len; c++) {
            if (comparisonDetails['values'][this.comparison.values[c]]) {
              const comparisonItem = comparisonDetails['values'][this.comparison.values[c]].toString();
              const comparisonValue = this.comparison.values[c];
              const comparisonData = setData(axisGroup, this.comparison.key, comparisonItem, comparisonValue);

              if (this.comparisonMode && this.comparisonMode === 'joined') {
                joinedData.children = joinedData.children.concat(comparisonData.children);
                joinedData.id = comparisonData.id;
                joinedData.sliderDetails = comparisonData.sliderDetails;
                joinedData.group = comparisonData.group;
                (comparisonData.questions || []).forEach(joinedQuestions.add, joinedQuestions);
              } else {
                chartData.push(comparisonData);
              }
            }
          }

          if (this.comparisonMode && this.comparisonMode === 'joined') {
            joinedData.questions = Array.from(joinedQuestions).sort(
              (a: string, b: string) => Number(a.split('.')[0]) - Number(b.split('.')[0]),
            ) as string[];
            chartData.push(joinedData);
          }
        }
      } else {
        chartData.push(setData(axisGroup));
      }
    }

    if (this.chartData.length === 0) {
      this.chartData = chartData;
    } else {
      const orderChanges = () => {
        const oldArr = this.chartData.slice().map((item) => item.id);
        const newArr = chartData.slice().map((item) => item.id);

        if (oldArr.length === newArr.length) {
          for (let o = 0; o < oldArr.length; o++) {
            if (oldArr[o] !== newArr[o]) {
              return true;
            }
          }
          return false;
        } else {
          return true;
        }
      };

      if (orderChanges()) {
        this.chartData = chartData;
      } else {
        for (const item in chartData) {
          const index = this.chartData.findIndex((test) => test.id === chartData[item].id);

          if (index > -1) {
            this.chartData[index]['group'] = chartData[item]['group'];
            this.chartData[index]['id'] = chartData[item]['id'];
            this.chartData[index]['children'] = chartData[item]['children'];
            this.chartData[index]['questions'] = chartData[item]['questions'];
            this.chartData[index]['sliderDetails'] = chartData[item]['sliderDetails'];
          } else {
            this.chartData.push(chartData[item]);
          }
        }
        // removing old items
        let i = this.chartData.length;
        while (i--) {
          const index = chartData.findIndex((test) => test.id === this.chartData[i].id);

          if (index === -1) {
            this.chartData.splice(i, 1);
          }
        }
      }
    }
  }

  private setQuestionGroups(): void {
    const groupSeparator: string = '\u001D';

    for (let c = 0, lenc = this.chartData.length; c < lenc; c++) {
      const chartId = this.chartData[c]?.['id'];
      this.questionCount[chartId] = Array.from(
        new Set(this.chartData[c]?.children?.map((cItem) => cItem?.key)?.reduce((a, b) => a?.concat(b), []) || []),
      )?.length;
    }

    this.questionGroups = {};

    if (Math.max(...Object.values(this.questionCount)) > 1) {
      for (let ic = 0, lenc = this.chartData.length; ic < lenc; ic++) {
        const chartId = this.chartData[ic]['id'];
        const rawList = this.chartData[ic].children;

        this.questionGroups[chartId] = {};
        const uniqueQuestionGroups: string[] = [];

        for (let i = 0, len = rawList.length; i < len; i++) {
          const questionGroup = rawList[i]['questionGroup'];
          const group = rawList[i]['group'] + groupSeparator + questionGroup;
          const question = rawList[i];

          if (questionGroup && question) {
            if (!this.questionGroups[chartId][group]) {
              this.questionGroups[chartId][group] = [];
            }
            this.questionGroups[chartId][group].push(question);
          }
          if (!uniqueQuestionGroups.includes(questionGroup)) {
            uniqueQuestionGroups.push(questionGroup);
          }
        }

        const groupCount: number = uniqueQuestionGroups.length;

        for (const qg in this.questionGroups[chartId]) {
          const questions: SummaryChartDataChild[] = this.questionGroups[chartId][qg];
          const n: number = questions.reduce((a, b) => a + b.n, 0);
          const isUnderAnonymityTreshold: boolean = this.anonymityTreshold && this.anonymityTreshold > n;
          const newIndex: number = rawList.findIndex((item) => item.questionGroup === qg.split(groupSeparator)[1]);
          const indexInAllGroups = Object.keys(this.questionGroups[chartId])
            .map((key) => key.split(groupSeparator)[1])
            .map((groupItem) => rawList.findIndex((item) => item.questionGroup === groupItem))
            .indexOf(newIndex);
          const reducedStats: { [s: string]: number } = questions.reduce(
            (a, b) => ({
              average: a.average + b.average * b.n,
              std: a.std + Math.pow(b.std, 2),
              averageY: a.averageY + b.averageY * b.n,
              stdY: a.stdY + Math.pow(b.stdY, 2),
              zAverage: a.zAverage + b.zAverage * b.n,
              zStd: a.zStd + Math.pow(b.zStd, 2),
              zAverageY: a.zAverageY + b.zAverageY * b.n,
              zStdY: a.zStdY + Math.pow(b.zStdY, 2),
            }),
            {
              average: 0,
              std: 0,
              averageY: 0,
              stdY: 0,
              zAverage: 0,
              zStd: 0,
              zAverageY: 0,
              zStdY: 0,
            },
          );
          const dom: number[] = questions[0]?.['domain'];
          const domY: number[] = questions[0]?.['domainY'];
          const average: number = reducedStats.average / n;
          const std: number = Math.sqrt(reducedStats.std / questions.length);
          const averageY: number = reducedStats.averageY / n;
          const stdY: number = Math.sqrt(reducedStats.stdY / questions.length);
          const zAverage: number =
            groupCount > 1 && !isNaN(reducedStats.zAverage / n)
              ? reducedStats.zAverage / n
              : dom[0] + (dom[1] - dom[0]) / 2;
          const zStd: number = Math.sqrt(reducedStats.zStd / questions.length);
          const zAverageY: number =
            groupCount > 1 && !isNaN(reducedStats.zAverageY / n)
              ? reducedStats.zAverageY / n
              : domY[0] + (domY[1] - domY[0]) / 2;
          const zStdY: number = Math.sqrt(reducedStats.zStdY / questions.length);
          const cx: number = d3.scaleLinear().rangeRound([0, this.width]).domain(dom)(
            this.zvalues ? zAverage : average,
          );
          const cy: number = d3.scaleLinear().rangeRound([this.height, 0]).domain(domY)(
            this.zvalues ? zAverageY : averageY,
          );
          const rx: number =
            d3
              .scaleLinear()
              .rangeRound([0, this.width])
              .domain([0, dom[1] - dom[0]])(this.zvalues ? zStd : std) / 2;
          const ry: number =
            d3
              .scaleLinear()
              .rangeRound([0, this.height])
              .domain([0, domY[1] - domY[0]])(this.zvalues ? zStdY : stdY) / 2;

          const questionGroupItem: SummaryChartDataChild = {
            key: qg,
            title: questions[0]?.['questionGroupLabel'] || '',
            n,
            cx,
            cy,
            rx,
            ry,
            domain: dom,
            domainY: domY,
            average,
            std,
            averageY,
            stdY,
            zAverage,
            zStd,
            zAverageY,
            zStdY,
            number: null,
            numberAlphabet: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[indexInAllGroups] || '.',
            group: questions[0]?.['group'],
            groupLabel: questions[0]?.['groupLabel'],
            index: questions[0]?.['index'],
            questionGroup: '',
            questionGroupLabel: '',
            isGroupAverage: true,
            isUnderAnonymityTreshold,
          };

          const existingGroupIndex: number = rawList.findIndex((item) => item.key === questionGroupItem['key']);
          if (existingGroupIndex < 0) {
            rawList.splice(newIndex, 0, questionGroupItem);
          } else {
            rawList[existingGroupIndex] = questionGroupItem;
          }
        }
      }
    }
  }

  public settingsChanged(): void {
    this.settingsChange.emit({
      summaryChartQuestionList: {
        sortKeys: this.sortKeys,
        sortDirections: this.sortDirections,
        selectedQuestions: this.selectedQuestions,
        showGroupAverages: this.showGroupAverages,
      },
    });
  }

  public highlightAdd(question: SummaryChartDataChild, chartId: string): void {
    const getQuestionTexts: (q: SummaryChartDataChild[]) => string[] = (questions) =>
      questions.map((q) => `${q.number}. ${q.title}`);

    this.highlighted =
      !question.isGroupAverage || this.showGroupAverages[chartId]
        ? getQuestionTexts([question])
        : getQuestionTexts(this.questionGroups[chartId][question.key]);

    this.cdRef.detectChanges();
  }

  public highlightRemove(): void {
    this.highlighted = [];
    this.cdRef.detectChanges();
  }

  public onSearchUpdate(searchTerm, id: string): void {
    this.searchTerms[id] = searchTerm;
    this.updateQuestionList$.next('search');
  }

  public onSortColumn(column: string, id: string): void {
    if (column !== 'survey') {
      this.sortDirections[id] = 'desc';
    } else {
      this.sortDirections[id] = 'asc';
    }

    this.sortKeys[id] = column;
    this.updateQuestionList$.next('sort column changed');
    this.settingsChanged();
  }

  public onSortDir(id: string): void {
    this.sortDirections[id] = this.sortDirections[id] === 'asc' ? 'desc' : 'asc';
    this.updateQuestionList$.next('sort dir changed');
    this.settingsChanged();
  }

  public hoverFromChart($event: string[], id: string): void {
    if ($event && $event.length > 0) {
      const groupSeparator: string = '\u001D';
      const comparisonFriends =
        $event.findIndex((item) => item.indexOf(groupSeparator) >= 0) >= 0
          ? this.chartData[this.chartData.findIndex((item) => item.id === id)]?.children
              ?.map((item) => item.key)
              ?.filter(
                (item) =>
                  $event
                    .map((selectedItem) => selectedItem.split(groupSeparator)[1])
                    .indexOf(item.split(groupSeparator)[1] || item) >= 0,
              )
          : [];
      const questionList = Array.from(new Set([...$event, ...comparisonFriends]));

      if (!this.hoveredFromChart[id]) {
        this.hoveredFromChart[id] = [];
      }

      if (questionList && this.hoveredFromChart[id] && questionList.join() !== this.hoveredFromChart[id].join()) {
        if (questionList && questionList.length > 0) {
          this.hoveredFromChart[id] = questionList;
        } else if (this.hoveredFromChart[id].length > 0) {
          this.hoveredFromChart[id] = [];
        }
        this.updateQuestionList$.next('hovered from chart');
      }
    } else {
      this.hoveredFromChart[id] = [];
      this.updateQuestionList$.next('hovered from chart');
    }
  }

  public selectQuestions(questions: string[] = [], id, isGroup?: boolean): void {
    const questionList = Array.from(
      new Set(
        !isGroup
          ? questions
          : [...questions, ...assertArray(this.questionGroups[id][questions[0]]?.map((item) => item.key))],
      ),
    );

    if (!this.selectedQuestions[id]) {
      this.selectedQuestions[id] = [];
    }

    for (const question of questionList || []) {
      if (this.selectedQuestions[id].indexOf(question) < 0) {
        this.selectedQuestions[id].push(question);
      } else {
        this.selectedQuestions[id].splice(this.selectedQuestions[id].indexOf(question), 1);
      }
    }

    this.updateQuestionList$.next('selected question');
    this.settingsChanged();
  }

  public toggleGroupAverages(chartId: string): void {
    const previousState: boolean = !!this.showGroupAverages[chartId];
    this.showGroupAverages[chartId] = !previousState;
    this.highlightRemove();
    this.selectedQuestions[chartId] = [];
    this.updateQuestionList$.next('toggled group averages');
    this.settingsChanged();
  }

  public colorScale(index) {
    if (this.comparison && this.comparison.values.length > 0 && this.comparisonMode === 'joined') {
      return Colors.getComparisonColor(index);
    } else {
      return Colors.TEXT;
    }
  }

  public isFound(arr, item): boolean {
    return !!arr && !!arr.length && !!item && arr.indexOf(item) >= 0;
  }

  public mapValues(arr: SummaryChartDataChild[] = [], property: string = ''): string[] {
    return arr.map((item) => item[property]);
  }

  public hasOnlyAnonymousQuestions(questions): boolean {
    return !questions.some((q) => !q?.isUnderAnonymityTreshold);
  }

  trackByKey(i: number, item: any[]): number {
    return (item && item[0] && item[0]['key']) || null;
  }

  // Helpers

  /**
   * Parsing group ids from axis details.
   *
   * @param detailsX   Dimension details for axis x.
   * @param detailsY  Dimension details for axis x.
   * @returns        Parsed group id.
   */
  parseGroupId(detailsX: DimensionDataItem, detailsY: DimensionDataItem): string {
    return `${detailsX?.labelsLinear?.axis || ''}: ${detailsX?.labelsLinear?.min || ''} - ${
      detailsX?.labelsLinear?.max || ''
    } (${detailsX?.valueScaleLinear?.min}-${detailsX?.valueScaleLinear?.max}) - ${
      detailsY?.labelsLinear?.axis || ''
    }: ${detailsY?.labelsLinear?.min || ''} - ${detailsY?.labelsLinear?.max || ''} (${
      detailsY?.valueScaleLinear?.min
    }-${detailsY?.valueScaleLinear?.max})`;
  }
}
