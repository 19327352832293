<div
  #tooltip
  *ngIf="hoveredBar != null"
  class="item-tooltip"
  [innerHtml]="hoveredContent"
  [style.transform]="'translate(' + tooltipPosition[0] + 'px,' + tooltipPosition[1] + 'px)'"
></div>
<div class="z-fx-col z-fx-auto z-fx-gap-16 summary-choice-container" (elementResize)="resizeChart()">
  <div
    *ngFor="let group of chartData$ | async; trackBy: trackById; let gi = index; let gl = count"
    class="z-fx-col z-fx-auto z-fx-gap-16 bar-chart-table"
    (mouseover)="hoveredGroupKey = group?.key; updateTooltip()"
    (mouseout)="hoveredGroupKey = ''; updateTooltip()"
  >
    <div *ngIf="group.key !== 'no-comparison'" class="z-fx z-fx-center-center group-title">
      {{ group.title }}
    </div>
    <ng-scrollbar class="z-fx-col z-fx-between-start z-fx-fill scrollable-area">
      <div class="z-fx-col z-fx-fill summary-choice-charts-container">
        <div
          *ngFor="let chart of group?.data; trackBy: trackById; let i = index; let l = count"
          class="z-fx z-fx-start-center bar-chart-table-row"
          [class.hovered]="hoveredBar === i"
          [style.font-size.px]="exportChart ? 14 : null"
          [style.max-height.px]="rowHeight"
          (mouseover)="hoveredBar = i; updateTooltip()"
          (mouseout)="hoveredBar = null; updateTooltip()"
        >
          <div class="z-fx z-fx-end-start title-container">
            <div class="choice-title">
              <span>{{ chart?.title }}</span>
            </div>
          </div>
          <div
            class="bar-chart-container"
            stackedBarChartH
            [style.height.px]="rowHeight"
            [values]="chart.values"
            [previousValues]="chart.previousValues"
            [colors]="chart.colors"
            [scale]="[0, maxs?.[gi]?.[i]]"
            [previousScale]="[0, previousMaxs?.[gi]?.[i]]"
            [showNumbers]="showNumbers || hasSelections || exportChart"
            [percentageValues]="percentageValues"
            [previousPercentageValues]="previousPercentageValues"
            [drawHelperLines]="false"
            [update]="Math.max(update?.valueOf(), sizeUpdate?.valueOf())"
            [hover]="hoveredBar === i && hoveredGroupKey === group?.key"
            [currentHoveredBar]="hoveredBarChoice?.[i]"
            [transitionDuration]="transitionDuration"
            [selections]="chart.selections"
            (hoveredBar)="newHoveredChoice($event)"
            (click)="onFilter()"
          ></div>
        </div>
      </div>
    </ng-scrollbar>
    <div #legendsContainer class="z-fx z-fx-none z-fx-center-center z-fx-wrap z-fx-gap-16 legends-container">
      <div
        *ngFor="let choice of choices; trackBy: trackByTitle; let i = index"
        class="z-fx z-fx-start-center z-fx-gap-8 legend"
        [class.hovered]="hoveredOverallChoice === i"
        (mouseover)="newHoveredChoiceFromLegends(i)"
        (mouseout)="newHoveredChoiceFromLegends(null)"
      >
        <span
          class="circle"
          [style.background-color]="
            !choice?.colorsIncluded?.length || !choice.color ? getColor(0, i, group?.color) : choice.color
          "
        ></span>
        <div class="legend-title" [title]="choice.title">
          <span class="text-label">{{ choice.title }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
