import * as d3 from 'd3';

import { Directive, ElementRef, HostListener, Input, OnChanges } from '@angular/core';

import { ChartDistribution, ChartDomain } from '@shared/models/report.model';

import { Crossfilter } from '@report/shared/services/crossfilter.service';

/**
 * This is a pie chart.
 */
@Directive({
  selector: '[stackedPieChart]',
})
export class StackedPieChart implements OnChanges {
  @Input() data: ChartDistribution[] = [];
  @Input() domain: ChartDomain = {} as ChartDomain;
  @Input() scale: any;
  @Input() filterInput: any;
  @Input() transitionDuration: number = 0;
  @Input() filtering: boolean = false;

  private svg: any;
  private base: any;

  private slices: any;

  private legendsSlice: any;
  private legendSlicesGroup: any;
  private legendsPie: any;

  private enterLegend: any;

  private pie: any;

  private colorScale: any;
  private maxPercentage: any;

  private transition: any;

  private width: any;
  private height: any;
  private margin: any;
  private radius: any;

  private filter: any;
  private selections: any = [];

  @HostListener('window:resize') resize() {
    this.ngOnChanges();
  }

  constructor(
    private _element: ElementRef,
    private cf: Crossfilter,
  ) {
    this.constructBody();
  }

  ngOnChanges() {
    this.setEnvironment();
    this.setTransition(this.transitionDuration);
    this.setScales();
    this.setArcs();
    this.setDataSVG();
    this.setDataSlices();
    this.setLegendPie();
    this.setLegendsSlice();
    this.filterUpdate();
  }

  constructBody() {
    this.base = d3
      .select(this._element.nativeElement)
      .append('div')
      .style('text-align', 'left')
      .attr('class', 'charts');
    this.legendSlicesGroup = d3.select(this._element.nativeElement).append('div').attr('class', 'legends');
  }

  setEnvironment() {
    // TODO: Algorithm to set proper widths & heights
    this.margin = { top: 0, right: 0, bottom: 0, left: 0 };

    // Algorithm to calculate optimal pie size
    // More from: http://stackoverflow.com/a/870967
    let sqW = 1;
    let sqH = 1;
    let maxSq: number = 1;
    const aW = 0.8 * this._element.nativeElement.clientWidth;
    const aH = this._element.nativeElement.clientHeight;
    const n: number = this.data.length;

    let cW;
    let cH;

    while (n > maxSq) {
      cW = aW / (sqH + 1);
      cH = aH / (sqW + 1);
      if (cW >= cH) {
        sqH = sqH + 1;
      } else {
        sqW = sqW + 1;
      }
      maxSq = sqH * sqW;
    }

    const size = Math.min(aW / sqH, aH / sqW);

    this.width = size > 0 ? size : 0;
    this.height = size > 0 ? size : 0;

    this.radius = Math.min(this.width, this.height) / 2 - 20;
  }

  setTransition(duration) {
    this.transition = d3.transition().duration(duration);
  }

  setScales() {
    this.pie = d3
      .pie()
      .sort(null)
      .value((d: any) => d.percentage);

    if (this.domain.scale === 'linear') {
      this.colorScale = d3
        .scaleOrdinal()
        .range([
          '#CA0404',
          '#D33535',
          '#DC6666',
          '#E69797',
          '#EFC8C8',
          '#F9F9F9',
          '#D5E2F1',
          '#B2CBEA',
          '#8FB5E2',
          '#6C9EDB',
          '#4988D4',
        ]);
    } else {
      this.colorScale = d3
        .scaleOrdinal()
        .range([
          '#4988D4',
          '#FF7D1B',
          '#00934F',
          '#CA0404',
          '#BD144E',
          '#660529',
          '#FFA2BE',
          '#2C92A7',
          '#E3D472',
          '#4EE67D',
          '#004B39',
        ]);
    }

    this.maxPercentage = d3.max(this.data, (d: any) => d.percentage);

    // this.pieSizeScale =
    d3.scaleLinear().rangeRound([50, this.width]).domain([0, this.maxPercentage]);
  }

  setArcs() {
    // this.arc =
    d3.arc().outerRadius(this.radius).innerRadius(0);

    // this.labelArc =
    d3.arc()
      .outerRadius(this.radius)
      .innerRadius(this.radius / 1.5);
  }

  setDataSVG() {
    // JOIN new data
    this.svg = this.base.selectAll('.donut-svg').data(this.data);

    // Exit old elemets not present in new data
    this.svg.exit().transition(this.transition).remove();

    // Update
    this.svg
      .attr('width', this.width)
      .attr('height', this.height)
      .select('.donut')
      .attr(
        'transform',
        `
            translate(${this.margin.left + this.width / 2},${this.margin.top + this.height / 2})
          `,
      );

    // Enter new elements
    this.svg
      .enter()
      .append('svg')
      .attr('class', 'donut-svg')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .append('g')
      .attr('class', 'donut')
      .attr(
        'transform',
        `
          translate(${this.margin.left + this.width / 2},${this.margin.top + this.height / 2})
          `,
      );
  }

  setDataSlices() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const __this = this; /* This is needed for arc transitions where there is 2 different this elements used */

    // Join new data
    this.slices = this.base
      .selectAll('.donut-svg')
      .select('.donut')
      .selectAll('.slice')
      .data((d) => this.pie(d.children));

    // Exit old elemets not present in new data
    this.slices.exit().transition(this.transition).remove();

    // Update existing elements
    this.slices.transition(this.transition).attrTween('d', function arcTween(a) {
      const i = d3.interpolate(this._current, a);
      const p = d3.interpolate(this._parent, this.parentNode.__data__.percentage);
      const m = d3.interpolate(this._maxPercentage, __this.maxPercentage);

      this._current = i(0);
      this._parent = this.parentNode.__data__.percentage;
      this._maxPercentage = __this.maxPercentage;
      return (t) =>
        d3
          .arc()
          .outerRadius(
            d3
              .scaleLinear()
              .rangeRound([50, __this.width])
              .domain([0, m(t)])(p(t)) /
              2 -
              20,
          )
          .innerRadius(0)(i(t));
    });

    // Enter new elements
    this.slices
      .enter()
      .append('path')
      .attr('class', 'slice')
      .style('fill', (d, i) => this.colorScale(i))
      .transition(this.transition)
      .attrTween('d', function arcTween(a) {
        const empty = {
          index: a.index,
          padAngle: a.padAngle,
          startAngle: 0,
          endAngle: 0,
          value: a.value,
          data: a.data,
        };
        const i = d3.interpolate(empty, a);
        const p = d3.interpolate(0, this.parentNode.__data__.percentage);
        const m = d3.interpolate(0, __this.maxPercentage);

        this._current = i(0);
        this._parent = this.parentNode.__data__.percentage;
        this._maxPercentage = __this.maxPercentage;
        return (t) =>
          d3
            .arc()
            .outerRadius(
              d3
                .scaleLinear()
                .rangeRound([50, __this.width])
                .domain([0, m(t)])(p(t)) /
                2 -
                20,
            )
            .innerRadius(0)(i(t));
      });
  }

  setLegendPie() {
    // Join new data
    this.legendsPie = this.base
      .selectAll('.donut')
      .selectAll('.legend-pie')
      .data((d) => [d]);

    // Exit old elemets not present in new data
    this.legendsPie.exit().transition(this.transition).remove();

    // Update existing texts
    this.legendsPie
      .transition(this.transition)
      .attr('y', this.height / 2)
      .attr('text-anchor', 'middle')
      .text((d) => this.domain.labels[d.key] + ' (' + (d.percentage * 100).toFixed(1) + '%)');

    // Enter new elements
    this.legendsPie
      .enter()
      .append('text')
      .attr('class', 'legend-pie')
      .attr('y', this.height / 2)
      .attr('text-anchor', 'middle')
      .on('click', (event, d) => {
        this.sliceSelection(d);
        this.callFilter();
        return '';
      })
      .text((d) => this.domain.labels[d.key] + ' (' + (d.percentage * 100).toFixed(1) + '%)');
  }

  setLegendsSlice() {
    // Join new data
    this.legendsSlice = this.legendSlicesGroup.selectAll('.legend-slice').data(this.domain.keysY, (d) => d);

    // Exit old elemets not present in new data
    this.legendsSlice.exit().transition(this.transition).remove();

    // Update existing legend circles
    this.legendsSlice.select('.legend-slice-circle').transition(this.transition);

    // Update existing texts
    this.legendsSlice
      .select('.legend-slice-text')
      .transition(this.transition)
      .text((d, i) => this.domain.labelsY[this.domain.keysY[this.legendHelper(i)]]);

    // Enter new elements
    this.enterLegend = this.legendsSlice
      .enter()
      .append('div')
      .attr('class', 'legend-slice z-fx-sc')
      // .attr('transform', (d,i) => { return `translate(0, ${ this.legendHelper(i) * 20 })` })
      .on('click', () => /*this.rowSelection(d); this.callFilterY();*/ '');

    this.enterLegend
      .append('div')
      .attr('class', 'legend-slice-circle')
      .style('width', '10px')
      .style('height', '10px')
      .style('background-color', (d, i) => this.colorScale(i))
      .style('border-radius', '50%')
      .transition(this.transition);

    this.enterLegend
      .append('text')
      .attr('class', 'legend-slice-text')
      .attr('x', 10)
      .text((d, i) => this.domain.labelsY[this.domain.keysY[this.legendHelper(i)]]);
  }

  legendHelper(i) {
    return this.domain.scaleY === 'linear' ? this.domain.keysY.length - 1 - i : i;
  }

  sliceSelection(sel) {
    const index = this.selections.indexOf(sel.key);

    if (this.selections.length < 1) {
      this.selections.push(sel.key);
    } else if (index >= 0) {
      this.selections.splice(index, 1);
    } else {
      this.selections.push(sel.key);
    }

    this.base
      .selectAll('.donut')
      .classed('included', (d) => this.selections.indexOf(d.key) >= 0)
      .classed('excluded', (d) => this.selections.length > 0 && this.selections.indexOf(d.key) < 0)
      .classed('default', () => this.selections.length < 1);

    return '';
  }

  filterUpdate() {
    // Updating selections
    this.base
      .selectAll('.donut')
      .classed(
        'included',
        (d, i) =>
          this.filterInput &&
          this.filterInput[0] &&
          this.filterInput[0].length > 0 &&
          this.filterInput[0].indexOf(this.domain.keys[i]) >= 0,
      )
      .classed(
        'excluded',
        (d, i) =>
          this.filterInput &&
          this.filterInput[0] &&
          this.filterInput[0].length > 0 &&
          this.filterInput[0].indexOf(this.domain.keys[i]) < 0,
      )
      .classed('default', () => !this.filterInput || !this.filterInput[0] || this.filterInput[0].length < 1);
    // Removing selections if filter is removed somewhere else
    if (!this.filterInput && this.selections.length > 0) {
      this.selections = [];
    }
  }

  callFilter() {
    if (this.filtering) {
      this.filter = [];
      const filter = { key: this.domain.key, values: this.domain.keys, filter: [] as string[] };
      this.base.selectAll('.included').each((d) => {
        filter.filter.push(d.key);
      });
      this.filter.push(filter);
      this.cf.filter(this.filter);
    }
  }
}
