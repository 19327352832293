import * as d3 from 'd3';

import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

/**
 * This is a Z-scoring tutorial.
 */
@Directive({
  selector: '[Standardize2DTutorial]',
})
export class Standardize2DTutorial implements OnChanges {
  @Input() show: boolean = false;

  private data: any;

  private width: number = 300;
  private height: number = 300;
  private margin: any = { top: 40, right: 40, bottom: 40, left: 40 };

  private base: any;

  private answerArea: any;

  private currentValueX: string = '';
  private currentValueY: string = '';

  private ball: any;
  private averageBall: any;
  private line: any;

  private transition: any;
  private transitionDuration: number = 2000;

  private first: any;
  private second: any;
  private third: any;
  private fourth: any;

  constructor(private _element: ElementRef) {
    this.base = d3.select(this._element.nativeElement).append('svg').attr('height', '300px');
  }

  ngOnChanges() {
    if (this.show) {
      this.transitionDuration = 2000;
      this.setTransitions();
      this.setDataAnswerArea();
      this.setData();
    } else {
      clearTimeout(this.first);
      clearTimeout(this.second);
      clearTimeout(this.third);
      clearTimeout(this.fourth);
      this.transitionDuration = 0;
      this.data = [];
      this.setDataBall();
      this.setDataAverageBall();
      this.setDataLines();
    }
  }

  setData() {
    this.data = [
      { id: 1, x0: 191, x1: 183, y0: 22, y1: 68 },
      { id: 2, x0: 147, x1: 37, y0: 22, y1: 68 },
      { id: 3, x0: 169, x1: 110, y0: 66, y1: 195 },
    ];
    this.currentValueX = 'x0';
    this.currentValueY = 'y0';
    this.setDataBall();
    this.setDataAverageBall();
    this.setDataLines();

    this.first = setTimeout(() => {
      if (this.show) {
        this.currentValueX = 'x1';
        this.currentValueY = 'y1';
        this.setDataBall();
        this.setDataAverageBall();
        this.setDataLines();

        this.second = setTimeout(() => {
          if (this.show) {
            this.data = [
              { id: 4, x0: 91, x1: 183, y0: 22, y1: 68 },
              { id: 5, x0: 47, x1: 37, y0: 22, y1: 68 },
              { id: 6, x0: 69, x1: 110, y0: 66, y1: 195 },
            ];
            this.currentValueX = 'x0';
            this.currentValueY = 'y0';
            this.setDataBall();
            this.setDataAverageBall();
            this.setDataLines();

            this.third = setTimeout(() => {
              if (this.show) {
                this.currentValueX = 'x1';
                this.currentValueY = 'y1';
                this.setDataBall();
                this.setDataAverageBall();
                this.setDataLines();

                this.fourth = setTimeout(() => {
                  if (this.show) {
                    this.setData();
                  }
                }, 4000);
              }
            }, 4000);
          }
        }, 4000);
      }
    }, 4000);
  }

  setTransitions() {
    this.transition = d3.transition().duration(this.transitionDuration);
  }

  setDataAnswerArea() {
    const areaW = this.width - this.margin.left - this.margin.right;
    const areaH = this.height - this.margin.top - this.margin.bottom;

    const path = `M${areaW / 2 - 2} 0
           L 5 0
           A 5 5, 0, 0, 0, 0 5
           L 0 ${areaH / 2 - 2}
           L ${areaW / 4} ${areaH / 2 - 2}
           L ${areaW / 4} ${areaH / 2 + 2}
           L 0 ${areaH / 2 + 2}
           L 0 ${areaH - 5}
           A 5 5, 0, 0, 0, 5 ${areaH}
           L ${areaW / 2 - 2} ${areaH}
           L ${areaW / 2 - 2} ${(3 * areaH) / 4}
           L ${areaW / 2 + 2} ${(3 * areaH) / 4}
           L ${areaW / 2 + 2} ${areaH}
           L ${areaW - 5} ${areaH}
           A 5 5, 0, 0, 0, ${areaW} ${areaH - 5}
           L ${areaW} ${areaH / 2 + 2}
           L ${(3 * areaW) / 4} ${areaH / 2 + 2}
           L ${(3 * areaW) / 4} ${areaH / 2 - 2}
           L ${areaW} ${areaH / 2 - 2}
           L ${areaW} 5
           A 5 5, 0, 0, 0, ${areaW - 5} 0
           L ${areaW / 2 + 2} 0
           L ${areaW / 2 + 2} ${areaH / 4}
           L ${areaW / 2 - 2} ${areaH / 4}
           Z`;

    this.answerArea = this.base.selectAll('.summary2d-area').data([this.data]);

    this.answerArea.exit().remove();

    this.answerArea.attr('d', path);

    this.answerArea
      .enter()
      .append('g')
      .attr('class', 'summary2d-area')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`)
      .append('path')
      .attr('class', 'summary2d-answer-area')
      .attr('fill', '#dae2e5')
      .attr('d', path);
  }

  setDataBall() {
    this.base.selectAll('.summary2d-answer').interrupt();

    // JOIN new data
    this.ball = this.base
      .selectAll('.summary2d-area')
      .selectAll('.summary2d-answer')
      .data(this.data, (d) => d.id);

    // Exit old elemets not present in new data
    this.ball.exit().transition(this.transition).attr('r', 0).remove();

    // Update
    this.ball
      .transition(this.transition)
      .style('fill', '#a4b0b9')
      .attr('cx', (d) => d[this.currentValueX])
      .attr('cy', (d) => d[this.currentValueY])
      .attr('r', 12.5);

    // Enter new elements
    this.ball
      .enter()
      .append('circle')
      .attr('class', 'summary2d-answer')
      .style('fill', '#a4b0b9')
      .attr('cx', (d) => d[this.currentValueX])
      .attr('cy', (d) => d[this.currentValueY])
      .attr('r', 0)
      .transition(this.transition)
      .attr('r', 12.5);
  }

  setDataAverageBall() {
    this.base.selectAll('.summary2d-average').interrupt();

    // JOIN new data
    this.averageBall = this.base
      .selectAll('.summary2d-area')
      .selectAll('.summary2d-average')
      .data(this.data.length > 0 ? [this.data] : []);

    // Exit old elemets not present in new data
    this.averageBall.exit().transition(this.transition).attr('r', 0).remove();

    // Update
    this.averageBall
      .transition(this.transition)
      .style('fill', 'red')
      .attr('cx', (d) => d3.mean(d, (c) => c[this.currentValueX]))
      .attr('cy', (d) => d3.mean(d, (c) => c[this.currentValueY]))
      .attr('r', 5);

    // Enter new elements
    this.averageBall
      .enter()
      .append('circle')
      .attr('class', 'summary2d-average')
      .style('fill', 'red')
      .attr('cx', (d) => d3.mean(d, (c) => c[this.currentValueX]))
      .attr('cy', (d) => d3.mean(d, (c) => c[this.currentValueY]))
      .attr('r', 0)
      .transition(this.transition)
      .attr('r', 5);
  }

  setDataLines() {
    this.base.selectAll('.summary2d-line').interrupt();

    // JOIN new data
    this.line = this.base
      .selectAll('.summary2d-area')
      .selectAll('.summary2d-line')
      .data(this.data, (d) => d.id);

    // Exit old elemets not present in new data
    this.line.exit().transition(this.transition).attr('r', 0).remove();

    // Update
    this.line
      .transition(this.transition)
      .style('stroke', '#a4b0b9')
      .style('stroke-dasharray', '5, 10')
      .attr('x1', (d) => d[this.currentValueX])
      .attr('y1', (d) => d[this.currentValueY])
      .attr('x2', () => d3.mean(this.data, (c) => c[this.currentValueX]))
      .attr('y2', () => d3.mean(this.data, (c) => c[this.currentValueY]));

    // Enter new elements
    this.line
      .enter()
      .append('line')
      .attr('class', 'summary2d-line')
      .style('stroke', '#a4b0b9')
      .style('stroke-dasharray', '5, 10')
      .attr('x1', (d) => d[this.currentValueX])
      .attr('y1', (d) => d[this.currentValueY])
      .attr('x2', () => d3.mean(this.data, (c) => c[this.currentValueX]))
      .attr('y2', () => d3.mean(this.data, (c) => c[this.currentValueY]))
      .transition(this.transition);
  }
}
