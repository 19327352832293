import * as d3 from 'd3';

import { Directive, ElementRef, HostListener, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';

import { ChartDistribution, ChartDomain, ChartLegend, NPSData, NPSDistribution } from '@shared/models/report.model';

import { Crossfilter } from '@report/shared/services/crossfilter.service';

import { Colors } from '@report/shared/enums/colors.enum';

import { drawContactIcon, drawRoundRect, shortenText } from '@shared/utilities/canvas.utilities';

export interface CanvasContext {
  context: CanvasRenderingContext2D;
  data: NPSData;
}

/**
 * This is a NPS Gauge chart.
 */
@Directive({
  selector: '[npsGauge]',
})
export class NPSGauge implements OnChanges {
  @Input() NPSdata: NPSData = {} as NPSData;
  @Input() domain: ChartDomain = {} as ChartDomain;
  @Input() scale: any;
  @Input() filterInput: any;
  @Input() transitionDuration: number = 0;
  @Input() showNumbers: boolean = true;
  @Input() update: Date = new Date();
  @Input() filtering: boolean = false;
  @Input() anonymityLock: boolean = false;
  @Input() hideDistribution: boolean = false;
  @Input() touchDevice: boolean = false;
  @Input() selectionExists: boolean = false;

  private base: any;

  private context: CanvasContext = {} as CanvasContext;
  private canvas: any;

  private scaleX: any;
  private colorScale: any;
  private pie: any;

  private legends: ChartLegend[] = [];

  private tooltip: any;
  private tooltipData: any;

  private width: number;
  private height: number;
  private margin: { [s: string]: number };
  private fontSize: number = 0;
  private unit: number = 0;
  private marginBottomUnits: number = 0;
  private arcRadius: number = 0;

  private sum: number = 0;
  private previousSum: number = 0;

  private filter: any;
  private selections: any = new Set();

  @HostListener('window:resize') resize() {
    this.updateChart(null);
  }

  constructor(
    private _element: ElementRef,
    private cf: Crossfilter,
  ) {
    this.constructBody();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.NPSdata ||
      changes.domain ||
      changes.scale ||
      changes.filterInput ||
      changes.transitionDuration ||
      changes.showNumbers ||
      changes.update ||
      changes.filtering ||
      changes.anonymityLock ||
      changes.hideDistribution ||
      changes.touchDevice ||
      changes.selectionExists
    ) {
      this.updateChart(changes.NPSdata);
    }
  }

  updateChart(dataChanges: SimpleChange | null): void {
    this.setEnvironment();
    this.setScales();
    this.setCanvas(dataChanges);
  }

  constructBody(): void {
    this.marginBottomUnits = 3;
    this.base = d3
      .select(this._element.nativeElement)
      .append('div')
      .attr('class', 'nps-chart')
      .style('position', 'relative');
    this.tooltip = d3
      .select(this._element.nativeElement)
      .append('div')
      .attr('class', 'item-tooltip')
      .style('display', 'none');
  }

  setEnvironment(): void {
    this.fontSize = parseFloat(window.getComputedStyle(this._element.nativeElement).fontSize);
    this.unit = (10 / 14) * this.fontSize;

    this.margin = {
      top: 3 * this.unit,
      right: 2 * this.unit,
      bottom: this.marginBottomUnits * this.unit,
      left: 2 * this.unit,
    };

    const width = this._element.nativeElement.clientWidth - this.margin.left - this.margin.right;
    const height = this._element.nativeElement.clientHeight - this.margin.top - this.margin.bottom;

    this.width = width > 0 ? width : 0;
    this.height = height > 0 ? height : 0;

    this.arcRadius = Math.min(this.width / 2, this.height / 1.5);
  }

  setScales(): void {
    let sum = 0;

    for (let c = 0, lenc = this.NPSdata['distribution'].length; c < lenc; c++) {
      sum += this.NPSdata['distribution'][c]['value'] || 0;
    }

    this.scaleX = d3.scaleLinear().rangeRound([0, this.width]).domain([0, sum]);

    this.colorScale = d3.scaleOrdinal().range(Colors.NPS);

    this.pie = d3
      .pie()
      .sort(null)
      .value((d: any) => d)
      .startAngle(0.5 * Math.PI * -1)
      .endAngle(0.5 * Math.PI);

    this.previousSum = this.sum;
    this.sum = sum;
  }

  setCanvas(dataChanges: SimpleChange | null): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const __this = this;
    const hoverFunction = function (event) {
      if (!__this.touchDevice || !__this.filtering || __this.anonymityLock) {
        const area = d3.pointer(event);
        __this.selectForHover(event, area, d3.select(this));
      }
    };
    const mouseOutFunction = (event) => {
      this.setTooltip(d3.pointer(event, this._element.nativeElement));
    };
    const clickFunction = function (event) {
      if (!__this.hideDistribution) {
        const area = d3.pointer(event);
        __this.selectFromBars(area, d3.select(this));
        __this.selectFromLegends(area, d3.select(this));
        __this.callFilter();
      }
    };

    const drawContent = function (d) {
      const context = d3.select(this).node().getContext('2d');

      context.clearRect(
        0,
        0,
        __this.width + __this.margin.right + __this.margin.left,
        __this.height + __this.margin.top + __this.margin.bottom,
      );

      __this.setLegends(context, d.distribution);

      if (dataChanges && !dataChanges.firstChange && __this.transitionDuration > 0) {
        const dataObj =
          __this.context && __this.context.data && __this.context.data ? __this.context.data.distribution : [];
        const previousNPSNumber =
          __this.context && __this.context.data && __this.context.data ? __this.context.data.npsScore : 0;
        const interpolator = d3.interpolateArray(dataObj, d.distribution);
        const interpolateSum = d3.interpolateNumber(__this.previousSum || 0, __this.sum);
        const interpolateNPSNumber = d3.interpolateNumber(previousNPSNumber, d.npsScore);
        const ease = d3.easeCubic;

        const t = d3.timer((elapsed) => {
          context.clearRect(
            0,
            0,
            __this.width + __this.margin.right + __this.margin.left,
            __this.height + __this.margin.top,
          );

          const step = elapsed / __this.transitionDuration;
          let data;
          let scaleX;
          let npsNumber;

          if (step >= 1) {
            data = interpolator(ease(1));
            npsNumber = interpolateNPSNumber(ease(1));
            scaleX = d3
              .scaleLinear()
              .rangeRound([0, __this.width])
              .domain([0, interpolateSum(ease(1))]);
            t.stop();
          } else {
            data = interpolator(ease(step));
            npsNumber = Math.round(interpolateNPSNumber(ease(step)));
            scaleX = d3
              .scaleLinear()
              .rangeRound([0, __this.width])
              .domain([0, interpolateSum(ease(step))]);
          }

          __this.setNPSNumber(context, npsNumber, __this.sum);
          __this.setGauge(context, npsNumber, d.color, __this.sum);
          __this.setRects(context, data, scaleX);
          __this.setTexts();
          __this.setTitle(context, d.title, __this.sum);
        });
      } else {
        __this.setNPSNumber(context, d.npsScore, __this.sum);
        __this.setGauge(context, d.npsScore, d.color, __this.sum);
        __this.setRects(context, d.distribution, __this.scaleX);
        __this.setTexts();
        __this.setTitle(context, d.title, __this.sum);
      }

      __this.context.context = context;
      __this.context.data = d;
    };

    this.canvas = this.base.selectAll('.nps-chart-canvas').data([this.NPSdata]);

    this.canvas.exit().remove();

    this.canvas
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .each(drawContent);

    this.canvas
      .enter()
      .append('canvas')
      .attr('class', 'nps-chart-canvas')
      .style('position', 'absolute')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .on('mousemove', hoverFunction)
      .on('mouseout', mouseOutFunction)
      .on('click', clickFunction)
      .each(drawContent);
  }

  setLegends(context, legends): void {
    this.legends = [];
    const y = this.margin.top + this.height + this.unit;
    const width = this.width + this.margin.left + this.margin.right;
    const height = 2 * this.unit;
    let usedSpace = 0;
    let arr: any[] = [];
    let currentX = 0;
    let currentY = 0;
    let margin;

    context.clearRect(0, y - 1, width, this.margin.bottom);

    if (!this.hideDistribution) {
      context.textBaseline = 'top';
      context.textAlign = 'left';
      context.font = 10 / 14 + 'em Inter';

      for (let i = 0, length = legends.length; i < length; i++) {
        const key = legends[i].key;
        const text = shortenText(context, legends[i].label, width, 3 * this.unit);
        const elW = context.measureText(text).width;

        if (usedSpace + elW + 2 * this.unit <= width) {
          usedSpace += elW + 2 * this.unit;
          arr.push([key, i, text]);
        } else {
          margin = (width - usedSpace) / 2;
          currentX = margin;

          for (let a = 0, len = arr.length; a < len; a++) {
            const itemText = arr[a][2];
            const itemColor = arr[a][1];
            const itemKey = arr[a][0];
            currentX += this.unit;

            context.fillStyle = Colors.TEXT;
            context.fillText(itemText, currentX, currentY + y);

            context.beginPath();
            context.arc(currentX - 0.8 * this.unit, currentY + y + 0.6 * this.unit, 0.5 * this.unit, 0, 2 * Math.PI);
            context.closePath();
            context.fillStyle = this.colorScale(itemColor);
            context.fill();
            this.legends.push({
              key: itemKey,
              x: currentX,
              y: currentY + y,
              width: context.measureText(itemText).width,
              height,
            });
            currentX += context.measureText(itemText).width + this.unit;
          }

          currentY += height;
          usedSpace = elW + 2 * this.unit;

          arr = [[key, i, text]];
        }
      }

      margin = (width - usedSpace) / 2;
      currentX = margin;

      for (let a = 0, len = arr.length; a < len; a++) {
        const itemText = arr[a][2];
        const itemColor = arr[a][1];
        const itemKey = arr[a][0];
        currentX += this.unit;

        context.font = 10 / 14 + 'em Inter';
        context.fillStyle = Colors.TEXT;
        context.fillText(itemText, currentX, currentY + y);

        context.beginPath();
        context.arc(currentX - 0.8 * this.unit, currentY + y + 0.6 * this.unit, 0.5 * this.unit, 0, 2 * Math.PI);
        context.closePath();
        context.fillStyle = this.colorScale(itemColor);
        context.fill();
        this.legends.push({
          key: itemKey,
          x: currentX,
          y: currentY + y,
          width: context.measureText(itemText).width,
          height,
        });
        currentX += context.measureText(itemText).width + this.unit;
      }

      const spaceNeeded = currentY / this.unit + 3;

      if (!isNaN(spaceNeeded) && this.marginBottomUnits !== spaceNeeded) {
        this.marginBottomUnits += spaceNeeded - this.marginBottomUnits;
        this.setEnvironment();
        this.setLegends(context, legends);
      }
    }
  }

  setTitle(context, data = null, answerCount = 0): void {
    context.globalAlpha = 1;
    context.fillStyle = Colors.TEXT;
    context.textAlign = 'left';
    context.textBaseline = 'middle';

    const h = this.margin.top / 2;

    const wIcon = this.fontSize + 4;

    context.font = 10 / 14 + 'em Inter';
    const wNumber = context.measureText(answerCount).width + 8;

    context.font = 12 / 14 + 'em Inter';
    const title = data != null ? shortenText(context, data, this.width, 8 + (wIcon + wNumber) / 2) : '';

    const wTitle = title ? context.measureText(title).width + 8 : 0;

    const startPoint = this.margin.left + this.width / 2 - (wIcon + wNumber + wTitle) / 2;

    if (title) {
      context.fillText(title, startPoint, h);
    }

    drawContactIcon(context, this.fontSize, startPoint + wTitle, h, context.fillStyle);

    context.font = 10 / 14 + 'em Inter';
    context.fillText(answerCount, startPoint + wTitle + wIcon, h);
  }

  setGauge(context, data, colorIndex: number | null, answerCount: number = 0): void {
    const arc = d3
      .arc()
      .outerRadius(this.arcRadius - 10)
      .innerRadius(this.arcRadius / 1.667)
      .padAngle(0.02)
      .context(context);

    const percentage: number = (data + 100) / 200;

    const arcs = this.pie([percentage, 1 - percentage]);

    context.save();
    context.translate(this.margin.left + this.width / 2, this.margin.top + this.height / 1.5);
    context.globalAlpha = answerCount > 0 ? 1 : 0.2;
    arcs.forEach((d: any) => {
      context.beginPath();
      arc(d);

      context.fillStyle =
        colorIndex != null
          ? Colors.getComparisonColor(colorIndex)
          : this.selectionExists
            ? Colors.SELECTED
            : Colors.DEFAULT;

      context.fill();
    });

    context.font = 10 / 14 + 'em Inter';
    context.fillStyle = Colors.TEXT;
    context.textBaseline = 'alphabetic';
    context.textAlign = 'right';
    context.fillText('-100', -this.arcRadius + 2, -7);

    context.textAlign = 'left';
    context.fillText('100', this.arcRadius - 2, -7);

    context.restore();
  }

  setNPSNumber(context, data, answerCount: number = 0): void {
    const height: number = this.arcRadius / 2.5;
    const number: string = answerCount > 0 ? data.toString() : '-';
    context.font = height + 'px Inter';
    context.textAlign = 'center';
    context.textBaseline = 'alphabetic';
    context.fillStyle = Colors.TEXT;

    context.fillText(number, this.margin.left + this.width / 2, this.margin.top + this.height / 1.5);

    context.font = this.height / 20 + 'px Inter';
    context.textBaseline = 'top';

    context.fillText('NPS', this.margin.left + this.width / 2, this.margin.top + this.height / 1.5 + 4);
  }

  setRects(context, data: NPSDistribution[] = [], scaleX, highlight: any[] = []): void {
    context.clearRect(
      0,
      this.margin.top + (3 * this.height) / 4 - 2,
      this.margin.left + this.margin.right + this.width,
      this.height / 4 + 4,
    );

    this.selections = new Set((this.filterInput || []).filter((item) => item != null));

    const matching = (filter, orig) => {
      let match = false;
      for (const i of orig) {
        if (filter.indexOf(i) >= 0) {
          match = true;
          break;
        }
      }
      return match;
    };

    if (!this.hideDistribution) {
      for (let i = 0, len = data.length; i < len; i++) {
        const key = data[i].key;
        const start = data[i].cumulativeValue;
        const value = data[i].value;
        const percentage = data[i].percentage;
        const originalValues = data[i].originalValues;

        const width = value ? scaleX(value) : 0;
        const height = this.height / 6;
        const xPos = this.margin.left + scaleX(start);
        const yPos = this.margin.top + (3 * this.height) / 4 + this.height / 12;

        context.fillStyle = this.colorScale(i);
        context.globalAlpha = 1;

        if (this.filterInput && this.filterInput && this.filterInput.length > 0) {
          const parent = matching(this.filterInput, originalValues);

          if (!parent) {
            context.globalAlpha = 0.2;
          } else {
            context.globalAlpha = 1;
          }
        }

        context.lineWidth = 1;
        context.strokeStyle = Colors.BACKGROUND;

        if (highlight && highlight.length > 0) {
          const yHi = highlight[0] && highlight[0].indexOf(key) > -1;

          if (yHi && parent) {
            context.lineWidth = 2;
            context.strokeStyle = Colors.HIGHLIGHT;
          }
        }

        const leftRound = data.findIndex((item) => item.value > 0);
        const rightRound = data[2] && data[2]['value'] > 0 ? 2 : data[1] && data[1]['value'] > 0 ? 1 : 0;

        drawRoundRect(
          context,
          xPos,
          yPos,
          width,
          height,
          {
            tl: leftRound === i ? 5 : 0,
            tr: rightRound === i ? 5 : 0,
            bl: leftRound === i ? 5 : 0,
            br: rightRound === i ? 5 : 0,
          },
          context.fillStyle,
          true,
        );

        // if (this.showNumbers) {
        context.font = 10 / 14 + 'em Inter';
        context.textBaseline = 'middle';
        context.textAlign = 'center';
        context.fillStyle = Colors.TEXT;
        context.fillText(
          value > 0 ? (this.scale === 'percentage' ? (percentage * 100).toFixed(1) + '%' : Math.round(value)) : '',
          xPos + width / 2,
          yPos + height / 2,
        );
        // }

        context.globalAlpha = 1;
      }
    } else {
      context.font = 24 / 14 + 'em zef-icons-full';
      context.textBaseline = 'middle';
      context.textAlign = 'center';
      context.fillStyle = Colors.UNSELECTED;
      context.lineWidth = 1;
      context.strokeStyle = Colors.BACKGROUND;

      drawRoundRect(
        context,
        this.margin.left,
        this.margin.top + (3 * this.height) / 4 + this.height / 12,
        this.width,
        this.height / 6,
        5,
        true,
        true,
      );

      context.fillStyle = Colors.TEXT;
      context.fillText(
        'anonymous_on',
        this.margin.left + this.width / 2,
        this.margin.top + (3 * this.height) / 4 + (2 * this.height) / 12,
      );
    }
  }

  setTexts() {}

  setTooltip(position, data: any[] = []): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const __this = this;

    if (data.length > 0 && data[0] && !this.hideDistribution) {
      if (JSON.stringify(data[0]) !== JSON.stringify(this.tooltipData)) {
        this.tooltip.html(
          `
            <div class="question">${data[0].label}</div>
            <div class="stats">
              <span class="icon">contact</span> ${data[0].value} (${(data[0].percentage * 100).toFixed(1)}%)
            </div>
          `,
        );
      }
      this.tooltip.style('display', 'block').style('transform', function () {
        return `
              translate(${position[0] - this.offsetWidth / 2}px,${position[1] - this.offsetHeight - 24}px)`;
      });
    } else {
      this.tooltip.html('').style('display', 'none');
    }

    this.tooltipData = data[0];

    // adding hovering effect
    this.base.selectAll('.nps-chart-canvas').each(function (d) {
      const highlight = data.map((item) => item.key);

      __this.setRects(__this.context.context, d.distribution, __this.scaleX, highlight);
    });

    if (data.length > 0 && this.filtering && !this.anonymityLock && !this.hideDistribution) {
      this.base.select('.nps-chart-canvas').style('cursor', 'pointer');
    } else {
      this.base.select('.nps-chart-canvas').style('cursor', null);
    }
  }

  callFilter(): void {
    if (this.filtering && !this.anonymityLock) {
      this.filter = [];

      const filter = {
        key: this.domain.key,
        values: this.domain.keys,
        filter: Array.from(this.selections),
      };

      let customName: string = '';
      for (const item of this.NPSdata['distribution']) {
        if (JSON.stringify(Array.from(this.selections)).indexOf(JSON.stringify(item.originalValues)) >= 0) {
          customName += customName.length > 0 ? ', ' : '';
          customName += item.label;
        }
      }

      if (customName.length > 0) {
        filter['customName'] = customName;
      }

      this.filter.push(filter);
      this.cf.filter(this.filter);
    }
  }

  // Helpers
  multiLineWrap(context, text, width, height, lineheight): string[] {
    const textLength = context.measureText(text).width;
    const room = Math.floor(height / lineheight);

    if (textLength > width - 10 && text.length > 0) {
      const words = text.split(/\s+/).reverse();
      const lines: string[] = [];
      let limitReached = false;
      let line = '';
      let word;

      while ((word = words.pop())) {
        const testLine = line + word + ' ';
        const metrics = context.measureText(testLine);
        const testWidth = metrics.width;
        if (testWidth > width - 10 && line.length > 0) {
          if (lines.length + 1 === room) {
            lines.push(line + '...');
            limitReached = true;
            break;
          } else {
            lines.push(line);
          }

          line = word + ' ';
        } else {
          line = testLine;
        }
      }

      if (!limitReached) {
        lines.push(line);
      }

      return lines;
    } else {
      return [text];
    }
  }

  selectForHover(event, area, elem): void {
    const itemsBelow = this.itemsBelow(area, elem);
    const parents = itemsBelow.parents;

    const legends = itemsBelow.legends;

    if (parents.length > 0) {
      this.setTooltip(d3.pointer(event, this._element.nativeElement.firstElementChild), parents);
    } else if (legends.length > 0) {
      const legendItems = elem
        .datum()
        .distribution.filter((item) => legends.find((legend) => legend.key.toString() === item.key));

      this.setTooltip(d3.pointer(event, this._element.nativeElement.firstElementChild), legendItems);
    } else {
      this.setTooltip(d3.pointer(event, this._element.nativeElement.firstElementChild));
    }
  }

  selectFromBars(area, elem): void {
    const itemsBelow = this.itemsBelow(area, elem);
    const parents = itemsBelow.parents;

    for (let p = 0, len = parents.length; p < len; p++) {
      const sel = this.selections;
      const newItems = parents[p]['originalValues'].find((item) => !sel.has(item));

      for (let o = 0, leno = parents[p]['originalValues'].length; o < leno; o++) {
        if (newItems == null) {
          this.selections.delete(parents[p]['originalValues'][o]);
        } else {
          this.selections.add(parents[p]['originalValues'][o]);
        }
      }
    }
  }

  selectFromLegends(area, elem): void {
    const legends = this.itemsBelow(area, elem).legends;
    const values = elem.datum().distribution;

    for (let l = 0, len = legends.length; l < len; l++) {
      const sel = this.selections;
      const legendValues = values.find((val) => legends[l]['key'] === val.key);
      const originalValues = legendValues ? legendValues.originalValues : [];
      const newItems = originalValues.length > 0 ? originalValues.find((item) => !sel.has(item)) : null;

      for (let o = 0, leno = originalValues.length; o < leno; o++) {
        if (newItems == null) {
          this.selections.delete(originalValues[o]);
        } else {
          this.selections.add(originalValues[o]);
        }
      }
    }
  }

  itemsBelow(area, elem): { parents: ChartDistribution[]; legends: ChartLegend[] } {
    const parents = elem.datum().distribution.filter((item) => {
      const start = item.cumulativeValue;
      const value = item.value;

      const width = this.scaleX(value);
      const height = this.height / 4;
      const xPos = this.margin.left + this.scaleX(start);
      const yPos = this.margin.top + (3 * this.height) / 4;

      return area[1] < yPos + height && area[1] > yPos && area[0] < xPos + width && area[0] > xPos;
    });

    const legends = this.legends.filter(
      (item) =>
        area[0] > item.x - 15 && area[0] < item.x + item.width && area[1] > item.y && area[1] < item.y + item.height,
    );

    return { parents, legends };
  }
}
