/**
 * Main module for the report.
 *
 * @stable
 */

import { environment } from '@env/environment';

import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { APP_BASE_HREF, LocationStrategy, PlatformLocation } from '@angular/common';

import { MatIconRegistry } from '@angular/material/icon';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';

import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';

import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxsModule, Store } from '@ngxs/store';

import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

import { NgxWebstorageModule } from 'ngx-webstorage';
import { NgEventOptionsModule } from 'ng-event-options';

import { ZefErrorHandler } from '@shared/error.handler';

import { PublicModule } from '@report/shared/modules/public.module';

import { LocalesModule } from '@shared/modules/locales.module';

import { StorageLocation } from '@shared/strategies/storage-location.strategy';
import { InitAuthentication } from '@shared/states/auth.actions';
import { AuthModule } from '@auth/auth.module';
import { HelpCenterModule } from '@shared/modules/help-center/help-center.module';
import { DialogState } from '@shared/states/dialog.state';
import { LocalesState } from '@shared/states/locales/locales.state';

import { ZefApp } from './zef.component';
import { ZefRouting } from './zef.routing';

@NgModule({
  bootstrap: [ZefApp],
  declarations: [ZefApp],
  exports: [],
  imports: [
    ZefRouting,
    AuthModule,
    PublicModule,
    HelpCenterModule,
    LocalesModule,
    NgEventOptionsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HammerModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgxsModule.forRoot([DialogState, LocalesState], {
      developmentMode: false,
    }),
    NgxWebstorageModule.forRoot({
      prefix: 'zef',
      separator: '.',
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: true,
    }),
    AngularFireDatabaseModule,
    AngularFireModule.initializeApp(environment.firebase, 'zefApp'),
  ],
  providers: [
    ZefErrorHandler,
    {
      provide: ErrorHandler,
      useExisting: ZefErrorHandler,
    },
    {
      provide: APP_BASE_HREF,
      useValue: '/',
    },
    {
      provide: APP_INITIALIZER,
      useFactory: authInitializer,
      deps: [Store],
      multi: true,
    },
    {
      provide: LocationStrategy,
      useClass: StorageLocation,
      deps: [APP_BASE_HREF, PlatformLocation],
    },
  ],
})
export class ZefModule {
  constructor(registry: MatIconRegistry) {
    registry.registerFontClassAlias('zeficons', 'zef-icons-full');
    registry.setDefaultFontSetClass('zef-icons-full');
  }
}

export function authInitializer(store: Store): () => Promise<void> {
  return async () => {
    store.dispatch(new InitAuthentication());
  };
}
