import { BehaviorSubject } from 'rxjs';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import { Colors } from '@report/shared/enums/colors.enum';
import {
  ChartDistribution,
  ChartDomain,
  ChartSettings,
  ChartStats,
  DimensionDataItem,
  DistributionData,
  SummaryChartChoiceGroup,
  SummaryChartChoiceData,
  SummaryChartChoiceDataChoice,
} from '@shared/models/report.model';

import * as d3 from 'd3';
import { Crossfilter } from '@report/shared/services/crossfilter.service';

/**
 * This is a Summary Choice chart component.
 */
@Component({
  selector: 'summary-choice',
  templateUrl: './summary-choice.component.html',
  styleUrls: ['./summary-choice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryChoice implements OnChanges, OnInit {
  @Input() distribution: ChartDistribution[][] = [];
  @Input() stats: ChartStats[] = [];
  @Input() domain: ChartDomain[] = [];
  @Input() details: DimensionDataItem[] = [];
  @Input() filterInput: any;
  @Input() showNumbers: boolean = false;
  @Input() scale: string = 'percentage';
  @Input() transitionDuration: number = 0;
  @Input() update: Date = new Date();
  @Input() comparison: any;
  @Input() comparisonMode: string = 'grouped';
  @Input() selectionExists: boolean = false;
  @Input() filtersDemo: boolean = false;
  @Input() anonymityTreshold: number = null;
  @Input() crossfilter: Crossfilter | null = null;
  @Input() filtering: boolean = false;
  @Input() anonymityLock: boolean = false;

  @Input() chartSettings: ChartSettings = {} as ChartSettings;

  @Output() settingsChange: EventEmitter<any> = new EventEmitter<any>();

  public chartData$: BehaviorSubject<SummaryChartChoiceGroup[]> = new BehaviorSubject<SummaryChartChoiceGroup[]>([]);
  public choices: SummaryChartChoiceDataChoice[] = [];

  public maxs: number[][] = [];
  public previousMaxs: number[][] = [];
  public percentageValues: boolean = false;
  public previousPercentageValues: boolean = false;
  public hasSelections: boolean = false;
  public exportChart: boolean = false;

  public hoveredGroupKey: string = '';
  public hoveredChoice: number = null;
  public hoveredOverallChoice: number = null;
  public hoveredBarChoice: number[] = [];
  public hoveredBar: number = null;
  public hoveredContent: string = '';
  public tooltipPosition: number[] = [];

  public sizeUpdate: number = 0;
  public rowHeight: number = 0;

  private filter: any;
  private selections: any = [];
  private dataService: Crossfilter | null = this.cf;

  get Math() {
    return Math;
  }

  @ViewChildren('legendsContainer') legendsContainerElem!: QueryList<ElementRef>;
  @ViewChild('tooltip') tooltipEl!: ElementRef;

  constructor(
    private _element: ElementRef,
    private cdRef: ChangeDetectorRef,
    private cf: Crossfilter,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.exportChart = this.hasParentClass(this._element.nativeElement, 'export-chart');

    if (
      changes.distribution ||
      changes.stats ||
      changes.domain ||
      changes.details ||
      changes.filterInput ||
      changes.update ||
      changes.comparison ||
      changes.comparisonMode ||
      changes.filtersDemo ||
      changes.chartSettings
    ) {
      this.updateChart();
    }
  }

  ngOnInit(): void {
    const base = d3.select(this._element.nativeElement);

    base
      .on('mousemove', (event) => {
        const pos = d3.pointer(event);

        if (this.hoveredChoice != null || this.hoveredBar != null) {
          const w = (this.tooltipEl && this.tooltipEl.nativeElement && this.tooltipEl.nativeElement.offsetWidth) || 0;
          const h = (this.tooltipEl && this.tooltipEl.nativeElement && this.tooltipEl.nativeElement.offsetHeight) || 0;

          this.tooltipPosition[0] = pos[0] - w / 2;
          this.tooltipPosition[1] = pos[1] - h - 16;
          this.cdRef.detectChanges();
        }
      })
      .on('mouseout', () => {
        this.hoveredChoice = null;
        this.hoveredBar = null;
        this.cdRef.detectChanges();
      });
  }

  private updateChart(): void {
    this.setData();
    this.setSizes();
  }

  private setSizes(): void {
    const availableExportHeight: number = 360 - (this.choices?.length ? Math.ceil(this.choices?.length / 2) * 20 : 0);
    const aH = this._element.nativeElement.clientHeight;
    const lHs = Array.from(this.legendsContainerElem || []).map((elem) => elem && elem.nativeElement.clientHeight);
    const lH = Math.max(...lHs, 0);
    const count = this.comparison?.values?.length || 1;
    const gaps = 16 * count + (count > 1 ? 16 * (count - 1) : 0) + (this.comparison?.values?.length ? count * 40 : 0);
    const bars =
      this.domain.filter((dom) => dom.scale === 'categorical').length - (this.comparison?.values?.length ? 1 : 0);
    this.rowHeight = !this.exportChart
      ? Math.max(Math.floor((aH - lH * count - gaps) / (bars * count || 1)), 48)
      : Math.min(Math.max(28, availableExportHeight / (bars || 1)), 80);
  }

  private setData(): void {
    if (this.crossfilter) {
      this.dataService = this.crossfilter;
    } else {
      this.dataService = this.cf;
    }

    this.previousPercentageValues = this.percentageValues;
    this.percentageValues = this.scale === 'percentage';
    this.selections = (this.filterInput || []).map((f) => new Set(f));
    this.hasSelections = this.selections.some((s) => s.size > 0);

    this.setChoices();
    const chartData: SummaryChartChoiceGroup[] = [];

    for (let g = 0, leng = this.comparison?.values?.length || 1; g < leng; g++) {
      const compDom: ChartDomain = this.domain[this.comparison?.index];
      const compDet: DimensionDataItem = this.details[this.comparison?.index];
      const compItemKey: string = compDet?.values?.[this.comparison?.values?.[g]]?.toString() || 'no-comparison';
      const title: string = compDom?.labels[compItemKey] || '';
      const color: string = Colors.COMPARISON[compDom?.colors[compItemKey]] || '';
      const data: SummaryChartChoiceData[] = [];

      for (let i = 0, len = this.domain?.length; i < len; i++) {
        if (this.domain[i]?.scale === 'categorical' && i !== this.comparison?.index) {
          const key = this.domain[i].key;
          const prevGroup: SummaryChartChoiceData[] = this.chartData$.value?.find((pv) => pv.key === compItemKey)?.data;
          const prevItem: SummaryChartChoiceData =
            prevGroup?.[i] && prevGroup[i]['key'] === key ? prevGroup[i] : prevGroup?.find((pv) => pv.key === key);
          const customColors = this.domain[i]?.itemColors || {};

          const colors = this.domain[i]?.keys?.map(
            (domItemKey) =>
              customColors[domItemKey] ||
              this.getColor(
                i,
                this.choices.findIndex((c) => c.title === this.domain[i]?.labels?.[domItemKey]),
                color,
              ),
          );
          const values = this.comparison?.values?.length
            ? this.distribution[i]?.map(
                (d) =>
                  d?.['children']?.find((child) => child.key === compItemKey)?.[
                    this.scale === 'percentage' ? 'percentage_all' : 'value'
                  ],
              )
            : this.distribution[i].map((d) => d[this.scale === 'percentage' ? 'percentage' : 'value']);
          const selections: boolean[] = this.selections[i]?.size
            ? this.distribution[i].map((d) => this.selections[i]?.has(d.key))
            : [];

          const item: SummaryChartChoiceData = {
            key,
            title: this.domain[i].title,
            n: this.stats[i].count,
            colors,
            previousValues: prevItem ? prevItem.values : [],
            values,
            selections,
          };

          data.push(item);
        }
      }

      chartData.push({
        key: compItemKey,
        title,
        color,
        data,
      });
    }

    const sums: number[][] = [];
    const maxs: number[] = [];

    for (let i = 0, len = chartData?.length; i < len; i++) {
      const groupSums: number[] = [];
      for (let j = 0, lenj = chartData[i]?.['data']?.length; j < lenj; j++) {
        let sum = 0;
        chartData[i]['data'][j]?.['values'].forEach((c) => {
          sum += c;
        });
        groupSums.push(sum);
        maxs.push(sum);
      }
      sums.push(groupSums);
    }

    this.previousMaxs = this.maxs;
    this.maxs = sums?.map((groupSum) =>
      this.scale === 'percentage' ? groupSum : groupSum.map(() => Math.max(...maxs)),
    );

    this.chartData$.next(chartData);
  }

  private setChoices(): void {
    this.choices = [];

    for (let i = 0, len = this.domain?.length; i < len; i++) {
      const key = this.domain[i].key;

      if (this.domain[i]?.scale === 'categorical' && (!this.comparison?.key || this.comparison?.key !== key)) {
        for (let di = 0, lendi = this.distribution[i].length; di < lendi; di++) {
          const distrItem: DistributionData = this.distribution[i][di];
          const distrItemKey: string = distrItem.key;
          const index = this.choices.findIndex((c) => c.title === this.domain[i].labels[distrItemKey]);
          const color = this.domain[i]?.itemColors?.[distrItemKey] || '';

          if (index >= 0) {
            this.choices[index]['choicesIncluded'][key] = distrItemKey;
            if (color && !this.choices[index]?.['colorsIncluded'].includes(color)) {
              this.choices[index]['colorsIncluded'].push(color);
            }
          } else {
            const choicesIncluded = {};
            choicesIncluded[key] = distrItemKey;
            const colorsIncluded = color ? [color] : [];
            const newChoiceItem: SummaryChartChoiceDataChoice = {
              key: '',
              title: this.domain[i].labels[distrItemKey],
              choicesIncluded,
              colorsIncluded,
              color: '',
            };
            this.choices.push(newChoiceItem);
          }
        }
      }
    }

    for (let i = 0, len = this.choices.length; i < len; i++) {
      this.choices[i]['color'] = this.choices[i]['colorsIncluded']?.[0] || this.getColor(0, i);
    }
  }

  private getColor(questionIndex, choiceIndex, comparisonColor?): string {
    const color = d3.hsl(
      comparisonColor
        ? comparisonColor
        : this.filterInput?.[questionIndex]
          ? Colors.FILTER
          : this.selectionExists
            ? Colors.SELECTED
            : this.filtersDemo
              ? Colors.UNSELECTED
              : Colors.DEFAULT,
    );
    color.s = color.s - (color.s - 0.2) * (choiceIndex / (this.choices?.length || 1));
    if (choiceIndex % 2) {
      color.l = color.l + 0.1;
    }

    return d3.rgb(color).toString();
  }

  private updateTooltip(): void {
    const questionTitle: string = this.domain[this.hoveredBar]?.title;
    const choiceKey: string = this.distribution[this.hoveredBar]?.[this.hoveredChoice]?.key;
    const choiceTitle: string = this.domain[this.hoveredBar]?.labels?.[choiceKey];
    const count: string | number =
      this.hoveredChoice != null
        ? this.distribution[this.hoveredBar]?.[this.hoveredChoice]
          ? `${
              !this.comparison?.values?.length
                ? this.distribution[this.hoveredBar][this.hoveredChoice]['value']
                : this.distribution[this.hoveredBar][this.hoveredChoice]?.['children']?.find(
                    (item) => item.key === this.hoveredGroupKey,
                  )?.['value']
            } (${(
              (!this.comparison?.values?.length
                ? this.distribution[this.hoveredBar][this.hoveredChoice]['percentage']
                : this.distribution[this.hoveredBar][this.hoveredChoice]?.['children']?.find(
                    (item) => item.key === this.hoveredGroupKey,
                  )?.['percentage_all']) * 100
            ).toFixed(1)}%)`
          : '-'
        : this.stats[this.hoveredBar]?.count;
    const titleText: string = `<div class="question">${
      (questionTitle || '') + (choiceTitle ? (questionTitle ? ': ' : '') + choiceTitle : '')
    }</div>`;
    const statsText: string = `<div class="stats"><span class="icon">contact</span> ${count}</div>`;

    this.hoveredContent = titleText + statsText;
  }

  private callFilter(domIndex: number): void {
    if (this.domain[domIndex] && this.filtering && !this.anonymityLock) {
      this.filter = [];
      const filterX = {
        key: this.domain[domIndex]?.key,
        values: this.domain[domIndex].keys,
        filter: Array.from(this.selections[domIndex]),
      };

      this.filter.push(filterX);

      const filterInput = JSON.stringify(this.filterInput[domIndex]?.map((item) => (item == null ? [] : item)));
      const filter = JSON.stringify(this.filter.map((item) => item.filter));

      if (filter !== filterInput) {
        this.dataService.filter(this.filter);
      }
    }
  }

  trackById(i: number, item: any[]): number {
    return (item && item['id']) || null;
  }

  trackByTitle(i: number, item: any[]): number {
    return (item && item['title']) || null;
  }

  public onFilter(): void {
    const itemKey = this.distribution[this.hoveredBar]?.[this.hoveredChoice]?.key;

    if (itemKey) {
      if (this.selections[this.hoveredBar].has(itemKey)) {
        this.selections[this.hoveredBar].delete(itemKey);
      } else {
        this.selections[this.hoveredBar].add(itemKey);
      }
      this.callFilter(this.hoveredBar);
    }
  }

  public newHoveredChoice(choiceIndex): void {
    this.hoveredChoice = choiceIndex;
    const choiceKey: string = this.distribution[this.hoveredBar]?.[this.hoveredChoice]?.key;
    const choiceTitle: string = this.domain[this.hoveredBar]?.labels?.[choiceKey];
    const overallIndex = this.choices.findIndex((c) => c.title === choiceTitle);
    this.hoveredOverallChoice = overallIndex > -1 ? overallIndex : null;
    this.hoveredBarChoice = this.distribution
      .map((d, i) => [d, this.domain[i]] as [DistributionData[], ChartDomain])
      .filter((d) => d[1]?.scale === 'categorical' && d[1]?.key !== this.comparison?.key)
      .map((d) =>
        d[0]?.findIndex(
          (dItem) => this.choices[this.hoveredOverallChoice]?.choicesIncluded?.[d[1]?.key] === dItem?.key,
        ),
      )
      .map((d) => (this.hoveredOverallChoice != null ? d : null));
    this.updateTooltip();
  }

  public newHoveredChoiceFromLegends(choiceIndex): void {
    this.hoveredOverallChoice = choiceIndex;
    this.hoveredBarChoice = this.distribution
      .map((d, i) => [d, this.domain[i]] as [DistributionData[], ChartDomain])
      .filter((d) => d[1]?.scale === 'categorical' && d[1]?.key !== this.comparison?.key)
      .map((d) => d[0]?.findIndex((dItem) => this.choices[choiceIndex]?.choicesIncluded?.[d[1]?.key] === dItem?.key))
      .map((d) => (this.hoveredOverallChoice != null ? d : null));
  }

  public resizeChart(): void {
    this.setSizes();
    this.sizeUpdate = new Date().valueOf();
  }

  hasParentClass(element, classname): boolean {
    if (element.className && element.className.split(' ').indexOf(classname) >= 0) {
      return true;
    }

    return !!element.parentNode && this.hasParentClass(element.parentNode, classname);
  }
}
