/**
 * Standalone login module.
 *
 * @unstable
 */

import { NgModule } from '@angular/core';
import { AuthSharedModule } from '@auth/auth-shared.module';
import { AuthSidenavModule } from '@auth/auth-sidenav.module';
import { AuthApp } from '@auth/auth.component';
import { AuthRouting } from '@auth/auth.routing';
import { InvalidVerification } from '@auth/invalid-verification/invalid-verification.component';
import { ResetPassword } from '@auth/reset-password/reset-password.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [AuthApp, InvalidVerification, ResetPassword],
  exports: [],
  imports: [AuthRouting, AuthSidenavModule, AuthSharedModule, SharedModule],
  providers: [],
})
export class AuthModule {}
