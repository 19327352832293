/**
 * App routing for the report.
 *
 * @stable
 */

import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ReportApp } from '@report/report.component';

import { OfflineView } from '@report/+offline/offline.component';

import { SurveyBasics } from '@shared/resolves/survey-basics.resolve';

import { SavedReports } from '@report/shared/resolves/saved-reports.resolve';

import { BillingStatus } from '@home/shared/resolves/billing-status.resolve';

import { AuthApp } from '@auth/auth.component';
import { AuthGuard } from '@auth/auth.guard';

export const ZefRoutes: Routes = [
  {
    path: '',
    children: [
      { path: 'offline', component: OfflineView },

      { path: 'private/:linkKey', canActivate: [AuthGuard], component: AuthApp },

      // Used for private shared reports - url is not changed on browser and thus this is not seen by shared user
      {
        path: 'report/:survey/:report',
        component: ReportApp,
        data: { shared: true },
        resolve: { survey: SurveyBasics, reports: SavedReports, billing: BillingStatus },
      },

      {
        path: 'preview/:survey/:report',
        component: ReportApp,
        data: { shared: true },
        resolve: { survey: SurveyBasics, reports: SavedReports },
      },

      { path: 'templates/:template', component: ReportApp, data: { shared: true } },

      { path: ':public', component: ReportApp, data: { shared: true } },

      { path: '**', redirectTo: 'demo' },
    ],
  },
];

export const ZefRouting: ModuleWithProviders<RouterModule> = RouterModule.forRoot(ZefRoutes, {
  paramsInheritanceStrategy: 'always',
  enableTracing: false,
});
