<div class="z-fx-col z-fx-auto chart-container" (elementResize)="onViewResize($event)">
  <div
    *ngIf="
      content &&
      content.distributions &&
      content.domain &&
      isData &&
      settings.type &&
      (!anonymityStatus.hide || settings.type === chartTypes.NPS) &&
      !anonymityStatus.trend
    "
    class="z-fx-col z-fx-auto"
  >
    <!--  Single dimension distribution charts  -->
    <div class="z-fx-col z-fx-none">
      <div *ngFor="let item of content.domain; let i = index" [ngSwitch]="settings.type" class="z-fx-col z-fx-none">
        <bar-chart-table
          *ngSwitchCase="chartTypes.BARCHARTH"
          class="z-fx-col barChartH"
          [style.height]="chartItemHeight"
          [data]="content.distributions"
          [dataIndex]="i"
          [stats]="content.stats"
          [scale]="settings.scale"
          [filterInput]="content.filters"
          [domain]="content.domain[i]"
          [transitionDuration]="transition"
          [showNumbers]="settings.showNumbers"
          [update]="updateChart"
          [filtering]="filtering"
          [anonymityLock]="anonymityStatus?.atRisk"
          [title]="content.domain.length > 1 ? content.domain[i].title : ''"
          [chartType]="chartTypes.BARCHARTH"
          [totalAnswers]="content.totalAnswers"
          [selectionExists]="selectionExists"
          [filtersDemo]="filtersDemo"
          [touchDevice]="touchDevice"
          [chartSettings]="settings"
          [crossfilter]="crossfilter"
          (settingsChange)="settingsChange.emit($event)"
        ></bar-chart-table>
        <div
          *ngSwitchCase="chartTypes.BARCHARTV"
          class="barChartV"
          [style.height]="chartItemHeight"
          barChartV
          [data]="content.distributions[i]"
          [stats]="content.stats[i]"
          [scale]="settings.scale"
          [filterInput]="content.filters ? content.filters[i] : null"
          [domain]="content.domain[i]"
          [transitionDuration]="transition"
          [showNumbers]="settings.showNumbers"
          [update]="updateChart"
          [filtering]="filtering"
          [anonymityLock]="anonymityStatus?.atRisk"
          [title]="content.domain.length > 1 ? content.domain[i].title : ''"
          [totalAnswers]="content.totalAnswers[i]"
          [selectionExists]="selectionExists"
          [filtersDemo]="filtersDemo"
          [touchDevice]="touchDevice"
        ></div>
        <div
          *ngSwitchCase="chartTypes.PIECHART"
          class="pieChart"
          [style.height]="chartItemHeight"
          pieChart
          [data]="content.distributions[i]"
          [stats]="content.stats[i]"
          [scale]="settings.scale"
          [filterInput]="content.filters ? content.filters[i] : null"
          [domain]="content.domain[i]"
          [transitionDuration]="transition"
          [update]="updateChart"
          [filtering]="filtering"
          [anonymityLock]="anonymityStatus?.atRisk"
          [title]="content.domain.length > 1 ? content.domain[i].title : ''"
          [totalAnswers]="content.totalAnswers[i]"
          [selectionExists]="selectionExists"
          [filtersDemo]="filtersDemo"
          [touchDevice]="touchDevice"
        ></div>
        <div
          *ngSwitchCase="chartTypes.LINECHART"
          class="lineChart"
          [style.height]="chartItemHeight"
          lineChart
          [data]="content.distributions[i]"
          [scale]="settings.scale"
          [filterInput]="content.filters ? content.filters[i] : null"
          [domain]="content.domain[i]"
          [transitionDuration]="transition"
          [showNumbers]="settings.showNumbers"
          [update]="updateChart"
          [filtering]="filtering"
          [anonymityLock]="anonymityStatus?.atRisk"
          [title]="content.domain.length > 1 ? content.domain[i].title : ''"
          [totalAnswers]="content.totalAnswers[i]"
          [selectionExists]="selectionExists"
          [filtersDemo]="filtersDemo"
          [touchDevice]="touchDevice"
        ></div>
        <div
          *ngSwitchCase="chartTypes.DONUTCHART"
          class="donutChart"
          [style.height]="chartItemHeight"
          donutChart
          [data]="content.distributions[i]"
          [stats]="content.stats[i]"
          [scale]="settings.scale"
          [filterInput]="content.filters ? content.filters[i] : null"
          [domain]="content.domain[i]"
          [transitionDuration]="transition"
          [update]="updateChart"
          [filtering]="filtering"
          [anonymityLock]="anonymityStatus?.atRisk"
          [title]="content.domain.length > 1 ? content.domain[i].title : ''"
          [totalAnswers]="content.totalAnswers[i]"
          [selectionExists]="selectionExists"
          [filtersDemo]="filtersDemo"
          [touchDevice]="touchDevice"
        ></div>
        <div
          *ngSwitchCase="chartTypes.MULTIDONUTCHART"
          class="multiDonutChart"
          [style.height]="chartItemHeight"
          multiDonutChart
          [data]="content.distributions[i]"
          [stats]="content.stats[i]"
          [scale]="settings.scale"
          [filterInput]="content.filters ? content.filters[i] : null"
          [domain]="content.domain[i]"
          [transitionDuration]="transition"
          [update]="updateChart"
          [filtering]="filtering"
          [anonymityLock]="anonymityStatus?.atRisk"
          [title]="content.domain.length > 1 ? content.domain[i].title : ''"
          [totalAnswers]="content.totalAnswers[i]"
          [selectionExists]="selectionExists"
          [filtersDemo]="filtersDemo"
          [touchDevice]="touchDevice"
        ></div>
        <div
          *ngSwitchCase="chartTypes.WORDCLOUD"
          class="wordCloud"
          [style.height]="chartItemHeight"
          wordCloud
          [data]="content.distributions[i]"
          [scale]="settings.scale"
          [filterInput]="content.filters ? content.filters[i] : null"
          [domain]="content.domain[i]"
          [transitionDuration]="transition"
          [update]="updateChart"
          [filtering]="filtering"
          [anonymityLock]="anonymityStatus?.atRisk"
          [title]="content.domain.length > 1 ? content.domain[i].title : ''"
          [totalAnswers]="content.totalAnswers[i]"
          [crossfilter]="crossfilter"
          [touchDevice]="touchDevice"
          [isSharedReport]="isSharedReport"
        ></div>
      </div>
    </div>

    <div [ngSwitch]="settings.type" class="z-fx-col z-fx-none">
      <!--  Multidimension distribution charts  -->

      <div *ngSwitchCase="chartTypes.BARCHARTHPLUSTEXT" [style.height]="chartHeight">
        <bar-chart-table
          *ngIf="!content.trend; else trendVersion"
          class="z-fx-col"
          [class.barChartH]="!content.comparison"
          [class.multiBarChartH]="content.comparison"
          [style.height]="chartHeightDivider(chartHeight, 50)"
          [data]="content.distributions"
          [dataIndex]="0"
          [dataYIndex]="!content.comparison ? null : 2"
          [stats]="content.stats"
          [scale]="settings.scale"
          [filterInput]="content.filters"
          [domain]="content.domain[0]"
          [transitionDuration]="transition"
          [showNumbers]="settings.showNumbers"
          [update]="updateChart"
          [filtering]="filtering"
          [anonymityLock]="anonymityStatus?.atRisk"
          [title]="''"
          [chartType]="content.comparison ? chartTypes.MULTIBARCHARTH : chartTypes.BARCHARTH"
          [totalAnswers]="content.totalAnswers"
          [selectionExists]="selectionExists"
          [filtersDemo]="filtersDemo"
          [touchDevice]="touchDevice"
          [chartSettings]="settings"
          [crossfilter]="crossfilter"
          (settingsChange)="settingsChange.emit($event)"
        ></bar-chart-table>
        <ng-template #trendVersion>
          <div
            class="trendCategoricalChart"
            [style.height]="chartHeightDivider(chartHeight, 50)"
            trendCategoricalChart
            [data]="content.distributions"
            [stats]="content.stats"
            [scale]="settings.scale"
            [comparison]="content.comparison"
            [comparisonMode]="settings.summaryChartComparisonMode"
            [filterInput]="content.filters"
            [domain]="content.domain"
            [details]="content.details"
            [zvalues]="zChecked"
            [transitionDuration]="transition"
            [showNumbers]="settings.showNumbers"
            [update]="updateChart"
            [filtering]="filtering"
            [totalAnswers]="content.totalCrosstabAnswers"
            [touchDevice]="touchDevice"
            [trendHoverInput]="trendHoverInput"
            [selectionExists]="selectionExists"
            [filtersDemo]="filtersDemo"
            (hover)="trendHoverOutput.emit($event)"
          ></div>
        </ng-template>
        <text-answer-table
          class="z-fx-col z-fx-gap-8 text-answer-table"
          [style.height]="chartHeightDivider(chartHeight, 50)"
          [details]="content?.details ? content?.details : []"
          [domain]="content?.domain"
          [filterInput]="content?.filters ? content?.filters : []"
          [crossfilter]="crossfilter"
          [filtering]="filtering"
          [anonymityLock]="anonymityStatus?.atRisk"
          [size]="chartHeightDivider(chartHeight, 50)"
          [isSharedReport]="isSharedReport"
          [comparison]="content.comparison"
          [update]="Math.max(update?.valueOf(), updateChart?.valueOf())"
          [chartSettings]="settings"
          (settingsChange)="settingsChange.emit($event)"
        ></text-answer-table>
      </div>
      <div
        *ngSwitchCase="chartTypes.HEATMAP"
        class="z-fx-col z-fx-around-center heatmap"
        [style.height]="chartHeight"
        heatmap
        [data]="content.distributions"
        [stats]="content.stats"
        [scale]="settings.scale"
        [filterInput]="content.filters"
        [domain]="content.domain"
        [transitionDuration]="transition"
        [update]="updateChart"
        [filtering]="filtering"
        [anonymityLock]="anonymityStatus?.atRisk"
        [selectionExists]="selectionExists"
        [filtersDemo]="filtersDemo"
        [touchDevice]="touchDevice"
      ></div>
      <div
        *ngSwitchCase="chartTypes.MULTILINECHART"
        class="multiLineChart"
        [style.height]="chartHeight"
        multiLineChart
        [data]="content.distributions"
        [stats]="content.stats"
        [scale]="settings.scale"
        [filterInput]="content.filters"
        [domain]="content.domain[0]"
        [transitionDuration]="transition"
        [showNumbers]="settings.showNumbers"
        [update]="updateChart"
        [filtering]="filtering"
        [anonymityLock]="anonymityStatus?.atRisk"
        [totalAnswers]="content.totalCrosstabAnswers"
        [touchDevice]="touchDevice"
      ></div>
      <!-- <div
        *ngSwitchCase="chartTypes.STACKEDBARCHARTH"
        class="stackedBarChartH"
        [style.height]="chartHeight"
        stackedBarChartH
        [data]="content.distributions"
        [stats]="content.stats"
        [scale]="settings.scale"
        [filterInput]="content.filters"
        [domain]="content.domain[0]"
        [transitionDuration]="transition"
        [showNumbers]="settings.showNumbers"
        [update]="updateChart"
        [filtering]="filtering"
        [totalAnswers]="content.totalCrosstabAnswers"
        [touchDevice]="touchDevice"
      ></div> -->
      <div
        *ngSwitchCase="chartTypes.STACKEDBARCHARTV"
        class="stackedBarChartV"
        [style.height]="chartHeight"
        stackedBarChartV
        [data]="content.distributions"
        [stats]="content.stats"
        [scale]="settings.scale"
        [filterInput]="content.filters"
        [domain]="content.domain[0]"
        [transitionDuration]="transition"
        [showNumbers]="settings.showNumbers"
        [update]="updateChart"
        [filtering]="filtering"
        [anonymityLock]="anonymityStatus?.atRisk"
        [totalAnswers]="content.totalCrosstabAnswers"
        [touchDevice]="touchDevice"
      ></div>
      <bar-chart-table
        *ngSwitchCase="
          settings.type === chartTypes.MULTIBARCHARTH || settings.type === chartTypes.STACKEDBARCHARTH
            ? settings.type
            : chartTypes.MULTIBARCHARTH
        "
        class="z-fx-col multiBarChartH"
        [style.height]="chartHeight"
        [data]="content.distributions"
        [stats]="content.stats"
        [scale]="settings.scale"
        [filterInput]="content.filters"
        [domain]="content.domain[0]"
        [transitionDuration]="transition"
        [showNumbers]="settings.showNumbers"
        [update]="updateChart"
        [filtering]="filtering"
        [anonymityLock]="anonymityStatus?.atRisk"
        [chartType]="settings.type"
        [totalAnswers]="content.totalAnswers"
        [selectionExists]="selectionExists"
        [filtersDemo]="filtersDemo"
        [touchDevice]="touchDevice"
        [chartSettings]="settings"
        [crossfilter]="crossfilter"
        (settingsChange)="settingsChange.emit($event)"
      ></bar-chart-table>
      <!-- <div
        *ngSwitchCase="chartTypes.MULTIBARCHARTH"
        class="multiBarChartH"
        [style.height]="chartHeight"
        multiBarChartH
        [data]="content.distributions"
        [stats]="content.stats"
        [scale]="settings.scale"
        [filterInput]="content.filters"
        [domain]="content.domain[0]"
        [transitionDuration]="transition"
        [showNumbers]="settings.showNumbers"
        [update]="updateChart"
        [filtering]="filtering"
        [totalAnswers]="content.totalCrosstabAnswers"
        [touchDevice]="touchDevice"
      ></div> -->
      <div
        *ngSwitchCase="chartTypes.MULTIBARCHARTV"
        class="multiBarChartV"
        [style.height]="chartHeight"
        multiBarChartV
        [data]="content.distributions"
        [stats]="content.stats"
        [scale]="settings.scale"
        [filterInput]="content.filters"
        [domain]="content.domain[0]"
        [transitionDuration]="transition"
        [showNumbers]="settings.showNumbers"
        [update]="updateChart"
        [filtering]="filtering"
        [anonymityLock]="anonymityStatus.atRisk"
        [totalAnswers]="content.totalCrosstabAnswers"
        [touchDevice]="touchDevice"
      ></div>

      <!--  Summary chart z-scoring switches  -->
      <div
        class="z-fx z-fx-none z-fx-center-center z-values-toggle"
        *ngIf="
          settings.type === chartTypes.SUMMARY2D ||
          settings.type === chartTypes.SUMMARY1D ||
          settings.type === chartTypes.TRENDLINEAR1DCHART ||
          settings.type === chartTypes.TRENDLINEAR2DCHART
        "
        (mouseenter)="toggleAverageHelper(true)"
        (mouseleave)="toggleAverageHelper(false)"
      >
        <mat-slide-toggle [disabled]="zScoreCheck()" [checked]="zChecked" (change)="zScoringSwitch($event)">
          <div class="z-fx z-fx-start-center z-fx-gap-4">
            <mat-icon color="smart">intelligence</mat-icon>
            <span class="intelligent-zoom-text" i18n>Intelligent Zoom™</span>
          </div>
        </mat-slide-toggle>

        <zef-help-icon [subject]="subjects.AnalyzeIntelligentZoom"></zef-help-icon>
      </div>

      <!--  Summary charts  -->
      <ng-container *ngSwitchCase="chartTypes.SUMMARY1D">
        <ng-container *ngIf="!exportChart || zScoreCheck(); else exportSummary1d">
          <ng-container
            *ngTemplateOutlet="summary1DChart; context: { zv: zChecked, sa: showAverageHelper }"
          ></ng-container>
        </ng-container>
        <ng-template #exportSummary1d>
          <ng-container *ngTemplateOutlet="summary1DChart; context: { zv: false, sa: true }"></ng-container>
          <ng-container *ngTemplateOutlet="summary1DChart; context: { zv: true, sa: true }"></ng-container>
        </ng-template>
      </ng-container>
      <ng-template let-zv="zv" let-sa="sa" #summary1DChart>
        <summary-1d
          class="z-fx-col summary1d"
          [class.intelligently-zoomed]="zv"
          [style.height]="summaryChartHeight(chartHeight)"
          [style.width]="'100%'"
          [distribution]="content.distributions"
          [stats]="content.stats"
          [filterInput]="content.filters"
          [domain]="content.domain"
          [details]="content.details"
          [zvalues]="zv"
          [transitionDuration]="transition"
          [showAverages]="sa"
          [update]="update"
          [comparison]="content.comparison"
          [comparisonMode]="settings.summaryChartComparisonMode"
          [selectionExists]="selectionExists"
          [filtersDemo]="filtersDemo"
          [chartSettings]="settings"
          [anonymityTreshold]="anonymityTreshold"
          (settingsChange)="settingsChange.emit($event)"
        ></summary-1d>
      </ng-template>

      <ng-container *ngSwitchCase="chartTypes.SUMMARY2D">
        <ng-container *ngIf="!exportChart || zScoreCheck(); else exportSummary2d">
          <ng-container
            *ngTemplateOutlet="summary2DChart; context: { zv: zChecked, sa: showAverageHelper, export: false }"
          ></ng-container>
        </ng-container>
        <ng-template #exportSummary2d>
          <ng-container
            *ngTemplateOutlet="summary2DChart; context: { zv: false, sa: true, export: true }"
          ></ng-container>
          <ng-container
            *ngTemplateOutlet="summary2DChart; context: { zv: true, sa: true, export: true }"
          ></ng-container>
        </ng-template>
      </ng-container>
      <ng-template let-zv="zv" let-sa="sa" let-export="export" #summary2DChart>
        <summary-2d
          class="z-fx-col summary2d"
          [style.height]="summaryChartHeight(chartHeight)"
          [style.width]="'100%'"
          [distribution]="content.distributions"
          [stats]="content.stats"
          [filterInput]="content.filters"
          [domain]="content.domain"
          [details]="content.details"
          [zvalues]="zv"
          [transitionDuration]="transition"
          [showAverages]="sa"
          [showZoomText]="export && zv"
          [update]="update"
          [comparison]="content.comparison"
          [comparisonMode]="settings.summaryChartComparisonMode"
          [selectionExists]="selectionExists"
          [filtersDemo]="filtersDemo"
          [chartSettings]="settings"
          [anonymityTreshold]="anonymityTreshold"
          (settingsChange)="settingsChange.emit($event)"
        ></summary-2d>
      </ng-template>

      <summary-choice
        *ngSwitchCase="chartTypes.SUMMARYCHOICE"
        class="z-fx-col summaryChoice"
        [style.height]="summaryChartHeight(chartHeight)"
        [style.width]="'100%'"
        [distribution]="content.distributions"
        [stats]="content.stats"
        [filterInput]="content.filters"
        [domain]="content.domain"
        [details]="content.details"
        [transitionDuration]="transition"
        [showNumbers]="settings.showNumbers"
        [scale]="settings.scale || 'percentage'"
        [update]="update"
        [comparison]="content.comparison"
        [comparisonMode]="settings.summaryChartComparisonMode"
        [selectionExists]="selectionExists"
        [filtersDemo]="filtersDemo"
        [chartSettings]="settings"
        [anonymityTreshold]="anonymityTreshold"
        [crossfilter]="crossfilter"
        [filtering]="filtering"
        [anonymityLock]="anonymityStatus?.atRisk"
        (settingsChange)="settingsChange.emit($event)"
      ></summary-choice>

      <!--  Trend charts  -->
      <div
        *ngSwitchCase="chartTypes.TRENDCATEGORICALCHART"
        class="trendCategoricalChart"
        [style.height]="chartHeight"
        trendCategoricalChart
        [data]="content.distributions"
        [stats]="content.stats"
        [scale]="settings.scale"
        [comparison]="content.comparison"
        [comparisonMode]="settings.summaryChartComparisonMode"
        [filterInput]="content.filters"
        [domain]="content.domain"
        [details]="content.details"
        [zvalues]="zChecked"
        [transitionDuration]="transition"
        [showNumbers]="settings.showNumbers"
        [update]="updateChart"
        [filtering]="filtering"
        [anonymityLock]="anonymityStatus.atRisk"
        [totalAnswers]="content.totalCrosstabAnswers"
        [touchDevice]="touchDevice"
        [trendHoverInput]="trendHoverInput"
        [selectionExists]="selectionExists"
        [filtersDemo]="filtersDemo"
        (hover)="trendHoverOutput.emit($event)"
      ></div>
      <div
        *ngSwitchCase="chartTypes.TRENDNPSCHART"
        class="trendNPSChart"
        [style.height]="chartHeight"
        trendNPSChart
        [data]="content.distributions"
        [stats]="content.stats"
        [scale]="settings.scale"
        [comparison]="content.comparison"
        [comparisonMode]="settings.summaryChartComparisonMode"
        [filterInput]="content.filters"
        [domain]="content.domain"
        [details]="content.details"
        [zvalues]="zChecked"
        [transitionDuration]="transition"
        [showNumbers]="settings.showNumbers"
        [update]="updateChart"
        [filtering]="filtering"
        [anonymityLock]="anonymityStatus.atRisk"
        [totalAnswers]="content.totalCrosstabAnswers"
        [touchDevice]="touchDevice"
        [trendHoverInput]="trendHoverInput"
        [selectionExists]="selectionExists"
        [filtersDemo]="filtersDemo"
        (hover)="trendHoverOutput.emit($event)"
      ></div>
      <div
        *ngSwitchCase="chartTypes.TRENDLINEAR1DCHART"
        class="trendLinear1dChart"
        [style.height]="summaryChartHeight(chartHeight)"
        trendLinear1dChart
        [data]="content.distributions"
        [stats]="content.stats"
        [scale]="settings.scale"
        [comparison]="content.comparison"
        [comparisonMode]="settings.summaryChartComparisonMode"
        [filterInput]="content.filters"
        [domain]="content.domain"
        [details]="content.details"
        [zvalues]="zChecked"
        [transitionDuration]="transition"
        [showNumbers]="settings.showNumbers"
        [update]="updateChart"
        [filtering]="filtering"
        [totalAnswers]="content.totalCrosstabAnswers"
        [touchDevice]="touchDevice"
        [trendHoverInput]="trendHoverInput"
        [selectionExists]="selectionExists"
        [filtersDemo]="filtersDemo"
        (hover)="trendHoverOutput.emit($event)"
      ></div>
      <div
        *ngSwitchCase="chartTypes.TRENDLINEAR2DCHART"
        class="z-fx-col z-fx-around-center trendLinear2dChart"
        [style.height]="summaryChartHeight(chartHeight)"
        trendLinear2dChart
        [data]="content.distributions"
        [stats]="content.stats"
        [scale]="settings.scale"
        [comparison]="content.comparison"
        [comparisonMode]="settings.summaryChartComparisonMode"
        [filterInput]="content.filters"
        [domain]="content.domain"
        [details]="content.details"
        [zvalues]="zChecked"
        [transitionDuration]="transition"
        [showNumbers]="settings.showNumbers"
        [update]="updateChart"
        [filtering]="filtering"
        [totalAnswers]="content.totalCrosstabAnswers"
        [touchDevice]="touchDevice"
        [trendHoverInput]="trendHoverInput"
        [selectionExists]="selectionExists"
        [filtersDemo]="filtersDemo"
        (hover)="trendHoverOutput.emit($event)"
      ></div>

      <!--  Custom charts  -->
      <!-- <div *ngSwitchCase="chartTypes.NPS" class="nps" [style.height]="chartHeight" nps [data]='content.distributions' [scale]='settings.scale' [filterInput]='content.filters' [domain]='content.domain' [transitionDuration]='transition' [showNumbers]='settings.showNumbers' [update]="updateChart" [filtering]="filtering" [touchDevice]="touchDevice" [selectionExists]="selectionExists"></div> -->
      <div
        *ngSwitchCase="chartTypes.RADARCHART"
        class="z-fx-col z-fx-around-center radarChart"
        [style.height]="chartHeight"
        radarChart
        [distributions]="content.distributions"
        [stats]="content.stats"
        [scale]="settings.scale"
        [filterInput]="content.filters"
        [domain]="content.domain"
        [details]="content.details"
        [zValues]="settings.zScoredValues"
        [transitionDuration]="transition"
        [comparison]="content.comparison"
        [showNumbers]="settings.showNumbers"
        [update]="updateChart"
        [filtering]="filtering"
        [totalAnswers]="content.comparison ? content.totalCrosstabAnswers : content.totalAnswers"
        [selectionExists]="selectionExists"
        [filtersDemo]="filtersDemo"
      ></div>

      <!-- NPS chart -->
      <nps-chart
        *ngSwitchCase="chartTypes.NPS"
        class="z-fx-col npsChart"
        [style.height]="chartHeight"
        [details]="content?.details ? content?.details : []"
        [domain]="content?.domain"
        [distribution]="content.distributions"
        [scale]="settings.scale"
        [transition]="transition"
        [showNumbers]="settings.showNumbers"
        [filterInput]="content?.filters ? content?.filters : []"
        [filtering]="filtering"
        [anonymityLock]="anonymityStatus?.atRisk || extraAnonymityProtection"
        [anonymityTreshold]="anonymityTreshold"
        [hideDistribution]="anonymityStatus?.hide"
        [size]="chartHeight"
        [isSharedReport]="isSharedReport"
        [comparison]="content.comparison"
        [update]="updateChart"
        [chartHeight]="chartHeight"
        [chartSettings]="settings"
        (settingsChange)="settingsChange.emit($event)"
      ></nps-chart>

      <!-- Timeline chart -->
      <div
        *ngSwitchCase="chartTypes.TIMELINECHART"
        class="timelineChart"
        [style.height]="chartHeight"
        timelineChart
        [data]="content.distributions"
        [stats]="content.stats"
        [scale]="settings.scale"
        [filterInput]="content.filters"
        [domain]="content.domain[0]"
        [details]="content.details[0]"
        [transitionDuration]="transition"
        [comparison]="content.comparison"
        [showNumbers]="settings.showNumbers"
        [update]="updateChart"
        [filtering]="filtering"
        [anonymityLock]="anonymityStatus?.atRisk"
        [totalAnswers]="content.totalCrosstabAnswers"
        [touchDevice]="touchDevice"
        [trendHoverInput]="trendHoverInput"
        [selectionExists]="selectionExists"
        [filtersDemo]="filtersDemo"
        (hover)="trendHoverOutput.emit($event)"
      ></div>

      <!-- Interviewer summary -->
      <interviewer-summary
        *ngSwitchCase="chartTypes.INTERVIEWERSUMMARY"
        class="interviewer-summary"
        [style.height]="chartHeight"
        [details]="content?.details ? content?.details : []"
        [domain]="content?.domain"
        [distributions]="content?.distributions"
        [stats]="content?.stats"
        [filterInput]="content?.filters ? content?.filters : []"
        [crossfilter]="crossfilter"
        [filtering]="filtering"
        [anonymityLock]="anonymityStatus?.atRisk"
        [size]="chartHeight"
        [isSharedReport]="isSharedReport"
        [comparison]="content.comparison"
        [trend]="content.trend"
        [trendHoverInput]="trendHoverInput"
        [update]="Math.max(update?.valueOf(), updateChart?.valueOf())"
        [transitionDuration]="transition"
        [totalAnswers]="content.totalAnswers"
        [selectionExists]="selectionExists"
        [filtersDemo]="filtersDemo"
        [touchDevice]="touchDevice"
        [chartSettings]="settings"
        [anonymityTreshold]="anonymityTreshold"
        (settingsChange)="settingsChange.emit($event)"
        (hover)="trendHoverOutput.emit($event)"
      ></interviewer-summary>

      <!-- Why Finder summary -->
      <why-finder-summary
        *ngSwitchCase="chartTypes.WHYFINDERSUMMARY"
        class="why-finder-summary"
        [style.height]="chartHeight"
        [details]="content?.details ? content?.details : []"
        [domain]="content?.domain"
        [distributions]="content?.distributions"
        [stats]="content?.stats"
        [filterInput]="content?.filters ? content?.filters : []"
        [crossfilter]="crossfilter"
        [filtering]="filtering"
        [anonymityLock]="anonymityStatus?.atRisk"
        [size]="chartHeight"
        [isSharedReport]="isSharedReport"
        [comparison]="content.comparison"
        [trend]="content.trend"
        [trendHoverInput]="trendHoverInput"
        [update]="Math.max(update?.valueOf(), updateChart?.valueOf())"
        [transitionDuration]="transition"
        [totalAnswers]="content.totalAnswers"
        [selectionExists]="selectionExists"
        [filtersDemo]="filtersDemo"
        [touchDevice]="touchDevice"
        [chartSettings]="settings"
        [anonymityTreshold]="anonymityTreshold"
        (settingsChange)="settingsChange.emit($event)"
        (hover)="trendHoverOutput.emit($event)"
      ></why-finder-summary>

      <!-- Response rates -->
      <response-rates
        *ngSwitchCase="chartTypes.RESPONSERATES"
        class="z-fx-col z-fx-gap-8 response-rates"
        [style.height]="chartHeight"
        [details]="content?.details ? content?.details : []"
        [domain]="content?.domain"
        [crossfilter]="crossfilter"
        [size]="chartHeight"
        [update]="Math.max(update?.valueOf(), updateChart?.valueOf())"
      ></response-rates>

      <!-- Text sentiment analysis -->
      <text-sentiment-analysis
        *ngSwitchCase="chartTypes.TEXTSENTIMENTANALYSIS"
        class="text-sentiment-analysis"
        [style.height]="chartHeight"
        [details]="content?.details ? content?.details : []"
        [domain]="content?.domain"
        [distributions]="content?.distributions"
        [stats]="content?.stats"
        [filterInput]="content?.filters ? content?.filters : []"
        [crossfilter]="crossfilter"
        [filtering]="filtering"
        [anonymityLock]="anonymityStatus?.atRisk"
        [size]="chartHeight"
        [isSharedReport]="isSharedReport"
        [comparison]="content.comparison"
        [trend]="content.trend"
        [trendHoverInput]="trendHoverInput"
        [update]="Math.max(update?.valueOf(), updateChart?.valueOf())"
        [transitionDuration]="transition"
        [totalAnswers]="content.totalAnswers"
        [selectionExists]="selectionExists"
        [filtersDemo]="filtersDemo"
        [touchDevice]="touchDevice"
        [chartSettings]="settings"
        [anonymityTreshold]="anonymityTreshold"
        (settingsChange)="settingsChange.emit($event)"
        (hover)="trendHoverOutput.emit($event)"
      ></text-sentiment-analysis>

      <!-- Text emotions -->
      <text-emotions
        *ngSwitchCase="chartTypes.TEXTEMOTIONS"
        class="text-emotions"
        [style.height]="chartHeight"
        [details]="content?.details ? content?.details : []"
        [domain]="content?.domain"
        [distributions]="content?.distributions"
        [stats]="content?.stats"
        [filterInput]="content?.filters ? content?.filters : []"
        [crossfilter]="crossfilter"
        [filtering]="filtering"
        [anonymityLock]="anonymityStatus?.atRisk"
        [size]="chartHeight"
        [isSharedReport]="isSharedReport"
        [comparison]="content.comparison"
        [trend]="content.trend"
        [trendHoverInput]="trendHoverInput"
        [update]="Math.max(update?.valueOf(), updateChart?.valueOf())"
        [transitionDuration]="transition"
        [totalAnswers]="content.totalAnswers"
        [selectionExists]="selectionExists"
        [filtersDemo]="filtersDemo"
        [touchDevice]="touchDevice"
        [chartSettings]="settings"
        [anonymityTreshold]="anonymityTreshold"
        (settingsChange)="settingsChange.emit($event)"
        (hover)="trendHoverOutput.emit($event)"
      ></text-emotions>

      <!-- Text answer table -->
      <text-answer-table
        *ngSwitchCase="chartTypes.TEXTANSWERTABLE"
        class="z-fx-col z-fx-gap-8 text-answer-table"
        [style.height]="chartHeight"
        [details]="content?.details ? content?.details : []"
        [domain]="content?.domain"
        [filterInput]="content?.filters ? content?.filters : []"
        [crossfilter]="crossfilter"
        [filtering]="filtering"
        [anonymityLock]="anonymityStatus?.atRisk"
        [size]="chartHeight"
        [isSharedReport]="isSharedReport"
        [comparison]="content.comparison"
        [update]="Math.max(update?.valueOf(), updateChart?.valueOf())"
        [chartSettings]="settings"
        [anonymityTreshold]="anonymityTreshold"
        (settingsChange)="settingsChange.emit($event)"
      ></text-answer-table>

      <!-- Word analysis -->
      <word-analysis
        *ngSwitchCase="chartTypes.WORDANALYSIS"
        class="word-analysis"
        [style.height]="chartHeight"
        [details]="content?.details ? content?.details : []"
        [domain]="content?.domain"
        [distributions]="content?.distributions"
        [stats]="content?.stats"
        [filterInput]="content?.filters ? content?.filters : []"
        [crossfilter]="crossfilter"
        [filtering]="filtering"
        [anonymityLock]="anonymityStatus?.atRisk"
        [size]="chartHeight"
        [isSharedReport]="isSharedReport"
        [comparison]="content.comparison"
        [trend]="content.trend"
        [trendHoverInput]="trendHoverInput"
        [update]="Math.max(update?.valueOf(), updateChart?.valueOf())"
        [transitionDuration]="transition"
        [totalAnswers]="content.totalAnswers"
        [selectionExists]="selectionExists"
        [filtersDemo]="filtersDemo"
        [touchDevice]="touchDevice"
        [chartSettings]="settings"
        [anonymityTreshold]="anonymityTreshold"
        (settingsChange)="settingsChange.emit($event)"
        (hover)="trendHoverOutput.emit($event)"
      ></word-analysis>
    </div>
    <ng-container *ngIf="!anonymityStatus?.atRisk; else anonymityLockTemplate"></ng-container>
  </div>
  <div
    *ngIf="content && settings.type && !isData && !(anonymityStatus.hide || anonymityStatus.trend)"
    class="z-fx-col z-fx-auto nothing"
  >
    <div class="z-fx z-fx-center-center answerers">
      <mat-icon class="respondent-icon">contact</mat-icon>
      <span class="number">0</span>
    </div>
    <div class="z-fx-col z-fx-auto z-fx-start-center">
      <div class="z-fx-col z-fx-end-center image">
        <img src="assets/decos/art-nothing.png" height="64px" width="64px" class="art-nothing" alt="nothing" />
      </div>
      <div class="description" i18n>Looks like there is no data to show.</div>
    </div>
  </div>
  <div
    *ngIf="
      content && settings.type && (anonymityStatus.hide || anonymityStatus.trend) && settings.type !== chartTypes.NPS
    "
    class="z-fx-col z-fx-auto anonymous"
  >
    <div *ngIf="!extraAnonymityProtection" class="z-fx z-fx-center-center answerers">
      <mat-icon class="respondent-icon">contact</mat-icon>
      <span class="number" i18n>under {{ anonymityTreshold }}</span>
    </div>
    <div class="z-fx-col z-fx-auto z-fx-start-center z-fx-gap-16">
      <div class="z-fx-col z-fx-end-center image">
        <mat-icon class="zef-icon-larger">anonymous_on</mat-icon>
      </div>
      <div class="description zef-text-center">
        <ng-container *ngIf="!extraAnonymityProtection; else extraAnonymityText">
          <ng-container *ngIf="(anonymityStatus.hide && !anonymityStatus.trend) || !isData; else trendText" i18n>
            Charts are hidden if there are less than {{ anonymityTreshold }} respondents in Anonymous survey.
          </ng-container>
        </ng-container>
        <ng-template #extraAnonymityText>
          <ng-container i18n>Charts are hided to protect respondents anonymity.</ng-container>
        </ng-template>
        <ng-template #trendText>
          <ng-container i18n>
            Charts are hidden if there are less than {{ anonymityTreshold }} respondents in Anonymous survey in any time
            period.
          </ng-container>
          <ng-container *ngIf="content?.trend?.timePeriod !== 'year'">
            <span i18n>Try changing time period:</span>
            <zef-select [value]="content?.trend?.timePeriod" type="inline-h3" (change)="changeTimePeriod.emit($event)">
              <zef-select-option value="day" i18n>Daily</zef-select-option>
              <zef-select-option value="week" i18n>Weekly</zef-select-option>
              <zef-select-option value="month" i18n>Monthly</zef-select-option>
              <zef-select-option value="year" i18n>Yearly</zef-select-option>
            </zef-select>
          </ng-container>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<ng-template #anonymityLockTemplate>
  <div
    *ngIf="chartTypes.LOCKABLECHARTS.includes(settings.type)"
    class="anonymity-lock"
    matTooltip="Chart is locked to protect anonymity of the respondent"
    matTooltipClass="zef-tooltip-ink-lighter"
    i18n-matTooltip
    matTooltipPosition="above"
  >
    <mat-icon class="zef-icon-small lock-icon">lock</mat-icon>
  </div>
</ng-template>
