import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * This is a chart menu component which offers chart type selection,
  editin options, saving & sharing chart, changing scale etc..
 */
@Component({
  selector: 'chart-menu',
  templateUrl: './chart-menu.component.html',
  styleUrls: ['./chart-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartMenu {
  @Input() pinned: boolean = false;
  @Input() editRights: boolean = false;
  @Input() isCustomCard: boolean = false;
  @Input() isNotMergeable: boolean = false;
  @Input() isSharedReport: boolean = false;
  @Input() isSubheader: boolean = false;
  @Input() isDataTableOpen: boolean = false;
  @Input() showDataTableSwitch: boolean = false;
  @Input() showRegenerate: boolean = false;
  @Output() editChartMenu = new EventEmitter<any>();
  @Output() pinChart = new EventEmitter<any>();
  @Output() chartBuilder = new EventEmitter<any>();
  @Output() duplicateChart = new EventEmitter<any>();
  @Output() hideChart = new EventEmitter<any>();
  @Output() removeChart = new EventEmitter<any>();
  @Output() openDataTable = new EventEmitter<any>();
  @Output() regenerateChart = new EventEmitter<any>();

  constructor() {}
  close() {}
}
