<ng-container *ngIf="!isUnderAnonymityTreshold()">
  <div *ngIf="!isNotFreezed() || !(trend || comparison?.values?.length)" class="z-fx z-fx-around-center stats">
    <div class="z-fx z-fx-around-center z-fx-gap-8">
      <div class="z-fx z-fx-around-center">
        <mat-icon class="icon">contact</mat-icon>
        <span numberTransition class="number" [data]="responses" [duration]="transitionDuration"></span>
      </div>
    </div>
  </div>

  <ng-container *ngIf="responses > 0">
    <div class="charts">
      <ng-container *ngIf="!trend || !isNotFreezed(); else trendVersion">
        <ng-container *ngIf="!comparison || !isNotFreezed(); else comparisonVersion">
          <ng-container *ngIf="responses > 0; else nothing">
            <div class="z-fx chart-cells">
              <div class="categories">
                <ng-container *ngIf="totalAnswers[rootCauseCategoryIndex]; else nothing">
                  <div
                    class="pieChart"
                    [class.export]="exportChart"
                    [style.height.px]="!exportChart ? (chartCount / (chartCount + 1)) * 0.8 * availableHeight : 562"
                    pieChart
                    [data]="distributions[rootCauseCategoryIndex]"
                    [stats]="stats[rootCauseCategoryIndex]"
                    [scale]="'percentage'"
                    [showNumbers]="true"
                    [hideRespondentCounts]="!exportChart"
                    [filterInput]="filterInput ? filterInput[rootCauseCategoryIndex] : null"
                    [domain]="domain[rootCauseCategoryIndex]"
                    [transitionDuration]="transition"
                    [update]="Math.max(update?.valueOf(), sizeUpdate?.valueOf())"
                    [filtering]="filtering"
                    [anonymityLock]="anonymityStatus?.atRisk"
                    [title]="''"
                    [totalAnswers]="totalAnswers[rootCauseCategoryIndex]"
                    [selectionExists]="selectionExists"
                    [filtersDemo]="filtersDemo"
                    [touchDevice]="touchDevice"
                  ></div>
                </ng-container>
              </div>
              <div class="cloud">
                <ng-container *ngIf="responses; else nothing">
                  <div
                    class="wordCloud"
                    [class.export]="exportChart"
                    [style.height.px]="!exportChart ? (chartCount / (chartCount + 1)) * 0.8 * availableHeight : 562"
                    wordCloud
                    [data]="distributions[wordsIndex]"
                    [scale]=""
                    [filterInput]="filterInput ? filterInput[wordsIndex] : null"
                    [domain]="domain[wordsIndex]"
                    [transitionDuration]="transitionDuration"
                    [update]="Math.max(update?.valueOf(), sizeUpdate?.valueOf())"
                    [hideRespondentCounts]="true"
                    [filtering]="filtering"
                    [anonymityLock]="anonymityLock"
                    [title]="''"
                    [totalAnswers]="responses"
                    [crossfilter]="crossfilter"
                    [touchDevice]="touchDevice"
                    [hideRespondentCounts]="!exportChart"
                    [isSharedReport]="isSharedReport"
                  ></div>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-template #comparisonVersion>
        <ng-container *ngFor="let compItem of comparisonTable$ | async; trackBy: trackByKey">
          <div class="z-fx z-fx-center-center comparison-label">{{ compItem.label }}</div>
          <ng-container *ngIf="responses > 0; else nothing">
            <div class="z-fx chart-cells">
              <div class="categories">
                <ng-container *ngIf="compItem.responses; else nothing">
                  <div
                    class="pieChart"
                    [class.export]="exportChart"
                    [style.height.px]="
                      !exportChart ? ((chartCount / (chartCount + 1)) * 0.8 * availableHeight) / chartCount : 562
                    "
                    pieChart
                    [data]="compItem.categoryDistributions"
                    [stats]="compItem.stats"
                    [scale]="'percentage'"
                    [showNumbers]="true"
                    [hideRespondentCounts]="!exportChart"
                    [filterInput]="filterInput ? filterInput[rootCauseCategoryIndex] : null"
                    [domain]="domain[rootCauseCategoryIndex]"
                    [transitionDuration]="transition"
                    [update]="Math.max(update?.valueOf(), sizeUpdate?.valueOf())"
                    [filtering]="filtering"
                    [anonymityLock]="anonymityStatus?.atRisk"
                    [title]="!exportChart ? '' : compItem.label"
                    [color]="compItem.color"
                    [totalAnswers]="compItem.responses"
                    [selectionExists]="selectionExists"
                    [filtersDemo]="filtersDemo"
                    [touchDevice]="touchDevice"
                  ></div>
                </ng-container>
              </div>
              <div class="cloud">
                <ng-container *ngIf="compItem.wordResponses; else nothing">
                  <div
                    class="wordCloud"
                    [class.export]="exportChart"
                    [style.height.px]="
                      !exportChart ? ((chartCount / (chartCount + 1)) * 0.8 * availableHeight) / chartCount : 562
                    "
                    wordCloud
                    [data]="compItem.wordDistributions"
                    [scale]=""
                    [filterInput]="filterInput ? filterInput[wordsIndex] : null"
                    [domain]="domain[wordsIndex]"
                    [transitionDuration]="transitionDuration"
                    [update]="Math.max(update?.valueOf(), sizeUpdate?.valueOf())"
                    [hideRespondentCounts]="!!langFilter"
                    [filtering]="filtering"
                    [anonymityLock]="anonymityLock"
                    [title]="!exportChart ? '' : compItem.label"
                    [color]="compItem.color"
                    [totalAnswers]="compItem.wordResponses"
                    [crossfilter]="crossfilter"
                    [touchDevice]="touchDevice"
                    [hideRespondentCounts]="!exportChart"
                    [isSharedReport]="isSharedReport"
                  ></div>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-template>
      <ng-template #trendVersion>
        <div class="trend-chart-container">
          <div
            class="trendCategoricalChart"
            [style.height.px]="
              !exportChart ? (chartCount / (chartCount + 1)) * 0.8 * availableHeight : chartCount * 562
            "
            trendCategoricalChart
            [data]="distributions"
            [stats]="stats"
            [scale]="chartSettings.scale"
            [comparison]="comparison"
            [comparisonMode]="chartSettings.summaryChartComparisonMode"
            [filterInput]="filterInput"
            [domain]="domain"
            [details]="details"
            [zvalues]="zChecked"
            [transitionDuration]="transition"
            [showNumbers]="chartSettings.showNumbers"
            [update]="Math.max(update?.valueOf(), sizeUpdate?.valueOf())"
            [filtering]="filtering && isNotFreezed()"
            [anonymityLock]="anonymityLock"
            [totalAnswers]="totalAnswers"
            [touchDevice]="touchDevice"
            [trendHoverInput]="trendHoverInput"
            [selectionExists]="selectionExists"
            [filtersDemo]="filtersDemo"
            (hover)="hover.emit($event)"
          ></div>
        </div>
      </ng-template>
    </div>
    <div *ngIf="isPoweredBy" class="z-fx z-fx-end-end powered-by">
      <span i18n>Text analysis powered by</span>
      <a class="zef-text-p2-link link" href="https://www.lingsoft.fi/" rel="noopener noreferrer" target="_blank">
        Lingsoft
      </a>
    </div>
  </ng-container>
</ng-container>

<div class="table-area" [class.no-data]="isUnderAnonymityTreshold()">
  <div *ngIf="!isUnderAnonymityTreshold()" class="search-box">
    <zef-searchbox (input)="searchUpdate$.next($event)">
      <span i18n>Find...</span>
    </zef-searchbox>
    <ng-container *ngIf="sortKey$ | async as sortkey">
      <ng-container *ngIf="sortDirection$ | async as sortdir">
        <div class="z-fx z-fx-between-center sort-tools">
          <div class="z-fx z-fx-center-center sorter">
            <zef-select [value]="sortkey" type="inline-p2" (change)="onSortColumn($event)">
              <zef-select-option value="survey">
                <div *selectOptionLabel class="z-fx z-fx-gap-8 z-fx-start-center">
                  <mat-icon>time</mat-icon>
                  <strong class="zef-text-p2 zef-text-ellipsis z-s-option" i18n>Date</strong>
                </div>
              </zef-select-option>
              <zef-select-option [value]="details[rootQuestionIndex]?.key">
                <div *selectOptionLabel class="z-fx z-fx-gap-8 z-fx-start-center">
                  <mat-icon>{{ questionTypes.QuestionIcons[details[rootQuestionIndex]?.originalType] }}</mat-icon>
                  <strong class="zef-text-p2 zef-text-ellipsis z-s-option" i18n>
                    {{ domain[rootQuestionIndex]?.title }}
                  </strong>
                </div>
              </zef-select-option>
              <zef-select-option [value]="domain[rootCauseCategoryIndex]?.key">
                <div *selectOptionLabel class="z-fx z-fx-gap-8 z-fx-start-center">
                  <mat-icon>az</mat-icon>
                  <strong class="zef-text-p2 zef-text-ellipsis z-s-option" i18n>
                    {{ domain[rootCauseCategoryIndex]?.title }}
                  </strong>
                </div>
              </zef-select-option>
              <zef-select-option [value]="domain[rootCauseIndex]?.key">
                <div *selectOptionLabel class="z-fx z-fx-gap-8 z-fx-start-center">
                  <mat-icon>az</mat-icon>
                  <strong class="zef-text-p2 zef-text-ellipsis z-s-option" i18n>
                    {{ domain[rootCauseIndex]?.title }}
                  </strong>
                </div>
              </zef-select-option>
              <zef-select-option [value]="domain[interviewSummaryIndex]?.key">
                <div *selectOptionLabel class="z-fx z-fx-gap-8 z-fx-start-center">
                  <mat-icon>az</mat-icon>
                  <strong class="zef-text-p2 zef-text-ellipsis z-s-option" i18n>
                    {{ domain[interviewSummaryIndex]?.title }}
                  </strong>
                </div>
              </zef-select-option>
              <ng-container *ngIf="comparison?.values?.length">
                <mat-divider [style.margin]="'8px 0'"></mat-divider>
                <zef-select-option [value]="comparison.key">
                  <div *selectOptionLabel class="z-fx z-fx-gap-8 z-fx-start-center">
                    <mat-icon>az</mat-icon>
                    <strong class="zef-text-p2 zef-text-ellipsis z-s-option" i18n>Comparison</strong>
                  </div>
                </zef-select-option>
              </ng-container>
            </zef-select>

            <button mat-icon-button [@rotate]="sortdir !== 'asc'" (click)="onSortDir()">
              <mat-icon class="zef-color-primary">sort_up</mat-icon>
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <ng-container *ngIf="chartTable$ | async as chartTable">
    <ng-container
      *ngIf="!this.exportChart && availableHeight > 0 && !isUnderAnonymityTreshold() && chartTable?.length > 0"
    >
      <ng-scrollbar
        class="table"
        [style.height.px]="0.2 * availableHeight + (1 - chartCount / (chartCount + 1)) * 0.8 * availableHeight - 84"
      >
        <cdk-virtual-scroll-viewport
          *ngIf="!vsHide && (chartTable$ | async).length > 0"
          [style.height.px]="0.2 * availableHeight + (1 - chartCount / (chartCount + 1)) * 0.8 * availableHeight - 84"
          scrollViewport
          autosize
        >
          <div
            *cdkVirtualFor="let item of chartTable$ | async; trackBy: trackByRow"
            class="z-fx z-fx-sc text-row"
            [style.cursor]="isNotFreezed() ? 'pointer' : 'default'"
            (click)="filterData(item[rootCauseIndex + 1].value, rootCauseIndex)"
          >
            <ng-container *ngIf="item[rootCauseIndex + 1] as rootCauseItem">
              <div
                *ngIf="isNotFreezed() && timeIndex >= 0"
                class="cell cell-10"
                (click)="$event.stopPropagation(); filterData(item[timeIndex + 1].value, timeIndex)"
              >
                <span class="zef-color-ink">
                  {{ item[timeIndex + 1].text }}
                </span>
              </div>
              <div
                class="cell cell-10"
                (click)="$event.stopPropagation(); filterData(item[rootQuestionIndex + 1].value, rootQuestionIndex)"
              >
                <span class="zef-color-ink">
                  {{ item[rootQuestionIndex + 1].text }}
                </span>
              </div>
              <div
                class="cell cell-10"
                (click)="
                  $event.stopPropagation(); filterData(item[rootCauseCategoryIndex + 1].value, rootCauseCategoryIndex)
                "
              >
                <span class="zef-color-ink">
                  {{ item[rootCauseCategoryIndex + 1].text }}
                </span>
              </div>
              <div class="cell cell-30">
                <span>{{ rootCauseItem.text }}</span>
              </div>
              <div
                class="cell cell-100"
                (click)="
                  $event.stopPropagation(); filterData(item[interviewSummaryIndex + 1].value, interviewSummaryIndex)
                "
              >
                <span class="zef-color-ink">
                  {{ item[interviewSummaryIndex + 1].text }}
                </span>
              </div>
              <div *ngIf="isNotFreezed() && comparison && comparison.index != null" class="cell cell-10">
                <ng-container
                  *ngFor="let comparisonItem of parseItems(item[comparison.index + 1], comparison.index); let i = index"
                >
                  <div class="chips z-fx z-fx-start-center z-fx-wrap">
                    <span
                      class="chip"
                      [style.color]="getColor(item[comparison.index + 1], i)"
                      (click)="$event.stopPropagation(); filterData(item[comparison.index + 1].value, comparison.index)"
                    >
                      {{ getText(item[comparison.index + 1], i) }}
                    </span>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </cdk-virtual-scroll-viewport>
      </ng-scrollbar>
    </ng-container>
    <ng-container *ngIf="!anonymityTreshold && chartTable.length === 0">
      <ng-container *ngTemplateOutlet="nothing"></ng-container>
    </ng-container>
    <ng-container *ngIf="isUnderAnonymityTreshold()">
      <ng-container *ngTemplateOutlet="anonymous"></ng-container>
    </ng-container>
  </ng-container>
</div>
<ng-container *ngIf="!anonymityLock; else anonymityLockTemplate"></ng-container>

<ng-template #nothing>
  <div class="z-fx-col nothing">
    <div class="z-fx-col z-fx-auto z-fx-start-center">
      <div class="z-fx-col z-fx-end-center image">
        <img src="assets/decos/art-nothing.png" height="48px" width="48px" class="art-nothing" alt="nothing" />
      </div>
      <div class="description" i18n>Looks like there is no data to show.</div>
    </div>
  </div>
</ng-template>
<ng-template #anonymous>
  <div class="z-fx-col z-fx-auto anonymous">
    <div class="z-fx z-fx-center-center answerers">
      <mat-icon class="respondent-icon">contact</mat-icon>
      <span class="number" i18n>under {{ anonymityTreshold }}</span>
    </div>
    <div class="z-fx-col z-fx-auto z-fx-start-center z-fx-gap-16">
      <div class="z-fx-col z-fx-end-center image">
        <mat-icon class="zef-icon-larger">anonymous_on</mat-icon>
      </div>
      <div class="description zef-text-center">
        <ng-container i18n>
          Charts are hidden if there are less than {{ anonymityTreshold }} respondents in Anonymous survey.
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #anonymityLockTemplate>
  <div
    class="anonymity-lock"
    matTooltip="Chart is locked to protect anonymity of the respondent"
    matTooltipClass="zef-tooltip-ink-lighter"
    i18n-matTooltip
    matTooltipPosition="above"
  >
    <mat-icon class="zef-icon-small lock-icon">lock</mat-icon>
  </div>
</ng-template>
