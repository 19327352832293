/**
 * Main component for the report.
 *
 * @stable
 */

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ZefErrorHandler } from '@shared/error.handler';

@Component({
  selector: 'zef-app',
  templateUrl: './zef.component.html',
  styleUrls: ['./zef.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZefApp {
  constructor(readonly errorHandler: ZefErrorHandler) {}
}
