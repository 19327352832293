import * as d3 from 'd3';

import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';

import {
  CanvasContext,
  ChartDistribution,
  ChartDomain,
  ChartLegend,
  DimensionDataItem,
  SummaryChartDataChild,
} from '@shared/models/report.model';

import { Colors } from '@report/shared/enums/colors.enum';

import { Crossfilter } from '@report/shared/services/crossfilter.service';
import { shortenText } from '@shared/utilities/canvas.utilities';

/**
 * This is a Summary 2d chart directive.
 */
@Directive({
  selector: '[trendLinear2dChart]',
})
export class TrendLinear2DChart implements OnChanges {
  @Input() data: ChartDistribution[][] = [];
  @Input() domain: ChartDomain[] = [];
  @Input() details: DimensionDataItem[] = [];
  @Input() stats: any;
  @Input() scale: any;
  @Input() zvalues: boolean = false;
  @Input() comparison: any;
  @Input() comparisonMode: any;
  @Input() filterInput: any;
  @Input() transitionDuration: number = 0;
  @Input() showNumbers: boolean = false;
  @Input() update: Date = new Date();
  @Input() filtering: boolean = false;
  @Input() totalAnswers: number = 0;
  @Input() touchDevice: boolean = false;
  @Input() trendHoverInput: string = '';
  @Input() selectionExists: boolean = false;
  @Input() filtersDemo: boolean = false;

  @Input() highlight: any[] = [];
  @Input() showAverages: boolean = false;

  @Output() hover: EventEmitter<string> = new EventEmitter<string>();

  private axisGroups: any;
  private newSize: number = 0;
  private timeIndex: number = 0;

  public chartData: any[] = [];
  public highlighted: string[] = [];
  public size: number = 0;
  public flexMode: string = 'row';

  private margin: any = { top: 50, right: 50, bottom: 50, left: 50 };
  private width: number = 0;
  private height: number = 0;
  private timelineHeight: number = 0;
  private questionListWidth: number = 0;
  private ballSize: number = 0;
  private fontSize: number = 0;
  private unit: number = 0;

  private context: CanvasContext[] = [];
  private timelineContext: CanvasContext = {} as CanvasContext;
  private legends: ChartLegend[] = [];

  private hoveredItem: string = '';

  private timePeriod: string = 'day';
  private timelineFormat: any;
  private locale: string = 'en';

  // D3 elements
  private base: any;
  private canvas: any;
  private tooltip: any;
  private tooltipTexts: any;
  private chart: any;
  private questionList: any;
  private questionListItem: any;

  private startTime: string = '';
  private endTime: string = '';
  private selectedPoints: string[] = [];
  private customSelections: boolean = false;

  @HostListener('window:resize') resize() {
    this.updateChart(null, null);
  }

  constructor(
    private _element: ElementRef,
    private cf: Crossfilter,
  ) {
    this.constructBody();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.data ||
      changes.domain ||
      changes.scale ||
      changes.filterInput ||
      changes.showNumbers ||
      changes.update ||
      changes.filtering ||
      changes.title ||
      changes.zvalues ||
      changes.comparisonMode ||
      changes.comparison ||
      changes.details ||
      (changes.trendHoverInput && !this.hoveredItem) ||
      changes.filtersDemo ||
      changes.selectionExists
    ) {
      this.updateChart(changes.data, changes.zvalues);
    }
  }

  constructBody() {
    this.margin = { top: 50, right: 50, bottom: 50, left: 50 };
    this.base = d3.select(this._element.nativeElement);
    this.selectedPoints = [];
  }

  updateChart(dataChanges: SimpleChange | null, zChanges: SimpleChange | null) {
    this.setQuestions();
    this.setSizes();
    this.setTimelineSelections();
    this.setData();
    this.setChartContainer();
    this.setCanvas(dataChanges, zChanges);
    this.setQuestionList();
  }

  /**
   * Parsing questions from data by connecting dimension pairs together.
   */
  setQuestions() {
    this.axisGroups = {};

    const sliders: string[] = [];
    const naturalPairs = {};
    const xIndexes = {};
    for (let i = 0; i < this.domain.length; i++) {
      const item = i.toString();
      if (this.domain[i].scale === 'linear') {
        sliders.push(item);
      }
      if (this.domain[item].key.split(':')[1] === '0') {
        naturalPairs[this.domain[item].key] = null;
        xIndexes[this.domain[item].key] = item;
      } else if (this.domain[item].key.split(':')[1] === '1') {
        const x = this.domain[item].key.split(':')[0] + ':0';
        naturalPairs[x] = [xIndexes[x], item];
      }
    }
    Object.keys(naturalPairs).forEach((key) => naturalPairs[key] == null && delete naturalPairs[key]);
    for (const pair in naturalPairs) {
      if (!naturalPairs[pair][0] || !naturalPairs[pair][1]) {
        delete naturalPairs[pair];
      }
    }

    if (Object.keys(naturalPairs).length < 1) {
      if (sliders.length > 1) {
        const pairsLen = sliders.length > 2 ? Math.floor(sliders.length / 2) : 1;

        for (let i = 0; i < pairsLen; i++) {
          const x = sliders[2 * i].toString();
          const y = sliders[2 * i + 1].toString();
          const detailsX = this.details.find((det) => det && det.key === this.domain[x]['key']);
          const detailsY = this.details.find((det) => det && det.key === this.domain[y]['key']);
          const group: string = detailsX.group === detailsY.group ? detailsX.group : '';
          const groupId: string = this.parseGroupId(this.domain[x], this.domain[y]);
          const item = [x, y, group, groupId];

          if (!this.axisGroups[groupId]) {
            this.axisGroups[groupId] = { groups: new Set(), questions: [] };
          }

          this.axisGroups[groupId]['groups'].add(group);
          this.axisGroups[groupId]['questions'].push(item);
        }
      } else {
      }
    } else {
      Object.keys(naturalPairs).forEach((key) => {
        const detailsX = this.details.find((det) => det && det.key === this.domain[naturalPairs[key][0]]['key']);
        const group: string = detailsX.group;
        const groupId: string = this.parseGroupId(this.domain[naturalPairs[key][0]], this.domain[naturalPairs[key][1]]);

        if (!this.axisGroups[groupId]) {
          this.axisGroups[groupId] = { groups: new Set(), questions: [] };
        }

        naturalPairs[key].push(group, groupId);

        this.axisGroups[groupId]['groups'].add(group);
        this.axisGroups[groupId]['questions'].push(naturalPairs[key]);
      });
    }
  }

  /**
   * Setting sizes for 2Ds.
   */
  setSizes() {
    const countCharts = () => {
      const axisGroups = Object.keys(this.axisGroups).length;
      const comparisonFactor =
        this.comparison && this.comparison.values.length > 0 && this.comparisonMode !== 'joined'
          ? this.comparison.values.length
          : 1;
      return comparisonFactor * axisGroups;
    };
    const aW = this._element.nativeElement.clientWidth;
    const aH = this._element.nativeElement.clientHeight;
    const chartCount: number = countCharts();

    this.fontSize = parseFloat(window.getComputedStyle(this._element.nativeElement).fontSize);
    this.unit = (10 / 14) * this.fontSize;
    this.timelineHeight = 8 * this.unit;
    const size = Math.min(aW, aH / chartCount - this.timelineHeight);

    this.timeIndex =
      this.domain.findIndex((item) => item && item.key === 'time') > -1
        ? this.domain.findIndex((item) => item && item.key === 'time')
        : 0;
    this.questionListWidth = aW - size;

    this.margin = {
      top: this.fontSize * 3,
      right: this.fontSize * 3,
      bottom: this.fontSize * 3,
      left: this.fontSize * 3,
    };
    this.width = size - this.margin.left - this.margin.right > 0 ? size - this.margin.left - this.margin.right : 0;
    this.height = size - this.margin.bottom - this.margin.top > 0 ? size - this.margin.bottom - this.margin.top : 0;
    this.fontSize = parseFloat(window.getComputedStyle(this._element.nativeElement).fontSize);
    this.ballSize = this.width / 12 / 2;
  }

  setTimelineSelections() {
    if (this.timePeriod !== this.cf.getTimePeriod()) {
      this.customSelections = false;
    }

    this.timePeriod = this.cf.getTimePeriod();

    this.startTime = '';
    this.endTime = '';

    if (!this.customSelections) {
      let startPoint;
      let endPoint;
      if (this.filterInput && this.filterInput[this.timeIndex] && this.filterInput[this.timeIndex].length > 0) {
        for (let f = 0, lenf = this.filterInput[this.timeIndex].length; f < lenf; f++) {
          if (!startPoint || Number(this.filterInput[this.timeIndex][f]) < Number(startPoint)) {
            startPoint = this.filterInput[this.timeIndex][f];
          }
          if (!endPoint || Number(this.filterInput[this.timeIndex][f]) > Number(endPoint)) {
            endPoint = this.filterInput[this.timeIndex][f];
          }
        }
      } else {
        startPoint = this.startTime
          ? this.startTime
          : this.domain &&
              this.domain[this.timeIndex] &&
              this.domain[this.timeIndex]['keys'] &&
              this.domain[this.timeIndex]['keys'].length > 0
            ? this.domain[this.timeIndex]['keys'][0]
            : '';
        endPoint = this.endTime
          ? this.endTime
          : this.domain &&
              this.domain[this.timeIndex] &&
              this.domain[this.timeIndex]['keys'] &&
              this.domain[this.timeIndex]['keys'].length > 0
            ? this.domain[this.timeIndex]['keys'][this.domain[this.timeIndex]['keys'].length - 1]
            : '';
      }

      this.selectedPoints = [startPoint, endPoint];
    }
  }

  /**
   * Setting data for 2Ds.
   */
  setData() {
    const setData = (axisGroup, comparisonGroup = '', comparisonItem = '', cidx = 0) => {
      const c = 'children';
      const children: SummaryChartDataChild[] = [];
      const questions = new Set();
      let id;
      let sliderDetails;
      let group;
      const comparisonDomain = this.domain.find((dom) => dom.key === comparisonGroup);
      const cdi = this.domain.findIndex((dom) => dom.key === comparisonGroup);

      let number = 0;

      for (const pair of axisGroup['questions']) {
        const x = pair[0];
        const y = pair[1];
        const groupId = (comparisonItem ? `${comparisonItem}: ` : ``) + `${pair[3]}`;

        const sliderLabels = this.domain[x].labelsLinear;
        const sliderLabelsY = this.domain[y].labelsLinear;
        const sliderValues = this.domain[x].origin;
        const sliderValuesY = this.domain[y].origin;

        const zMethod = axisGroup.groups.size > 1 ? 'globalZ' : 'z';
        const avgVal = this.zvalues ? zMethod + 'Average' : 'average';
        const stdVal = this.zvalues ? zMethod + 'Std' : 'std';
        const key = this.domain[x].key;
        const domIndex = this.domain[x].index;
        const domIndexY = this.domain[y].index;
        const dom = [sliderValues.min, sliderValues.max];
        const domY = [sliderValuesY.min, sliderValuesY.max];
        const title =
          this.domain[x].title && this.domain[y].title && this.domain[x].title !== this.domain[y].title
            ? this.domain[x].title + ' - ' + this.domain[y].title
            : this.domain[x].title
              ? this.domain[x].title
              : (this.details[x] && this.details[x].title) || '';
        const lines = [];

        if (comparisonDomain) {
          const timeIndex = this.domain[this.timeIndex].index;
          for (let s = 0, len = this.domain[this.timeIndex].keys.length; s < len; s++) {
            const time = this.domain[this.timeIndex].keys[s];
            const idx =
              this.stats &&
              this.stats[cdi] &&
              this.stats[cdi][c] &&
              this.stats[cdi][c][cidx] &&
              this.stats[cdi][c][cidx][c] &&
              this.stats[cdi][c][cidx][c][timeIndex] &&
              this.stats[cdi][c][cidx][c][timeIndex][c]
                ? this.stats[cdi][c][cidx][c][timeIndex][c].findIndex((item) => item && item.key === time)
                : -1;
            let avg;
            let sd;
            let count;
            let avgY;
            let sdY;
            let countY;
            let cxi;
            let rxi;
            let cyi;
            let ryi = null;

            if (
              this.stats &&
              this.stats[cdi] &&
              this.stats[cdi][c] &&
              this.stats[cdi][c][cidx] &&
              this.stats[cdi][c][cidx][c] &&
              this.stats[cdi][c][cidx][c][timeIndex] &&
              this.stats[cdi][c][cidx][c][timeIndex][c] &&
              this.stats[cdi][c][cidx][c][timeIndex][c][idx] &&
              this.stats[cdi][c][cidx][c][timeIndex][c][idx][c]
            ) {
              if (this.stats[cdi][c][cidx][c][timeIndex][c][idx][c][domIndex]) {
                avg = this.stats[cdi][c][cidx][c][timeIndex][c][idx][c][domIndex][avgVal];
                sd = this.stats[cdi][c][cidx][c][timeIndex][c][idx][c][domIndex][stdVal];
                count = this.stats[cdi][c][cidx][c][timeIndex][c][idx][c][domIndex]['count'];
                cxi = d3.scaleLinear().rangeRound([0, this.width]).domain(dom)(
                  this.zvalues ? (avg != null ? avg : dom[0] + (dom[1] - dom[0]) / 2) : avg,
                );
                rxi =
                  d3
                    .scaleLinear()
                    .rangeRound([0, this.width])
                    .domain([0, dom[1] - dom[0]])(sd) / 2;

                if (this.stats[cdi][c][cidx][c][timeIndex][c][idx][c][domIndexY]) {
                  avgY = this.stats[cdi][c][cidx][c][timeIndex][c][idx][c][domIndexY][avgVal];
                  sdY = this.stats[cdi][c][cidx][c][timeIndex][c][idx][c][domIndexY][stdVal];
                  countY = this.stats[cdi][c][cidx][c][timeIndex][c][idx][c][domIndexY]['count'];
                  cyi = d3.scaleLinear().rangeRound([this.height, 0]).domain(domY)(
                    this.zvalues ? (avgY != null ? avgY : domY[0] + (domY[1] - domY[0]) / 2) : avgY,
                  );
                  ryi =
                    d3
                      .scaleLinear()
                      .rangeRound([0, this.height])
                      .domain([0, domY[1] - domY[0]])(sdY) / 2;
                }
              }
            }

            lines.push({
              time,
              average: avg,
              std: sd,
              n: count,
              averageY: avgY,
              stdY: sdY,
              nY: countY,
              cx: cxi,
              rx: rxi,
              cy: cyi,
              ry: ryi,
            });
          }
        } else {
          const timeIndex = this.stats.findIndex((statItem) => statItem.key === 'time');

          for (let s = 0, len = this.domain[this.timeIndex].keys.length; s < len; s++) {
            const time = this.domain[this.timeIndex].keys[s];
            const idx =
              this.stats && this.stats[timeIndex] && this.stats[timeIndex][c]
                ? this.stats[timeIndex][c].findIndex((item) => item && item.key === time)
                : -1;
            let avg;
            let sd;
            let count;
            let avgY;
            let sdY;
            let countY;
            let cxi;
            let rxi;
            let cyi;
            let ryi = null;

            if (
              this.stats &&
              this.stats[timeIndex] &&
              this.stats[timeIndex][c] &&
              this.stats[timeIndex][c][idx] &&
              this.stats[timeIndex][c][idx][c]
            ) {
              if (this.stats[timeIndex][c][idx][c][domIndex]) {
                avg = this.stats[timeIndex][c][idx][c][domIndex][avgVal];
                sd = this.stats[timeIndex][c][idx][c][domIndex][stdVal];
                count = this.stats[timeIndex][c][idx][c][domIndex]['count'];
                cxi = d3.scaleLinear().rangeRound([0, this.width]).domain(dom)(
                  this.zvalues ? (avg != null ? avg : dom[0] + (dom[1] - dom[0]) / 2) : avg,
                );
                rxi =
                  d3
                    .scaleLinear()
                    .rangeRound([0, this.width])
                    .domain([0, dom[1] - dom[0]])(sd) / 2;
              }
              if (this.stats[timeIndex][c][idx][c][domIndexY]) {
                avgY = this.stats[timeIndex][c][idx][c][domIndexY][avgVal];
                sdY = this.stats[timeIndex][c][idx][c][domIndexY][stdVal];
                countY = this.stats[timeIndex][c][idx][c][domIndexY]['count'];
                cyi = d3.scaleLinear().rangeRound([this.height, 0]).domain(domY)(
                  this.zvalues ? (avgY != null ? avgY : domY[0] + (domY[1] - domY[0]) / 2) : avgY,
                );
                ryi =
                  d3
                    .scaleLinear()
                    .rangeRound([0, this.height])
                    .domain([0, domY[1] - domY[0]])(sdY) / 2;
              }
            }
            lines.push({
              time,
              average: avg,
              std: sd,
              n: count,
              averageY: avgY,
              stdY: sdY,
              nY: countY,
              cx: cxi,
              rx: rxi,
              cy: cyi,
              ry: ryi,
            });
          }
        }

        const average = comparisonDomain
          ? this.stats[cdi] &&
            this.stats[cdi]['children'] &&
            this.stats[cdi]['children'][cidx] &&
            this.stats[cdi]['children'][cidx]['children'] &&
            this.stats[cdi]['children'][cidx]['children'][domIndex] &&
            this.stats[cdi]['children'][cidx]['children'][domIndex]['average']
            ? this.stats[cdi]['children'][cidx]['children'][domIndex]['average']
            : null
          : this.stats[x] && this.stats[x]['average'];
        const std = comparisonDomain
          ? this.stats[cdi] &&
            this.stats[cdi]['children'] &&
            this.stats[cdi]['children'][cidx] &&
            this.stats[cdi]['children'][cidx]['children'] &&
            this.stats[cdi]['children'][cidx]['children'][domIndex] &&
            this.stats[cdi]['children'][cidx]['children'][domIndex]['std']
            ? this.stats[cdi]['children'][cidx]['children'][domIndex]['std']
            : null
          : this.stats[x] && this.stats[x]['std'];
        const averageY = comparisonDomain
          ? this.stats[cdi] &&
            this.stats[cdi]['children'] &&
            this.stats[cdi]['children'][cidx] &&
            this.stats[cdi]['children'][cidx]['children'] &&
            this.stats[cdi]['children'][cidx]['children'][domIndexY] &&
            this.stats[cdi]['children'][cidx]['children'][domIndexY]['average']
            ? this.stats[cdi]['children'][cidx]['children'][domIndexY]['average']
            : null
          : this.stats[y] && this.stats[y]['average'];
        const stdY = comparisonDomain
          ? this.stats[cdi] &&
            this.stats[cdi]['children'] &&
            this.stats[cdi]['children'][cidx] &&
            this.stats[cdi]['children'][cidx]['children'] &&
            this.stats[cdi]['children'][cidx]['children'][domIndexY] &&
            this.stats[cdi]['children'][cidx]['children'][domIndexY]['std']
            ? this.stats[cdi]['children'][cidx]['children'][domIndexY]['std']
            : null
          : this.stats[y] && this.stats[y]['std'];
        const zAverage = comparisonDomain
          ? this.stats[cdi] &&
            this.stats[cdi]['children'] &&
            this.stats[cdi]['children'][cidx] &&
            this.stats[cdi]['children'][cidx]['children'] &&
            this.stats[cdi]['children'][cidx]['children'][domIndex] &&
            this.stats[cdi]['children'][cidx]['children'][domIndex][`${zMethod}Average`]
            ? this.stats[cdi]['children'][cidx]['children'][domIndex][`${zMethod}Average`]
            : null
          : this.stats[x] && this.stats[x][`${zMethod}Average`];
        const zStd = comparisonDomain
          ? this.stats[cdi] &&
            this.stats[cdi]['children'] &&
            this.stats[cdi]['children'][cidx] &&
            this.stats[cdi]['children'][cidx]['children'] &&
            this.stats[cdi]['children'][cidx]['children'][domIndex] &&
            this.stats[cdi]['children'][cidx]['children'][domIndex][`${zMethod}Std`]
            ? this.stats[cdi]['children'][cidx]['children'][domIndex][`${zMethod}Std`]
            : null
          : this.stats[x] && this.stats[x][`${zMethod}Std`];
        const zAverageY = comparisonDomain
          ? this.stats[cdi] &&
            this.stats[cdi]['children'] &&
            this.stats[cdi]['children'][cidx] &&
            this.stats[cdi]['children'][cidx]['children'] &&
            this.stats[cdi]['children'][cidx]['children'][domIndexY] &&
            this.stats[cdi]['children'][cidx]['children'][domIndexY][`${zMethod}Average`]
            ? this.stats[cdi]['children'][cidx]['children'][domIndexY][`${zMethod}Average`]
            : null
          : this.stats[y] && this.stats[y][`${zMethod}Average`];
        const zStdY = comparisonDomain
          ? this.stats[cdi] &&
            this.stats[cdi]['children'] &&
            this.stats[cdi]['children'][cidx] &&
            this.stats[cdi]['children'][cidx]['children'] &&
            this.stats[cdi]['children'][cidx]['children'][domIndexY] &&
            this.stats[cdi]['children'][cidx]['children'][domIndexY][`${zMethod}Std`]
            ? this.stats[cdi]['children'][cidx]['children'][domIndexY][`${zMethod}Std`]
            : null
          : this.stats[y] && this.stats[y][`${zMethod}Std`];
        const n = comparisonDomain
          ? this.stats[cdi] &&
            this.stats[cdi]['children'] &&
            this.stats[cdi]['children'][cidx] &&
            this.stats[cdi]['children'][cidx]['children'] &&
            this.stats[cdi]['children'][cidx]['children'][domIndex] &&
            this.stats[cdi]['children'][cidx]['children'][domIndex]['count']
            ? this.stats[cdi]['children'][cidx]['children'][domIndex]['count']
            : null
          : this.stats[x] && this.stats[x]['count'];

        /* const percentage = comparisonGroup
                         ? this.stats[comparisonGroup][comparisonItem][key]['percentage']
                         : this.stats['aggregate'][key]['percentage']; */

        const cx = d3.scaleLinear().rangeRound([0, this.width]).domain(dom)(
          this.zvalues ? (zAverage != null ? zAverage : dom[0] + (dom[1] - dom[0]) / 2) : average,
        );
        const cy = d3.scaleLinear().rangeRound([this.height, 0]).domain(domY)(
          this.zvalues ? (zAverageY != null ? zAverageY : domY[0] + (domY[1] - domY[0]) / 2) : averageY,
        );
        const rx =
          d3
            .scaleLinear()
            .rangeRound([0, this.width])
            .domain([0, dom[1] - dom[0]])(this.zvalues ? zStd : std) / 2;
        const ry =
          d3
            .scaleLinear()
            .rangeRound([0, this.height])
            .domain([0, domY[1] - domY[0]])(this.zvalues ? zStdY : stdY) / 2;

        if (!id) {
          id = groupId;
        }
        if (!sliderDetails) {
          sliderDetails = {
            labels: sliderLabels,
            labelsY: sliderLabelsY,
            values: sliderValues,
            valuesY: sliderValuesY,
          };
        }
        if (!group) {
          group = {
            key: comparisonItem ? comparisonItem : 'aggregate',
            title:
              comparisonItem && this.comparisonMode !== 'joined'
                ? `${comparisonDomain ? comparisonDomain['labels'][comparisonItem] : ''}`
                : ``,
          };
        }

        number += 1;

        if (n > 0) {
          const child: any = {
            key,
            title,
            lines,
            n,
            cx,
            cy,
            rx,
            ry,
            domain: dom,
            domainY: domY,
            average,
            std,
            averageY,
            stdY,
            zAverage,
            zStd,
            zAverageY,
            zStdY,
            number,
            group: comparisonItem ? comparisonItem : 'aggregate',
            groupLabel: comparisonDomain ? comparisonDomain['labels'][comparisonItem] : '',
            index: comparisonDomain && comparisonDomain['colors'] ? comparisonDomain['colors'][comparisonItem] : null,
          };
          children.push(child);
          questions.add(number + '. ' + title);
        }
      }
      return {
        children,
        id,
        sliderDetails,
        group,
        questions: Array.from(questions) as string[],
      };
    };

    const chartData: any[] = [];

    for (const group in this.axisGroups) {
      const axisGroup = this.axisGroups[group];

      if (
        this.comparison &&
        this.comparison.values.length > 0 &&
        this.details.find((details) => details.key === this.comparison.key) // ensuring details' existence
      ) {
        const comparisonDetails = this.details.find((details) => details.key === this.comparison.key);
        const joinedData = {
          children: [] as any[],
          id: '',
          sliderDetails: {},
          group: {},
          questions: [] as string[],
        };
        const joinedQuestions = new Set();
        if (comparisonDetails) {
          for (let c = 0, len = this.comparison.values.length; c < len; c++) {
            if (comparisonDetails['values'][this.comparison.values[c]]) {
              const comparisonItem = comparisonDetails['values'][this.comparison.values[c]].toString();
              const comparisonValue = this.comparison.values[c];
              const comparisonData = setData(axisGroup, this.comparison.key, comparisonItem, comparisonValue);

              if (this.comparisonMode && this.comparisonMode === 'joined') {
                joinedData.children = joinedData.children.concat(comparisonData.children);
                joinedData.id = comparisonData.id;
                joinedData.sliderDetails = comparisonData.sliderDetails;
                joinedData.group = comparisonData.group;
                (comparisonData.questions || []).forEach(joinedQuestions.add, joinedQuestions);
              } else {
                chartData.push(comparisonData);
              }
            }
          }

          if (this.comparisonMode && this.comparisonMode === 'joined') {
            joinedData.questions = Array.from(joinedQuestions).sort(
              (a: string, b: string) => Number(a.split('.')[0]) - Number(b.split('.')[0]),
            ) as string[];
            chartData.push(joinedData);
          }
        }
      } else {
        chartData.push(setData(axisGroup));
      }
    }

    if (this.chartData.length === 0) {
      this.chartData = chartData;
    } else {
      const orderChanges = () => {
        const oldArr = this.chartData.slice().map((item) => item.id);
        const newArr = chartData.slice().map((item) => item.id);

        if (oldArr.length === newArr.length) {
          for (let o = 0; o < oldArr.length; o++) {
            if (oldArr[o] !== newArr[o]) {
              return true;
            }
          }
          return false;
        } else {
          return true;
        }
      };

      if (orderChanges()) {
        this.chartData = chartData;
      } else {
        for (const item in chartData) {
          const index = this.chartData.findIndex((test) => test.id === chartData[item].id);

          if (index > -1) {
            this.chartData[index]['group'] = chartData[item]['group'];
            this.chartData[index]['id'] = chartData[item]['id'];
            this.chartData[index]['children'] = chartData[item]['children'];
            this.chartData[index]['questions'] = chartData[item]['questions'];
            this.chartData[index]['sliderDetails'] = chartData[item]['sliderDetails'];
          } else {
            this.chartData.push(chartData[item]);
          }
        }
        // removing old items
        let i = this.chartData.length;
        while (i--) {
          const index = chartData.findIndex((test) => test.id === this.chartData[i].id);

          if (index === -1) {
            this.chartData.splice(i, 1);
          }
        }
      }
    }
    // console.log(this.chartData, this.stats);
  }

  /**
   * Selecting colors.
   */
  colorScale(index) {
    if (this.comparison) {
      return Colors.getComparisonColor(index);
    } else {
      return this.selectionExists ? Colors.SELECTED : this.filtersDemo ? Colors.UNSELECTED : Colors.DEFAULT;
    }
  }

  selectedColorScale(dataIndex, selectionIndex) {
    const color = this.comparison
      ? d3.hsl(Colors.getComparisonColor(dataIndex))
      : this.selectionExists
        ? d3.hsl(Colors.SELECTED)
        : this.filtersDemo
          ? d3.hsl(Colors.UNSELECTED)
          : d3.hsl(Colors.DEFAULT);

    if (this.selectedPoints.length > 1) {
      color.s =
        color.s -
        (color.s - 0.1) * ((this.selectedPoints.length - 1 - selectionIndex) / (this.selectedPoints.length - 1));
      color.l =
        color.l / (1 + 0.2 * ((this.selectedPoints.length - 1 - selectionIndex) / (this.selectedPoints.length - 1)));
    }

    return color;
  }

  setChartContainer() {
    this.chart = this.base.selectAll('.trend-linear-2d-content').data(this.chartData);

    this.chart.exit().remove();

    this.chart.attr('data-index', (d, i) => i);

    this.chart
      .enter()
      .append('div')
      .attr('class', 'trend-linear-2d-content')
      .style('display', 'flex')
      .style('position', 'relative')
      .attr('data-index', (d, i) => i);
  }

  setCanvas(dataChanges: SimpleChange | null, zChanges: SimpleChange | null) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const __this = this;
    const drawContent = function (d) {
      if (this.parentElement) {
        const i = this.parentElement.attributes['data-index'].value;
        const context = d3.select(this).node().getContext('2d');
        let averages: number[] = [];

        if (__this.showAverages) {
          averages = [
            __this.zvalues
              ? __this.margin.left + __this.width / 2
              : d3.mean(d.children, (c: any) => c.cx + __this.margin.left),
            __this.zvalues
              ? __this.margin.top + __this.height / 2
              : d3.mean(d.children, (c: any) => c.cy + __this.margin.top),
          ];
        }

        if (
          ((dataChanges && !dataChanges.firstChange) || (zChanges && !zChanges.firstChange)) &&
          __this.transitionDuration > 0
        ) {
          const dataObj = __this.context && __this.context[i] && __this.context[i].data ? __this.context[i].data : [];

          const interpolator = d3.interpolateArray(dataObj, d.children);
          const ease = d3.easeCubic;

          const t = d3.timer((elapsed) => {
            const step = elapsed / __this.transitionDuration;
            let data;

            if (step >= 1) {
              data = d.children;

              t.stop();
            } else {
              data = interpolator(ease(step));
            }

            __this.setChart(context, data, d.sliderDetails, d.group, [], []);
            __this.setTimeline(context, d.children, __this.hoveredItem);
          });
        } else {
          __this.setChart(context, d.children, d.sliderDetails, d.group, [], []);
          __this.setTimeline(context, d.children, __this.hoveredItem);
        }

        __this.context[i] = { context, data: d.children, averages };
      }
    };

    const hoverFunction = function (event, d) {
      const area = d3.pointer(event);
      if (area[1] > __this.margin.top + __this.height + __this.margin.bottom) {
        let highlight = '';
        const keys = __this.domain[__this.timeIndex].keys;

        for (let l = 0, len = keys.length; l < len; l++) {
          const w = __this.width / len;
          const xPos1 = l * w + __this.margin.left;
          const xPos2 = l * w + __this.margin.left + w;

          if (area[0] < xPos2 && area[0] >= xPos1) {
            highlight = keys[l];
            break;
          }
        }
        if (highlight !== __this.hoveredItem) {
          __this.hoveredItem = highlight;
          __this.setHoverEffects();
        }
      } else {
        __this.hoveredItem = '';
        __this.setHoverEffects();
        __this.selectForHover(event, area, d);
      }
    };

    const clickFunction = function (event) {
      const area = d3.pointer(event);
      let selection = '';

      if (area[1] > __this.margin.top + __this.height + __this.margin.bottom) {
        const keys = __this.domain[__this.timeIndex].keys;

        for (let l = 0, len = keys.length; l < len; l++) {
          const w = __this.width / len;
          const xPos1 = l * w + __this.margin.left;
          const xPos2 = l * w + __this.margin.left + w;

          if (area[0] < xPos2 && area[0] >= xPos1) {
            selection = keys[l];
            break;
          }
        }

        if (!__this.customSelections) {
          __this.selectedPoints = [selection];
        } else {
          if (__this.selectedPoints.indexOf(selection) > -1) {
            __this.selectedPoints.splice(__this.selectedPoints.indexOf(selection), 1);
          } else {
            __this.selectedPoints.push(selection);
          }
        }
        if (__this.selectedPoints.length > 0) {
          __this.customSelections = true;
        } else {
          __this.setTimelineSelections();
        }
        __this.selectedPoints.sort((a, b) => Number(a) - Number(b));

        __this.setHoverEffects();
      }
    };

    this.canvas = this.base
      .selectAll('.trend-linear-2d-content')
      .selectAll('.trend-linear-2d-chart-canvas')
      .data((d) => [d]);

    this.canvas.exit().remove();

    this.canvas
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom + this.timelineHeight)
      .each(drawContent);

    this.canvas
      .enter()
      .append('canvas')
      .attr('class', 'trend-linear-2d-chart-canvas')
      .on('mousemove', hoverFunction)
      .on('mouseout', function (event) {
        __this.setTooltip(event);
        __this.hoveredItem = '';
        __this.setHoverEffects();
      })
      .on('click', clickFunction)
      .style('position', 'relative')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom + this.timelineHeight)
      .each(drawContent);
  }

  setQuestionList() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const __this = this;

    this.questionList = this.base
      .selectAll('.trend-linear-2d-content')
      .selectAll('.trend-linear-2d-chart-question-list')
      .data((d) => (d && d.children && d.children.length > 1 ? [d] : []));

    this.questionList.exit().remove();

    this.questionList
      .style('width', this.questionListWidth + 'px')
      .style('height', this.height + this.timelineHeight + 'px')
      .style('font-size', 12 / 14 + 'em')
      .style('margin-top', this.margin.top + 'px');

    this.questionList
      .enter()
      .append('div')
      .attr('class', 'trend-linear-2d-chart-question-list')
      .style('width', this.questionListWidth + 'px')
      .style('height', this.height + this.timelineHeight + 'px')
      .style('font-size', 12 / 14 + 'em')
      .style('margin-top', this.margin.top + 'px')
      .style('overflow-y', 'auto');

    this.questionListItem = this.base
      .selectAll('.trend-linear-2d-content')
      .selectAll('.trend-linear-2d-chart-question-list')
      .selectAll('.trend-linear-2d-chart-question-list-item')
      .data((d) =>
        d.children
          .filter(
            (item, index, self) => index === self.findIndex((t) => t.number === item.number && t.title === item.title),
          )
          .sort((a, b) => a.number - b.number),
      );

    this.questionListItem.exit().remove();

    this.questionListItem.text((d) => `${d.number}. ${d.title}`);

    this.questionListItem
      .enter()
      .append('div')
      .attr('class', 'trend-linear-2d-chart-question-list-item')
      .on('mouseenter', function (event, d) {
        d3.select(this).style('font-weight', 'bold');
        const items = __this.itemsBelow([], d, d.key).childs;

        __this.setTooltip(event);
        __this.setHoverEffects([d], items);
      })
      .on('mouseleave', function (event) {
        d3.select(this).style('font-weight', 'normal');
        __this.setTooltip(event);
        // __this.hoveredItem = '';
        __this.setHoverEffects();
      })
      .text((d) => `${d.number}. ${d.title}`);
  }

  setTimeline(context, data, highlight: string = '') {
    const y = this.margin.bottom + this.margin.top + this.height;
    context.clearRect(0, y, this.width + this.margin.left + this.margin.right, this.timelineHeight);

    const keys = this.domain[this.timeIndex].keys;
    context.beginPath();
    context.moveTo(this.margin.left, y + this.unit * 4);
    context.lineTo(this.margin.left + this.width, y + this.unit * 4);
    context.lineWidth = 1;
    context.strokeStyle = Colors.HELPERLINE;
    context.stroke();

    context.font = 10 / 14 + 'em Inter';
    context.fillStyle = Colors.TEXT;
    context.textAlign = 'center';
    context.textBaseline = 'top';

    this.timelineFormat = this.getTimelineFormat(keys);
    const w = this.width / keys.length;
    this.setTimelineTicks(context, keys, this.width, this.margin.bottom + this.margin.top + this.height);

    context.strokeStyle = 'transparent';
    context.font = 10 / 14 + 'em Inter';

    if (this.selectedPoints.length > 0) {
      for (let s = 0, lens = this.selectedPoints.length; s < lens; s++) {
        const index = keys.indexOf(this.selectedPoints[s]);
        context.fillStyle = this.selectedColorScale(data[0] && data[0]['index'], s);
        if (index > -1) {
          const xPos = Number(index) * w + this.margin.left + w / 2;
          const yPos = y + 2.5 * this.unit;
          context.beginPath();
          context.arc(xPos, yPos, this.unit, 0, 2 * Math.PI);
          context.closePath();
          context.fill();
          context.stroke();

          context.textAlign = 'center';
          context.textBaseline = 'middle';
          context.fillStyle = Colors.BACKGROUND;
          context.fillText('✓', xPos, yPos);

          context.font = 8 / 14 + 'em Inter';
          const tw = context.measureText(this.timelineFormat.format(new Date(Number(keys[index]) || 0))).width + 8;

          context.fillStyle = this.selectedColorScale(data[0] && data[0]['index'], s);
          context.fillRect(xPos - tw / 2, y + 1, tw, (8 / 14) * this.fontSize + 5);

          context.textAlign = 'center';
          context.textBaseline = 'middle';
          context.fillStyle = Colors.BACKGROUND;
          context.fillText(
            this.timelineFormat.format(new Date(Number(keys[index]) || 0)),
            xPos,
            y + 1 + ((8 / 14) * this.fontSize + 5) / 2,
          );
        }
      }
    }

    if (highlight || (!highlight && this.trendHoverInput)) {
      const index = highlight ? keys.indexOf(highlight) : keys.indexOf(this.trendHoverInput);
      if (index > -1) {
        const xPos = Number(index) * w + this.margin.left + w / 2;
        context.strokeStyle = Colors.TEXT;
        context.fillStyle = Colors.TEXT;
        context.lineWidth = 1;
        context.beginPath();
        context.arc(xPos, y + 2.5 * this.unit, this.unit, 0, 2 * Math.PI);
        context.closePath();
        context.fill();
        context.stroke();

        context.font = 8 / 14 + 'em Inter';
        const tw = context.measureText(this.timelineFormat.format(new Date(Number(keys[index]) || 0))).width + 8;

        context.fillStyle = Colors.TEXT;
        context.fillRect(xPos - tw / 2, y + 1, tw, (8 / 14) * this.fontSize + 5);

        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.fillStyle = Colors.BACKGROUND;
        context.fillText(
          this.timelineFormat.format(new Date(Number(keys[index]) || 0)),
          Number(index) * w + this.margin.left + w / 2,
          y + 1 + ((8 / 14) * this.fontSize + 5) / 2,
        );
      }
    }

    context.strokeStyle = 'transparent';
    context.font = 10 / 14 + 'em Inter';
  }

  getTimelineFormat(times) {
    this.locale =
      this.cf.getActiveLocale().length > 5 || !this.cf.getActiveLocale()
        ? navigator.language
        : this.cf.getActiveLocale();
    const years =
      new Date(Number(times[0])).getFullYear() !== new Date(Number(times[times.length - 1])).getFullYear() ||
      new Date(Number(times[0])).getFullYear() !== new Date().getFullYear();

    if (years) {
      if (this.timePeriod === 'year') {
        return new Intl.DateTimeFormat(this.locale, { year: 'numeric' });
      } else if (this.timePeriod === 'month') {
        return new Intl.DateTimeFormat(this.locale, { month: 'short', year: 'numeric' });
      } else {
        return new Intl.DateTimeFormat(this.locale, { day: 'numeric', month: 'numeric', year: 'numeric' });
      }
    } else if (this.timePeriod === 'year') {
      return new Intl.DateTimeFormat(this.locale, { year: 'numeric' });
    } else if (this.timePeriod === 'month') {
      return new Intl.DateTimeFormat(this.locale, { month: 'long' });
    } else {
      return new Intl.DateTimeFormat(this.locale, { day: 'numeric', month: 'short' });
    }
  }

  setTimelineTicks(context, times, width, y) {
    // this.timelineFormat = this.getTimelineFormat(times);
    context.font = 10 / 14 + 'em Inter';
    let lastItem = times.length - 1;
    const tickW =
      context.measureText(this.timelineFormat.format(new Date(2019, this.locale !== 'fi' ? 11 : 10, 24))).width + 16;
    const allTicks = Number(times.length);
    const tickPossible = Math.floor((width + tickW) / tickW);
    const possibleTicksBetween =
      tickPossible < allTicks
        ? tickPossible > 2
          ? tickPossible / (allTicks - 2) > 0.5
            ? Math.floor((allTicks - 2) / 2)
            : tickPossible - 2
          : 0
        : allTicks - 2;
    const availableExcessSpace = (width + tickW) / tickW - tickPossible;

    const calcTickReducer = (all, possible) => {
      const rounder = (number) => Math.round(number * Math.pow(10, 5)) / Math.pow(10, 5);

      return possible > 0 && (all - 1) / (possible + 1) >= 1 ? rounder((all - 1) / (possible + 1)) : all;
    };

    const arr = [
      allTicks,
      allTicks,
      allTicks,
      Number.isInteger(calcTickReducer(allTicks, possibleTicksBetween + availableExcessSpace > 0.7 ? 1 : 0))
        ? calcTickReducer(allTicks, possibleTicksBetween + availableExcessSpace > 0.7 ? 1 : 0)
        : allTicks,
    ];
    for (let i = possibleTicksBetween; i > 0; i--) {
      const reducer = calcTickReducer(allTicks, i);
      const reducer1 = calcTickReducer(allTicks - 1, i);
      const reducer2 = calcTickReducer(allTicks - 2, i);

      if (Number.isInteger(reducer) && reducer < arr[0]) {
        arr[0] = reducer;
      }
      if (Number.isInteger(reducer1) && reducer1 < arr[1]) {
        arr[1] = reducer1;
      }
      if (Number.isInteger(reducer2) && reducer2 < arr[2] && allTicks - 2 > possibleTicksBetween + 2) {
        arr[2] = reducer2;
      }
    }
    const reducerIndex = arr.indexOf(Math.min(...arr));

    const tickReducer = arr[reducerIndex];
    lastItem -= reducerIndex < 3 ? reducerIndex : 0;

    const w = width / times.length;
    times.forEach((tick, i) => {
      if (Number(i) === 0 || Number(i) % tickReducer === 0 || Number(i) === lastItem) {
        const posX = Number(i) * w + this.margin.left + w / 2;
        context.beginPath();
        context.moveTo(posX, y + 4 * this.unit);
        context.lineTo(posX, y + 4 * this.unit - 5);
        context.lineWidth = 1;
        context.strokeStyle = Colors.HELPERLINE;
        context.stroke();

        context.textBaseline = 'middle';
        context.textAlign = 'center';

        context.fillStyle = Colors.TEXT;
        context.fillText(this.timelineFormat.format(new Date(Number(tick) || 0)), posX, y + 5 * this.unit);
      }
    });
  }

  setChart(context, data, details, group, parentHighlight: any[] = [], childHighlight: any[] = []) {
    context.clearRect(
      0,
      0,
      this.width + this.margin.top + this.margin.bottom,
      this.height + this.margin.left + this.margin.right,
    );
    context.globalAlpha = 1;

    this.setArea(context);
    this.setTexts(context, details, group);
    if (this.comparison && this.comparisonMode === 'joined') {
      this.setLegends(context);
    }

    for (let i = 0, len = data.length; i < len; i++) {
      context.globalAlpha = 1;

      this.setHistoryLines(context, data[i], parentHighlight, childHighlight);
    }

    for (let i = 0, len = data.length; i < len; i++) {
      context.globalAlpha = 1;

      this.setEndPointsEllipses(context, data[i], childHighlight);
    }

    for (let i = 0, len = data.length; i < len; i++) {
      context.globalAlpha = 1;

      this.setEndPoints(context, data[i], childHighlight);
    }

    // for (let i = 0, len = data.length; i < len; i++) {
    //   context.globalAlpha = 1;
    //   if (averages && averages.length === 2) {
    //     this.setAverageLines(context, data[i], averages[0], averages[1]);
    //   }

    //   if (highlightsOn) {
    //     const highlight = highlighted.find((item) => `${data[i].number}. ${data[i].title}` === item);
    //     if (highlight) {
    //       context.globalAlpha = 0.1;
    //     } else {
    //       context.globalAlpha = 0.03;
    //     }
    //   } else {
    //     context.globalAlpha = 0.1;
    //   }

    //   this.setEllipses(context, data[i]);
    // }

    // for (let i = 0, len = data.length; i < len; i++) {
    //   let highlight;
    //   if (highlightsOn) {
    //     highlight = highlighted.find(
    //       (item) => {
    //         const legends = !this.highlight || this.highlight.length === 0
    //                       ? this.itemsBelow(d3.pointer(this._element.nativeElement.firstElementChild)).legends
    //                       : [];

    //         return `${data[i].number}. ${data[i].title}` === item
    //                 && (legends.length === 0 || legends.find((legend) => data[i].group === legend.key) != null);
    //         }
    //     );
    //     if (highlight) {
    //       context.globalAlpha = 0.9;
    //       highlightArea.push([data[i].cx, data[i].cy]);
    //     } else {
    //       const onTop = highlightArea.filter(area => area[0] < (data[i].cx + this.ballSize)
    //               && area[0] > (data[i].cx - this.ballSize)
    //               && area[1] < (data[i].cy + this.ballSize)
    //               && area[1] > (data[i].cy - this.ballSize));
    //       if (onTop.length > 0) {
    //         context.globalAlpha = 0.03;
    //       } else {
    //         context.globalAlpha = 0.15;
    //       }
    //     }
    //   } else {
    //     context.globalAlpha = 0.9;
    //   }

    //   this.setBalls(context, data[i], highlight, true);
    // }

    // if (averages && averages.length === 2) {
    //   context.fillStyle = Colors.AVERAGEHELPER;
    //   context.beginPath();
    //   context.arc(averages[0], averages[1], 5, 0, 2 * Math.PI, false);
    //   context.closePath();
    //   context.fill();
    // }
  }

  setArea(context) {
    const areaW = this.width;
    const areaH = this.height;

    context.fillStyle = Colors.BACKGROUNDSUMMARY;

    context.translate(this.margin.left, this.margin.top);
    context.beginPath();
    context.moveTo(areaW / 2 - 2, 0);
    context.lineTo(5, 0);
    context.translate(5, 5);
    context.rotate(0);
    context.scale(1, 1);
    context.arc(0, 0, 5, -1.5707963267948966, -3.141592653589793, 1);
    context.scale(1, 1);
    context.rotate(0);
    context.translate(-5, -5);
    context.lineTo(0, areaH / 2 - 2);
    context.lineTo(areaW / 4, areaH / 2 - 2);
    context.lineTo(areaW / 4, areaH / 2 + 2);
    context.lineTo(0, areaH / 2 + 2);
    context.lineTo(0, areaH - 5);
    context.translate(5, areaH - 5);
    context.rotate(0);
    context.scale(1, 1);
    context.arc(0, 0, 5, 3.141592653589793, 1.5707963267948966, 1);
    context.scale(1, 1);
    context.rotate(0);
    context.translate(-5, -(areaH - 5));
    context.lineTo(areaW / 2 - 2, areaH);
    context.lineTo(areaW / 2 - 2, (3 * areaH) / 4);
    context.lineTo(areaW / 2 + 2, (3 * areaH) / 4);
    context.lineTo(areaW / 2 + 2, areaH);
    context.lineTo(areaW - 5, areaH);
    context.translate(areaW - 5, areaH - 5);
    context.rotate(0);
    context.scale(1, 1);
    context.arc(0, 0, 5, 1.5707963267948966, 0, 1);
    context.scale(1, 1);
    context.rotate(0);
    context.translate(-(areaW - 5), -(areaH - 5));
    context.lineTo(areaW, areaH / 2 + 2);
    context.lineTo((3 * areaW) / 4, areaH / 2 + 2);
    context.lineTo((3 * areaW) / 4, areaH / 2 - 2);
    context.lineTo(areaW, areaH / 2 - 2);
    context.lineTo(areaW, 5);
    context.translate(areaW - 5, 5);
    context.rotate(0);
    context.scale(1, 1);
    context.arc(0, 0, 5, 0, -1.5707963267948966, 1);
    context.scale(1, 1);
    context.rotate(0);
    context.translate(-(areaW - 5), -5);
    context.lineTo(areaW / 2 + 2, 0);
    context.lineTo(areaW / 2 + 2, areaH / 4);
    context.lineTo(areaW / 2 - 2, areaH / 4);
    context.closePath();
    context.fill();
    context.translate(-this.margin.left, -this.margin.top);
  }

  setLegends(context) {
    this.legends = [];
    const y = 0;
    const width = this.width + this.margin.left + this.margin.right;
    const height = 15;
    let usedSpace = 0;
    let arr: any[] = [];
    let currentX = 0;
    let currentY = 0;
    let margin;

    let choiceLabels;
    let choices = [];
    let colors;

    for (const dom in this.domain) {
      if (this.domain[dom].key === this.comparison.key) {
        choiceLabels = this.domain[dom].labels;
        choices = this.domain[dom].keys;
        colors = this.domain[dom].colors;
      }
    }

    const textWidth = (width * (choices.length > 2 ? 3 : choices.length < 2 ? 1 : 2)) / choices.length;

    context.textBaseline = 'top';
    context.textAlign = 'left';
    context.font = 10 / 14 + 'em Inter';

    for (let i = 0, length = choices.length; i < length; i++) {
      const key = choices[i];
      const text = shortenText(context, choiceLabels[key], textWidth, 40);
      const elW = context.measureText(text).width;

      if (usedSpace + elW + 20 <= width) {
        usedSpace += elW + 20;
        arr.push([key, colors[key] != null ? colors[key] : i, text]);
      } else {
        margin = (width - usedSpace) / 2;
        currentX = margin;

        for (let a = 0, len = arr.length; a < len; a++) {
          const itemText = arr[a][2];
          const itemColor = arr[a][1];
          const itemKey = arr[a][0];
          currentX += 10;

          context.font = 10 / 14 + 'em Inter';
          context.fillStyle = Colors.TEXT;
          context.fillText(itemText, currentX, currentY + y);

          context.beginPath();
          context.arc(currentX - 8, currentY + y + 6, 5, 0, 2 * Math.PI);
          context.closePath();
          context.fillStyle = this.colorScale(itemColor);
          context.fill();
          this.legends.push({
            key: itemKey,
            x: currentX,
            y: currentY + y,
            width: context.measureText(itemText).width,
            height,
          });
          currentX += context.measureText(itemText).width + 10;
        }

        currentY += height;
        usedSpace = elW + 20;

        arr = [[key, colors[key] != null ? colors[key] : i, text]];
      }
    }

    margin = (width - usedSpace) / 2;
    currentX = margin;

    for (let a = 0, len = arr.length; a < len; a++) {
      const itemText = arr[a][2];
      const itemColor = arr[a][1];
      const itemKey = arr[a][0];
      currentX += 10;

      context.font = 10 / 14 + 'em Inter';
      context.fillStyle = Colors.TEXT;
      context.fillText(itemText, currentX, currentY + y);

      context.beginPath();
      context.arc(currentX - 8, currentY + y + 6, 5, 0, 2 * Math.PI);
      context.closePath();
      context.fillStyle = this.colorScale(itemColor);
      context.fill();
      this.legends.push({
        key: itemKey,
        x: currentX,
        y: currentY + y,
        width: context.measureText(itemText).width,
        height,
      });
      currentX += context.measureText(itemText).width + 10;
    }
  }

  setTexts(context, sliderDetails, group) {
    context.font = 12 / 14 + 'em Inter';
    context.textBaseline = 'top';
    context.fillStyle = Colors.TEXT;

    context.textAlign = 'start';
    const xMinLabel = shortenText(context, sliderDetails.labels.min, this.width / 2, 10);
    context.fillText(xMinLabel, this.margin.left, this.height + this.margin.top + this.margin.bottom / 12);

    context.textAlign = 'end';
    const xMaxLabel = shortenText(context, sliderDetails.labels.max, this.width / 2, 10);
    context.fillText(xMaxLabel, this.margin.left + this.width, this.height + this.margin.top + this.margin.bottom / 12);

    context.font = 'normal bold ' + 12 / 14 + 'em Inter';
    context.textAlign = 'center';
    const xAxisLabel = shortenText(context, sliderDetails.labels.axis, this.width, 10);
    context.fillText(
      xAxisLabel,
      this.margin.left + this.width / 2,
      this.height + this.margin.top + (1.5 * this.margin.bottom) / 3,
    );

    context.font = 12 / 14 + 'em Inter';
    context.textBaseline = 'bottom';
    context.rotate((270 * Math.PI) / 180);

    context.textAlign = 'start';
    const yMinLabel = shortenText(context, sliderDetails.labelsY.min, this.width / 2, 10);
    context.fillText(yMinLabel, 0 - (this.margin.top + this.height), (11 * this.margin.left) / 12);

    context.textAlign = 'end';
    const yMaxLabel = shortenText(context, sliderDetails.labelsY.max, this.width / 2, 10);
    context.fillText(yMaxLabel, 0 - this.margin.top, (11 * this.margin.left) / 12);

    context.font = 'normal bold ' + 12 / 14 + 'em Inter';
    context.textAlign = 'center';
    const yAxisLabel = shortenText(context, sliderDetails.labelsY.axis, this.width, 10);
    context.fillText(yAxisLabel, 0 - (this.margin.top + this.height / 2), (1.5 * this.margin.left) / 3);

    context.rotate((90 * Math.PI) / 180);

    if (this.comparison && this.comparisonMode !== 'joined') {
      context.font = this.margin.top / 3.5 + 'px Inter';
      context.textBaseline = 'middle';

      const title = group.title ? shortenText(context, group.title, this.width, 10) : '';

      context.fillText(title, this.margin.left + this.width / 2, this.margin.top / 2);
    }
  }

  setHistoryLines(context, data, parentHighlight, childHighlight = []) {
    const lines = data && data.lines ? data.lines : [];
    const color = d3.hsl(this.colorScale(data.index));
    color.s = color.s / 3;

    const line = d3
      .line()
      .x((d) => d['cx'] + this.margin.left)
      .y((d) => d['cy'] + this.margin.top)
      .curve(d3.curveNatural)
      .context(context);

    context.lineWidth = 1;
    context.beginPath();
    line(lines.filter((c) => c && c.n > 0));
    context.globalAlpha =
      childHighlight &&
      childHighlight.find(
        (item) => item.parentKey === data.key && (!item.parentGroup || item.parentGroup === data.group),
      )
        ? 0.6
        : !childHighlight || childHighlight.length === 0
          ? 0.3
          : 0.05;
    context.strokeStyle = color;
    context.setLineDash([5, 5]);
    context.lineCap = 'round';
    context.stroke();

    context.setLineDash([]);
    context.lineCap = 'butt';

    if (lines.length < 30) {
      for (let d = 0, lend = lines.length; d < lend; d++) {
        context.fillStyle = color;
        context.strokeStyle = color;

        const xPos = lines[d]['cx'] + this.margin.left;
        const yPos = lines[d]['cy'] + this.margin.top;
        const hasValue = lines[d]['average'] != null;

        if (hasValue) {
          context.strokeStyle =
            childHighlight &&
            childHighlight.find(
              (item) => item.parentKey === data.key && (!item.parentGroup || item.parentGroup === data.group),
            )
              ? Colors.TEXT
              : 'transparent';
          context.fillStyle = color;
          context.globalAlpha =
            childHighlight &&
            childHighlight.find(
              (item) => item.parentKey === data.key && (!item.parentGroup || item.parentGroup === data.group),
            )
              ? 0.8
              : !childHighlight || childHighlight.length === 0
                ? 0.4
                : 0.1;

          context.beginPath();
          context.arc(xPos, yPos, 2, 0, 2 * Math.PI);
          context.closePath();

          context.fill();
          context.stroke();
        }
      }
    }

    context.globalAlpha = 1;
  }

  setEndPointsEllipses(context, data, highlight) {
    const lines = data && data.lines ? data.lines : [];
    // const origColor = this.colorScale(data.index);
    const color = d3.hsl(this.colorScale(data.index));
    color.s = color.s / 3;
    const startPointColor = d3.hsl(this.colorScale(data.index));
    startPointColor.s = startPointColor.s / 3;
    startPointColor.l = startPointColor.l / 1.2;

    context.globalAlpha = 0.1;

    if (this.hoveredItem || this.trendHoverInput) {
      const hover = this.hoveredItem || this.trendHoverInput;
      const itemNum = hover ? lines.findIndex((l) => l.time === hover) : -1;
      if (itemNum > -1 && lines[itemNum]) {
        context.fillStyle = Colors.TEXT;
        const cx = lines[itemNum]['cx'] + this.margin.left;
        const cy = lines[itemNum]['cy'] + this.margin.top;
        const w = lines[itemNum]['rx'] || 1;
        const h = lines[itemNum]['ry'] || 1;
        const dominantScale = w > h ? 'w' : h > w ? 'h' : 'both';
        const radius = dominantScale === 'w' ? h : w;
        const scaleX = dominantScale === 'w' ? w / h : 1;
        const scaleY = dominantScale === 'h' ? h / w : 1;
        const hasValue = lines[itemNum]['average'] != null;

        if (hasValue) {
          context.save();
          context.beginPath();
          context.scale(scaleX, scaleY);
          context.arc(cx / scaleX, cy / scaleY, radius, 0, 2 * Math.PI, false);
          context.closePath();
          context.restore();
          context.fill();
        }
      }
    }

    for (let i = 0, len = this.selectedPoints.length; i < len; i++) {
      const point = lines.find((line) => line.time === this.selectedPoints[i]);
      if (point && point !== (this.hoveredItem || this.trendHoverInput)) {
        const cxs = point['cx'] + this.margin.left;
        const cys = point['cy'] + this.margin.top;
        const ws = point['rx'] || 1;
        const hs = point['ry'] || 1;
        const dominantScales = ws > hs ? 'w' : hs > ws ? 'h' : 'both';
        const radiuss = dominantScales === 'w' ? hs : ws;
        const scaleXs = dominantScales === 'w' ? ws / hs : 1;
        const scaleYs = dominantScales === 'h' ? hs / ws : 1;
        const hasValue = point['average'] != null;

        if (hasValue) {
          context.fillStyle = this.selectedColorScale(data.index, i);
          context.globalAlpha =
            highlight &&
            highlight.find(
              (item) => item.parentKey === data.key && (!item.parentGroup || item.parentGroup === data.group),
            )
              ? 0.1
              : !highlight || highlight.length === 0
                ? 0.1
                : 0.01;
          context.save();
          context.beginPath();
          context.scale(scaleXs, scaleYs);
          context.arc(cxs / scaleXs, cys / scaleYs, radiuss, 0, 2 * Math.PI, false);
          context.closePath();
          context.restore();
          context.fill();
        }
      }
    }

    context.globalAlpha = 1;
  }

  setEndPoints(context, data, highlight) {
    const lines = data && data.lines ? data.lines : [];
    const color = d3.hsl(this.colorScale(data.index));
    color.s = color.s / 3;
    const startPointColor = d3.hsl(this.colorScale(data.index));
    startPointColor.s = startPointColor.s / 3;
    startPointColor.l = startPointColor.l / 1.2;

    context.textBaseline = 'middle';
    context.textAlign = 'center';

    if (this.hoveredItem || this.trendHoverInput) {
      const hover = this.hoveredItem || this.trendHoverInput;
      const itemNum = hover ? lines.findIndex((l) => l.time === hover) : -1;

      if (itemNum > -1 && lines[itemNum]) {
        const xPos = lines[itemNum]['cx'] + this.margin.left;
        const yPos = lines[itemNum]['cy'] + this.margin.top;
        const hasValue = lines[itemNum]['average'] != null;

        if (hasValue) {
          context.fillStyle =
            this.comparisonMode === 'joined'
              ? this.selectedColorScale(data.index, this.selectedPoints.length - 1)
              : Colors.TEXT;
          context.strokeStyle = Colors.TEXT;
          context.lineWidth = this.selectedPoints.indexOf(hover) > -1 ? 0.2 * this.ballSize : 1;
          context.beginPath();
          context.arc(xPos, yPos, this.ballSize, 0, 2 * Math.PI);
          context.closePath();
          context.fill();
          context.stroke();

          context.fillStyle = Colors.BACKGROUND;
          context.font = this.ballSize * 0.8 + 'px Inter';
          context.fillText(data && data.number ? Math.round(Number(data.number)) : '', xPos, yPos);
        }
      }
    }

    context.lineWidth = 1;

    for (let i = 0, len = this.selectedPoints.length; i < len; i++) {
      const point = lines.find((line) => line.time === this.selectedPoints[i]);
      if (point) {
        const startX = point['cx'] + this.margin.left;
        const startY = point['cy'] + this.margin.top;
        const hasValue = point['average'] != null;

        if (hasValue) {
          context.fillStyle = this.selectedColorScale(data.index, i);
          context.strokeStyle =
            highlight &&
            highlight.find(
              (item) =>
                item.parentKey === data.key &&
                item.time === point.time &&
                (!item.parentGroup || item.parentGroup === data.group),
            )
              ? Colors.TEXT
              : 'transparent';
          context.globalAlpha =
            highlight &&
            highlight.find(
              (item) => item.parentKey === data.key && (!item.parentGroup || item.parentGroup === data.group),
            )
              ? 0.8
              : !highlight || highlight.length === 0
                ? 0.8
                : 0.05;
          context.save();
          context.beginPath();
          context.arc(startX, startY, this.ballSize, 0, 2 * Math.PI);
          context.closePath();
          context.restore();
          context.stroke();
          context.fill();

          context.fillStyle = Colors.BACKGROUND;
          context.font = this.ballSize * 0.8 + 'px Inter';
          context.fillText(data && data.number ? Math.round(Number(data.number)) : '', startX, startY);
        }
      }
    }

    context.globalAlpha = 1;
    context.strokeStyle = 'transparent';
  }

  setTooltip(event, parents: any[] = [], childs: any[] = []) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const __this = this;
    const options = {
      textColor: (d) => this.selectedColorScale(d.parentColorIndex, this.selectedPoints.indexOf(d.time)),
      html: (d) => `<div class="question">${parents.length > 1 ? '<span class="circle">●</span>' : ''}
       <span class="text">${d.parentTitle} (${
         d['time'] && Number(d['time']) ? this.timelineFormat.format(Number(d['time'])) : ''
       })</span> <span class="stats">
        <span class="icon">contact</span> <span class="number">${
          d.n != null ? d.n : '-'
        }</span> <span class="icon">axis_x</span> <span class="number">${
          d.average != null ? d.average.toFixed(1) : '-'
        }</span><span class="icon">axis_y</span> <span class="number">${
          d.averageY != null ? d.averageY.toFixed(1) : '-'
        }</span></span>`,
    };

    this.setHoverEffects(parents, childs);

    this.base.selectAll('.trend-linear-2d-content').selectAll('.trend-chart-tooltip').remove();

    this.tooltip = this.base
      .selectAll('.trend-linear-2d-content')
      .filter((d) => childs.find((child) => d.id === child.dataId))
      .selectAll('.trend-chart-tooltip')
      .data(childs.length > 0 ? [childs] : []);

    this.tooltip.exit().remove();

    this.tooltip.enter().append('div').attr('class', 'trend-chart-tooltip');

    this.tooltipTexts = this.base
      .selectAll('.trend-chart-tooltip')
      .selectAll('.trend-chart-tooltip-text')
      .data((d) => d);

    this.tooltipTexts.exit().remove();

    this.tooltipTexts.style('color', options.textColor).html(options.html);

    this.tooltipTexts
      .enter()
      .append('div')
      .attr('class', 'trend-chart-tooltip-text')
      .style('color', options.textColor)
      .html(options.html);

    this.base
      .selectAll('.trend-linear-2d-content')
      .selectAll('.trend-chart-tooltip')
      .style('transform', function (d, i, g) {
        const w = __this.margin.left + __this.width + __this.margin.right;
        const tipW = this.offsetWidth;
        const pos = d3.pointer(event, this.parentElement)[0];
        let sumHeight = 15;
        for (let e = 0, len = g.length; e < len; e++) {
          if (e <= i) {
            sumHeight += g[e].offsetHeight;
          }
        }

        return `translate(${
          tipW < w ? (pos + tipW / 2 > w ? w - tipW : pos - tipW / 2 < 0 ? 0 : pos - tipW / 2) : 0
        }px,${__this.margin.top - sumHeight}px)`;
      });
  }

  setHoverEffects(parents = [], childs = []) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const __this = this;
    // adding hovering effect
    this.base
      .selectAll('.trend-linear-2d-content')
      .selectAll('.trend-linear-2d-chart-canvas')
      .each(function () {
        if (this.parentElement) {
          const i = this.parentElement.attributes['data-index'].value;

          __this.setChart(
            __this.context[i].context,
            __this.context[i].data,
            __this.chartData[i].sliderDetails,
            __this.chartData[i].group,
            parents, // d.map(item => `${item.number}. ${item.title}`)
            childs,
          );
          __this.setTimeline(__this.context[i].context, __this.context[i].data, __this.hoveredItem);
        }
      });

    __this.hover.emit(__this.hoveredItem);
  }

  // Helpers
  selectForHover(event, area, data) {
    const itemsBelow = this.itemsBelow(area, data);
    const parents = itemsBelow.parents;
    const childs = itemsBelow.childs;
    const legends = itemsBelow.legends;

    if (parents.length > 0) {
      this.setTooltip(event, parents, childs);
    } else if (legends.length > 0) {
      const legendItems = this.chartData[0].children.filter((item) =>
        legends.find((legend) => legend.key.toString() === item.group),
      );
      const legendChilds = [];

      for (let p = 0, lenp = legendItems.length; p < lenp; p++) {
        const parentItem = legendItems[p];

        for (let s = 0, lens = this.selectedPoints.length; s < lens; s++) {
          const pointIndex = parentItem['lines'].findIndex((line) => line.time === this.selectedPoints[s]);
          const item = Object.assign({}, parentItem['lines'][pointIndex]);

          item['parentIndex'] = p;
          item['parentKey'] = parentItem.key;
          item['parentGroup'] = parentItem.group;
          item['parentColorIndex'] = parentItem.index;
          item['parentTitle'] = parentItem.title;
          item['dataId'] = data.id;

          legendChilds.push(item);
        }
      }

      this.setHoverEffects(legendItems, legendChilds);
    } else {
      this.setTooltip(event);
    }
  }

  itemsBelow(area, data, question = '') {
    const parents = question ? [data] : data.children || [];
    const filteredParents = [];
    const childs = [];

    for (let p = 0, lenp = parents.length; p < lenp; p++) {
      const parentItem = parents[p];

      for (let s = 0, lens = this.selectedPoints.length; s < lens; s++) {
        const pointIndex = parentItem['lines'].findIndex((line) => line.time === this.selectedPoints[s]);
        const item = Object.assign({}, parentItem['lines'][pointIndex]);

        item['parentIndex'] = p;
        item['parentKey'] = parentItem.key;
        item['parentColorIndex'] = parentItem.index;
        item['parentTitle'] = parentItem.title;
        item['dataId'] = data.id;

        if (
          !question &&
          item.n > 0 &&
          area[0] < item.cx + this.ballSize + this.margin.left &&
          area[0] > item.cx - this.ballSize + this.margin.left &&
          area[1] < item.cy + this.ballSize + this.margin.top &&
          area[1] > item.cy - this.ballSize + this.margin.top
        ) {
          childs.push(item);
          filteredParents.push(parentItem);
        } else if (question) {
          childs.push(item);
        }
      }
    }

    const legends =
      this.legends && this.legends.length > 0
        ? this.legends.filter(
            (item) =>
              area[0] > item.x - 15 &&
              area[0] < item.x + item.width &&
              area[1] > item.y &&
              area[1] < item.y + item.height,
          )
        : [];

    return { parents: filteredParents, childs, legends };
  }

  /**
   * Parsing group ids from axis details.
   *
   * @param detailsX   Dimension details for axis x.
   * @param detailsY  Dimension details for axis x.
   * @returns        Parsed group id.
   */
  parseGroupId(domainX: ChartDomain, domainY: ChartDomain): string {
    return `${domainX?.labelsLinear?.axis || ''}: ${domainX?.labelsLinear?.min || ''} - ${
      domainX?.labelsLinear?.max || ''
    } (${domainX?.origin?.min}-${domainX?.origin?.max}) - ${domainY?.labelsLinear?.axis || ''}: ${
      domainY?.labelsLinear?.min || ''
    } - ${domainY?.labelsLinear?.max || ''} (${domainY?.origin?.min}-${domainY?.origin?.max})`;
  }
}
