import { Observable, Subject, combineLatest, of } from 'rxjs';

import { map, filter, debounceTime, distinctUntilChanged, takeUntil, switchMap } from 'rxjs/operators';

import { environment } from '@env/environment';

import { Router, ActivatedRoute } from '@angular/router';
import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  Input,
} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { GridItem, DimensionDataItem } from '@shared/models/report.model';
import { ReportData, SurveyModel } from '@shared/models/survey.model';

import { Rights } from '@shared/enums/rights.enum';

import { ReportManager } from '@shared/services/report-manager.service';
import { DialogControl } from '@shared/services/dialog-control.service';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';

import { ChartsManager } from '@report/shared/services/charts-manager.service';

import { PromoMerge } from '@report/shared/components/promo-merge/promo-merge.dialog';
import { PromoShare } from '@report/shared/components/promo-share/promo-share.dialog';
import { RawDataView } from '@report/shared/components/raw-data-view/raw-data-view.dialog';
import { ReportShare } from '@report/shared/components/report-share/report-share.dialog';

import { Select, Store } from '@ngxs/store';

import { SurveyState } from '@shared/states/survey.state';
import { usageCollected, totalLocked } from '@shared/states/billing.functions';
import { BillingState } from '@shared/states/billing.state';
import { LicenseFeature } from '@shared/enums/license-feature.enum';

import { Crossfilter } from '@report/shared/services/crossfilter.service';
import { ReportSave } from '@report/shared/services/report-save.service';
import { GetUsage } from '@shared/states/survey.actions';
import { DisplaySnackbar } from '@shared/states/dialog.actions';
import { HelpSubject } from '@shared/modules/help-center/help-subject.enum';
import { ReportCommon } from '@report/shared/services/report-common.service';
import { AccountState } from '@shared/states/account.state';
import { TeamData } from '@shared/models/account.model';

/**
 * This is a navigation pane component which shows surveyname, maintains view tabs and handles exports & stuff.
 */
@Component({
  selector: 'nav-pane',
  templateUrl: './nav-pane.component.html',
  styleUrls: ['./nav-pane.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavPane implements OnInit, OnDestroy {
  @Input() userRights: number = 0;
  @Input() surveyRights: number = 0;
  readonly Rights = Rights;
  readonly subjects = HelpSubject;

  public selectedSurvey: string = '';
  public reportName: string = '';

  public exportFilters: any;

  public sampleData: boolean = false;

  public shareLinkBase: string = 'https:' + environment.publicUrl + '/r/';

  public isAuthorUnsavedChanges: boolean = false;

  public gridAvailable: boolean = false;

  private cfSub: any;
  private cmSub: any;

  private promoDialog: MatDialogRef<PromoShare, any> | undefined = undefined;
  private promoMergeDialog: MatDialogRef<PromoMerge, any> | undefined = undefined;

  private grid: GridItem[] = [];
  private originalReportsList: ReportData[] = [];

  // Search reports
  private searchSub: any;
  public searchTerm: string = '';
  public searchUpdate: Subject<string> = new Subject<string>();

  // Saving analysis
  public reportsList: ReportData[] = [];
  public selectedReport: string = '';
  public selectedReportData: ReportData = new ReportData();
  public newReportSavingOnProgress: boolean = false;

  public reportNameEditModeOn: boolean = false;

  public enabledBenchmarkData: boolean = false;
  public anonymityTreshold: number = null;

  public pinnedItemsCount: number | null = null;
  iconTooltip = $localize`:tooltip@@zef-i18n-00277:Copy public link`;

  @ViewChild('actionMenu') actionMenu: ElementRef | undefined;

  @Select(SurveyState.activeSurvey())
  survey$!: Observable<SurveyModel>;

  @Select(AccountState.benchmarkDataEnabled)
  readonly isBenchmarkDataFeatureEnabled$!: Observable<boolean>;

  @Select(AccountState.parentBenchmarkDataEnabled)
  readonly parentBenchmarkDataFeatureEnabled$!: Observable<boolean>;

  surveyUsage = this.survey$.pipe(
    map((data) => (data ? data.usage : null)),
    filter((usage) => !!usage),
    map((usage) => ({
      collectedAnswers: usageCollected(usage.answers),
      lockedAnswers: totalLocked(usage.answers),
    })),
  );

  canAddBenchmarkData$: Observable<boolean> = combineLatest([
    this.isBenchmarkDataFeatureEnabled$,
    this.parentBenchmarkDataFeatureEnabled$,
  ]).pipe(
    filter(([enabled, enabledParent]) => enabled || enabledParent),
    switchMap(() =>
      this.survey$.pipe(
        map((survey) => survey?.survey?.template),
        switchMap((template) => this.rm.getBenchmarkDataStatus(template)),
      ),
    ),
  );

  @Select(AccountState.parent)
  readonly parent$!: Observable<TeamData>;

  parentName$: Observable<string> = this.parent$.pipe(map((parent) => (parent ? parent.name : '')));

  @Select(BillingState.featureActive(LicenseFeature.ReportSharing))
  canShare$: Observable<boolean>;

  @Select(BillingState.featureActive(LicenseFeature.ReportMerging))
  canMerge$: Observable<boolean>;

  constructor(
    private hooks: LifecycleHooks,
    private rm: ReportManager,
    private rs: ReportSave,
    private route: ActivatedRoute,
    private router: Router,
    readonly cf: Crossfilter,
    private cm: ChartsManager,
    private cdRef: ChangeDetectorRef,
    private dc: DialogControl,
    private store: Store,
    private rc: ReportCommon,
  ) {}

  ngOnInit() {
    combineLatest(this.route.params, this.route.data)
      .pipe(
        switchMap(([params, data]) => {
          this.selectedSurvey = params['survey'];
          this.selectedReport = params['report'] || '';

          this.store.dispatch(new GetUsage(this.selectedSurvey));

          return data.reports || of(null);
        }),
        debounceTime(50),
        takeUntil(this.hooks.destroy),
      )
      .subscribe((reports: any[]) => {
        if (reports !== null) {
          for (const item of reports) {
            if (item['$key'] === this.selectedReport) {
              this.selectedReportData = item;
              if (!this.reportNameEditModeOn) {
                this.reportName = item.title || '';
              }
              this.isAuthorUnsavedChanges = item.isAuthorUnsavedChanges;
            }
          }

          this.reportsList = reports.filter((item) => !item.isAuthorUnsavedChanges);
          this.originalReportsList = reports.filter((item) => !item.isAuthorUnsavedChanges);

          if (this.searchTerm) {
            this.reportsList = this.filterItems(this.searchTerm, this.originalReportsList);
          }
          this.cdRef.detectChanges();
        }
      });

    this.cfSub = this.cf.crossfilter.subscribe((crossfilter) => {
      if (crossfilter) {
        this.anonymityTreshold = this.cf.getAnonymityTreshold();
        this.enabledBenchmarkData = this.cf.getBenchmarkDataStatus();
        this.sampleData = crossfilter.stats.sampleData;
        this.exportFilters = crossfilter.filters;

        this.cdRef.detectChanges();
      }
    });

    this.cmSub = this.cm.gridSubj.subscribe((grid) => {
      if (grid) {
        this.grid = grid;
        this.gridAvailable = grid.length > 0;

        this.cdRef.detectChanges();
      }
    });

    this.searchSub = this.searchUpdate.pipe(debounceTime(150), distinctUntilChanged()).subscribe((term) => {
      this.reportsList = term ? this.filterItems(term, this.originalReportsList) : this.originalReportsList.slice();
      this.cdRef.detectChanges();
    });
  }

  ngOnDestroy() {
    this.originalReportsList = [];
    this.reportsList = [];
    this.gridAvailable = false;

    this.cmSub.unsubscribe();
    this.cfSub.unsubscribe();

    this.newReportSavingOnProgress = false;
    this.searchSub.unsubscribe();
    if (this.promoDialog) {
      this.promoDialog.close();
    }
  }

  // For Searchable menu
  filterItems(term: string, list: any) {
    return list.filter((row) => row.title.toLowerCase().indexOf(term.toLowerCase()) > -1);
  }

  openExportDialog() {
    this.rc.openExportDialog({
      cm: this.cm,
      cf: this.cf,
      sampleData: this.sampleData,
      reportData: this.selectedReportData,
    });
  }

  openShareDialog() {
    if (!this.selectedReport) {
      this.saveAsReport('shared');
    }
    let reportShareDialog = this.dc.open(
      ReportShare,
      {
        data: {
          selectedSurvey: this.selectedSurvey,
          selectedReport: this.selectedReport,
          selectedReportData: this.selectedReportData,
          cm: this.cm,
          cf: this.cf,
        },
      },
      {
        width: '800px',
        maxWidth: '800px',
      },
    );

    reportShareDialog.afterClosed().subscribe((count) => {
      if (count > 0) {
        this.store.dispatch(
          new DisplaySnackbar($localize`Report shared with ${count} people.`, {
            actionName: $localize`View`,
            actionCallback: () => {
              reportShareDialog = this.dc.open(
                ReportShare,
                {
                  data: {
                    selectedSurvey: this.selectedSurvey,
                    selectedReport: this.selectedReport,
                    selectedReportData: this.selectedReportData,
                    cm: this.cm,
                    cf: this.cf,
                    manageUsers: true,
                  },
                },
                {
                  width: '800px',
                  maxWidth: '800px',
                  minHeight: '562px',
                },
              );
            },
            color: 'success',
          }),
        );
      } else if (count === 0) {
        this.store.dispatch(
          new DisplaySnackbar($localize`Report shared with ${count} people.`, {
            color: 'warning',
          }),
        );
      }
      reportShareDialog = undefined;
    });
  }

  openPromoDialog() {
    this.promoDialog = this.dc.open(
      PromoShare,
      {},
      {
        width: '800px',
        maxWidth: '800px',
        closeOnNavigation: true,
      },
    );
    this.promoDialog.afterClosed().subscribe(() => {
      this.promoDialog = undefined;
    });
  }

  openPromoMergeDialog() {
    this.promoMergeDialog = this.dc.open(
      PromoMerge,
      {},
      {
        width: '800px',
        maxWidth: '800px',
        closeOnNavigation: true,
      },
    );
    this.promoMergeDialog.afterClosed().subscribe(() => {
      this.promoMergeDialog = undefined;
    });
  }

  openRawDataViewDialog() {
    const dimensions: DimensionDataItem[] = Object.keys(this.cf.dimensions)
      .map((item) => this.cf.dimensions[item])
      .filter((item) => {
        const notShowableEmpties = ['hashtags', 'shareLink', 'language', 'outcome', 'userSegments'];

        return notShowableEmpties.indexOf(item.key) < 0 || item.values.length > 0;
      })
      .sort((a, b) => 1 + (a.key === 'time' ? 0 : a.order || 1) - (1 + (b.key === 'time' ? 0 : b.order || 1)));

    dimensions.unshift({ key: 'id', title: 'Id' } as DimensionDataItem);
    dimensions.unshift({ key: 'select', title: '' } as DimensionDataItem);

    this.dc.open(
      RawDataView,
      {
        data: {
          selectedSurvey: this.selectedSurvey,
          dimensions,
          cf: this.cf,
          userRights: this.userRights,
          surveyRights: this.surveyRights,
        },
      },
      {
        width: '90%',
        height: '90%',
        maxHeight: '100%',
        maxWidth: '90%',
      },
    );
  }

  deleteReport(selectedReport: string, title: string = '', panelRef) {
    this.pinnedItemsCount = 0;

    for (const item in this.grid) {
      if (this.grid[item].isPinned) {
        this.pinnedItemsCount += this.grid[item]['isPinned'] ? 1 : 0;
      }
    }

    const survey = this.store.selectSnapshot(SurveyState.activeSurvey());

    this.dc
      .openSurveyDialog(
        survey,
        'alert',
        $localize`:dialog title@@zef-i18n-00136:Confirm delete`,
        $localize`:dialog header@@zef-i18n-00278:Are you sure you want to delete ${
          title ? '"' + title + '"' : 'this report'
        }:INTERPOLATION:?`,
        $localize`:dialog content@@zef-i18n-00279:There is ${this.pinnedItemsCount}:INTERPOLATION: pinnings on the charts on this report. These pinned charts will disappear from dashboards.`,
        $localize`:dialog button label@@zef-i18n-00138:Yes, Delete`,
      )
      .afterClosed()
      .subscribe((action: string) => {
        if (action === 'Yes, Delete' || action === 'Kyllä, Poista') {
          this.rs
            .save(
              this.cf,
              this.cm,
              this.selectedSurvey,
              selectedReport,
              this.selectedReportData.title,
              this.selectedReportData.online,
              false,
              -1,
            )
            .subscribe(() => {
              if (this.selectedReport === selectedReport) {
                panelRef.closePanel();
                this.router.navigate([`/surveys/${this.selectedSurvey}/analyze/`]);
              } else {
                this.cdRef.detectChanges();
              }
            });
        }
      });
  }

  duplicateReport(selectedReport) {
    this.rs.duplicate(this.selectedSurvey, selectedReport.$key, selectedReport).subscribe(() => {
      this.cdRef.detectChanges();
    });
  }

  openReport(report, panelRef) {
    this.router.navigate([`/surveys/${this.selectedSurvey}/analyze/${report}/`]);
    panelRef.closePanel();
  }

  openDefaultReport() {
    this.router.navigate([`/surveys/${this.selectedSurvey}/analyze/`]);
  }

  saveAsReport(action: string = '') {
    const date: Date = new Date();

    const title = `${
      action === 'shared' ? $localize`:@@zef-i18n-00280:Shared Report` : $localize`:@@zef-i18n-00281:Saved Report`
    } ${date.getUTCDate()}.${date.getUTCMonth() + 1} ${
      date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    }:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`;

    if (this.isAuthorUnsavedChanges) {
      this.rm.unsavedChangesToReport(this.selectedSurvey, this.selectedReport, title);
    } else {
      this.cm.closeMenusSubj.next(true);
      this.cm.updateSubj.next(true);
      this.newReportSavingOnProgress = true;

      setTimeout(() => {
        this.rs.save(this.cf, this.cm, this.selectedSurvey, '', title, false, false, 1).subscribe((res) => {
          this.cm.closeMenusSubj.next(false);
          this.cm.updateSubj.next(false);

          if (res) {
            this.router.navigate([`/surveys/${this.selectedSurvey}/analyze/${res}`]);
          }
        });
      }, 0);
    }
  }

  saveReport() {
    this.cm.closeMenusSubj.next(true);
    this.cm.updateSubj.next(true);

    this.rs.quickSave().subscribe(() => {
      this.cm.closeMenusSubj.next(false);
      this.cm.updateSubj.next(false);
    });
  }

  public inputtingReportName(name) {
    this.selectedReportData.title = name;
  }

  public updateReportName() {
    this.rm.editReportName(this.selectedSurvey, this.selectedReport, this.reportName);
    this.reportNameEditModeOn = false;
    this.cm.gridChange.next('Edited report name');
  }

  public switchBenchmarkData() {
    this.enabledBenchmarkData = !this.enabledBenchmarkData;
    this.cf.switchBenchmarkData();
  }

  close() {}
}
