import * as d3 from 'd3';

import { Directive, ElementRef, HostListener, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';

import { CanvasContext, ChartDistribution, ChartDomain, ChartLegend } from '@shared/models/report.model';

import { Crossfilter } from '@report/shared/services/crossfilter.service';

import { Colors } from '@report/shared/enums/colors.enum';

import { drawContactIcon, shortenText } from '@shared/utilities/canvas.utilities';

/**
 * This is a donut chart.
 */
@Directive({
  selector: '[donutChart]',
})
export class DonutChart implements OnChanges {
  @Input() data: ChartDistribution[] = [];
  @Input() domain: ChartDomain = {} as ChartDomain;
  @Input() stats: any;
  @Input() scale: any;
  @Input() filterInput: any;
  @Input() transitionDuration: number = 0;
  @Input() update: Date = new Date();
  @Input() filtering: boolean = false;
  @Input() anonymityLock: boolean = false;
  @Input() title: string = '';
  @Input() totalAnswers: number = 0;
  @Input() selectionExists: boolean = false;
  @Input() filtersDemo: boolean = false;
  @Input() touchDevice: boolean = false;

  private width: number = 0;
  private height: number = 0;
  private margin: any = { top: 30, right: 20, bottom: 60, left: 20 };
  private radius: number = 0;
  private fontSize: number = 0;
  private unit: number = 0;
  private marginBottomUnits: number = 0;

  private responses: number = 0;
  private previousResponses: number = 0;

  private context: CanvasContext = {} as CanvasContext;

  private filter: any;
  private selections: any = new Set();
  private legends: ChartLegend[] = [];

  // d3 elements
  private base: any;
  private canvas: any;
  private colorScale: any;
  private pie: any;
  private tooltip: any;

  @HostListener('window:resize') resize() {
    this.updateChart(null);
  }

  constructor(
    private _element: ElementRef,
    private cf: Crossfilter,
  ) {
    this.constructBody();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.data ||
      changes.domain ||
      changes.scale ||
      changes.filterInput ||
      changes.update ||
      changes.filtering ||
      changes.anonymityLock ||
      changes.title ||
      changes.stats ||
      changes.filtersDemo
    ) {
      this.updateChart(changes.data);
    }
  }

  updateChart(dataChanges: SimpleChange | null) {
    this.setEnvironment();
    this.setScales();
    this.setCanvas(dataChanges);
  }

  constructBody() {
    this.base = d3.select(this._element.nativeElement).append('div').attr('class', 'donut-chart');
  }

  setEnvironment() {
    this.fontSize = parseFloat(window.getComputedStyle(this._element.nativeElement).fontSize);
    this.unit = (10 / 14) * this.fontSize;

    this.margin = {
      top: 3 * this.unit,
      right: 2 * this.unit,
      bottom: this.marginBottomUnits * this.unit,
      left: 2 * this.unit,
    };

    const width = this._element.nativeElement.clientWidth - this.margin.left - this.margin.right;
    const height = this._element.nativeElement.clientHeight - this.margin.top - this.margin.bottom;

    this.width = width > 0 ? width : 0;
    this.height = height > 0 ? height : 0;
    this.radius = Math.min(this.width, this.height) / 2;
  }

  setScales() {
    this.pie = d3
      .pie()
      .sort(null)
      .value((d: any) => d.percentage);

    const domain: number[] = [];

    for (let i = 0, len = this.domain.keys.length; i < len; i++) {
      domain.push(i);
    }

    const colors = this.domain.keys.map((item, index) => {
      const color = d3.hsl(
        this.filterInput
          ? Colors.FILTER
          : this.selectionExists
            ? Colors.SELECTED
            : this.filtersDemo
              ? Colors.UNSELECTED
              : Colors.DEFAULT,
      );
      color.s = color.s - (color.s - 0.2) * (index / this.domain.keys.length);
      if (index % 2) {
        color.l = color.l + 0.1;
      }

      return color;
    });

    this.colorScale =
      domain.length > 1
        ? d3.scaleLinear<any, any>().domain(domain).range(colors)
        : () =>
            d3.hsl(
              this.filterInput
                ? Colors.FILTER
                : this.selectionExists
                  ? Colors.SELECTED
                  : this.filtersDemo
                    ? Colors.UNSELECTED
                    : Colors.DEFAULT,
            );

    this.previousResponses = this.responses;
    this.responses = this.stats && this.stats['responses'] != null ? this.stats['responses'] : this.totalAnswers;
  }

  setCanvas(dataChanges: SimpleChange | null) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const __this = this;
    const hoverFunction = function (event) {
      if (!__this.touchDevice || !__this.filtering || __this.anonymityLock) {
        const area = d3.pointer(event);
        __this.selectForHover(event, area);
      }
    };
    const mouseOutFunction = (event) => {
      this.setTooltip(d3.pointer(event));
    };
    const clickFunction = function (event) {
      const area = d3.pointer(event);
      __this.selectFromArcs(area);
      __this.selectFromLegends(area);
      __this.callFilter();
    };

    const drawContent = function (d) {
      const context = d3.select(this).node().getContext('2d');
      // __this.setTexts(context);
      __this.setLegends(context);

      if (dataChanges && !dataChanges.firstChange && __this.transitionDuration > 0) {
        const dataObj = __this.context && __this.context.data ? __this.context.data : [];
        const interpolator = d3.interpolateArray(dataObj, d);
        const interpolateResponses = d3.interpolateNumber(__this.previousResponses, __this.responses);
        const ease = d3.easeCubic;

        const t = d3.timer((elapsed) => {
          const step = elapsed / __this.transitionDuration;
          let data;
          let responses;

          if (step >= 1) {
            data = interpolator(ease(1));
            responses = interpolateResponses(ease(1));
            t.stop();
          } else {
            data = interpolator(ease(step));
            responses = Math.round(interpolateResponses(ease(step)));
          }

          __this.setArcs(context, data);
          __this.setTexts(context, responses);
        });
      } else {
        __this.setArcs(context, d);
        __this.setTexts(context, __this.responses);
      }

      __this.context = { context, data: d };
    };

    this.canvas = this.base.selectAll('.donut-chart-canvas').data([this.data]);

    this.canvas.exit().remove();

    this.canvas
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .each(drawContent);

    this.canvas
      .enter()
      .append('canvas')
      .attr('class', 'donut-chart-canvas')
      .style('position', 'relative')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .on('mousemove', hoverFunction)
      .on('mouseout', mouseOutFunction)
      .on('click', clickFunction)
      .each(drawContent);
  }

  setLegends(context) {
    this.legends = [];
    const y = this.margin.top + this.height + 1 * this.unit;
    const width = this.width + this.margin.left + this.margin.right;
    const height = 2 * this.unit;
    let usedSpace = 0;
    let arr: any[] = [];
    let currentX = 0;
    let currentY = 0;
    let margin;

    context.clearRect(0, y - 1, width, this.margin.bottom);

    context.textBaseline = 'top';
    context.textAlign = 'left';
    context.font = 10 / 14 + 'em Inter';

    for (let i = 0, length = this.domain.keys.length; i < length; i++) {
      context.save();
      const key = this.domain.keys[i];
      context.font = (this.domain.labels[key] === '☑' || this.domain.labels[key] === '☐' ? 16 : 10) / 14 + 'em Inter';
      const text = shortenText(context, this.domain.labels[key], width, 3 * this.unit);
      const elW = context.measureText(text).width;

      if (usedSpace + elW + 2 * this.unit <= width) {
        usedSpace += elW + 2 * this.unit;
        arr.push([key, i, text]);
      } else {
        margin = (width - usedSpace) / 2;
        currentX = margin;

        for (let a = 0, len = arr.length; a < len; a++) {
          const itemText = arr[a][2];
          const itemColor = arr[a][1];
          const itemKey = arr[a][0];
          currentX += this.unit;

          context.fillStyle = Colors.TEXT;
          context.fillText(itemText, currentX, currentY + y);

          context.beginPath();
          context.arc(currentX - 0.8 * this.unit, currentY + y + 0.6 * this.unit, 0.5 * this.unit, 0, 2 * Math.PI);
          context.closePath();
          context.fillStyle = this.colorScale(itemColor);
          context.fill();
          this.legends.push({
            key: itemKey,
            x: currentX,
            y: currentY + y,
            width: context.measureText(itemText).width,
            height,
          });
          currentX += context.measureText(itemText).width + this.unit;
        }

        currentY += height;
        usedSpace = elW + 2 * this.unit;

        arr = [[key, i, text]];
      }
      context.restore();
    }

    margin = (width - usedSpace) / 2;
    currentX = margin;

    for (let a = 0, len = arr.length; a < len; a++) {
      context.save();
      const itemText = arr[a][2];
      const itemColor = arr[a][1];
      const itemKey = arr[a][0];
      currentX += this.unit;
      context.font = (itemText === '☑' || itemText === '☐' ? 16 : 10) / 14 + 'em Inter';
      context.fillStyle = Colors.TEXT;
      context.fillText(itemText, currentX, currentY + y);

      context.beginPath();
      context.arc(currentX - 0.8 * this.unit, currentY + y + 0.6 * this.unit, 0.5 * this.unit, 0, 2 * Math.PI);
      context.closePath();
      context.fillStyle = this.colorScale(itemColor);
      context.fill();
      this.legends.push({
        key: itemKey,
        x: currentX,
        y: currentY + y,
        width: context.measureText(itemText).width,
        height,
      });
      currentX += context.measureText(itemText).width + this.unit;
      context.restore();
    }

    const spaceNeeded = currentY / this.unit + 6;

    if (!isNaN(spaceNeeded) && this.marginBottomUnits !== spaceNeeded) {
      this.marginBottomUnits += spaceNeeded - this.marginBottomUnits;
      this.setEnvironment();
      this.setScales();
      this.setLegends(context);
    }
  }

  setArcs(context, data: any[] = [], highlight: any[] | null = []) {
    context.clearRect(this.margin.left - 1, this.margin.top - 1, this.width + this.margin.right, this.height + 3);
    this.selections = new Set();

    const arc = d3
      .arc()
      .outerRadius(this.radius - 10)
      .innerRadius(this.radius / 1.667)
      .padAngle(0.02)
      .context(context);

    const arcExtended = d3
      .arc()
      .outerRadius(this.radius)
      .innerRadius(this.radius / 1.667)
      .padAngle(0.02)
      .context(context);

    const labelArc = d3
      .arc()
      .outerRadius(this.radius - 10)
      .innerRadius(this.radius / 1.667)
      .context(context);

    const arcs = this.pie(data);

    context.save();
    context.translate(this.margin.left + this.width / 2, this.margin.top + this.height / 2);

    arcs.forEach((d: any, i) => {
      let inFilter;
      let inHighlight;

      if (this.filterInput && this.filterInput.length > 0) {
        inFilter = this.filterInput.indexOf(d.data.key) > -1;

        if (!inFilter) {
          context.globalAlpha = 0.2;
        } else {
          context.globalAlpha = 1;
          this.selections.add(d.data.key);
        }
      }

      if (highlight && highlight.length > 0) {
        inHighlight = highlight && highlight.indexOf(d.data.key) > -1;
      }

      context.beginPath();
      if (inFilter || inHighlight) {
        arcExtended(d);
      } else {
        arc(d);
      }
      context.fillStyle = this.colorScale(i);
      context.fill();

      if (inHighlight) {
        context.lineWidth = 2;
        context.strokeStyle = Colors.HIGHLIGHT;
        context.stroke();
      }

      context.textAlign = 'center';
      context.textBaseline = 'middle';
      context.fillStyle = Colors.TEXT;
      const c = labelArc.centroid(d);
      const percentage = d.data.percentage && this.radius > 70 ? (d.data.percentage * 100).toFixed(1) + '%' : '';
      context.fillText(percentage, c[0], c[1]);
    });

    context.restore();
  }

  setTexts(context, responses) {
    context.clearRect(0, 0, this.width + this.margin.right + this.margin.left, this.margin.top);

    context.fillStyle = this.filterInput ? Colors.FILTER : Colors.TEXT;
    context.textAlign = 'left';
    context.textBaseline = 'middle';

    const h = this.margin.top / 2;

    const wIcon = this.fontSize + 4;

    context.font = 10 / 14 + 'em Inter';
    const wNumber = context.measureText(responses).width + 3;

    context.font = 12 / 14 + 'em Inter';
    const title = this.title ? shortenText(context, this.title, this.width, 8 + (wIcon + wNumber) / 2) : '';

    const wTitle = title ? context.measureText(title).width + 8 : 0;

    const startPoint = this.margin.left + this.width / 2 - (wIcon + wNumber + wTitle) / 2;

    if (title) {
      context.fillText(title, startPoint, h);
    }

    drawContactIcon(context, this.fontSize, startPoint + wTitle, h, context.fillStyle);

    context.font = 10 / 14 + 'em Inter';
    context.fillText(responses, startPoint + wTitle + wIcon, h);
  }

  setTooltip(position, data: any[] = []) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const __this = this;

    this.tooltip = d3
      .select(this._element.nativeElement)
      .selectAll('.item-tooltip')
      .data(data.length > 0 ? data : []);

    this.tooltip.exit().remove();

    this.tooltip
      .html(
        (d) => `
            <div class="question">${this.domain.labels[d.key]}</div>
            <div class="stats">
              <span class="icon">contact</span> ${d.value} (${(d.percentage * 100).toFixed(1)}%)
            </div>
          `,
      )
      .style('transform', function () {
        return `translate(${
          position[0] - this.getBoundingClientRect().width / 2
        }px,${position[1] - (this.getBoundingClientRect().height + 15)}px)`;
      });

    this.tooltip
      .enter()
      .append('div')
      .attr('class', 'item-tooltip')
      .html(
        (d) => `
            <div class="question">${this.domain.labels[d.key]}</div>
            <div class="stats">
              <span class="icon">contact</span> ${d.value} (${(d.percentage * 100).toFixed(1)}%)
            </div>
          `,
      )
      .style('transform', function () {
        return `translate(${
          position[0] - this.getBoundingClientRect().width / 2
        }px,${position[1] - (this.getBoundingClientRect().height + 15)}px)`;
      });

    // adding hovering effect
    this.base.select('.donut-chart-canvas').each(function (d) {
      const highlight = data.map((item) => item.key);

      __this.setArcs(__this.context.context, d, highlight);
    });

    if (data.length > 0 && this.filtering && !this.anonymityLock) {
      this.base.select('.donut-chart-canvas').style('cursor', 'pointer');
    } else {
      this.base.select('.donut-chart-canvas').style('cursor', null);
    }
  }

  callFilter() {
    if (this.filtering && !this.anonymityLock) {
      this.filter = [];
      const filter = { key: this.domain.key, values: this.domain.keys, filter: Array.from(this.selections) };

      this.filter.push(filter);
      if (JSON.stringify(this.filter[0].filter) !== JSON.stringify(this.filterInput)) {
        this.cf.filter(this.filter);
      }
    }
  }

  // Helpers
  selectForHover(event, area) {
    const itemsBelow = this.itemsBelow(area);
    const parents = itemsBelow.parents;
    const legends = itemsBelow.legends;

    if (parents.length > 0) {
      this.setTooltip(d3.pointer(event), parents);
    } else if (legends.length > 0) {
      const legendItems = this.data.filter((item) => legends.find((legend) => legend.key.toString() === item.key));

      this.setTooltip(d3.pointer(event), legendItems);
    } else {
      this.setTooltip(d3.pointer(event));
    }
  }

  selectFromArcs(area) {
    const parents = this.itemsBelow(area).parents;

    for (let s = 0, len = parents.length; s < len; s++) {
      if (this.selections.has(parents[s].key)) {
        this.selections.delete(parents[s].key);
      } else {
        this.selections.add(parents[s].key);
      }
    }
  }

  selectFromLegends(area) {
    const legends = this.itemsBelow(area).legends;

    for (let l = 0, len = legends.length; l < len; l++) {
      if (this.selections.has(legends[l].key)) {
        this.selections.delete(legends[l].key);
      } else {
        this.selections.add(legends[l].key);
      }
    }
  }

  itemsBelow(area) {
    const parents = this.pie(this.data)
      .filter((parent) => {
        const angle =
          Math.PI -
          Math.atan2(area[0] - (this.margin.left + this.width / 2), area[1] - (this.margin.top + this.height / 2));
        const radius = Math.sqrt(
          Math.pow(area[0] - (this.margin.left + this.width / 2), 2) +
            Math.pow(area[1] - (this.margin.top + this.height / 2), 2),
        );

        return (
          angle > parent.startAngle && angle < parent.endAngle && radius <= this.radius && radius >= this.radius / 1.667
        );
      })
      .map((parent) => parent.data);

    const legends = this.legends.filter(
      (item) =>
        area[0] > item.x - 15 && area[0] < item.x + item.width && area[1] > item.y && area[1] < item.y + item.height,
    );

    return { parents, legends };
  }
}
