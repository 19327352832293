// TODO: Rename with better name

import * as d3 from 'd3';

import { Directive, ElementRef, OnChanges, Input, HostListener } from '@angular/core';

/**
 * This is a chart data table which is shown below chart.
 */
@Directive({
  selector: '[chartDataTable]',
})
export class ChartDataTable implements OnChanges {
  @Input() data: any;
  @Input() transitionDuration: number = 0;

  private base: any;
  private table: any;

  private header: any;
  private headers: any;

  private rows: any;
  private row: any;

  @HostListener('window:resize') resize() {
    this.ngOnChanges();
  }

  constructor(private _element: ElementRef) {
    this.base = d3.select(this._element.nativeElement).append('div').attr('class', 'base');
  }

  ngOnChanges() {
    this.setTransition(this.transitionDuration);
    this.setTable();
    this.setHeaders();
    this.setContent();
  }

  setTransition(duration) {
    d3.transition().duration(duration);
  }

  setTable() {
    this.table = this.base.selectAll('.chartDataTable').data(this.data || []);

    this.table.exit().remove();

    this.table.enter().append('table').attr('class', 'chartDataTable');
  }

  setHeaders() {
    this.headers = this.base
      .selectAll('.chartDataTable')
      .selectAll('.headers')
      .data((d) => [d.headers]);

    this.headers.exit().remove();

    this.headers.enter().append('tr').attr('class', 'headers');

    this.header = this.base
      .selectAll('.chartDataTable')
      .selectAll('.headers')
      .selectAll('.dt-header')
      .data((d) => d);

    this.header.exit().remove();

    this.header.text((d) => d);

    this.header
      .enter()
      .append('th')
      .attr('class', 'dt-header')
      .text((d) => d);
  }

  setContent() {
    this.rows = this.base
      .selectAll('.chartDataTable')
      .selectAll('.row')
      .data((d) => d.values);

    this.rows.exit().remove();

    this.rows.enter().append('tr').attr('class', 'row');

    this.row = this.base
      .selectAll('.chartDataTable')
      .selectAll('.row')
      .selectAll('.cell')
      .data((d) => d);

    this.row.exit().remove();

    this.row.text((d) => d);

    this.row
      .enter()
      .append('td')
      .attr('class', 'cell')
      .text((d) => d);
  }
}
