export class Exports {
  static readonly CSV: string = 'csv';
  static readonly DOCX: string = 'docx';
  static readonly PDF: string = 'pdf';
  static readonly PPTX: string = 'pptx';
  static readonly XLSX: string = 'xlsx';

  static readonly ExportIcons: { [Export: string]: string } = {
    [Exports.CSV]: 'logo_csv',
    [Exports.DOCX]: 'logo_word',
    [Exports.PDF]: 'logo_pdf',
    [Exports.PPTX]: 'logo_ppt',
    [Exports.XLSX]: 'logo_excel',
  };

  static readonly ExportIconColors: { [Export: string]: string } = {
    [Exports.CSV]: '#000',
    [Exports.DOCX]: '#2a5699',
    [Exports.PDF]: '#c22222',
    [Exports.PPTX]: '#d24625',
    [Exports.XLSX]: '#207245',
  };

  static readonly ExportAll: string[] = [Exports.PDF, Exports.PPTX, Exports.DOCX, Exports.XLSX, Exports.CSV];

  static readonly ExportPro: string[] = [Exports.PDF, Exports.PPTX, Exports.DOCX, Exports.XLSX];

  static readonly ExportAdvancedSettings: string[] = [Exports.PDF, Exports.PPTX, Exports.DOCX];

  static readonly ExportRaw: string[] = [Exports.XLSX, Exports.CSV];
  static readonly ExportPrivate: string[] = [Exports.PDF, Exports.PPTX];
}
