import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Store } from '@ngxs/store';

import { GetActivePlan, GetUsage } from '@shared/states/billing.actions';

import { GetConsoleTeamPlan } from '@admin/shared/states/console-state.actions';

@Injectable({
  providedIn: 'root',
})
export class BillingStatus {
  constructor(readonly store: Store) {}

  resolve({ params }: ActivatedRouteSnapshot): Observable<any> {
    const teamKey = params.team;

    return (
      teamKey
        ? this.store.dispatch([new GetConsoleTeamPlan(teamKey)])
        : this.store.dispatch([new GetActivePlan(), new GetUsage()])
    ).pipe(map(() => undefined));
  }
}
