import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { Crossfilter } from '@report/shared/services/crossfilter.service';
import { ChartsManager } from '@report/shared/services/charts-manager.service';

import { Rights } from '@shared/enums/rights.enum';
import { Questions } from '@shared/enums/questions.enum';
import { Charts } from '@shared/enums/charts.enum';

import { ChartDistribution, GridItem } from '@shared/models/report.model';

/**
 * This is a FilterBar. It will show all active filters.
 */
@Component({
  selector: 'filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterBar implements OnInit, OnDestroy {
  @Input() userRights: number = 0;
  @Input() surveyRights: number = 0;
  @Input() isSharedReport: boolean = false;
  @Input() isTemplateReport: boolean = false;
  @Output() scrollTo: EventEmitter<any> = new EventEmitter<any>();
  readonly Rights = Rights;

  private cfSub: any;
  private filterObj: any;
  private nonFilters: string[] = [
    Questions.FREE_TEXT,
    Questions.INPUT_URL,
    Questions.INPUT_EMAIL,
    Questions.INPUT_PHONE,
    Questions.INPUT_NUMBER,
    Questions.INPUT_STRING,
    Questions.INPUT_NUMERIC,
    Questions.INPUT_ADDRESS,
    Questions.FILE_UPLOAD,
    Questions.ESKO_WHY_FINDER,
  ];

  public disableCrop: boolean = false;
  public filters: any = [];
  public sampleData: boolean = false;
  public responses: number = 0;
  public filtersDemo: boolean = false;
  public safeReportLock: boolean = false;
  public safeReportCount: number = null;
  public anonymityTreshold: number;
  divTooltip = $localize`:tooltip@@zef-i18n-00282:Cropping is not currently allowed for text inputs.`;

  constructor(
    private cf: Crossfilter,
    private cdRef: ChangeDetectorRef,
    private cm: ChartsManager,
  ) {}

  ngOnInit() {
    // subscribing to crossfilter
    this.cfSub = this.cf.crossfilter.subscribe((crossfilter) => {
      if (crossfilter) {
        this.filtersDemo = crossfilter.other ? crossfilter.other.filtersDemo : false;
        this.responses = crossfilter.stats ? crossfilter.stats.respondents : 0;
        this.filterObj = crossfilter.filters;
        this.filters = [];
        this.disableCrop = false;
        this.safeReportLock = false;
        this.safeReportCount = null;
        this.anonymityTreshold = this.cf.getAnonymityTreshold();

        for (const filter in this.filterObj) {
          this.filters.push({
            key: filter,
            label: crossfilter.dimensions[filter].title,
            filter: this.filterObj[filter].filter,
            values: this.filterObj[filter].values,
          });

          if (
            (this.filterObj[filter].dimensionData &&
              this.nonFilters.indexOf(this.filterObj[filter].dimensionData.originalType) >= 0 &&
              this.filterObj[filter].dimensionData.originalTypeSpecifier !== 'text-sentiment' &&
              this.filterObj[filter].dimensionData.originalTypeSpecifier !== 'text-words') ||
            this.filterObj[filter].filter?.includes('__masked__')
          ) {
            this.disableCrop = true;
          }

          if (this.filterObj[filter]['safeReportCount'] != null) {
            this.safeReportLock = true;
            this.safeReportCount = this.filterObj[filter]['safeReportCount'];

            if (this.anonymityTreshold) {
              this.disableCrop = true;
            }
          }
        }
      }

      if (crossfilter && crossfilter.stats && crossfilter.stats.sampleData) {
        this.sampleData = true;
      } else {
        this.sampleData = false;
      }
      this.cdRef.detectChanges();
    });
  }

  ngOnDestroy() {
    this.cfSub.unsubscribe();
  }

  public stringTruncate(str: string) {
    const maxLength = 10;
    str = str.length > maxLength ? str.substr(0, maxLength) + '...' : str;
    return str;
  }

  public removeFilter(filter) {
    const removedFilter = [{ key: filter.key, values: filter.values, filter: [] }];
    this.cf.filter(removedFilter);
  }

  public removeFilters() {
    const removedFilters: any[] = [];

    for (const filter of this.filters) {
      removedFilters.push({ key: filter.key, values: filter.values, filter: [] });
    }

    this.cf.filter(removedFilters);
  }

  public saveFilters() {
    this.cf.userSegmentFromFilters();
  }

  public cropFilters() {
    if (!this.disableCrop) {
      this.cf.saveCropFilters();
    }
  }

  public exampleFilters() {
    const visibleCharts = this.cm.getVisibleItems();
    const firstUsableChart: GridItem = visibleCharts.find(
      (item) =>
        Charts.DISTRIBUTIONCHARTS.indexOf(item.chartSettings.type) >= 0 ||
        item.chartSettings.type === Charts.HEATMAP ||
        item.chartSettings.type === Charts.TIMELINECHART,
    );

    if (firstUsableChart && firstUsableChart.chartSettings.type === Charts.HEATMAP) {
      const filtersX: any[] = [];
      const filtersY: any[] = [];
      const scaleX: string = firstUsableChart.data.details[0].scale;
      const keyX: string = firstUsableChart.data.details[0].key;
      const valuesX: (number | string)[] = firstUsableChart.data.domain[0].keys;
      const distributionX: ChartDistribution[] = firstUsableChart.data.distributions[0];
      const biggestValueX: number = Math.max(...distributionX.map((o) => o.value));
      const biggestValueIndexX: number = distributionX.findIndex((item) => item.value === biggestValueX);

      const scaleY: string = firstUsableChart.data.details[1].scale;
      const keyY: string = firstUsableChart.data.details[1].key;
      const valuesY: (number | string)[] = firstUsableChart.data.domain[0].keysY;
      const distributionY: ChartDistribution[] = firstUsableChart.data.distributions[0][biggestValueIndexX]['children'];
      const biggestValueY: number = Math.max(...distributionY.map((o) => o.value));
      const biggestValueIndexY: number = distributionY.findIndex((item) => item.value === biggestValueY);

      if (scaleX === 'categorical') {
        const filter: string[] = [distributionX[biggestValueIndexX]['key']];
        filtersX.push({ key: keyX, values: valuesX, filter });
      } else if (scaleX === 'linear' || scaleX === 'time') {
        const filter: string[] = [];

        if (biggestValueIndexX > 1) {
          filter.push(distributionX[biggestValueIndexX - 2]['key']);
        }

        if (biggestValueIndexX > 0) {
          filter.push(distributionX[biggestValueIndexX - 1]['key']);
        }

        filter.push(distributionX[biggestValueIndexX]['key']);

        if (biggestValueIndexX < distributionX.length - 1) {
          filter.push(distributionX[biggestValueIndexX + 1]['key']);
        }

        if (biggestValueIndexX < distributionX.length - 2) {
          filter.push(distributionX[biggestValueIndexX + 2]['key']);
        }

        filtersX.push({ key: keyX, values: valuesX, filter });
      }

      if (scaleY === 'categorical') {
        const filter: string[] = [distributionY[biggestValueIndexY]['key']];
        filtersY.push({ key: keyY, values: valuesY, filter });
      } else if (scaleY === 'linear' || scaleY === 'time') {
        const filter: string[] = [];

        if (biggestValueIndexY > 1) {
          filter.push(distributionY[biggestValueIndexY - 2]['key']);
        }

        if (biggestValueIndexY > 0) {
          filter.push(distributionY[biggestValueIndexY - 1]['key']);
        }

        filter.push(distributionY[biggestValueIndexY]['key']);

        if (biggestValueIndexY < distributionY.length - 1) {
          filter.push(distributionY[biggestValueIndexY + 1]['key']);
        }

        if (biggestValueIndexY < distributionY.length - 2) {
          filter.push(distributionY[biggestValueIndexY + 2]['key']);
        }

        filtersY.push({ key: keyY, values: valuesY, filter });
      }

      this.cf.filter(filtersX);
      this.cf.filter(filtersY);
    } else if (firstUsableChart) {
      const filters: any[] = [];
      const scale: string = firstUsableChart.data.details[0].scale;
      const key: string = firstUsableChart.data.details[0].key;
      const values: (number | string)[] = firstUsableChart.data.domain[0].keys;
      const distribution: ChartDistribution[] = firstUsableChart.data.distributions[0];
      const biggestValue: number = Math.max(...distribution.map((o) => o.value));

      if (scale === 'categorical') {
        const filter: string[] = [distribution.find((item) => item.value === biggestValue)['key']];
        filters.push({ key, values, filter });
      } else if (scale === 'linear' || scale === 'time') {
        const biggestValueIndex: number = distribution.findIndex((item) => item.value === biggestValue);

        const filter: string[] = [];

        if (biggestValueIndex > 0) {
          filter.push(distribution[biggestValueIndex - 1]['key']);
        }

        filter.push(distribution[biggestValueIndex]['key']);

        if (biggestValueIndex < distribution.length - 1) {
          filter.push(distribution[biggestValueIndex + 1]['key']);
        }

        filters.push({ key, values, filter });
      }

      this.cf.filter(filters);
    } else {
      const filters: any[] = [];
      const timeline: GridItem = this.cm.grid.find((item) => item.key === 'time');

      const key: string = timeline.data.details[0].key;
      const values: (number | string)[] = timeline.data.domain[0].keys;
      const distribution: ChartDistribution[] = timeline.data.distributions[0];
      const biggestValue: number = Math.max(...distribution.map((o) => o.value));

      const biggestValueIndex: number = distribution.findIndex((item) => item.value === biggestValue);

      const filter: string[] = [];

      if (biggestValueIndex > 0) {
        filter.push(distribution[biggestValueIndex - 1]['key']);
      }

      filter.push(distribution[biggestValueIndex]['key']);

      if (biggestValueIndex < distribution.length - 1) {
        filter.push(distribution[biggestValueIndex + 1]['key']);
      }

      filters.push({ key, values, filter });

      this.cf.filter(filters);

      const positionY: number = timeline
        ? document.getElementById(`grid-item-${timeline.key}`).offsetTop +
          document.getElementById(`report-grid-container`).offsetTop -
          16
        : 0;

      this.scrollTo.emit(positionY);
    }
  }
}
