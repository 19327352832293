import * as d3 from 'd3';

import { Directive, ElementRef, HostListener, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';

import { ChartDomain, ChartStats } from '@shared/models/report.model';

import { Crossfilter } from '@report/shared/services/crossfilter.service';

import { Colors } from '@report/shared/enums/colors.enum';

import { drawContactIcon, shortenText } from '@shared/utilities/canvas.utilities';

export interface CanvasContext {
  context: CanvasRenderingContext2D;
  data: ChartStats;
}

/**
 * This is a Single Gauge chart.
 */
@Directive({
  selector: '[singleGauge]',
})
export class SingleGauge implements OnChanges {
  @Input() stats: any;
  @Input() domain: ChartDomain = {} as ChartDomain;
  @Input() usedValue: string = 'average';
  @Input() scale: any;
  @Input() filterInput: any;
  @Input() transitionDuration: number = 0;
  @Input() update: Date = new Date();
  @Input() touchDevice: boolean = false;
  @Input() selectionExists: boolean = false;

  private base: any;

  private context: CanvasContext = {} as CanvasContext;
  private canvas: any;

  private colorScale: any;

  private pie: any;

  private width: number;
  private height: number;
  private margin: { [s: string]: number };
  private fontSize: number = 0;
  private unit: number = 0;
  private arcRadius: number = 0;

  @HostListener('window:resize') resize() {
    this.updateChart(null);
  }

  constructor(
    private _element: ElementRef,
    private cf: Crossfilter,
  ) {
    this.constructBody();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.stats ||
      changes.domain ||
      changes.scale ||
      changes.filterInput ||
      changes.transitionDuration ||
      changes.update ||
      changes.touchDevice ||
      changes.selectionExists
    ) {
      this.updateChart(changes.stats);
    }
  }

  updateChart(dataChanges: SimpleChange | null): void {
    this.setEnvironment();
    this.setScales();
    this.setCanvas(dataChanges);
  }

  constructBody(): void {
    this.base = d3
      .select(this._element.nativeElement)
      .append('div')
      .attr('class', 'single-gauge')
      .style('position', 'relative');
  }

  setEnvironment(): void {
    this.fontSize = parseFloat(window.getComputedStyle(this._element.nativeElement).fontSize);
    this.unit = (10 / 14) * this.fontSize;

    this.margin = {
      top: 2 * this.unit,
      right: 2 * this.unit,
      bottom: 2 * this.unit,
      left: 2 * this.unit,
    };

    const width = this._element.nativeElement.clientWidth - this.margin.left - this.margin.right;
    const height = this._element.nativeElement.clientHeight - this.margin.top - this.margin.bottom;

    this.width = width > 0 ? width : 0;
    this.height = height > 0 ? height : 0;

    this.arcRadius = Math.min(this.width / 2, this.height);
  }

  setScales(): void {
    this.colorScale = d3
      .scaleLinear<string>()
      .domain([this.domain?.origin?.min, 0, this.domain?.origin?.max])
      .range([Colors.SENTIMENT[0], Colors.SENTIMENT[1], Colors.SENTIMENT[2]]);

    this.pie = d3
      .pie()
      .sort(null)
      .value((d: any) => d)
      .startAngle(0.5 * Math.PI * -1)
      .endAngle(0.5 * Math.PI);
  }

  setCanvas(dataChanges: SimpleChange | null): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const __this = this;

    const drawContent = function (d) {
      const context = d3.select(this).node().getContext('2d');

      context.clearRect(
        0,
        0,
        __this.width + __this.margin.right + __this.margin.left,
        __this.height + __this.margin.top + __this.margin.bottom,
      );

      if (dataChanges && !dataChanges.firstChange && __this.transitionDuration > 0) {
        const previousNumber =
          __this.context && __this.context.data && __this.context.data ? __this.context.data[__this.usedValue] : 0;
        const interpolateNumber = d3.interpolateNumber(previousNumber, d[__this.usedValue]);
        const ease = d3.easeCubic;

        const t = d3.timer((elapsed) => {
          context.clearRect(
            0,
            0,
            __this.width + __this.margin.right + __this.margin.left,
            __this.height + __this.margin.top + __this.margin.bottom,
          );

          const step = elapsed / __this.transitionDuration;
          let number;

          if (step >= 1) {
            number = interpolateNumber(ease(1));
            t.stop();
          } else {
            number = interpolateNumber(ease(step));
          }

          __this.setNumber(context, number, d.count);
          __this.setGauge(context, number, d.color, d.count);
          __this.setTexts();
          // __this.setTitle(context, d.title, d.count);
        });
      } else {
        __this.setNumber(context, d[__this.usedValue], d.count);
        __this.setGauge(context, d[__this.usedValue], d.color, d.count);
        __this.setTexts();
        // __this.setTitle(context, d.title, d.count);
      }

      __this.context.context = context;
      __this.context.data = d;
    };

    this.canvas = this.base.selectAll('.single-gauge-canvas').data([this.stats]);

    this.canvas.exit().remove();

    this.canvas
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .each(drawContent);

    this.canvas
      .enter()
      .append('canvas')
      .attr('class', 'single-gauge-canvas')
      .style('position', 'relative')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .each(drawContent);
  }

  setTitle(context, data = null, answerCount = 0): void {
    context.globalAlpha = 1;
    context.fillStyle = Colors.TEXT;
    context.textAlign = 'left';
    context.textBaseline = 'middle';

    const h = this.margin.top / 2;

    const wIcon = this.fontSize + 4;

    context.font = 10 / 14 + 'em Inter';
    const wNumber = context.measureText(answerCount).width + 8;

    context.font = 12 / 14 + 'em Inter';
    const title = data != null ? shortenText(context, data, this.width, 8 + (wIcon + wNumber) / 2) : '';

    const wTitle = title ? context.measureText(title).width + 8 : 0;

    const startPoint = this.margin.left + this.width / 2 - (wIcon + wNumber + wTitle) / 2;

    if (title) {
      context.fillText(title, startPoint, h);
    }

    drawContactIcon(context, this.fontSize, startPoint + wTitle, h, context.fillStyle);

    context.font = 10 / 14 + 'em Inter';
    context.fillText(answerCount, startPoint + wTitle + wIcon, h);
  }

  setGauge(context, data, colorIndex: number | null, answerCount: number = 0): void {
    const arc = d3
      .arc()
      .outerRadius(this.arcRadius - 10)
      .innerRadius(this.arcRadius / 1.667)
      .padAngle(0.02)
      .context(context);

    const scaleWidth: number = this.domain?.origin?.max - this.domain?.origin?.min;
    const percentage: number = (data + scaleWidth / 2) / scaleWidth;

    const arcs = this.pie([percentage, 1 - percentage]);
    const grad = context.createLinearGradient(
      -this.arcRadius,
      this.margin.top + this.height,
      this.arcRadius,
      this.margin.top + this.height,
    );
    grad.addColorStop(0, this.colorScale(this.domain?.origin?.min) || Colors.SENTIMENT[0]);
    grad.addColorStop(0.5, this.colorScale(0) || Colors.SENTIMENT[1]);
    grad.addColorStop(1, this.colorScale(this.domain?.origin?.max) || Colors.SENTIMENT[2]);

    context.save();
    context.translate(this.margin.left + this.width / 2, this.margin.top + this.height);
    context.globalAlpha = answerCount > 0 ? 1 : 0.2;

    arcs.forEach((d: any) => {
      context.beginPath();
      arc(d);

      context.fillStyle = grad;
      context.fill();
    });

    context.font = 10 / 14 + 'em Inter';
    context.fillStyle = Colors.TEXT;
    context.textBaseline = 'alphabetic';
    context.textAlign = 'right';
    context.fillText(this.domain?.origin?.min?.toString(), -this.arcRadius + 2, -7);

    context.textAlign = 'left';
    context.fillText(this.domain?.origin?.max?.toString(), this.arcRadius - 2, -7);

    context.restore();
  }

  setNumber(context, data, answerCount: number = 0): void {
    const height: number = this.arcRadius / 2.5;
    const number: string = answerCount > 0 ? data.toFixed(1) : '-';
    context.font = height + 'px Inter';
    context.textAlign = 'center';
    context.textBaseline = 'alphabetic';
    context.fillStyle = Colors.TEXT;

    context.fillText(
      (this.domain?.origin?.min < 0 && data > 0 ? '+' : '') + number,
      this.margin.left + this.width / 2,
      this.margin.top + this.height,
    );
  }

  setTexts() {}
}
