import * as d3 from 'd3';

import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';

import { CanvasContext, ChartDomain, ChartLegend, SummaryChartData } from '@shared/models/report.model';

import { Colors } from '@report/shared/enums/colors.enum';
import { drawIntelligenceIcon, shortenText } from '@shared/utilities/canvas.utilities';

/**
 * This is a Summary 2d chart directive.
 */
@Directive({
  selector: '[summary2d]',
})
export class Summary2DChart implements OnChanges {
  @Input() chartData: SummaryChartData = {} as SummaryChartData;
  @Input() size: number = 0;
  @Input() highlight: any[] = [];
  @Input() selectedItems: any[] = [];
  @Input() domain: ChartDomain[] = [];
  @Input() zvalues: boolean = false;
  @Input() showAverages: boolean = false;
  @Input() showTooltip: boolean = false;
  @Input() showZoomText: boolean = false;
  @Input() transitionDuration: number = 0;
  @Input() update: Date = new Date();
  @Input() comparison: any;
  @Input() comparisonMode: string = 'grouped';
  @Input() selectionExists: boolean = false;
  @Input() filtersDemo: boolean = false;
  @Input() showOnlyGroupAverages: boolean = false;
  @Output() hover: EventEmitter<string[]> = new EventEmitter<string[]>();

  private margin: any = { top: 50, right: 50, bottom: 50, left: 50 };
  private width: number = 0;
  private height: number = 0;
  private ballSize: number = 0;
  private fontSize: number = 0;

  private context: CanvasContext = {} as CanvasContext;
  private legends: ChartLegend[] = [];

  // D3 elements
  private base: any;
  private canvas: any;
  private tooltip: any;
  private tooltipTexts: any;

  @HostListener('window:resize') resize() {
    this.updateChart(null, null);
  }

  constructor(private _element: ElementRef) {
    this.constructBody();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.chartData ||
      changes.size ||
      changes.highlight ||
      changes.domain ||
      changes.zvalues ||
      changes.showAverages ||
      changes.update ||
      changes.comparison ||
      changes.comparisonMode ||
      changes.filtersDemo ||
      changes.selectedItems ||
      changes.showOnlyGroupAverages
    ) {
      this.updateChart(changes.update, changes.zvalues);
    }
  }

  constructBody() {
    this.margin = { top: 50, right: 50, bottom: 50, left: 50 };
    this.base = d3.select(this._element.nativeElement);
  }

  updateChart(dataChanges: SimpleChange | null, zChanges: SimpleChange | null) {
    this.setSizes();
    this.setCanvas(dataChanges, zChanges);
  }

  /**
   * Setting sizes for 2Ds.
   */
  setSizes() {
    this.fontSize = parseFloat(window.getComputedStyle(this._element.nativeElement).fontSize);
    this.margin = {
      top: this.fontSize * 3,
      right: this.fontSize * 3,
      bottom: this.fontSize * 3,
      left: this.fontSize * 3,
    };
    this.width =
      this.size - this.margin.left - this.margin.right > 0 ? this.size - this.margin.left - this.margin.right : 0;
    this.height =
      this.size - this.margin.bottom - this.margin.top > 0 ? this.size - this.margin.bottom - this.margin.top : 0;
    this.ballSize = this.width / 12 / 2;
  }

  /**
   * Selecting colors.
   */
  colorScale(index) {
    if (this.comparison) {
      return Colors.getComparisonColor(index);
    } else {
      return this.selectionExists ? Colors.SELECTED : this.filtersDemo ? Colors.UNSELECTED : Colors.DEFAULT;
    }
  }

  setCanvas(dataChanges: SimpleChange | null, zChanges: SimpleChange | null) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const __this = this;
    const drawContent = function (d) {
      const context = d3.select(this).node().getContext('2d');
      const dataItems = (d.children || [])
        .filter((item) =>
          __this.showOnlyGroupAverages ? !item.questionGroup || item.isGroupAverage : !item.isGroupAverage,
        )
        .sort((a, b) =>
          (__this.selectedItems || []).indexOf(b.key) >= 0 && (__this.selectedItems || []).indexOf(a.key) < 0 ? -1 : 0,
        );
      let averages: number[] = [];

      if (__this.showAverages) {
        averages = [
          __this.zvalues
            ? __this.margin.left + __this.width / 2
            : d3.mean(
                dataItems.filter((c) => !c?.isUnderAnonymityTreshold),
                (c: any) => c.cx + __this.margin.left,
              ),
          __this.zvalues
            ? __this.margin.top + __this.height / 2
            : d3.mean(
                dataItems.filter((c) => !c?.isUnderAnonymityTreshold),
                (c: any) => c.cy + __this.margin.top,
              ),
        ];
      }

      if (
        ((dataChanges && !dataChanges.firstChange) || (zChanges && !zChanges.firstChange)) &&
        __this.transitionDuration > 0
      ) {
        const dataObj = __this.context && __this.context.data ? __this.context.data : [];
        const averageObj =
          averages.length === 2 && __this.context && __this.context.averages ? __this.context.averages : [];
        const interpolator = d3.interpolateArray(dataObj, dataItems);
        const averageInterpolator = d3.interpolateArray(averageObj, averages);
        const ease = d3.easeCubic;

        const t = d3.timer((elapsed) => {
          const step = elapsed / __this.transitionDuration;
          let data;
          let average;

          if (step >= 1) {
            data = dataItems;
            average = __this.showAverages ? averages : [];

            t.stop();
          } else {
            data = interpolator(ease(step));
            average = averageInterpolator(ease(step));
          }

          __this.setChart(context, data, d.sliderDetails, __this.highlight, average);
        });
      } else {
        __this.setChart(context, dataItems, d.sliderDetails, __this.highlight, averages);
      }

      __this.context = { context, data: dataItems, averages };
    };

    const hoverFunction = function (event) {
      const area = d3.pointer(event);
      __this.selectForHover(event, area);
    };

    this.canvas = this.base.selectAll('.summary-2d-canvas').data([this.chartData]);

    this.canvas.exit().remove();

    this.canvas
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .each(drawContent);

    this.canvas
      .enter()
      .append('canvas')
      .attr('class', 'summary-2d-canvas')
      .on('mousemove', hoverFunction)
      .on('mouseout', function (event) {
        __this.setTooltip(event, d3.pointer(event));
      })
      .style('position', 'relative')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .each(drawContent);
  }

  setChart(context, data, details, highlighted: string[] = [], averages: number[] = [], event = null) {
    context.clearRect(
      0,
      0,
      this.width + this.margin.top + this.margin.bottom,
      this.height + this.margin.left + this.margin.right,
    );
    context.globalAlpha = 1;

    this.setArea(context);
    this.setTexts(context, details);
    if (this.comparison && this.comparisonMode === 'joined') {
      this.setLegends(context);
    }

    const highlightsOn = highlighted && highlighted.length > 0;
    const highlightArea: number[][] = [];

    for (let i = 0, len = data.length; i < len; i++) {
      if (!data[i]?.isUnderAnonymityTreshold) {
        context.globalAlpha = 1;
        if (averages && averages.length === 2) {
          this.setAverageLines(context, data[i], averages[0], averages[1]);
        }

        if (highlightsOn) {
          const highlight = highlighted.find((item) => `${data[i].number}. ${data[i].title}` === item);
          if (highlight) {
            context.globalAlpha = 0.1;
          } else {
            context.globalAlpha = 0.03;
          }
        } else {
          context.globalAlpha = 0.1;
        }

        this.setEllipses(context, data[i]);
      }
    }

    for (let i = 0, len = data.length; i < len; i++) {
      if (!data[i]?.isUnderAnonymityTreshold) {
        let highlight;
        if (highlightsOn) {
          highlight = highlighted.find((item) => {
            const legends =
              (!this.highlight || this.highlight.length === 0) && event != null
                ? this.itemsBelow(d3.pointer(event, this._element.nativeElement.firstElementChild)).legends
                : [];

            return (
              `${data[i].number}. ${data[i].title}` === item &&
              (legends.length === 0 || legends.find((legend) => data[i].group === legend.key) != null)
            );
          });
          if (highlight) {
            context.globalAlpha = 0.9;
            highlightArea.push([data[i].cx, data[i].cy]);
          } else {
            const onTop = highlightArea.filter(
              (area) =>
                area[0] < data[i].cx + this.ballSize &&
                area[0] > data[i].cx - this.ballSize &&
                area[1] < data[i].cy + this.ballSize &&
                area[1] > data[i].cy - this.ballSize,
            );
            if (onTop.length > 0) {
              context.globalAlpha = 0.03;
            } else {
              context.globalAlpha = 0.15;
            }
          }
        } else {
          context.globalAlpha = 0.9;
        }

        this.setBalls(context, data[i], highlight);
      }
    }

    if (averages && averages.length === 2 && data.filter((d) => !d?.isUnderAnonymityTreshold)?.length > 0) {
      context.fillStyle = Colors.AVERAGEHELPER;
      context.beginPath();
      context.arc(averages[0], averages[1], 5, 0, 2 * Math.PI, false);
      context.closePath();
      context.fill();
    }
  }

  setArea(context) {
    const areaW = this.width;
    const areaH = this.height;

    context.fillStyle = Colors.BACKGROUNDSUMMARY;

    context.translate(this.margin.left, this.margin.top);
    context.beginPath();
    context.moveTo(areaW / 2 - 2, 0);
    context.lineTo(5, 0);
    context.translate(5, 5);
    context.rotate(0);
    context.scale(1, 1);
    context.arc(0, 0, 5, -1.5707963267948966, -3.141592653589793, 1);
    context.scale(1, 1);
    context.rotate(0);
    context.translate(-5, -5);
    context.lineTo(0, areaH / 2 - 2);
    context.lineTo(areaW / 4, areaH / 2 - 2);
    context.lineTo(areaW / 4, areaH / 2 + 2);
    context.lineTo(0, areaH / 2 + 2);
    context.lineTo(0, areaH - 5);
    context.translate(5, areaH - 5);
    context.rotate(0);
    context.scale(1, 1);
    context.arc(0, 0, 5, 3.141592653589793, 1.5707963267948966, 1);
    context.scale(1, 1);
    context.rotate(0);
    context.translate(-5, -(areaH - 5));
    context.lineTo(areaW / 2 - 2, areaH);
    context.lineTo(areaW / 2 - 2, (3 * areaH) / 4);
    context.lineTo(areaW / 2 + 2, (3 * areaH) / 4);
    context.lineTo(areaW / 2 + 2, areaH);
    context.lineTo(areaW - 5, areaH);
    context.translate(areaW - 5, areaH - 5);
    context.rotate(0);
    context.scale(1, 1);
    context.arc(0, 0, 5, 1.5707963267948966, 0, 1);
    context.scale(1, 1);
    context.rotate(0);
    context.translate(-(areaW - 5), -(areaH - 5));
    context.lineTo(areaW, areaH / 2 + 2);
    context.lineTo((3 * areaW) / 4, areaH / 2 + 2);
    context.lineTo((3 * areaW) / 4, areaH / 2 - 2);
    context.lineTo(areaW, areaH / 2 - 2);
    context.lineTo(areaW, 5);
    context.translate(areaW - 5, 5);
    context.rotate(0);
    context.scale(1, 1);
    context.arc(0, 0, 5, 0, -1.5707963267948966, 1);
    context.scale(1, 1);
    context.rotate(0);
    context.translate(-(areaW - 5), -5);
    context.lineTo(areaW / 2 + 2, 0);
    context.lineTo(areaW / 2 + 2, areaH / 4);
    context.lineTo(areaW / 2 - 2, areaH / 4);
    context.closePath();
    context.fill();
    context.translate(-this.margin.left, -this.margin.top);
  }

  setLegends(context) {
    this.legends = [];
    const y = 0;
    const width = this.width + this.margin.left + this.margin.right;
    const height = 15;
    let usedSpace = 0;
    let arr: any[] = [];
    let currentX = 0;
    let currentY = 0;
    let margin;

    let choiceLabels;
    let choices = [];
    let colors;

    for (const dom in this.domain) {
      if (this.domain[dom].key === this.comparison.key) {
        choiceLabels = this.domain[dom].labels;
        choices = this.domain[dom].keys;
        colors = this.domain[dom].colors;
      }
    }

    const textWidth = (width * (choices.length > 2 ? 3 : choices.length < 2 ? 1 : 2)) / choices.length;

    context.textBaseline = 'top';
    context.textAlign = 'left';
    context.font = 10 / 14 + 'em Inter';

    for (let i = 0, length = choices.length; i < length; i++) {
      const key = choices[i];
      const text = shortenText(context, choiceLabels[key], textWidth, 40);
      const elW = context.measureText(text).width;

      if (usedSpace + elW + 20 <= width) {
        usedSpace += elW + 20;
        arr.push([key, colors[key] != null ? colors[key] : i, text]);
      } else {
        margin = (width - usedSpace) / 2;
        currentX = margin;

        for (let a = 0, len = arr.length; a < len; a++) {
          const itemText = arr[a][2];
          const itemColor = arr[a][1];
          const itemKey = arr[a][0];
          currentX += 10;

          context.font = 10 / 14 + 'em Inter';
          context.fillStyle = Colors.TEXT;
          context.fillText(itemText, currentX, currentY + y);

          context.beginPath();
          context.arc(currentX - 8, currentY + y + 6, 5, 0, 2 * Math.PI);
          context.closePath();
          context.fillStyle = this.colorScale(itemColor);
          context.fill();
          this.legends.push({
            key: itemKey,
            x: currentX,
            y: currentY + y,
            width: context.measureText(itemText).width,
            height,
          });
          currentX += context.measureText(itemText).width + 10;
        }

        currentY += height;
        usedSpace = elW + 20;

        arr = [[key, colors[key] != null ? colors[key] : i, text]];
      }
    }

    margin = (width - usedSpace) / 2;
    currentX = margin;

    for (let a = 0, len = arr.length; a < len; a++) {
      const itemText = arr[a][2];
      const itemColor = arr[a][1];
      const itemKey = arr[a][0];
      currentX += 10;

      context.font = 10 / 14 + 'em Inter';
      context.fillStyle = Colors.TEXT;
      context.fillText(itemText, currentX, currentY + y);

      context.beginPath();
      context.arc(currentX - 8, currentY + y + 6, 5, 0, 2 * Math.PI);
      context.closePath();
      context.fillStyle = this.colorScale(itemColor);
      context.fill();
      this.legends.push({
        key: itemKey,
        x: currentX,
        y: currentY + y,
        width: context.measureText(itemText).width,
        height,
      });
      currentX += context.measureText(itemText).width + 10;
    }
  }

  setTexts(context, sliderDetails) {
    context.font = 12 / 14 + 'em Inter';
    context.textBaseline = 'top';
    context.fillStyle = Colors.TEXT;

    context.textAlign = 'start';
    const xMinLabel = shortenText(context, sliderDetails.labels.min, this.width / 2, 10);
    context.fillText(xMinLabel, this.margin.left, this.height + this.margin.top + this.margin.bottom / 12);

    context.textAlign = 'end';
    const xMaxLabel = shortenText(context, sliderDetails.labels.max, this.width / 2, 10);
    context.fillText(xMaxLabel, this.margin.left + this.width, this.height + this.margin.top + this.margin.bottom / 12);

    context.font = 'normal bold ' + 12 / 14 + 'em Inter';
    context.textAlign = 'center';
    const xAxisLabel = shortenText(context, sliderDetails.labels.axis, this.width, 10);
    context.fillText(
      xAxisLabel,
      this.margin.left + this.width / 2,
      this.height + this.margin.top + (1.5 * this.margin.bottom) / 3,
    );

    context.font = 12 / 14 + 'em Inter';
    context.textBaseline = 'bottom';
    context.rotate((270 * Math.PI) / 180);

    context.textAlign = 'start';
    const yMinLabel = shortenText(context, sliderDetails.labelsY.min, this.width / 2, 10);
    context.fillText(yMinLabel, 0 - (this.margin.top + this.height), (11 * this.margin.left) / 12);

    context.textAlign = 'end';
    const yMaxLabel = shortenText(context, sliderDetails.labelsY.max, this.width / 2, 10);
    context.fillText(yMaxLabel, 0 - this.margin.top, (11 * this.margin.left) / 12);

    context.font = 'normal bold ' + 12 / 14 + 'em Inter';
    context.textAlign = 'center';
    const yAxisLabel = shortenText(context, sliderDetails.labelsY.axis, this.width, 10);
    context.fillText(yAxisLabel, 0 - (this.margin.top + this.height / 2), (1.5 * this.margin.left) / 3);

    context.rotate((90 * Math.PI) / 180);

    if (this.comparison && this.comparisonMode !== 'joined') {
      context.font = this.margin.top / 3.5 + 'px Inter';
      context.textBaseline = 'middle';

      const title = this.chartData.group.title ? shortenText(context, this.chartData.group.title, this.width, 10) : '';

      context.fillText(title, this.margin.left + this.width / 2, this.margin.top / (!this.showZoomText ? 2 : 3 / 2.25));
    }

    if (this.showZoomText) {
      context.save();
      context.font = this.margin.top / 3.5 + 'px Inter';
      context.textBaseline = 'middle';
      context.fillStyle = '#ef5d65';

      const zoomText = $localize`Intelligent Zoom™`;
      const wZoomText = context.measureText(zoomText).width;
      const h = this.margin.top / (this.comparison ? (this.comparisonMode === 'joined' ? 4 / 3 : 3) : 2);

      drawIntelligenceIcon(context, 24, this.margin.left + this.width / 2 - wZoomText / 2 - 12 - 2, h - 12 - 2);

      context.fillText(zoomText, this.margin.left + this.width / 2 + 12 + 2, h);
      context.restore();
    }
  }

  setAverageLines(context, data, meanX, meanY) {
    context.save();
    context.setLineDash([5, 10]);
    context.strokeStyle = '#a4b0b9';
    context.lineWidth = 1;
    context.beginPath();
    context.moveTo(meanX, meanY);
    context.lineTo(data.cx + this.margin.left, data.cy + this.margin.top);
    context.stroke();
    context.restore();
  }

  setEllipses(context, data) {
    context.fillStyle = this.colorScale(data.index);

    const cx = data.cx + this.margin.left;
    const cy = data.cy + this.margin.top;
    const w = data.rx || 1;
    const h = data.ry || 1;
    const dominantScale = w > h ? 'w' : h > w ? 'h' : 'both';
    const radius = dominantScale === 'w' ? h : w;
    const scaleX = dominantScale === 'w' ? w / h : 1;
    const scaleY = dominantScale === 'h' ? h / w : 1;

    context.save();
    context.beginPath();
    context.scale(scaleX, scaleY);
    context.arc(cx / scaleX, cy / scaleY, radius, 0, 2 * Math.PI, false);
    context.closePath();
    context.restore();
    context.fill();
  }

  setBalls(context, data, highlight) {
    context.fillStyle = this.colorScale(data.index);
    context.strokeStyle = Colors.HIGHLIGHT;
    context.lineWidth = 2;

    const cx = data.cx + this.margin.left;
    const cy = data.cy + this.margin.top;
    const radius = this.ballSize;

    if (highlight || (!!this.selectedItems?.length && this.selectedItems.indexOf(data.key) >= 0)) {
      context.beginPath();
      context.arc(cx, this.margin.top + this.height, 3, 0, 2 * Math.PI, false);
      context.moveTo(cx, this.margin.top + this.height - 3);
      context.lineTo(cx, cy + radius);
      context.moveTo(cx - radius, cy);
      context.lineTo(this.margin.left + 3, cy);
      context.arc(this.margin.left, cy, 3, 0, 2 * Math.PI, false);

      context.stroke();
    }

    context.beginPath();
    context.arc(cx, cy, radius, 0, 2 * Math.PI, false);
    context.closePath();

    context.fill();
    if (highlight || (!!this.selectedItems?.length && this.selectedItems.indexOf(data.key) >= 0)) {
      context.stroke();
    }

    context.fillStyle = Colors.BACKGROUND;
    context.font = this.height / 25 + 'px Inter';
    context.textAlign = 'center';
    context.textBaseline = 'middle';

    if (data.number != null) {
      context.fillText(Math.round(Number(data.number)), cx, cy);
    } else if (data.numberAlphabet) {
      context.fillText(data.numberAlphabet, cx, cy);
    }
  }

  setTooltip(event, position, data: any[] = []) {
    if (this.showTooltip) {
      const options = {
        backgroundColor: (d) => (this.comparison && this.comparisonMode === 'joined' ? this.colorScale(d.index) : null),
        html: (d) =>
          `<div class="question">${d.number}. ${d.title}${
            d.groupLabel ? ` (${d.groupLabel})` : ''
          }</div> <div class="stats"><span class="icon">contact</span> ${d.n} <span class="icon">axis_x</span> ${
            this.zvalues
              ? d.zAverage != null
                ? d.zAverage.toFixed(1)
                : '-'
              : d.average != null
                ? d.average.toFixed(1)
                : '-'
          } <span class="icon">axis_y</span> ${
            this.zvalues
              ? d.zAverageY != null
                ? d.zAverageY.toFixed(1)
                : '-'
              : d.averageY != null
                ? d.averageY.toFixed(1)
                : '-'
          }</div>`,
      };

      this.tooltip = this.base.selectAll('.summary2d-tooltip').data(data.length > 0 ? data : []);

      this.tooltip.exit().remove();

      this.tooltip
        .style('background-color', options.backgroundColor)
        .style('border-top-color', options.backgroundColor);

      this.tooltip
        .enter()
        .append('div')
        .attr('class', 'summary2d-tooltip')
        .style('background-color', options.backgroundColor)
        .style('border-top-color', options.backgroundColor);

      this.tooltipTexts = this.base
        .selectAll('.summary2d-tooltip')
        .selectAll('.summary2d-tooltip-text')
        .data((d) => [d]);

      this.tooltipTexts.exit().remove();

      this.tooltipTexts.html(options.html);

      this.tooltipTexts.enter().append('div').attr('class', 'summary2d-tooltip-text').html(options.html);

      this.base.selectAll('.summary2d-tooltip').style('transform', function (d, i, g) {
        let sumHeight = 15;
        for (let e = 0, len = g.length; e < len; e++) {
          if (e <= i) {
            sumHeight += g[e].getBoundingClientRect().height + 10;
          }
        }
        return `translate(${position[0] - 125}px,${position[1] - sumHeight}px)`;
      });
    }

    this.hover.emit(data.map((item) => item.key));
    this.setHoverEffects(event, this.context, data);
  }

  setHoverEffects(event, context, data) {
    this.setChart(
      this.context.context,
      this.context.data,
      this.chartData.sliderDetails,
      data.map((item) => `${item.number}. ${item.title}`),
      null,
      event,
    );
  }

  // Helpers
  selectForHover(event, area) {
    const itemsBelow = this.itemsBelow(area);
    const parents = itemsBelow.parents;
    const legends = itemsBelow.legends;

    if (parents.length > 0) {
      this.setTooltip(event, d3.pointer(event, this._element.nativeElement), parents);
    } else if (legends.length > 0) {
      const legendItems = this.chartData.children.filter((item) =>
        // (this.showOnlyGroupAverages ? !item.questionGroup || item.isGroupAverage : !item.isGroupAverage) &&
        legends.find((legend) => legend.key.toString() === item.group),
      );

      this.setTooltip(event, d3.pointer(event, this._element.nativeElement), legendItems);
    } else {
      this.setTooltip(event, d3.pointer(event, this._element.nativeElement));
    }
  }

  itemsBelow(area) {
    const parents = this.chartData.children.filter(
      (item) =>
        !item.isUnderAnonymityTreshold &&
        (this.showOnlyGroupAverages ? !item.questionGroup || item.isGroupAverage : !item.isGroupAverage) &&
        area[0] < item.cx + this.ballSize + this.margin.left &&
        area[0] > item.cx - this.ballSize + this.margin.left &&
        area[1] < (item.cy ? item.cy : 0) + this.ballSize + this.margin.top &&
        area[1] > (item.cy ? item.cy : 0) - this.ballSize + this.margin.top,
    );

    const legends =
      this.legends && this.legends.length > 0
        ? this.legends.filter(
            (item) =>
              area[0] > item.x - 15 &&
              area[0] < item.x + item.width &&
              area[1] > item.y &&
              area[1] < item.y + item.height,
          )
        : [];

    return { parents, legends };
  }
}
