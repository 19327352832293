export enum DataChangeEvent {
  UpdatedNewAnswers = '[Crossfilter] Updated new answers',
  UpdatedOtherDataChanges = '[Crossfilter] Updated other data changes',
  UpdatedNewAnswersAndOtherDataChanges = '[Crossfilter] Updated new answers and other data changes',
  ServerErrorGetAnswers = '[Crossfilter] Server error Get Answers',
  RemovedRespondents = '[Crossfilter] Removed respondents',
  ToggledChart = '[Crossfilter] Toggled chart',
  AddedNewComparisonItem = '[Crossfilter] Added new comparison item',
  RenamedComparisonItem = '[Crossfilter] Renamed new comparison item',
  ChangedColorComparisonItem = '[Crossfilter] Changed color comparison item',
  RemovedComparisonItem = '[Crossfilter] Removed comparison item',
  UpdatedComparison = '[Crossfilter] Updated comparison',
  SwitchedTrendAnalysisOn = '[Crossfilter] Switched trend analysis on',
  SwitchedTrendAnalysisOff = '[Crossfilter] Switched trend analysis off',
  AddedBenchmarkData = '[Crossfilter] Added benchmark data',
  RemovedBenchmarkData = '[Crossfilter] Removed benchmark data',
  AddedCropFilter = '[Crossfilter] Added crop filter',
  RemovedCropFilter = '[Crossfilter] Removed crop filter',
  ChangedTimePeriod = '[Crossfilter] Changed time period',
  ChangedLocale = '[Crossfilter] Changed locale',
  RegenerateInsights = '[Crossfilter] Regenerate insights',
}

/* eslint-disable @typescript-eslint/no-namespace */
export namespace DataChangeEvent {
  export function isInsightUpdateNeeded(event: DataChangeEvent): boolean {
    return [
      DataChangeEvent.UpdatedNewAnswers,
      DataChangeEvent.UpdatedOtherDataChanges,
      DataChangeEvent.UpdatedNewAnswersAndOtherDataChanges,
      DataChangeEvent.RemovedRespondents,
      DataChangeEvent.AddedBenchmarkData,
      DataChangeEvent.RemovedBenchmarkData,
      DataChangeEvent.AddedCropFilter,
      DataChangeEvent.RemovedCropFilter,
      DataChangeEvent.ChangedLocale,
      DataChangeEvent.RegenerateInsights,
    ].includes(event);
  }
}
