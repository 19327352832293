<ng-container *ngIf="!isUnderAnonymityTreshold()">
  <div
    *ngIf="!isNotFreezed() || !(trend || (comparison && comparison.values && comparison.values.length)); else selector"
    class="z-fx z-fx-around-center stats"
  >
    <div class="z-fx z-fx-around-center z-fx-gap-8">
      <div class="z-fx z-fx-around-center">
        <mat-icon class="icon">contact</mat-icon>
        <span numberTransition class="number" [data]="responses" [duration]="transitionDuration"></span>
      </div>
    </div>
  </div>

  <ng-template #selector>
    <div *ngIf="!trend" class="mode-selector">
      <div class="zef-tabs-icon">
        <button mat-icon-button [class.active]="chartMode === 'gauge'" (click)="changeChartMode('gauge')">
          <mat-icon>chart_nps</mat-icon>
        </button>

        <button mat-icon-button [class.active]="chartMode === 'bar'" (click)="changeChartMode('bar')">
          <mat-icon>chart_bar_vertical</mat-icon>
        </button>
      </div>
    </div>
  </ng-template>

  <ng-container
    *ngIf="!isNotFreezed() || !(trend || (comparison && comparison.values && comparison.values.length)); else advanced"
  >
    <div *ngIf="sentimentIndex >= 0" class="charts" [style.height.px]="0.475 * availableHeight - 16">
      <div
        class="sentiment-gauge"
        [style.height.px]="0.2 * availableHeight"
        singleGauge
        [stats]="stats[sentimentIndex]"
        [domain]="domain[sentimentIndex]"
        [transitionDuration]="transitionDuration"
        [update]="Math.max(update?.valueOf(), sizeUpdate?.valueOf())"
      ></div>
      <div
        class="sentiment-bar-chart"
        [style.height.px]="0.275 * availableHeight"
        barChartV
        [data]="distributions[sentimentIndex]"
        [stats]="stats[sentimentIndex]"
        [scale]="'value'"
        [filterInput]="filterInput ? filterInput[sentimentIndex] : null"
        [domain]="domain[sentimentIndex]"
        [transitionDuration]="transitionDuration"
        [showNumbers]="false"
        [update]="Math.max(update?.valueOf(), sizeUpdate?.valueOf())"
        [filtering]="isNotFreezed()"
        [anonymityLock]="anonymityLock"
        [title]="''"
        [hideRespondentCounts]="true"
        [totalAnswers]="totalAnswers[sentimentIndex]"
        [selectionExists]="selectionExists"
        [filtersDemo]="filtersDemo"
        [touchDevice]="touchDevice"
      ></div>
      <ng-container *ngIf="!anonymityLock; else anonymityLockTemplate"></ng-container>
    </div>
  </ng-container>

  <ng-template #advanced>
    <ng-container *ngIf="!!trend; else comparisonTemplate">
      <div class="charts" [style.height.px]="0.475 * availableHeight">
        <div
          class="trendLinear1dChart"
          [style.height.px]="0.475 * availableHeight"
          trendLinear1dChart
          [data]="distributions"
          [stats]="stats"
          [scale]="'value'"
          [comparison]="comparison"
          [comparisonMode]="'joined'"
          [filterInput]="filterInput"
          [domain]="domain"
          [details]="details"
          [zvalues]="false"
          [transitionDuration]="transitionDuration"
          [showNumbers]="true"
          [update]="Math.max(update?.valueOf(), sizeUpdate?.valueOf())"
          [filtering]="isNotFreezed()"
          [touchDevice]="touchDevice"
          [trendHoverInput]="trendHoverInput"
          [selectionExists]="selectionExists"
          [filtersDemo]="filtersDemo"
          (hover)="hover.emit($event)"
        ></div>
      </div>
    </ng-container>

    <ng-template #comparisonTemplate>
      <ng-container *ngIf="chartMode === 'gauge'; else ccbar">
        <div class="z-fx comparison-header-row">
          <div class="label-header" i18n>Name</div>
          <div class="count-header">
            <mat-icon class="icon zef-icon-small">contact</mat-icon>
          </div>
          <div class="bar-chart-header" i18n>Average</div>
        </div>
        <ng-scrollbar [style.height.px]="0.475 * availableHeight - (40 + 34 + 32)">
          <div
            *ngFor="let compItem of comparisonTable$ | async; trackBy: trackByKey"
            class="z-fx comparison-row"
            (mouseover)="onHover(compItem)"
            (mouseleave)="onHover(null)"
            [style.height.px]="chartBarHeight"
          >
            <div
              #labelContainer
              class="z-fx z-fx-end-center label-cell"
              [class.hovered]="hoveredElement && hoveredElement.key === compItem.key"
              [style.align-items]="!showMoreButton(labelContainer, labelSpan) ? 'center' : 'flex-start'"
            >
              <span #labelSpan class="label-container">
                {{ compItem.label }}
              </span>
              <mat-icon
                *ngIf="showMoreButton(labelContainer, labelSpan)"
                class="show-more-texts"
                (click)="expandLabels(); $event.stopPropagation()"
              >
                more
              </mat-icon>
              <mat-icon
                *ngIf="expandedLabels && showLessButton(labelContainer, labelSpan)"
                fontSet="md"
                class="show-less-texts"
                (click)="shrinkLabels(); $event.stopPropagation()"
              >
                expand_less
              </mat-icon>
            </div>
            <div class="count-cell" [class.hovered]="hoveredElement && hoveredElement.key === compItem.key">
              {{ compItem.count }}
            </div>
            <div class="bar-chart-cell" [class.hovered]="hoveredElement && hoveredElement.key === compItem.key">
              <div
                class="single-bar-chart-container"
                singleBarChartH
                [value]="compItem.average"
                [colors]="compItem.color"
                [scale]="[-10, 10]"
                [update]="update"
                [hover]="hoveredElement && hoveredElement.key === compItem.key"
                [transitionDuration]="transition"
              ></div>
            </div>
          </div>
        </ng-scrollbar>
        <div class="z-fx comparison-row">
          <div class="label-cell-footer"></div>
          <div class="count-cell-footer"></div>
          <div class="bar-chart-cell-footer">
            <div
              class="bar-chart-scale"
              [style.height.px]="40"
              scaleX
              [scale]="[-10, 10]"
              [update]="Math.max(update?.valueOf(), sizeUpdate?.valueOf())"
              [transitionDuration]="0"
            ></div>
          </div>
        </div>
      </ng-container>

      <ng-template #ccbar>
        <ng-scrollbar class="comparison-distribution" [style.height.px]="0.475 * availableHeight - 34 - 16">
          <div
            *ngFor="let compItem of comparisonTable$ | async; trackBy: trackByKey"
            class="z-fx comparison-row"
            (mouseover)="onHover(compItem)"
            (mouseleave)="onHover(null)"
            [style.height.px]="Math.max((0.475 * availableHeight - 34) / comparison.values.length, 160)"
          >
            <div class="bar-chart-v" [class.hovered]="hoveredElement && hoveredElement.key === compItem.key">
              <div
                class="sentiment-bar-chart"
                [style.height.px]="160"
                barChartV
                [data]="compItem.distributions"
                [stats]="compItem.stats"
                [scale]="'value'"
                [filterInput]="filterInput ? filterInput[sentimentIndex] : null"
                [domain]="domain[sentimentIndex]"
                [transitionDuration]="transitionDuration"
                [showNumbers]="false"
                [update]="Math.max(update?.valueOf(), sizeUpdate?.valueOf())"
                [filtering]="isNotFreezed()"
                [anonymityLock]="anonymityLock"
                [title]="compItem.label"
                [totalAnswers]="compItem.count"
                [selectionExists]="selectionExists"
                [filtersDemo]="filtersDemo"
                [touchDevice]="touchDevice"
              ></div>
              <ng-container *ngIf="!anonymityLock; else anonymityLockTemplate"></ng-container>
            </div>
          </div>
        </ng-scrollbar>
      </ng-template>
    </ng-template>
  </ng-template>
</ng-container>

<div class="table-area" [class.no-data]="isUnderAnonymityTreshold()">
  <div *ngIf="!isUnderAnonymityTreshold()" class="search-box">
    <zef-searchbox (input)="searchUpdate$.next($event)">
      <span i18n>Find...</span>
    </zef-searchbox>
    <ng-container *ngIf="sortKey$ | async as sortkey">
      <ng-container *ngIf="sortDirection$ | async as sortdir">
        <div class="z-fx z-fx-between-center sort-tools">
          <div class="z-fx z-fx-center-center sorter">
            <zef-select [value]="sortkey" type="inline-p2" (change)="onSortColumn($event)">
              <zef-select-option value="survey">
                <div *selectOptionLabel class="z-fx z-fx-gap-8 z-fx-start-center">
                  <mat-icon>time</mat-icon>
                  <strong class="zef-text-p2" i18n>Date</strong>
                </div>
              </zef-select-option>
              <zef-select-option [value]="details[sentimentIndex]?.key">
                <div *selectOptionLabel class="z-fx z-fx-gap-8 z-fx-start-center">
                  <mat-icon>chart_sentiment</mat-icon>
                  <strong class="zef-text-p2" i18n>Sentiment</strong>
                </div>
              </zef-select-option>
              <zef-select-option [value]="details[textIndex]?.key">
                <div *selectOptionLabel class="z-fx z-fx-gap-8 z-fx-start-center">
                  <mat-icon>az</mat-icon>
                  <strong class="zef-text-p2" i18n>Alphabetical</strong>
                </div>
              </zef-select-option>
              <ng-container *ngIf="comparison?.values?.length">
                <mat-divider [style.margin]="'8px 0'"></mat-divider>
                <zef-select-option [value]="comparison.key">
                  <div *selectOptionLabel class="z-fx z-fx-gap-8 z-fx-start-center">
                    <mat-icon>az</mat-icon>
                    <strong class="zef-text-p2" i18n>Comparison</strong>
                  </div>
                </zef-select-option>
              </ng-container>
            </zef-select>

            <button mat-icon-button [@rotate]="sortdir !== 'asc'" (click)="onSortDir()">
              <mat-icon class="zef-color-primary">sort_up</mat-icon>
            </button>
          </div>
          <zef-select [value]="langFilter || ''" (change)="filterByLanguage($event)">
            <zef-select-option value="">
              <div *selectOptionLabel class="z-fx z-fx-gap-4 z-fx-start-center">
                <mat-icon>world</mat-icon>
                <strong class="zef-text-p2" i18n>All</strong>
              </div>
            </zef-select-option>

            <ng-container *ngFor="let lang of languages">
              <zef-select-option [value]="lang">
                <div *selectOptionLabel class="z-fx z-fx-gap-4 z-fx-start-center">
                  <figure class="zef-flag-small zef-lang-{{ lang }}"></figure>
                  <strong class="zef-text-p2">
                    {{ details?.[textIndex]?.['labelsValueGroup']?.[lang] }}
                  </strong>
                </div>
              </zef-select-option>
            </ng-container>
          </zef-select>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <ng-container *ngIf="chartTable$ | async as chartTable">
    <ng-container *ngIf="availableHeight > 0 && !isUnderAnonymityTreshold() && chartTable?.length > 0">
      <ng-scrollbar class="table" [style.height.px]="0.525 * availableHeight - 84 - (!isPoweredBy ? 0 : 32)">
        <cdk-virtual-scroll-viewport
          *ngIf="!vsHide"
          [style.height.px]="0.525 * availableHeight - 84 - (!isPoweredBy ? 0 : 32)"
          scrollViewport
          autosize
        >
          <div
            *cdkVirtualFor="let item of chartTable$ | async; trackBy: trackByRow"
            class="z-fx z-fx-sc text-row"
            [style.cursor]="isNotFreezed() ? 'pointer' : 'default'"
          >
            <ng-container *ngFor="let textItem of item; let idx = index; trackBy: trackByTextItem">
              <div
                *ngIf="sentimentIndex >= 0 && idx === sentimentIndex + 1"
                class="sentiment"
                [style.background-color]="getSentimentColor(textItem)"
                (click)="filterData(textItem.value, sentimentIndex)"
              >
                {{ textItem.text }}
              </div>
              <div *ngIf="idx === textIndex + 1" class="text" (click)="filterData(textItem.value, textIndex)">
                {{ textItem.text }}
              </div>
              <div
                *ngIf="isNotFreezed() && comparison && comparison.index != null && idx === comparison.index + 1"
                class="comparison"
                (click)="filterData(textItem.value, comparison.index)"
              >
                {{ textItem.text }}
              </div>
              <div
                *ngIf="isNotFreezed() && timeIndex >= 0 && idx === timeIndex + 1"
                class="time"
                (click)="filterData(textItem.value, timeIndex)"
              >
                {{ textItem.text }}
              </div>
            </ng-container>
          </div>
        </cdk-virtual-scroll-viewport>
      </ng-scrollbar>
    </ng-container>

    <div *ngIf="!anonymityTreshold && chartTable?.length === 0" class="z-fx-col nothing">
      <div class="z-fx-col z-fx-auto z-fx-start-center">
        <div class="z-fx-col z-fx-end-center image">
          <img src="assets/decos/art-nothing.png" height="48px" width="48px" class="art-nothing" alt="nothing" />
        </div>
        <div class="description" i18n>Looks like there is no data to show.</div>
      </div>
    </div>
    <div *ngIf="isUnderAnonymityTreshold()" class="z-fx-col z-fx-auto anonymous">
      <div class="z-fx z-fx-center-center answerers">
        <mat-icon class="respondent-icon">contact</mat-icon>
        <span class="number" i18n>under {{ anonymityTreshold }}</span>
      </div>
      <div class="z-fx-col z-fx-auto z-fx-start-center z-fx-gap-16">
        <div class="z-fx-col z-fx-end-center image">
          <mat-icon class="zef-icon-larger">anonymous_on</mat-icon>
        </div>
        <div class="description zef-text-center">
          <ng-container i18n>
            Charts are hidden if there are less than {{ anonymityTreshold }} respondents in Anonymous survey.
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div *ngIf="isPoweredBy" class="z-fx z-fx-end-end powered-by">
  <span i18n>Text analysis powered by</span>
  <a class="zef-text-p2-link link" href="https://www.lingsoft.fi/" target="_blank">Lingsoft</a>
</div>

<ng-template #anonymityLockTemplate>
  <div
    class="anonymity-lock"
    matTooltip="Chart is locked to protect anonymity of the respondent"
    matTooltipClass="zef-tooltip-ink-lighter"
    i18n-matTooltip
    matTooltipPosition="above"
  >
    <mat-icon class="zef-icon-small lock-icon">lock</mat-icon>
  </div>
</ng-template>
