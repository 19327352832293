import { Injectable } from '@angular/core';
import { DialogControl } from '@shared/services/dialog-control.service';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngxs/store';
import { ReportExport } from '../components/report-export/report-export.dialog';

type ReportCommonData = {
  surveyKey: string;
  reportKey: string;
  teamKey: string;
  isSharedReport: boolean;
};

/**
 * Shared service for report components.
 */
@Injectable({
  providedIn: 'root',
})
export class ReportCommon {
  data$ = new BehaviorSubject<ReportCommonData>(null);
  constructor(
    private dc: DialogControl,
    private store: Store,
  ) {}

  update(data: Partial<ReportCommonData>) {
    this.data$.next({ ...this.data$.value, ...data });
  }

  openExportDialog(data: any) {
    const { surveyKey } = this.data$.value;
    data.surveyKey = surveyKey;

    this.dc.open(
      ReportExport,
      { data },
      {
        width: '800px',
        maxWidth: '800px',
        closeOnNavigation: true,
      },
    );
  }
}
