import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  OnChanges,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';

import { Colors } from '@report/shared/enums/colors.enum';
import { Crossfilter } from '@report/shared/services/crossfilter.service';

import { HelpSubject } from '@shared/modules/help-center/help-subject.enum';
import { ChartContent } from '@shared/models/report.model';
import { DataChangeEvent } from '@report/shared/enums/data-change-events.enum';

/**
 * This is view component for comparison tools.
 */
@Component({
  selector: 'tools-view',
  templateUrl: './tools-view.component.html',
  styleUrls: ['./tools-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolsView implements OnChanges, OnInit {
  readonly subjects = HelpSubject;

  @Input() userSegments: ChartContent;
  @Input() filters: any;
  @Input() anonymityTreshold: number = null;

  public readonly comparisonColor = Colors.COMPARISON;
  public editModeOn: string = '';
  public comparisonList: string[] = [];
  public comparisonOn: boolean = false;

  public safeReportBannedList: string[] = [];
  public safeReportLock: boolean = false;

  private updateSegmentName: Subject<any> = new Subject();

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

  constructor(
    private cf: Crossfilter,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.updateSegmentName.pipe(debounceTime(300), distinctUntilChanged()).subscribe((change) => {
      this.cf.dimensions['userSegments']['labelsCategorical'][change.key] = change.text;
      this.cf.dataChange.next(DataChangeEvent.RenamedComparisonItem);
      this.cf.sendNewCrossfilter();
    });
  }

  ngOnChanges() {
    if (
      this.userSegments &&
      this.userSegments.details[0].values.filter((f: string | number) => f !== null).length > 0
    ) {
      this.checkAnonymity();

      if (this.userSegments.comparison != null) {
        const comparison = Object.assign([], this.userSegments.comparison.values)
          .map((item: number) => this.userSegments.details[0]?.values?.[item])
          .filter((key: string) => {
            const itemAllowed: boolean = this.isAnonymitySafe(key);

            if (!itemAllowed && !this.safeReportBannedList.includes(key)) {
              this.safeReportBannedList.push(key);
            }

            return itemAllowed;
          });

        for (let b = this.safeReportBannedList.length - 1; b >= 0; b--) {
          const bannedItem = this.safeReportBannedList[b];

          if (this.isAnonymitySafe(bannedItem)) {
            comparison.push(bannedItem);
            this.safeReportBannedList.splice(b, 1);
          }
        }

        const changeInComparison: boolean = !(
          this.comparisonList.length === comparison.length &&
          this.comparisonList.every((val, i) => val === comparison[i])
        );

        this.comparisonList = comparison;

        if (changeInComparison) {
          this.updateComparisonListOrder();
        }
      } else {
        this.comparisonList = [];
      }
      this.comparisonOn = !!this.comparisonList?.length;
      this.cdRef.detectChanges();
    }
  }

  private checkAnonymity() {
    this.safeReportLock = false;

    if (this.userSegments.comparison !== null && this.userSegments.comparison !== undefined) {
      for (const filterKey in this.filters || {}) {
        if (this.filters[filterKey]?.safeReportCount != null) {
          this.safeReportLock = true;
        }
      }
    }
  }

  public isAnonymitySafe(item: string): boolean {
    return (
      !(!!this.cf.getAnonymityTreshold() || (this.cf.getSafeReportStatus() && this.cf.isSharedReport)) ||
      this.userSegments?.distributions[0]?.[this.userSegments?.details?.[0]?.values?.indexOf(item)]?.value >=
        (this.anonymityTreshold || 5)
    );
  }

  public setFiltersDemoOn() {
    this.cf.setFiltersDemoOn();
  }

  public edit(item: string) {
    this.editModeOn = item;
  }

  public clickOutsideActive(item: string): boolean {
    return this.editModeOn === item;
  }

  public clickOutside() {
    if (!this.menuTrigger.menuOpen) {
      this.editModeOn = '';
    }
  }

  public rename(item: string, text: string) {
    this.updateSegmentName.next({ key: item, text });
  }

  public remove(item: string) {
    const comparisonIndex = this.comparisonList.indexOf(item);

    if (comparisonIndex >= 0) {
      this.comparisonList.splice(comparisonIndex, 1);
    }

    this.cf.deleteUserSegment(item);
  }

  public changeColor(item: string, index: number) {
    this.cf.dimensions['userSegments']['customColors'][item] = index;
    this.cf.dataChange.next(DataChangeEvent.ChangedColorComparisonItem);
    this.cf.sendNewCrossfilter();
  }

  public getComparisonColor(item: string) {
    const domain = this.userSegments.domain;
    const colors = domain && domain.length > 0 ? domain[0].colors : {};

    return Colors.getComparisonColor(colors[item]);
  }

  public getConsensus(item: string) {
    let sum = 0;
    let divider = 0;
    for (const dim in this.userSegments['stats']['userSegments'][item]) {
      const consensusSum = this.userSegments['stats']['userSegments'][item][dim]['consensusSum'];
      const totalConsensus = this.userSegments['stats']['userSegments'][item][dim]['totalConsensus'];
      if (consensusSum != null) {
        sum += consensusSum;
        divider += totalConsensus;
      }
    }

    return divider > 0 ? Math.round((sum / divider) * 100) : null;
  }

  public toggleComparison(item: string) {
    if (this.editModeOn === '') {
      if (this.isAnonymitySafe(item)) {
        if (this.comparisonList.indexOf(item) < 0) {
          this.comparisonList.push(item);
          this.updateComparisonListOrder();
        } else {
          this.comparisonList.splice(this.comparisonList.indexOf(item), 1);
          this.cf.updateComparisonList(this.comparisonList, 'userSegments');
        }

        this.comparisonOn = !!this.comparisonList?.length;
        this.cdRef.detectChanges();
      }
    }
  }

  public updateComparisonListOrder(event: CdkDragDrop<string[]> | null = null) {
    if (event) {
      moveItemInArray(this.userSegments.details[0].customValuesOrder, event.previousIndex, event.currentIndex);
    }

    const order: string[] = this.userSegments.details[0].customValuesOrder;
    const newComparison: string[] = [];

    for (let i = 0, len = order.length; i < len; i++) {
      if (this.comparisonList.indexOf(order[i]) >= 0) {
        newComparison.push(order[i]);
      }
    }

    this.comparisonList = newComparison;

    this.cf.updateComparisonList(this.comparisonList, 'userSegments');
  }

  comparisonSwitch($event) {
    this.comparisonOn = $event.checked;

    if ($event.checked) {
      const comparison = Object.assign(
        [],
        this.userSegments.details[0].values.filter((item: string) => this.isAnonymitySafe(item)),
      );
      this.comparisonList = comparison;
    } else {
      this.comparisonList = [];
    }

    this.cf.updateComparisonList(this.comparisonList, 'userSegments');
  }
}
