import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

import { Rights } from '@shared/enums/rights.enum';
import { GridItem, NotesCardData } from '@shared/models/report.model';

import { Calculations } from '@report/shared/enums/calculations.enum';

@Component({
  selector: 'notes-card',
  templateUrl: './notes-card.component.html',
  styleUrls: ['./notes-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesCard {
  tooltip1 = $localize`:tooltip@@zef-i18n-00287:Enter full screen`;
  tooltip2 = $localize`:tooltip@@zef-i18n-00288:Exit full screen`;

  @Input() settings: GridItem = {} as GridItem;
  @Input() data: NotesCardData = { content: '' };
  @Input() isSharedReport: boolean = false;
  @Input() userRights: number = 0;
  @Input() surveyRights: number = 0;
  @Input() fullscreen: boolean = false;
  @Input() mobileView: boolean = false;
  @Output() toggleFullscreen = new EventEmitter<any>();
  @Output() hidedChart = new EventEmitter<any>();

  readonly Rights = Rights;

  constructor(private cdRef: ChangeDetectorRef) {}

  public onResize($event) {
    const gridSizeNeed = Calculations.gridSizeFromHeightCeil($event.dimensions.height);

    if (this.settings.gridSettings?.sizey < gridSizeNeed) {
      this.settings.gridSettings.sizey = gridSizeNeed;
      this.cdRef.markForCheck();
    }
  }

  public toggleFullScreen() {
    this.toggleFullscreen.emit(this.settings.gridId);
  }

  public hideChart($event) {
    if ($event) {
      this.hidedChart.emit(this.settings.gridId);
    }
  }
}
