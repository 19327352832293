import { BehaviorSubject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { SnackbarService } from '@shared/services/snackbar.service';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';

import {
  ChartDistribution,
  ChartDomain,
  ChartSettings,
  DimensionDataItem,
  FilterData,
} from '@shared/models/report.model';

import { Colors } from '@report/shared/enums/colors.enum';

import { Crossfilter } from '@report/shared/services/crossfilter.service';
import { rotateAnimation } from '@shared/animations/rotate.anim';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Questions } from '@shared/enums/questions.enum';
import { Charts } from '@shared/enums/charts.enum';

/**
 * This is a text emotions analysis.
 */
@Component({
  selector: 'interviewer-summary',
  templateUrl: './interviewer-summary.component.html',
  styleUrls: ['./interviewer-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [rotateAnimation],
})
export class InterviewerSummary implements OnInit, OnChanges {
  @Input() details: DimensionDataItem[] = [];
  @Input() domain: ChartDomain[] = [];
  @Input() distributions: ChartDistribution[][] = [];
  @Input() stats: any[] = [];
  @Input() filterInput: any[] = [];
  @Input() crossfilter: Crossfilter | null = null;
  @Input() comparison: any;
  @Input() trend: any;
  @Input() totalAnswers: any;
  @Input() trendHoverInput: string = '';
  @Input() update: Date = new Date();
  @Input() chartSettings: ChartSettings = {} as ChartSettings;
  @Input() size: string = '0px';
  @Input() transitionDuration: number = 0;
  @Input() isSharedReport: boolean = false;
  @Input() selectionExists: boolean = false;
  @Input() filtersDemo: boolean = false;
  @Input() filtering: boolean = false;
  @Input() anonymityLock: boolean = false;
  @Input() touchDevice: boolean = false;
  @Input() anonymityTreshold: number = null;

  @Output() settingsChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() hover: EventEmitter<string> = new EventEmitter<string>();

  readonly chartTypes = Charts;
  readonly questionTypes = Questions;

  // BehaviorSubjects
  public searchUpdate$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public languageFilter$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public sortKey$: BehaviorSubject<string> = new BehaviorSubject<string>('survey');
  public sortDirection$: BehaviorSubject<string> = new BehaviorSubject<string>('asc');
  public chartData$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public categoryData$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public chartTable$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public comparisonTable$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  // Variables used in HTML template
  public sizeUpdate: Date = new Date();
  public vsHide: boolean = false;
  public responses: number = 0;

  public timeIndex: number = null;
  public rootQuestionIndex: number = null;
  public rootCauseIndex: number = null;
  public rootCauseCategoryIndex: number = null;
  public wordsIndex: number = null;
  public interviewSummaryIndex: number = null;

  public availableHeight: number = 0;
  public hoveredElement: any = null;
  public languages: any[] = [];
  public langFilter: string = '';
  public chartCount: number = 1;
  public exportChart: boolean = false;
  public isPoweredBy: boolean = false;

  private dataService: Crossfilter | null = this.cf;
  private filter: FilterData[];
  private selections: any = {};

  @ViewChild(CdkVirtualScrollViewport) vsViewport: CdkVirtualScrollViewport;

  get Math() {
    return Math;
  }

  constructor(
    private cdRef: ChangeDetectorRef,
    private cf: Crossfilter,
    private _element: ElementRef,
    readonly ns: SnackbarService,
    readonly hooks: LifecycleHooks,
  ) {}

  ngOnInit(): void {
    combineLatest([this.chartData$, this.searchUpdate$, this.languageFilter$, this.sortKey$, this.sortDirection$])
      .pipe(takeUntil(this.hooks.destroy))
      .subscribe(([chartData, searchTerm, , sortKey, sortDirection]) => {
        const rawArray = chartData.slice();
        const hasRootCauseArray = rawArray.filter((row) => row[this.rootCauseIndex + 1]?.text);
        const textArray = !this.langFilter
          ? hasRootCauseArray
          : hasRootCauseArray.filter((row) => row.find((col) => col.language === this.langFilter));
        const sortIndex = this.domain.findIndex((item) => item.key === sortKey) + 1;
        let filteredList: any[];

        if (!searchTerm) {
          filteredList = textArray;
        } else {
          const filteredResults = textArray.filter((col) =>
            col
              .map((c) => c.text)
              .join()
              .toLowerCase()
              .includes(searchTerm.toLowerCase()),
          );
          filteredList = filteredResults;
        }

        const numberCheck: (val: string | number) => number = (val: string | number) =>
          !isNaN(Number(val)) ? Number(val) : -200;
        const isLinear: (key: string) => boolean = (key) =>
          this.domain.find((dom) => dom.key === key)?.scale === 'linear';

        const sortedList =
          sortKey === 'survey'
            ? sortDirection === 'desc'
              ? filteredList.reverse()
              : filteredList
            : filteredList.sort((a, b) => {
                if (isLinear(sortKey)) {
                  if (a[sortIndex]['text'] && b[sortIndex]['text']) {
                    if (numberCheck(a[sortIndex]['text']) > numberCheck(b[sortIndex]['text'])) {
                      return sortDirection === 'asc' ? 1 : -1;
                    }
                    if (numberCheck(a[sortIndex]['text']) < numberCheck(b[sortIndex]['text'])) {
                      return sortDirection === 'asc' ? -1 : 1;
                    }
                  } else {
                    return a[sortIndex]['text'] ? -1 : 1;
                  }
                } else {
                  if (a[sortIndex]['text'] > b[sortIndex]['text']) {
                    return sortDirection === 'asc' ? 1 : -1;
                  }
                  if (a[sortIndex]['text'] < b[sortIndex]['text']) {
                    return sortDirection === 'asc' ? -1 : 1;
                  }
                }

                return 0;
              });

        if (sortedList.length === 0) {
          this.vsHide = true;
        } else {
          this.vsHide = false;
        }

        this.chartTable$.next(sortedList);
        this.cdRef.markForCheck();
        this.cdRef.detectChanges();

        if (this.vsViewport) {
          this.vsViewport.scrollTo({ top: 0 });
          this.cdRef.markForCheck();
          this.cdRef.detectChanges();

          this.vsViewport.setRenderedRange({ start: 0, end: this.vsViewport.getRenderedRange().end + 4 });
          this.cdRef.markForCheck();
          this.cdRef.detectChanges();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.exportChart = this.hasParentClass(this._element.nativeElement, 'export-chart');

    if (!(Object.keys(changes).length === 1 && changes.trendHoverInput && !this.trend)) {
      this.rootQuestionIndex = this.details.findIndex(
        (item, index) =>
          item.originalType !== Questions.AI_INTERVIEWER &&
          item.key !== 'time' &&
          (!this.comparison || this.comparison.index !== index),
      );
      this.rootCauseIndex = this.details.findIndex((item) => item.originalTypeSpecifier === 'interviewer-rootCause');
      this.rootCauseCategoryIndex = this.details.findIndex(
        (item) => item.originalTypeSpecifier === 'interviewer-rootCauseCategory',
      );
      this.wordsIndex = this.details.findIndex((item) => item.originalTypeSpecifier === 'text-words');
      this.interviewSummaryIndex = this.details.findIndex(
        (item) => item.originalTypeSpecifier === 'interviewer-summary',
      );
      this.timeIndex = this.details.findIndex((item) => item.scale === 'time');

      const languages = [];
      if (
        this.details[this.wordsIndex] &&
        this.details[this.wordsIndex]['valueGroupKeys'] &&
        this.details[this.wordsIndex]['valueGroupKeys'].length
      ) {
        for (let i = 0, len = this.details[this.wordsIndex]['valueGroupKeys'].length; i < len; i++) {
          if (this.details[this.wordsIndex]['valueGroupTypes'][i] === 'language') {
            const key = this.details[this.wordsIndex]['valueGroupKeys'][i];
            languages.push(key);
          }
        }
      }
      this.languages = languages;
      this.isPoweredBy =
        !this.trend &&
        languages.filter((lang) => lang === 'fi' || lang === 'sv' || lang === 'da' || lang === 'no').length > 0;

      this.chartCount =
        this.distributions?.[this.comparison?.index]?.length > 0
          ? (this.isNotFreezed() && this.comparison?.values?.length) || 1
          : 1;

      this.updateFilters();

      if (changes.size || changes.comparison) {
        const roughUpdateNeeded: boolean = !changes.size?.isFirstChange();

        if (this.size) {
          this.availableHeight =
            parseFloat(this.size) -
            (!this.trend
              ? this.chartCount * (32 + (this.distributions?.[this.comparison?.index]?.length > 0 ? 24 : 16))
              : 32) -
            (this.isPoweredBy ? 24 : 0);
          this.sizeUpdate = new Date();
          if (roughUpdateNeeded) {
            this.forceVSUpdate();
          }
        }
      }

      if (changes.chartSettings && changes.chartSettings.firstChange) {
        this.sortKey$.next(this.chartSettings.sortKey || 'survey');
        this.sortDirection$.next(this.chartSettings.sortDirection || 'asc');
      }

      this.responses =
        this.stats && this.stats[this.rootCauseIndex] && this.stats[this.rootCauseIndex]['responses'] != null
          ? this.stats[this.rootCauseIndex]['responses']
          : this.totalAnswers;

      if (
        this.isNotFreezed() &&
        !this.trend &&
        this.comparison &&
        this.comparison.values &&
        this.comparison.values.length
      ) {
        this.setComparisonData();
      }

      this.setData();
    }
  }

  private setData(): void {
    if (this.crossfilter) {
      this.dataService = this.crossfilter;
    } else {
      this.dataService = this.cf;
    }

    this.chartData$.next(
      this.details
        ? this.dataService.getTextAnswersFor(
            this.details,
            this.isSharedReport,
            false,
            true,
            !!(this.anonymityTreshold && (this.comparison || this.dataService.getTrendAnalysisStatus())),
          )
        : [],
    );
  }

  private setComparisonData(): void {
    const domain = this.domain[this.comparison.index];
    const categoryDomIndex = this.domain[this.rootCauseCategoryIndex]?.index;
    const wordsDomIndex = this.domain[this.wordsIndex]?.index;
    const allStats = this.stats[this.comparison.index];
    const table = [];

    for (let i = 0, len = domain?.keys?.length; i < len; i++) {
      const key = domain.keys[i];
      const label = domain.labels[key];
      const color = domain.colors[key] != null ? Colors.getComparisonColor(domain.colors[key]) : '';
      const statsBase = allStats && allStats.children && allStats.children.find((child) => child && child.key === key);
      const stats = statsBase && statsBase['children'] && statsBase['children'][categoryDomIndex];
      const responses = stats && stats.responses;
      const wordResponses = statsBase?.['children']?.[wordsDomIndex]?.responses;
      const wordAllResponses = this.distributions[this.comparison.index]?.find((item) => item?.key === key)?.value;
      const categoryDistributions =
        this.distributions[this.comparison.index]?.find((item) => item?.key === key)?.children || [];
      const wordDistributions =
        this.distributions[this.wordsIndex]?.map((item) => {
          const child = item.children.find((ch) => ch.key === key) || {};

          return {
            key: item.key,
            value: child.value,
            percentage: child.percentage,
            percentage_all: child.percentage_all,
            children: [],
          };
        }) || [];
      table.push({
        key,
        color,
        label,
        responses,
        stats,
        categoryDistributions,
        wordDistributions,
        wordResponses,
        wordAllResponses,
      });
    }

    this.comparisonTable$.next(table);
  }

  private forceVSUpdate(): void {
    this.vsHide = true;
    this.cdRef.detectChanges();
    setTimeout(() => {
      this.vsHide = false;
      this.cdRef.detectChanges();
    }, 0);
  }

  trackByKey(i: number, item: any): string {
    return item.key;
  }

  trackByRow(i: number, row: any[]): number {
    return (row && row[0] && row[0]['value']) || null;
  }

  hasParentClass(element, classname): boolean {
    if (element.className && element.className.split(' ').indexOf(classname) >= 0) {
      return true;
    }

    return !!element.parentNode && this.hasParentClass(element.parentNode, classname);
  }

  private updateFilters() {
    for (let i = 0, len = this.filterInput.length; i < len; i++) {
      const filters = this.filterInput[i];
      const key = this.details[i].key;
      this.selections[key] = new Set();
      if (filters && filters.length > 0) {
        for (const filter of filters) {
          this.selections[key].add(filter);
        }
      }
    }
  }

  public filterData(value: any[], i: number, valueIndex?: number): void {
    if (!(this.dataService.getTextFreezingStatus() && this.isSharedReport)) {
      const dimKey = this.details[i].key;
      const scale = this.details[i].scale;
      let values: any[] = [];
      let filterValue: number[] = [];

      if (scale === 'text') {
        values = Array.from(Array(this.dataService.getTextAnswers()[dimKey].length).keys());
        filterValue = value[1];
      } else if (scale === 'contact-text') {
        values = Array.from(Array(this.dataService.getTextContacts()[dimKey].length).keys());
        filterValue = value[1];
      } else if (scale === 'linear') {
        values = this.details[i].values;
        filterValue = value[0];
      } else if (this.details[i].values && this.details[i].values.length > 0) {
        values = this.details[i].values;
        filterValue = value[0]
          .filter((item) => {
            return !(valueIndex != null && value[0]?.[valueIndex] != null) || item === value[0]?.[valueIndex];
          })
          .map((item) => this.details[i]['values'][item]);
      }

      if (!this.selections[dimKey]) {
        this.selections[dimKey] = new Set();
      }

      for (const val of filterValue) {
        if (this.selections[dimKey].has(val)) {
          this.selections[dimKey].delete(val);
        } else {
          this.selections[dimKey].add(val);
        }
      }

      const newFilter: FilterData = {
        key: dimKey,
        values,
        filter: Array.from(this.selections[dimKey]),
        textFilter: this.details[i].scale === 'text' || this.details[i].scale === 'contact-text' ? true : false,
      };

      if (this.filtering && !this.anonymityLock) {
        this.filter = [];

        this.filter.push(newFilter);
        if (JSON.stringify(this.filter[0].filter) !== JSON.stringify(this.filterInput)) {
          this.dataService.filter(this.filter);
        }
      }
    }
  }

  public onSortColumn(column: string): void {
    const isLinear: boolean = this.domain.find((dom) => dom.key === column)?.scale === 'linear';
    if (isLinear) {
      this.sortDirection$.next('desc');
    } else {
      this.sortDirection$.next('asc');
    }

    this.sortKey$.next(column);
    this.settingsChanged();
  }

  public onSortDir(): void {
    this.sortDirection$.next(this.sortDirection$.value === 'asc' ? 'desc' : 'asc');
    this.settingsChanged();
  }

  public settingsChanged(): void {
    this.settingsChange.emit({
      sortKey: this.sortKey$.value,
      sortDirection: this.sortDirection$.value,
    });
  }

  public isNotFreezed(): boolean {
    return !(this.dataService.getTextFreezingStatus() && this.isSharedReport) && !(this.anonymityTreshold > 1);
  }

  public isUnderAnonymityTreshold(): boolean {
    return this.anonymityTreshold && this.chartData$?.value?.length < this.anonymityTreshold;
  }

  public filterByLanguage(lang: string) {
    this.langFilter = lang;
    this.languageFilter$.next(lang);
  }

  public parseItems(item, index) {
    return (item?.value?.[0] || []).map((val) => {
      return this.details[index]?.['values']?.[val];
    });
  }

  public getColor(items?: any, index?: number) {
    const valIndex: number = items?.value?.[0]?.[index];
    const key = valIndex != null && this.details[this.comparison?.index]?.values?.[valIndex];

    return valIndex != null && key != null
      ? Colors.getComparisonColor(
          this.domain[this.comparison.index]?.colors?.[key] != null
            ? this.domain[this.comparison.index].colors[key]
            : valIndex,
        )
      : Colors.DEFAULT;
  }

  public getText(items?: any, index?: number) {
    const valIndex: number = items?.value?.[0]?.[index];
    const key = valIndex != null && this.details[this.comparison?.index]?.values?.[valIndex];

    return this.domain[this.comparison.index]?.labels?.[key] || '';
  }
}
