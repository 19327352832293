<!--
  Report share component dialog.

  @unstable
-->

<zef-dialog
  *ngIf="{ survey: (survey$ | async)?.survey, design: (survey$ | async)?.design } as asyncData"
  [type]="'ask'"
  [hideHeader]="true"
  [hideActions]="true"
  [noActionPadding]="true"
  [transparentBackground]="true"
  class="report-share-dialog"
>
  <div dialog-content class="z-fx-col z-fx-auto z-fx-gap-16 dialog-content">
    <div *ngIf="selectedReport && showUsers" class="z-fx-col z-fx-auto z-fx-gap-32 users-table">
      <div class="z-fx z-fx-between-center">
        <div class="z-fx z-fx-start-center z-fx-gap-16">
          <button mat-icon-button class="zef-ghost-button" color="ink" (click)="showUsers = !showUsers">
            <mat-icon>back</mat-icon>
          </button>
          <div class="z-fx z-fx-start-center z-fx-gap-8">
            <span class="zef-text-h2" i18n>Private share</span>
            <span class="zef-text-h2-muted">{{ sharedEmails.length }}</span>
          </div>
        </div>
        <div *ngIf="usersToBeRemoved?.length" class="z-fx z-fx-start-center z-fx-gap-16 save-button">
          <button mat-raised-button color="primary" [loading]="deletingOnProgress" (click)="removeUsers()">
            <span i18n>Save</span>
          </button>
          <mat-divider class="zef-divider-16" [vertical]="true"></mat-divider>
        </div>
      </div>

      <ng-scrollbar [autoHeightDisabled]="false">
        <div class="email-list-content">
          <div
            *ngFor="let user of sharedEmails; trackBy: trackByFn; index as i"
            class="z-fx z-fx-start-center user"
            [class.zef-bgcolor-space]="i % 2 === 0"
          >
            <div class="z-fx-auto zef-text-p1 text" [class.removed]="isUserRemoved(user)">{{ user.email }}</div>
            <mat-icon
              *ngIf="user.rights > rights.NONE"
              class="delete-icon"
              [matTooltip]="iconTooltip"
              (click)="removeUser(user)"
            >
              close
            </mat-icon>
          </div>
        </div>
      </ng-scrollbar>
    </div>
    <div *ngIf="selectedReport && !showUsers" class="z-fx-col z-fx-start-start z-fx-gap-8 z-fx-auto">
      <div class="z-fx z-fx-w100 z-fx-start-start z-fx-gap-8 private-share-container">
        <mat-icon class="zef-icon-large">user</mat-icon>
        <div class="z-fx-auto private-share-content">
          <div class="z-fx z-fx-between-center">
            <div class="z-fx z-fx-start-center z-fx-gap-8">
              <div class="zef-text-h2" i18n>Private share</div>
              <div class="zef-text-h2-muted">
                <span>{{ sharedEmails.length }}</span>
                <span *ngIf="validNewEmails()?.length" class="new-emails-count">+{{ validNewEmails()?.length }}</span>
              </div>
              <button mat-icon-button [matMenuTriggerFor]="moreOptionsMenu">
                <mat-icon>more</mat-icon>
              </button>
              <mat-menu #moreOptionsMenu class="zef-menu-combo">
                <a mat-menu-item [href]="previewLink" target="_blank">
                  <mat-icon>new_tab</mat-icon>
                  <span i18n>Preview as shared</span>
                </a>
              </mat-menu>
            </div>
            <div *ngIf="sendingOnProgress" class="sending-progress-container">
              <button
                mat-raised-button
                cdkFocusInitial
                class="z-fx z-fx-center-center send-button"
                [color]="'success'"
                [loading]="sendingOnProgress"
              >
                <span i18n>Send</span>
              </button>
            </div>
          </div>
          <div *ngIf="!sendingOnProgress" class="z-fx-col z-fx-gap-24">
            <div class="shared-users-info">
              <div *ngIf="sharedEmails?.length > 0" class="users-toggle">
                <span class="zef-text-p2-link" (click)="toggleUsers()" i18n>Manage</span>
                <span class="zef-text-p2-muted" i18n>
                  <!-- eslint-disable -->
                  {{ sharedEmails[0] ? sharedEmails[0].email : '' }}{{ sharedEmails.length > 1 ? ' + ' +
                  (sharedEmails.length - 1) + ' more' : '' }}
                  <!-- eslint-enable -->
                </span>
              </div>
            </div>
            <div #inputContainer class="z-fx z-fx-gap-8">
              <div class="z-fx-auto z-fx-col z-fx-gap-8">
                <mat-form-field class="z-fx email-container">
                  <ng-scrollbar class="z-fx-grow" [autoHeightDisabled]="false">
                    <mat-chip-list #chipList class="z-fx z-fx-auto">
                      <mat-chip
                        *ngFor="let email of newEmails"
                        class="chip"
                        [class.invalid]="!validateEmail(email)"
                        [selectable]="false"
                        [removable]="true"
                        (removed)="removeChip(email)"
                      >
                        {{ email }}
                        <mat-icon matChipRemove>close</mat-icon>
                      </mat-chip>
                      <input
                        matInput
                        cdkFocusInitial
                        class="z-fx-auto new-email-input"
                        [placeholder]="newEmailPlaceholder"
                        [(ngModel)]="emailInput"
                        [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="true"
                        (matChipInputTokenEnd)="add($event)"
                        (paste)="paste($event)"
                      />
                    </mat-chip-list>
                  </ng-scrollbar>
                </mat-form-field>
                <mat-form-field *ngIf="newEmails.length > 0" class="z-fx-col message-container">
                  <textarea
                    matInput
                    class="z-fx-auto message-input"
                    [(ngModel)]="message"
                    [placeholder]="addNotePlaceholder"
                  ></textarea>
                </mat-form-field>
              </div>

              <div class="z-fx z-fx-center-center hint">
                <mat-icon color="warning" [hintDialogTriggerFor]="hintInfoBox">bulb</mat-icon>

                <zef-hint-dialog #hintInfoBox>
                  <ng-container dialog-content>
                    <img height="108" src="assets/images/figure-invitation-org.png" alt="invite" />
                  </ng-container>
                </zef-hint-dialog>
              </div>
            </div>
            <div class="z-fx-col z-fx-auto z-fx-gap-24 private-share-settings">
              <div class="z-fx z-fx-start-start z-fx-gap-8 live">
                <mat-slide-toggle
                  #liveToggle
                  *ngIf="selectedReport"
                  class="live-toggle"
                  color="primary"
                  [(ngModel)]="live"
                  [disabled]="croppedDates.length === 2"
                  (change)="onLiveChange($event)"
                ></mat-slide-toggle>
                <div class="z-fx-col">
                  <div class="z-fx z-fx-start-center z-fx-gap-8">
                    <mat-icon class="live-icon">live</mat-icon>
                    <span class="zef-text-h3" i18n>Live</span>
                    <zef-help-icon [subject]="subjects.AnalyzeReportShareLive"></zef-help-icon>
                  </div>
                  <div *ngIf="!croppedDates?.length; else cropText" class="zef-text-p2-muted" i18n>
                    Report updates in real time with new respondents
                  </div>
                  <ng-template #cropText>
                    <div *ngIf="croppedDates.length === 2" class="zef-text-p2 crop-text">
                      <span class="zef-color-accent" i18n>
                        The timeline is cropped from {{ croppedDates[0] }} till {{ croppedDates[1] }}
                      </span>
                      <span class="zef-text-p2-link-muted" (click)="uncropData()" i18n>Uncrop</span>
                    </div>
                    <div *ngIf="croppedDates.length === 1" class="zef-text-p2 zef-color-accent" i18n>
                      Time frozen until {{ croppedDates[0] }}
                    </div>
                  </ng-template>
                </div>
              </div>
              <div class="z-fx z-fx-start-start z-fx-gap-8 safe-reports">
                <mat-slide-toggle
                  #safeReportToggle
                  class="safe-report-toggle"
                  color="primary"
                  [(ngModel)]="safeReport"
                  [disabled]="!!anonymityTreshold"
                  (change)="onSafeReportChange($event)"
                ></mat-slide-toggle>
                <div class="z-fx-col">
                  <div class="z-fx z-fx-start-center z-fx-gap-8">
                    <mat-icon>anonymous_on</mat-icon>
                    <span class="zef-text-h3" i18n>Safe report</span>
                    <zef-help-icon [subject]="subjects.AnalyzeReportShareSafeReport"></zef-help-icon>
                  </div>
                  <div class="zef-text-p2-muted z-fx-col z-fx-gap-8">
                    <span *ngIf="!anonymityTreshold; else anonymityText" i18n>
                      Do not allow filtering if selection is less than 5 respondents
                    </span>
                    <ng-template #anonymityText>
                      <span i18n>
                        Only safe reports can be shared from Anonymous surveys. Filtering is not allowed if selection is
                        less than {{ anonymityTreshold }} respondents.
                      </span>
                      <a
                        class="zef-text-p2 zef-color-primary zef-text-link-hover-underline no-margin"
                        [routerLink]="['/surveys/edit/' + selectedSurvey + '/settings']"
                        matDialogClose
                        i18n
                      >
                        Manage
                      </a>
                    </ng-template>
                  </div>
                </div>
              </div>
              <!-- Deprecated <div *ngIf="safeReport || textFilters" class="z-fx z-fx-start-start z-fx-gap-8 text-filters">
                <mat-slide-toggle
                  #textFiltersToggle
                  class="text-filters-toggle"
                  color="primary"
                  [(ngModel)]="textFilters"
                  (change)="onTextFiltersChange($event)"
                ></mat-slide-toggle>
                <div class="z-fx-col">
                  <div class="z-fx z-fx-start-center z-fx-gap-8">
                    <mat-icon>question_text_off</mat-icon>
                    <span class="zef-text-h3" i18n>Disable text filtering</span>
                    <zef-help-icon [subject]="subjects.AnalyzeReportShareFreezeTexts"></zef-help-icon>
                  </div>
                  <div class="zef-text-p2-muted" i18n>Disable filters on all textual data</div>
                </div>
              </div> -->
            </div>
            <!-- </div> -->
          </div>
          <div
            *ngIf="!sendingOnProgress && selectedReport && newEmails.length > 0 && !showUsers"
            class="z-fx z-fx-auto z-fx-end-center z-fx-gap-4 private-share-action-buttons"
          >
            <button
              mat-raised-button
              cdkFocusInitial
              class="z-fx z-fx-center-center send-button"
              color="secondary"
              (click)="cancelPrivateShare()"
            >
              <span i18n>Cancel</span>
            </button>
            <button
              mat-raised-button
              cdkFocusInitial
              class="z-fx z-fx-center-center send-button"
              [color]="'success'"
              [loading]="onProgress"
              (click)="sendInvitations()"
            >
              <span i18n>Send</span>
            </button>
          </div>
        </div>
      </div>

      <div
        *ngIf="!sendingOnProgress && !(newEmails.length > 0)"
        class="z-fx z-fx-w100 z-fx-start-start z-fx-gap-8 public-share-container"
      >
        <mat-icon class="zef-icon-large">world</mat-icon>
        <div class="z-fx-auto z-fx-col public-share-content">
          <div class="z-fx z-fx-between-center">
            <div class="z-fx z-fx-start-center z-fx-gap-8">
              <div class="zef-text-h2" i18n>Public link</div>
              <zef-help-icon [subject]="subjects.AnalyzeReportSharePublicReport"></zef-help-icon>
            </div>
            <div class="z-fx z-fx-start-center z-fx-gap-16">
              <button
                *ngIf="!onProgress && selectedReport && online"
                mat-raised-button
                class="zef-combo-button"
                [color]="'success'"
                [disabled]="onProgress"
                [zefCopyToClipboard]="publicLink"
                copyToClipboardNotice="Link copied"
                i18n-copyToClipboardNotice
                (click)="publicLinkCopied()"
              >
                <mat-icon>action_copy</mat-icon>
                <span i18n>Copy link</span>
              </button>
              <mat-divider
                *ngIf="!onProgress && selectedReport && online"
                [vertical]="true"
                class="zef-divider-16"
              ></mat-divider>
              <mat-slide-toggle
                #onlineToggle
                *ngIf="!onProgress && selectedReport"
                class="online-toggle"
                color="primary"
                [(ngModel)]="online"
                (change)="onOnlineChange($event)"
              ></mat-slide-toggle>
              <zef-spinner *ngIf="onProgress" size="large"></zef-spinner>
            </div>
          </div>
          <div *ngIf="selectedReport && online" class="public-link-description-texts">
            <p class="zef-text-p2-muted" i18n>Anyone with the link can view.</p>
            <p class="zef-text-p2-muted" i18n>
              Open text answers, and sensitive infomation are hidden from public reports.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!selectedReport" class="z-fx-auto z-fx z-fx-center-center spinner-container">
      <zef-spinner *ngIf="!selectedReport" class="spinner" size="huge"></zef-spinner>
    </div>
  </div>
</zef-dialog>
