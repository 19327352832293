import * as d3 from 'd3';

import { Directive, ElementRef, HostListener, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';

import { CanvasContext } from '@shared/models/report.model';

import { Colors } from '@report/shared/enums/colors.enum';
import { shortenText } from '@shared/utilities/canvas.utilities';

/**
 * This is a funnel chart.
 */
@Directive({
  selector: '[funnelChartH]',
})
export class FunnelChartH implements OnChanges {
  @Input() data: any[] = [];
  @Input() steps: string[] = [];
  @Input() stepLabels: { [stepKey: string]: string } = {};
  @Input() filterInput: any;
  @Input() transitionDuration: number = 0;
  @Input() update: Date = new Date();
  @Input() touchDevice: boolean = false;

  private base: any;

  private context: CanvasContext = {} as CanvasContext;
  private canvas: any;

  private stack: any[] = [];

  private scaleX: any;

  private legends: any[] = [];

  private tooltip: any;

  private width: any;
  private height: any;
  private margin: any;
  private fontSize: number = 0;
  private unit: number = 0;
  private marginBottomUnits: number[] = [];

  @HostListener('window:resize') resize() {
    this.updateChart(null);
  }

  @HostListener('document:visibilitychange') visibilityChange() {
    if (document.visibilityState === 'visible') {
      this.updateChart(null);
    }
  }

  constructor(private _element: ElementRef) {
    this.constructBody();
  }

  constructBody() {
    this.base = d3.select(this._element.nativeElement).append('div').attr('class', 'funnel-chart-h');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data || changes.steps || changes.stepLabels || changes.filterInput || changes.update) {
      this.updateChart(changes.data);
    }
  }

  updateChart(dataChanges: SimpleChange | null) {
    this.setEnvironment();
    this.setScales();
    this.setCanvas(dataChanges);
    this.setBrush();
  }

  setEnvironment() {
    const marginBottomUnit = Math.max(d3.max(this.marginBottomUnits, (d: any) => d) || 0, 6);
    this.fontSize = parseFloat(window.getComputedStyle(this._element.nativeElement).fontSize);
    this.unit = (10 / 14) * this.fontSize;

    this.margin = {
      top: 7 * this.unit,
      right: 3 * this.unit,
      bottom: marginBottomUnit * this.unit,
      left: 3 * this.unit,
    };

    const width = this._element.nativeElement.clientWidth - this.margin.left - this.margin.right;
    const height = this._element.nativeElement.clientHeight - this.margin.top - this.margin.bottom;

    this.width = width > 0 ? width : 0;
    this.height = height > 0 ? height : 0;
  }

  setScales() {
    this.scaleX = d3.scaleLinear().rangeRound([0, this.width]).domain([0, this.steps.length]);
  }

  colorScale(data, lineIndex) {
    let colorScale;
    const defaultColor = this.filterInput?.find((item) => item) ? Colors.FILTER : Colors.DEFAULT;

    if (data.length > 1) {
      const cols = data.map((item, index) => {
        const color = d3.hsl(item.index != null ? Colors.getComparisonColor(item.index) : defaultColor);
        color.s = color.s - (color.s - 0.2) * (index / data.length);

        if (index % 2) {
          color.l = color.l + (color.l > 0.5 ? -0.15 : 0.1);
        }

        return color;
      });

      colorScale = d3
        .scaleLinear<any, any>()
        .domain(data.map((item, index) => Number(index)))
        .range(cols);
    }

    return colorScale
      ? colorScale(lineIndex)
      : data?.[lineIndex] && data?.[lineIndex].index != null
        ? Colors.getComparisonColor(data?.[lineIndex].index)
        : defaultColor;
  }

  setCanvas(dataChanges: SimpleChange | null) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const __this = this;
    this.legends = [];

    const drawContent = function (d, i) {
      if (this.parentElement) {
        const context = d3.select(this).node().getContext('2d', { willReadFrequently: true });

        __this.setLegends(context, d, Number(i));

        if (dataChanges && !dataChanges.firstChange && __this.transitionDuration > 0) {
          const dataObj = __this.context?.data || [];

          const interpolator = d3.interpolate(dataObj, d);
          const ease = d3.easeCubic;

          const timer = d3.timer((elapsed) => {
            const step = elapsed / __this.transitionDuration;
            let data;

            if (step >= 1) {
              data = d;
              timer.stop();
            } else {
              data = interpolator(ease(step));
            }

            __this.setAreas(context, data, null);
          });
        } else {
          __this.setAreas(context, d, null);
        }

        __this.context = { context, data: d.slice() };
      }
    };

    this.canvas = this.base.selectAll('.funnel-chart-h-canvas').data([this.data]);

    this.canvas.exit().remove();

    this.canvas
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .each(drawContent);

    this.canvas
      .enter()
      .append('canvas')
      .attr('class', 'funnel-chart-h-canvas')
      .style('position', 'relative')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .each(drawContent);
  }

  setLegends(context, data, dataIndex) {
    const y = this.margin.top + this.height + 3 * this.unit;
    const width = this.width + this.margin.left + this.margin.right;
    const height = 2 * this.unit;
    let usedSpace = 0;
    let arr: any[] = [];
    let currentX = 0;
    let currentY = 0;
    let margin;
    const legends = [];

    context.clearRect(0, y - 1, width, this.margin.bottom);

    context.textBaseline = 'top';
    context.textAlign = 'left';
    context.font = 10 / 14 + 'em Inter';

    for (let i = 0, length = data.length; i < length; i++) {
      const key = data[i]['key'];
      const text = shortenText(context, data[i]['title'], width, 3 * this.unit);
      const elW = context.measureText(text).width;

      if (usedSpace + elW + 2 * this.unit <= width) {
        usedSpace += elW + 2 * this.unit;
        arr.push([key, i, text]);
      } else {
        margin = (width - usedSpace) / 2;
        currentX = margin;

        for (let a = 0, len = arr.length; a < len; a++) {
          const itemText = arr[a][2];
          const itemColor = arr[a][1];
          const itemKey = arr[a][0];
          currentX += this.unit;

          context.fillStyle = Colors.TEXT;
          context.fillText(itemText, currentX, currentY + y);

          context.beginPath();
          context.arc(currentX - 0.8 * this.unit, currentY + y + 0.6 * this.unit, 0.5 * this.unit, 0, 2 * Math.PI);
          context.closePath();
          context.fillStyle = this.colorScale(data, itemColor);
          context.fill();
          legends.push({
            key: itemKey,
            x: currentX,
            y: currentY + y,
            width: context.measureText(itemText).width,
            height,
            title: itemText,
          });
          currentX += context.measureText(itemText).width + this.unit;
        }

        currentY += height;
        usedSpace = elW + 2 * this.unit;

        arr = [[key, i, text]];
      }
    }

    margin = (width - usedSpace) / 2;
    currentX = margin;

    for (let a = 0, len = arr.length; a < len; a++) {
      const itemText = arr[a][2];
      const itemColor = arr[a][1];
      const itemKey = arr[a][0];

      currentX += this.unit;

      context.fillStyle = Colors.TEXT;
      context.fillText(itemText, currentX, currentY + y);

      context.beginPath();
      context.arc(currentX - 0.8 * this.unit, currentY + y + 0.6 * this.unit, 0.5 * this.unit, 0, 2 * Math.PI);
      context.closePath();
      context.fillStyle = this.colorScale(data, itemColor);
      context.fill();
      legends.push({
        key: itemKey,
        x: currentX,
        y: currentY + y,
        width: context.measureText(itemText).width,
        height,
        title: itemText,
      });
      currentX += context.measureText(itemText).width + this.unit;
    }

    const spaceNeeded = currentY / this.unit + 6;

    if (!this.marginBottomUnits[dataIndex]) {
      this.marginBottomUnits[dataIndex] = 6;
    }

    if (
      !isNaN(spaceNeeded) &&
      this.marginBottomUnits[dataIndex] !== spaceNeeded &&
      this.marginBottomUnits[dataIndex] * this.unit > 0
    ) {
      this.marginBottomUnits[dataIndex] += spaceNeeded - this.marginBottomUnits[dataIndex];
      this.setEnvironment();
      this.setScales();
      this.setLegends(context, data, dataIndex);
    } else {
      for (let l = 0, len = legends.length; l < len; l++) {
        this.legends.push(legends[l]);
      }
    }
  }

  setAreas(context, data: any = [], highlight: any = null) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const __this = this;

    context.clearRect(this.margin.left, this.margin.top, this.width + this.margin.right, this.height + 5);

    this.setTexts(context, data);

    const steps = this.steps.concat(this.steps[this.steps.length - 1]);
    const stepTotals = steps.map((step) => data.reduce((acc, item) => acc + (item[step] || 0), 0));
    const stepScales = steps.map((step, index) =>
      d3
        .scaleLinear()
        .rangeRound([0, this.height * (stepTotals[index] / stepTotals[0])])
        .domain([0, 1]),
    );

    this.stack = data.map((item, index, arr) =>
      steps.map((step, stepIndex) => ({
        y0: Math.min(
          Math.max(
            this.margin.top +
              (this.height * (1 - stepTotals[stepIndex] / stepTotals[0])) / 2 +
              stepScales[stepIndex](
                arr
                  .filter((arrItem, arrItemIndex) => arrItemIndex < index)
                  .map((d) => d[step] || 0)
                  .reduce((acc, curr) => acc + curr, 0) / stepTotals[stepIndex],
              ),
            this.margin.top,
          ),
          this.margin.top + this.height,
        ),
        y1: Math.max(
          Math.min(
            this.margin.top +
              (this.height * (1 - stepTotals[stepIndex] / stepTotals[0])) / 2 +
              stepScales[stepIndex](
                arr
                  .filter((arrItem, arrItemIndex) => arrItemIndex <= index)
                  .map((d) => d[step] || 0)
                  .reduce((acc, curr) => acc + curr, 0) / stepTotals[stepIndex],
              ),
            this.margin.top + this.height,
          ),
          this.margin.top,
        ),
        item,
        color: this.colorScale(data, index),
      })),
    );

    const area = d3
      .area()
      .x(function (d, i) {
        return __this.margin.left + __this.scaleX(i);
      })
      .y0(function (d) {
        return d['y0'];
      })
      .y1(function (d) {
        return d['y1'];
      })
      .curve(d3.curveMonotoneX)
      .context(context);

    for (let l = 0, len = this.stack.length; l < len; l++) {
      const color = this.colorScale(data, l);
      const highlighted = highlight?.key === this.stack[l]?.[0]?.item?.key;

      context.lineWidth = 1.5;
      context.fillStyle = color;
      context.strokeStyle = color;

      context.beginPath();
      area(this.stack[l]);
      context.globalAlpha = !highlight ? 0.5 : !highlighted ? 0.25 : 0.8;
      context.strokeStyle = color;
      context.fill();
    }
  }

  setTexts(context, data) {
    context.clearRect(0, 0, this.width + this.margin.right + this.margin.left, this.margin.top);

    context.fillStyle = Colors.TEXT;
    context.textAlign = 'left';
    context.textBaseline = 'middle';
    context.globalAlpha = 1;

    const stepTotals = this.steps.map((step) => data?.reduce((acc, item) => acc + (item[step] || 0), 0));

    for (let s = 0, len = this.steps.length; s < len; s++) {
      const xPos = this.scaleX(s);
      const number: string = s === 0 ? stepTotals[s] : ((stepTotals[s] / stepTotals[0]) * 100).toFixed(1) + '%';
      const label: string = this.stepLabels[this.steps[s]];

      context.beginPath();
      context.moveTo(this.margin.left + xPos, 2 * this.unit);
      context.lineTo(this.margin.left + xPos, this.margin.top + this.height + 2 * this.unit);
      context.lineWidth = 2;
      context.strokeStyle = Colors.BACKGROUND;
      context.stroke();

      context.fillStyle = Colors.TEXT;
      context.textAlign = 'left';
      context.textBaseline = 'top';

      context.font = 'Bold ' + 14 / 14 + 'em Inter';
      context.fillText(number, this.margin.left + xPos + this.unit, 2 * this.unit);

      context.font = 10 / 14 + 'em Inter';
      context.fillText(label, this.margin.left + xPos + this.unit, 3.75 * this.unit);
    }
  }

  setBrush() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const __this = this;
    const stack = this.stack.map((d) => ({
      color: [d3.rgb(d?.[0]?.color).r, d3.rgb(d?.[0]?.color).g, d3.rgb(d?.[0]?.color).b],
      item: d?.[0]?.['item'],
    }));

    const hoverFunction = function (event) {
      if (!__this.touchDevice) {
        const area = d3.pointer(event);

        __this.selectForHover(area, stack);
      }
    };

    this.base
      .selectAll('.funnel-chart-h-canvas')
      .on('mousemove', hoverFunction)
      .on('mouseout', () => {
        this.setTooltip(null);
      });
  }

  setTooltip(position: number[] = [], data: any = null) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const __this = this;

    // adding hovering effect
    this.base.selectAll('.funnel-chart-h-canvas').each(function () {
      if (this.parentElement) {
        __this.setAreas(__this.context.context, __this.context.data, data);
      }
    });

    const options = {
      html: (d) => `
      <div class="question">${d.title}</div>
      <div class="z-fx z-fx-gap-16 stats">
        ${this.steps
          .map(
            (step, index) => `
            <div class="z-fx-col z-fx-center-center">
              <div class="zef-text-align-left number" style="font-size: 1em;">${
                index === 0 ? d?.[step] : (((d?.[step] || 0) / d?.[this.steps[0]]) * 100).toFixed(1) + '%'
              }</div>
              <div class="zef-text-align-left title" style="font-size: calc(12 / 14 * 1em);">${
                this.stepLabels?.[step]
              }</div>
            </div>
          `,
          )
          .join('\n')}
      </div>
      `,
    };

    this.tooltip = d3
      .select(this._element.nativeElement)
      .selectAll('.item-tooltip')
      .data(data ? [data] : []);

    this.tooltip.exit().remove();

    this.tooltip.html(options.html).style('transform', function () {
      return `translate(${
        position[0] - this.getBoundingClientRect().width / 2
      }px,${position[1] - (this.getBoundingClientRect().height + 15)}px)`;
    });

    this.tooltip
      .enter()
      .append('div')
      .attr('class', 'item-tooltip')
      .html(options.html)
      .style('transform', function () {
        return `translate(${
          position[0] - this.getBoundingClientRect().width / 2
        }px,${position[1] - (this.getBoundingClientRect().height + 15)}px)`;
      });
  }

  // Helpers
  selectForHover(area, data) {
    const itemsBelow = this.itemsBelow(area, data);

    if (itemsBelow) {
      this.setTooltip(area, itemsBelow);
    } else {
      this.setTooltip(null);
    }
  }

  itemsBelow(area, data) {
    const context = this.context?.context;
    const p = context?.getImageData(area[0], area[1], 1, 1)?.data;

    let itemBelow;

    for (let i = 0, len = data?.length; i < len; i++) {
      const difference =
        Math.abs(data[i]?.['color']?.[0] - p?.[0]) +
        Math.abs(data[i]?.['color']?.[1] - p?.[1]) +
        Math.abs(data[i]?.['color']?.[2] - p?.[2]);

      if (difference < 4) {
        itemBelow = data[i]?.item;
        break;
      }
    }

    if (!itemBelow) {
      for (let i = 0, len = this.legends?.length; i < len; i++) {
        const item = this.legends[i];
        if (
          area[0] > item.x - 15 &&
          area[0] < item.x + item.width &&
          area[1] > item.y - 8 &&
          area[1] < item.y - 8 + item.height
        ) {
          itemBelow = data.find((d) => d?.item?.key === item.key)?.item;
          break;
        }
      }
    }

    return itemBelow;
  }
}
