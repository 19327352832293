<mat-toolbar class="z-fx z-fx-auto container">
  <a href="https://zef.fi/" target="_blank" class="logo">
    <zeffi-icon class="zef-icon-large logo-icon"></zeffi-icon>
  </a>
  <mat-divider [vertical]="true" class="divider"></mat-divider>

  <div class="z-fx z-fx-auto z-fx-start-center title">
    {{ info?.title || '' }}
  </div>

  <a
    *ngIf="isAnonymous$ | async"
    mat-raised-button
    [href]="environment.wwwAddress"
    target="_blank"
    color="primary"
    class="z-fx z-fx-center-center zef-cta-button get-started-free"
    i18n
  >
    Create survey for free
  </a>

  <button
    *ngIf="isPrivateReport$ | async"
    mat-raised-button
    color="secondary"
    (click)="openExportDialog()"
    class="action-export"
    i18n
  >
    Export
  </button>

  <div *ngIf="(user$ | async) && (isAnonymous$ | async) === false" class="z-fx private-user-details">
    <div class="z-fx-auto email">{{ email }}</div>
    <div class="z-fx-none logout" (click)="logout()" i18n>Logout</div>
  </div>
</mat-toolbar>
