import * as d3 from 'd3';

import { Directive, ElementRef, HostListener, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';

import { CanvasContext, ChartDistribution, ChartDomain } from '@shared/models/report.model';

import { Crossfilter } from '@report/shared/services/crossfilter.service';

import { Colors } from '@report/shared/enums/colors.enum';

import { drawContactIcon, shortenText } from '@shared/utilities/canvas.utilities';

/**
 * This is a Bubble Chart.
 */
@Directive({
  selector: '[bubbleChart]',
})
export class BubbleChart implements OnChanges {
  @Input() data: ChartDistribution[] = [];
  @Input() domain: ChartDomain = {} as ChartDomain;
  @Input() scale: any;
  @Input() filterInput: any;
  @Input() transitionDuration: number = 0;
  @Input() update: Date = new Date();
  @Input() filtering: boolean = false;
  @Input() anonymityLock: boolean = false;
  @Input() title: string = '';
  @Input() color: string = '';
  @Input() hideRespondentCounts: boolean = false;
  @Input() totalAnswers: number = 0;
  @Input() crossfilter: Crossfilter | null = null;
  @Input() touchDevice: boolean = false;
  @Input() isSharedReport: boolean = false;

  private base: any;

  private context: CanvasContext = {} as CanvasContext;
  private canvas: any;

  private cloud: any[] = [];

  private responses: number = 0;
  private previousResponses: number = 0;

  private dataService: Crossfilter | null = null;

  private tooltip: any;

  private width: any;
  private height: number = 0;
  private margin: any = { top: 20, right: 20, bottom: 20, left: 20 };
  private fontSize: number = 0;
  private unit: number = 0;

  private timer: any;

  private filter: any;

  private selections: any = new Set();

  @HostListener('window:resize') resize() {
    this.updateChart(null);
  }

  constructor(
    private _element: ElementRef,
    private cf: Crossfilter,
  ) {
    this.constructBody();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.data ||
      changes.domain ||
      changes.scale ||
      changes.filterInput ||
      changes.update ||
      changes.filtering ||
      changes.anonymityLock ||
      changes.title ||
      changes.color ||
      changes.totalAnswers ||
      changes.hideRespondentCounts
    ) {
      this.updateChart(changes.data);
    }
  }

  updateChart(dataChanges: SimpleChange | null) {
    this.setEnvironment();
    this.setScales();
    this.setCanvas(dataChanges);
  }

  constructBody() {
    this.base = d3.select(this._element.nativeElement).append('div').attr('class', 'bubble-chart');
  }

  setEnvironment() {
    this.fontSize = parseFloat(window.getComputedStyle(this._element.nativeElement).fontSize);
    this.unit = (10 / 14) * this.fontSize;

    this.margin = {
      top: 3 * this.unit,
      right: 2 * this.unit,
      bottom: 2 * this.unit,
      left: 2 * this.unit,
    };

    const width = this._element.nativeElement.clientWidth - this.margin.left - this.margin.right;
    const height = this._element.nativeElement.clientHeight - this.margin.top - this.margin.bottom;

    this.width = width > 0 ? width : 0;
    this.height = height > 0 ? height : 0;

    if (this.crossfilter) {
      this.dataService = this.crossfilter;
    } else {
      this.dataService = this.cf;
    }
  }

  setScales() {
    const count = this.data?.reduce((a, b: ChartDistribution) => b.value + a, 0);

    if (this.height > 0 && this.width > 0 && count) {
      const data = {
        children: this.data.slice().map((item) => ({
          key: item?.key,
          value: item?.value,
          percentage: item?.percentage,
          percentage_all: item?.percentage_all,
        })),
      };
      const root = d3
        .hierarchy(data)
        .sum((d: any) => (d.hasOwnProperty('value') ? d.value : 0))
        .sort((a, b) => b.value - a.value);

      const partition = d3.pack().size([this.width, this.height]).padding(4);
      partition(root);
      this.cloud = root.leaves();
    } else {
      this.cloud = [];
    }

    this.previousResponses = this.responses;
    this.responses = this.totalAnswers;
  }

  setCanvas(dataChanges: SimpleChange | null) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const __this = this;
    const hoverFunction = function (event) {
      if (!__this.touchDevice || !__this.filtering || __this.anonymityLock) {
        const area = d3.pointer(event);
        __this.selectForHover(event, area);
      }
    };

    const drawContent = function (d) {
      const context = d3.select(this).node().getContext('2d');

      if (this.timer) {
        this.timer.stop();
      }

      if (dataChanges && !dataChanges.firstChange && __this.transitionDuration > 0) {
        const dataObj = __this.context && __this.context.data ? __this.context.data : [];
        const interpolateArray: any = (a, b) => {
          const nb: number = b ? b.length : 0;
          const na: number = a ? Math.min(nb, a.length) : 0;
          const x: any[] = new Array(na);
          const c: any[] = new Array(...b);

          for (let i = 0; i < na; ++i) {
            x[i] = {
              x: d3.interpolateNumber(a[i]['x'], b[i]['x']),
              y: d3.interpolateNumber(a[i]['y'], b[i]['y']),
              r: d3.interpolateNumber(a[i]['r'], b[i]['r']),
            };
          }

          return function (t) {
            for (let i = 0; i < na; ++i) {
              c[i]['x'] = x[i]['x'](t);
              c[i]['y'] = x[i]['y'](t);
              c[i]['r'] = x[i]['r'](t);
            }
            return c;
          };
        };

        const interpolator = interpolateArray(dataObj, d);
        const interpolateResponses = d3.interpolateNumber(__this.previousResponses, __this.responses);
        const ease = d3.easeCubic;

        this.timer = d3.timer((elapsed) => {
          const step = elapsed / __this.transitionDuration;
          let data;
          let responses;

          if (step >= 1) {
            data = d;
            responses = __this.responses;
            this.timer.stop();
          } else {
            data = interpolator(ease(step));
            responses = Math.round(interpolateResponses(ease(step)));
          }

          __this.setHeader(context, responses);
          __this.setBubbles(context, data);
        });
      } else {
        __this.setHeader(context, __this.responses);
        __this.setBubbles(context, d);
      }

      __this.context = { context, data: d };
    };

    this.canvas = this.base.selectAll('.bubble-chart-canvas').data([this.cloud]);

    this.canvas.exit().remove();

    this.canvas
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .each(drawContent);

    this.canvas
      .enter()
      .append('canvas')
      .attr('class', 'bubble-chart-canvas')
      .style('position', 'relative')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .on('mousemove', hoverFunction)
      .on('mouseout', function (event) {
        __this.setTooltip(d3.pointer(event));
      })
      .on('click', function (event) {
        if (__this.filtering && !__this.anonymityLock) {
          const area = d3.pointer(event);
          __this.selectFromBubbles(area);

          __this.callFilter();
        }
      })
      .each(drawContent);
  }

  setHeader(context, responses: number) {
    if (!this.hideRespondentCounts) {
      context.clearRect(0, 0, this.width + this.margin.right + this.margin.left, this.margin.top);
      context.fillStyle = this.color || Colors.TEXT;
      context.textAlign = 'left';
      context.textBaseline = 'middle';

      const h = this.margin.top / 2;

      const wIcon = this.fontSize + 4;

      context.font = 10 / 14 + 'em Inter';
      const wNumber = context.measureText(responses).width + 8;

      context.font = 12 / 14 + 'em Inter';
      const title = this.title ? shortenText(context, this.title, this.width, 8 + (wIcon + wNumber) / 2) : '';

      const wTitle = title ? context.measureText(title).width + 8 : 0;

      const startPoint = this.margin.left + this.width / 2 - (wIcon + wNumber + wTitle) / 2;

      if (title) {
        context.fillText(title, startPoint, h);
      }

      drawContactIcon(context, this.fontSize, startPoint + wTitle, h, context.fillStyle);

      context.font = 10 / 14 + 'em Inter';
      context.fillText(responses, startPoint + wTitle + wIcon, h);
    }
  }

  setBubbles(context, data: any[] = [], highlight: any[] = []) {
    context.clearRect(
      0,
      this.margin.top - 2,
      this.margin.left + this.margin.right + this.width,
      this.margin.bottom + this.height,
    );

    this.selections = new Set();

    for (let i = 0, len = data.length; i < len; i++) {
      const x: number = this.margin.left + data[i].x;
      const y: number = this.margin.top + data[i].y;
      const r: number = data[i].r;
      const label: string = this.domain?.labels?.[data?.[i]?.['data']?.['key']];
      const key: string = data[i]?.['data']?.key;

      let fontSize = this.fontSize;
      for (fontSize; fontSize >= 6; fontSize--) {
        context.font = fontSize + 'px Inter';
        if (context.measureText(label).width + 8 < r * 2) {
          break;
        }
      }

      context.textAlign = 'center';
      context.textBaseline = 'middle';
      context.fillStyle = this.color || Colors.DEFAULT;

      if (this.filterInput && this.filterInput.length > 0) {
        const xFi = this.filterInput.indexOf(key) > -1;

        if (!xFi) {
          context.fillStyle = Colors.UNSELECTED;
        } else {
          context.fillStyle = this.color || Colors.SELECTED;
          this.selections.add(key);
          if (data[i]?.['data']?.otherKeysIncluded && data[i]['data'].otherKeysIncluded.length) {
            this.selections.add(...data[i]['data'].otherKeysIncluded);
          }
        }
      }

      context.beginPath();
      context.arc(x, y, r, 0, 2 * Math.PI, false);
      context.fill();

      if (highlight.indexOf(key) >= 0) {
        context.lineWidth = 2;
        context.strokeStyle = Colors.HIGHLIGHT;
        context.beginPath();
        context.arc(x, y, r, 0, 2 * Math.PI, false);
        context.stroke();
      }

      if (r > 0) {
        context.fillStyle = '#fff';
        context.fillText(shortenText(context, label, r * 2, 4), x, y);
      }
    }
  }

  setTooltip(position, data: any[] = []) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const __this = this;

    this.tooltip = d3
      .select(this._element.nativeElement)
      .selectAll('.item-tooltip')
      .data(data.length > 0 ? data : []);

    this.tooltip.exit().remove();

    this.tooltip.html(
      (d) => `
            <div class="question">${this.domain?.labels?.[d?.['data']?.['key']]}</div>
            <div class="stats">
              <span class="icon">contact</span> ${d?.['data']?.value} (${(d?.['data']?.percentage_all * 100).toFixed(
                1,
              )}%)
            </div>
          `,
    );

    this.tooltip
      .enter()
      .append('div')
      .attr('class', 'item-tooltip')
      .html(
        (d) => `
            <div class="question">${this.domain?.labels?.[d?.['data']?.['key']]}</div>
            <div class="stats">
              <span class="icon">contact</span> ${d?.['data']?.value} (${(d?.['data']?.percentage_all * 100).toFixed(
                1,
              )}%)
            </div>
          `,
      );

    // adding hovering effect
    this.base.select('.bubble-chart-canvas').each(function (d) {
      const highlight = data.map((item) => item?.data?.key);

      __this.setBubbles(__this.context.context, d, highlight);
    });

    // positioning tooltip
    d3.select(this._element.nativeElement)
      .selectAll('.item-tooltip')
      .style('transform', function (d, i, g: any) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const el: any = this;
        let sumHeight = 15;
        for (let e = 0, len = g.length; e < len; e++) {
          if (e <= i) {
            sumHeight += g[e].getBoundingClientRect().height + 10;
          }
        }
        return `translate(${position[0] - el.getBoundingClientRect().width / 2}px,${position[1] - sumHeight}px)`;
      });

    if (data.length > 0 && this.filtering && !this.anonymityLock) {
      this.base.select('.bubble-chart-canvas').style('cursor', 'pointer');
    } else {
      this.base.select('.bubble-chart-canvas').style('cursor', null);
    }
  }

  callFilter() {
    if (this.filtering && !(this.dataService.getTextFreezingStatus() && this.isSharedReport) && !this.anonymityLock) {
      this.filter = [];
      const filter = { key: this.domain.key, values: this.domain.keys, filter: Array.from(this.selections) };

      this.filter.push(filter);
      if (JSON.stringify(this.filter[0].filter) !== JSON.stringify(this.filterInput)) {
        this.dataService.filter(this.filter);
      }
    }
  }

  // Helpers
  selectForHover(event, area) {
    const items = this.itemsBelow(area);

    if (items.length > 0) {
      this.setTooltip(d3.pointer(event, this._element.nativeElement), items);
    } else {
      this.setTooltip(d3.pointer(event, this._element.nativeElement));
    }
  }

  selectFromBubbles(area) {
    const items = this.itemsBelow(area);

    for (let s = 0, len = items.length; s < len; s++) {
      const item = items[s]?.['data'] || {};
      if (this.selections.has(item.key)) {
        this.selections.delete(item.key);
        if (item.otherKeysIncluded?.length) {
          this.selections.delete(...item.otherKeysIncluded);
        }
      } else {
        this.selections.add(item.key);
        if (item.otherKeysIncluded?.length) {
          this.selections.add(...item.otherKeysIncluded);
        }
      }
    }
  }

  itemsBelow(area) {
    const items = this.cloud.filter(
      (item) =>
        Math.pow(area[0] - (this.margin.left + item.x), 2) + Math.pow(area[1] - (this.margin.top + item.y), 2) <
        Math.pow(item.r, 2),
    );

    return items;
  }
}
