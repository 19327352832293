import { NgModule } from '@angular/core';
import { AccountExist } from '@auth/account-exist/account-exist.component';
import { AuthSharedModule } from '@auth/auth-shared.module';
import { AuthSidenav } from '@auth/auth-sidenav.component';
import { CancelSignup } from '@auth/cancel-signup/cancel-signup.component';
import { CheckEmail } from '@auth/check-email/check-email.component';
import { EmailSignup } from '@auth/email-signup/email-signup.component';
import { EmailVerified } from '@auth/email-verified/email-verified.component';
import { GdprConsent } from '@auth/gdpr-consent/gdpr-consent.component';
import { GoogleSignup } from '@auth/google-signup/google-signup.component';
import { InviteAccept } from '@auth/invite-accept/invite-accept.component';
import { InviteError } from '@auth/invite-error/invite-error.component';
import { InviteFound } from '@auth/invite-found/invite-found.component';
import { ReportSignin } from '@auth/report-signin/report-signin.component';
import { SigninInvite } from '@auth/signin-invite/signin-invite.component';
import { SigninMethod } from '@auth/signin-method/signin-method.component';
import { SignupMethod } from '@auth/signup-method/signup-method.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [
    AuthSidenav,

    // signin
    SigninMethod,
    SigninInvite,
    ReportSignin,

    // invite
    InviteAccept,
    InviteError,
    InviteFound,

    // signup
    SignupMethod,
    GoogleSignup,
    GdprConsent,
    EmailSignup,
    EmailVerified,
    CheckEmail,
    CancelSignup,
    AccountExist,
  ],
  imports: [SharedModule, AuthSharedModule],
  exports: [AuthSidenav, SignupMethod],
  providers: [],
})
export class AuthSidenavModule {}
