<div class="menu-open">
  <button mat-icon-button color="secondary" [mat-menu-trigger-for]="workflowsMenu">
    <mat-icon>more</mat-icon>
  </button>

  <mat-menu
    #workflowsMenu
    class="zef-menu-combo menu-dropdown"
    x-position="before"
    y-position="below"
    (close)="close()"
  >
    <ng-container *ngIf="!isSubheader">
      <button mat-menu-item (click)="editChartMenu.emit(true)">
        <mat-icon>edit</mat-icon>
        <span i18n>Edit</span>
      </button>

      <button *ngIf="!isSharedReport && !isNotMergeable" mat-menu-item (click)="duplicateChart.emit(true)">
        <mat-icon>action_duplicate</mat-icon>
        <span i18n>Duplicate</span>
      </button>

      <mat-divider *ngIf="showRegenerate || !isSharedReport || (isSharedReport && showDataTableSwitch)"></mat-divider>
    </ng-container>

    <ng-container *ngIf="showRegenerate">
      <button mat-menu-item (click)="regenerateChart.emit(true)">
        <mat-icon>refresh_ai</mat-icon>
        <span i18n>Regenerate</span>
      </button>

      <mat-divider *ngIf="!isSharedReport || (isSharedReport && showDataTableSwitch)"></mat-divider>
    </ng-container>

    <ng-container *ngIf="!isSharedReport">
      <button *ngIf="!isSubheader && !isNotMergeable" mat-menu-item (click)="chartBuilder.emit(true)">
        <mat-icon>merge_branch</mat-icon>
        <span i18n>Merge chart...</span>
      </button>
      <button mat-menu-item (click)="hideChart.emit(true)">
        <mat-icon>visible_off</mat-icon>
        <span i18n>Hide</span>
      </button>

      <ng-container *ngIf="isCustomCard">
        <mat-divider></mat-divider>
        <button mat-menu-item class="zef-menu-item-alert" (click)="removeChart.emit(true)">
          <mat-icon>action_delete</mat-icon>
          <span>
            <ng-container i18n>Delete...</ng-container>
          </span>
        </button>
      </ng-container>
    </ng-container>

    <button
      *ngIf="isSharedReport && !isSubheader && showDataTableSwitch"
      mat-menu-item
      (click)="openDataTable.emit(true)"
    >
      <mat-icon>chart_table</mat-icon>
      <ng-container *ngIf="!isDataTableOpen; else openedDataTable">
        <span i18n>Show datatable</span>
      </ng-container>
      <ng-template #openedDataTable>
        <span i18n>Hide datatable</span>
      </ng-template>
    </button>
  </mat-menu>
</div>
