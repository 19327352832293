import { Injectable } from '@angular/core';
import { Charts } from '@shared/enums/charts.enum';
import { AnonymityStatus, ChartContent, DimensionDataItem } from '@shared/models/report.model';
import { DataConverter } from './data-converter.service';

/**
 * This is a anonymity checker service where one can check anonymity status for the charts.
 */
@Injectable({
  providedIn: 'root',
})
export class AnonymityChecker {
  constructor(private dc: DataConverter) {}
  public checkAnonymityStatus(
    chartContent: ChartContent,
    chartType: string,
    treshold: number = 0,
    wasAtRisk: boolean = false,
    hasFilters: boolean = false,
    filters: Record<string, { dimensionData: DimensionDataItem }> = {},
  ): AnonymityStatus {
    if (this.getChartSkipAnonymity(chartContent, filters, chartType, treshold)) {
      return {
        atRisk: false,
        hide: false,
        trend: false,
      };
    }

    const atRisk: boolean = !hasFilters ? this.checkAtRiskStatus(chartContent, chartType, treshold) : false;
    const trend: boolean =
      treshold &&
      chartContent?.distributions?.length > 1 &&
      this.checkTrendAnonymity(chartContent, chartType, treshold);
    const hide: boolean =
      ((this.checkAnonymity(chartContent, chartType, treshold) ||
        this.checkCrosstabAnonymity(chartContent, chartType, treshold)) &&
        chartType !== Charts.SUMMARY2D &&
        chartType !== Charts.SUMMARY1D &&
        chartType !== Charts.TRENDLINEAR1DCHART &&
        chartType !== Charts.TRENDLINEAR2DCHART) ||
      wasAtRisk ||
      trend;

    return { atRisk, hide, trend };
  }

  public checkAtRiskStatus(chartContent: ChartContent, chartType: string, treshold: number = 0): boolean {
    return (
      (treshold > 1 &&
        chartType !== Charts.WHYFINDERSUMMARY &&
        chartType !== Charts.SUMMARY2D &&
        chartType !== Charts.SUMMARY1D &&
        chartType !== Charts.NPS &&
        (chartContent?.distributions?.some((d) => d?.some((c) => c?.value && c?.value < treshold)) ||
          chartType === Charts.TEXTANSWERTABLE ||
          ['text', 'contact-text'].includes(chartContent?.domain?.[0]?.scale))) ||
      (chartType === Charts.NPS && chartContent?.distributions?.some((d) => this.npsDistributionCheck(d, treshold))) ||
      (chartType === Charts.WHYFINDERSUMMARY && this.checkWhyFinderAnonymity(chartContent, treshold))
    );
  }

  private checkAnonymity(chartContent: ChartContent, chartType: string, treshold: number = 0): boolean {
    /* We are not counting helper dimensions such as sentiment and emotions */
    const answers: number[] = chartContent?.totalAnswers?.filter(
      (count, i) =>
        !!count &&
        !(
          chartType === Charts.TEXTSENTIMENTANALYSIS &&
          chartContent?.details?.[i]?.originalTypeSpecifier === 'text-sentiment'
        ) &&
        !(
          chartType === Charts.WHYFINDERSUMMARY &&
          chartContent?.details?.[i]?.originalTypeSpecifier !== 'why-finder-themes'
        ) &&
        !(chartType === Charts.TEXTEMOTIONS && chartContent?.details?.[i]?.originalTypeSpecifier === 'text-emotions'),
    );

    return Math.min(...(answers || [])) < treshold;
  }

  private checkCrosstabAnonymity(chartContent: ChartContent, chartType: string, treshold: number = 0): boolean {
    if (
      ((Charts.CROSSTABCHARTS.includes(chartType) ||
        chartType === Charts.TIMELINECHART ||
        chartType === Charts.RADARCHART) &&
        chartContent?.domain?.length === 2 &&
        !!chartContent?.domain?.find((d) => d?.scale === 'categorical')) ||
      (chartType === Charts.HEATMAP &&
        chartContent?.domain?.length === 3 &&
        !!chartContent?.domain?.find((d) => d?.scale === 'categorical')) ||
      (chartType === Charts.SUMMARYCHOICE && chartContent.comparison?.values?.length)
    ) {
      for (let d = 0, lend = chartContent?.domain?.length; d < lend; d++) {
        const distr = chartContent.distributions?.[d] || [];

        for (let i = 0, len = distr.length; i < len; i++) {
          const val = distr[i]?.['value'];

          if (val && val < treshold) {
            return true;
          }
        }
      }
    }

    return false;
  }

  private checkTrendAnonymity(chartContent: ChartContent, chartType: string, treshold: number = 0): boolean {
    const comparisonIndex: number = chartContent.comparison?.index;
    const trendIndex: number = chartContent.trend?.index;
    const c: string = 'children';
    const trendDimIndex: number = chartContent.domain?.[trendIndex]?.index;
    const compDimIndex: number = chartContent.domain?.[comparisonIndex]?.index;
    const inclDims: number[] = chartContent.domain
      ?.map((d) => d.index)
      .filter((d) => d !== trendDimIndex && d !== compDimIndex);

    if (trendIndex == null) {
      return false;
    }

    if (comparisonIndex != null) {
      if (chartType === Charts.TRENDLINEAR2DCHART || chartType === Charts.TRENDLINEAR1DCHART) {
        const s = chartContent.stats?.[comparisonIndex]?.[c];

        for (let i = 0, len = s?.length; i < len; i++) {
          const st = s[i]?.[c]?.[trendDimIndex]?.[c];

          for (let ci = 0, lenci = st?.length; ci < lenci; ci++) {
            const stc = st[ci]?.[c];

            for (let d = 0, lend = inclDims.length; d < lend; d++) {
              const idx = inclDims[d];

              if (stc?.[idx]?.count && stc?.[idx]?.count < treshold) {
                return true;
              }
            }
          }
        }
      } else if (chartType === Charts.TRENDCATEGORICALCHART) {
        const distrC = chartContent.distributions?.[comparisonIndex] || [];

        for (let i = 0, len = distrC.length; i < len; i++) {
          for (let o = 0, leno = distrC[i]?.['children']?.length; o < leno; o++) {
            const childCount = distrC[i]['children'][o]?.['children']?.reduce((a, b) => a + b.value, 0);

            if (childCount && childCount < treshold) {
              return true;
            }
          }
        }
      } else if (chartType === Charts.TRENDNPSCHART) {
        const distrC = chartContent.distributions?.[comparisonIndex] || [];

        for (let i = 0, len = distrC.length; i < len; i++) {
          for (let o = 0, leno = distrC[i]?.['children']?.length; o < leno; o++) {
            const npsDistrC = distrC[i]['children'][o]?.['children'];

            if (this.npsDistributionCheck(npsDistrC, treshold)) {
              return true;
            }
          }
        }
      }
    } else if (
      (inclDims.length > 1 && chartType === Charts.TRENDLINEAR2DCHART) ||
      chartType === Charts.TRENDLINEAR1DCHART ||
      chartType === Charts.TRENDNPSCHART
    ) {
      const stats = chartContent.stats?.[trendIndex]?.[c];

      for (let s = 0, lens = stats?.length; s < lens; s++) {
        const stc = stats[s]?.[c];

        for (let d = 0, lend = inclDims.length; d < lend; d++) {
          const idx = inclDims[d];

          if (stc?.[idx]?.count && stc?.[idx]?.count < treshold) {
            return true;
          }
        }
      }
    } else {
      const distr = chartContent.distributions?.[trendIndex] || [];
      for (let i = 0, len = distr.length; i < len; i++) {
        const childCount = distr[i]?.['children']?.reduce((a, b) => a + b.value, 0);

        if (childCount && childCount < treshold) {
          return true;
        }
      }
    }

    return false;
  }

  private npsDistributionCheck(distr, treshold): boolean {
    const npsCalc = distr ? this.dc.calculateNPS(distr) : null;
    const vals = npsCalc?.[0]?.['distribution']?.map((d) => d?.value)?.filter((d) => d) || [];

    return !!vals.length && Math.min(...vals) < treshold;
  }

  private checkWhyFinderAnonymity(chartContent: ChartContent, treshold: number): boolean {
    const themesIndex = chartContent?.details?.findIndex((d) => d?.originalTypeSpecifier === 'why-finder-themes');
    const distr = chartContent?.distributions?.[themesIndex] || [];
    const distrValues = distr.map((d) => d?.value).filter((d) => d);

    return !!distrValues.length && Math.min(...distrValues) < treshold;
  }

  public getChartSkipAnonymity(chartContent: ChartContent, filters: any, chartType: string, treshold: number): boolean {
    return (
      !this.checkAnonymity(chartContent, chartType, treshold) &&
      !this.hasSensitiveFilters(filters) &&
      !this.hasSensitiveComparison(chartContent?.comparison, chartContent?.details) &&
      !!chartContent.details
        ?.filter(
          (d) =>
            (!chartContent.trend || chartContent.trend?.key !== d.key) &&
            (!chartContent.comparison || chartContent.comparison?.key !== d.key),
        )
        ?.every((d) => d.skipAnonymity)
    );
  }

  // Helpers
  public hasSensitiveFilters(filters: any = {}): boolean {
    return Object.values(filters).some((value: any) => {
      return value?.dimensionData?.extraSensitiveAnonymity;
    });
  }

  public hasSensitiveComparison(comparison: any, details: DimensionDataItem[]): boolean {
    return (
      comparison &&
      comparison.values
        .map((v) => details?.[comparison.index]?.values?.[v])
        .map((v) => details?.[comparison.index]?.customOrigins?.[v]?.filters || {})
        .map((v) => Object.values(v).some((value: any) => value?.dimensionData?.extraSensitiveAnonymity))
        .some((v) => v)
    );
  }
}
