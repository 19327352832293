import { BehaviorSubject, ReplaySubject } from 'rxjs';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import { ChartDomain, DimensionDataItem } from '@shared/models/report.model';
import { Rights } from '@shared/enums/rights.enum';

import { Crossfilter } from '@report/shared/services/crossfilter.service';

/**
 * This is a response rate chart.
 */
@Component({
  selector: 'response-rates',
  templateUrl: './response-rates.component.html',
  styleUrls: ['./response-rates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResponseRates implements OnInit, OnChanges, OnDestroy {
  @Input() details: DimensionDataItem[] = [];
  @Input() domain: ChartDomain[] = [];
  @Input() crossfilter: Crossfilter | null = null;
  @Input() size: string = '0px';
  @Input() update: Date = new Date();

  @Output() settingsChange: EventEmitter<any> = new EventEmitter<any>();

  readonly Rights = Rights;

  public responseRates$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public responseRateIndex: number;
  public steps: string[] = ['n', 'clicked', 'answered'];
  public stepLabels: { [stepKey: string]: string } = {
    n: $localize`Total`,
    clicked: $localize`Clicked`,
    answered: $localize`Answered`,
  };

  private dataService: Crossfilter | null = this.cf;
  private responseRatesSub: any;
  private onChanges = new ReplaySubject<SimpleChanges>(1);

  constructor(private cf: Crossfilter) {}

  ngOnInit(): void {
    this.onChanges.subscribe((changes: SimpleChanges) => {
      if (changes?.crossfilter || changes?.details || changes?.domain) {
        this.setData();
      }
    });
  }

  private setData(): void {
    if (this.crossfilter) {
      this.dataService = this.crossfilter;
    } else {
      this.dataService = this.cf;
    }

    this.responseRateIndex = this.details.findIndex((detail) => detail.key === 'responseRates');

    if (this.responseRatesSub) {
      this.responseRatesSub.unsubscribe();
    }

    this.responseRatesSub = this.dataService.getResponseRates().subscribe((responseRates) => {
      const handledData = [
        ...responseRates
          .filter((item) => item?.n > 0)
          .map((item) => ({
            ...item,
            title: this.domain?.[this.responseRateIndex]?.labels?.[item?.key],
          }))
          .sort((a, b) => b?.n - a?.n),
      ];

      this.responseRates$.next(handledData?.length ? handledData : null);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.onChanges.next(changes);
  }

  ngOnDestroy() {
    this.onChanges.complete();
    this.responseRatesSub.unsubscribe();
  }
}
