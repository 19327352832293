<div *ngIf="!mergeDone" class="z-fx-col z-fx-auto content">
  <div class="z-fx-col z-fx-none header-card">
    <div class="z-fx z-fx-between-center">
      <div class="title" i18n>Merge surveys</div>
      <div class="z-fx z-fx-end-center actions">
        <button
          mat-raised-button
          color="primary"
          class="ok-button"
          [disabled]="selectedSurvey == null || !surveySelectionDone"
          (click)="doMerge()"
        >
          <div class="z-fx z-fx-none z-fx-center-center">
            <mat-icon class="icon close">merge</mat-icon>
            <div class="button-text" i18n>Merge</div>
          </div>
        </button>
        <button
          mat-raised-button
          color="secondary"
          class="z-fx z-fx-start-center close-button"
          (click)="closePanel.emit(true)"
        >
          <div class="z-fx z-fx-none z-fx-center-center">
            <mat-icon class="icon close">close</mat-icon>
          </div>
        </button>
      </div>
    </div>
  </div>

  <div class="z-fx z-fx-none z-fx-between-center selection-area">
    <div *ngIf="!selectedSurvey" class="find-card">
      <mat-form-field class="search-input">
        <mat-icon matPrefix class="search-icon">action_find</mat-icon>
        <input
          matInput
          placeholder="Find survey to merge..."
          i18n-placeholder="placeholder|link contacts search"
          [value]="searchTerm"
          (input)="searchTerm = $event.target.value"
          (keyup)="searchUpdate.next($event?.target?.value)"
        />
      </mat-form-field>
    </div>
    <div *ngIf="selectedSurvey" class="z-fx survey-card" [style.box-shadow]="'none'">
      <div class="thumb" [style.margin-left]="'0px'">
        <div
          *ngIf="selectedSurvey.design | async as design"
          class="survey-image"
          [style.background-image]="design.background.url | encodeURI: 'url' | safeStyle"
        ></div>
      </div>
      <div *ngIf="selectedSurvey.survey | async as ss" class="z-fx-col z-fx-auto texts">
        <div class="survey-name">{{ ss?.name | shorten: 25 : '...' }}</div>
        <div class="question-amount" i18n>
          {{ selectedSurveyUsage != null ? selectedSurveyUsage : '-' }} Respondents
        </div>
      </div>
      <div class="actions">
        <button
          mat-raised-button
          color="secondary"
          class="z-fx z-fx-start-center close-button"
          (click)="unselectSurvey()"
        >
          <div class="z-fx-none z-fx-center-center">
            <mat-icon class="icon close">close</mat-icon>
          </div>
        </button>
      </div>
    </div>

    <div class="separator">
      <mat-icon class="icon close">merge</mat-icon>
    </div>
    <div *ngIf="survey" class="z-fx survey-card" [style.box-shadow]="'none'">
      <div class="thumb" [style.margin-left]="'0px'">
        <div
          *ngIf="survey.design | async as design"
          class="survey-image"
          [style.background-image]="design.background.url | encodeURI: 'url' | safeStyle"
        ></div>
      </div>
      <div *ngIf="survey.survey | async as ss" class="z-fx-col z-fx-auto texts">
        <div class="survey-name">{{ ss?.name | shorten: 25 : '...' }}</div>
        <div class="question-amount" i18n>{{ activeSurveyUsage != null ? activeSurveyUsage : '-' }} Respondents</div>
      </div>
    </div>
  </div>

  <div *ngIf="!selectedSurvey" class="z-fx-col z-fx-auto surveys-list">
    <div class="subtitle" i18n>Pick a survey...</div>
    <mat-progress-bar
      *ngIf="loading$ | async"
      mode="indeterminate"
      color="primary"
      class="loading-bar"
    ></mat-progress-bar>
    <ng-scrollbar class="z-fx-col z-fx-flex">
      <div class="z-fx-col z-fx-auto content">
        <mat-grid-list [cols]="2" rowHeight="74px" gutterSize="16px">
          <mat-grid-tile
            *ngFor="let survey of surveys | async; trackBy: trackByFn"
            class="z-fx z-fx-none survey-card clickable"
            [colspan]="1"
            [rowspan]="1"
            (click)="selectSurvey(survey)"
          >
            <div class="thumb">
              <div
                *ngIf="survey?.background"
                class="survey-image"
                [style.background-image]="survey.background | encodeURI: 'url' | safeStyle"
              ></div>
            </div>
            <div class="z-fx-col z-fx-auto texts">
              <div class="survey-name">{{ survey?.surveyName | shorten: 35 : '...' }}</div>
              <div class="question-amount" i18n>{{ survey?.respondents }} Respondents</div>
            </div>
            <div *ngIf="alreadyMergedSurveys?.includes(survey?.$key)" class="already-merged">
              <mat-icon class="check-icon" matTooltip="Already merged" i18n-matTooltip>bullet_check</mat-icon>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </ng-scrollbar>
  </div>

  <div *ngIf="selectedSurvey" class="z-fx-col z-fx-auto question-mapping">
    <ng-scrollbar class="z-fx-col z-fx-flex">
      <div class="z-fx-col z-fx-auto content">
        <div class="z-fx-col z-fx-center-start matched-questions">
          <div class="z-fx z-fx-start-center settings-item">
            <div class="status-icon">
              <mat-icon class="check-icon" [class.disabled]="!matchedQuestions || !matchedQuestions.length">
                {{ matchedQuestions?.length ? 'bullet_check' : 'bullet_no' }}
              </mat-icon>
            </div>
            <div class="z-fx-auto texts">
              <div class="header" i18n>{{ matchedQuestions?.length }} matched questions</div>
              <div class="description" i18n>
                Questions with identical Title, Type and Labels will be merged into existing charts.
              </div>
            </div>
            <div *ngIf="matchedQuestions && matchedQuestions.length" class="toggle">
              <mat-icon class="icon clickable" (click)="showMatchedQuestions = !showMatchedQuestions">
                {{ !showMatchedQuestions ? 'pointer_down' : 'pointer_up' }}
              </mat-icon>
            </div>
          </div>
          <div *ngIf="matchedQuestions && matchedQuestions.length && showMatchedQuestions" class="question-list">
            <div class="z-fx z-fx-start-center select-all-container">
              <mat-checkbox
                class="z-fx-none selection-checkbox"
                i18n="checkbox|select all questions to be merged"
                color="primary"
                [ngModel]="selectedAllMatchedQuestions"
                (ngModelChange)="selectAllQuestions('matched', $event)"
              ></mat-checkbox>
              <div class="select-all-text" i18n>Select all</div>
            </div>
            <div *ngFor="let question of matchedQuestions; let i = index" class="z-fx-col question-item-container">
              <div class="z-fx z-fx-start-center question-item">
                <mat-checkbox
                  class="z-fx-none selection-checkbox"
                  i18n="checkbox|select question to be merged"
                  color="primary"
                  [ngModel]="selectedQuestions[question['$key']]"
                  (ngModelChange)="selectQuestion(question['$key'], $event)"
                ></mat-checkbox>
                <div class="z-fx z-fx-none z-fx-center-center {{ 'question-icon ' + iconColors[question.type] }}">
                  <mat-icon>{{ icons[question.type] || 'question_unknown' }}</mat-icon>
                </div>
                <div class="z-fx-auto question-title">{{ question.title | shorten: 80 : '...' }}</div>
                <mat-icon class="z-fx-none more-icon clickable" (click)="editMatchedItem(question)">more</mat-icon>
              </div>
              <div *ngIf="openManualMergeItem === question['$key']" class="z-fx-col map-question">
                <div class="z-fx z-fx-between-center header">
                  <div class="title" i18n>Merge into...</div>
                  <div class="unmatch clickable" (click)="clearMapping(question)" i18n>Unmatch</div>
                </div>
                <button
                  mat-raised-button
                  color="secondary"
                  class="z-fx z-fx-between-center dropdown"
                  [mat-menu-trigger-for]="dropMenu"
                >
                  <div class="z-fx z-fx-auto text" [ngSwitch]="selectedQuestionManualMerge">
                    <div
                      *ngIf="selectedQuestionManualMerge"
                      class="z-fx z-fx-none z-fx-center-center {{
                        'question-icon ' + iconColors[selectedQuestionManualMerge.type]
                      }}"
                    >
                      <mat-icon>{{ icons[selectedQuestionManualMerge.type] || 'question_unknown' }}</mat-icon>
                    </div>
                    <div class="z-fx-sc z-fx-auto question-title">
                      <span *ngIf="selectedQuestionManualMerge">
                        {{ selectedQuestionManualMerge.title | shorten: 80 : '...' }}
                      </span>
                      <span *ngIf="!selectedQuestionManualMerge" i18n>Select question...</span>
                    </div>
                  </div>
                  <div class="z-arrow-container">
                    <mat-icon class="z-arrow-icon">arrow_down</mat-icon>
                  </div>
                  <mat-menu #dropMenu class="merge-drop-menu" x-position="before" y-position="below">
                    <button
                      *ngFor="let q of filterQuestions(survey.questions, question) | async"
                      mat-menu-item
                      class="z-fx z-fx-between-center menu-option"
                      [class.z-selected]="
                        selectedQuestionManualMerge && q['$key'] === selectedQuestionManualMerge['$key']
                      "
                      [disabled]="checkIfMapped(q)"
                      [style.width]="menuWidth"
                      (click)="selectedQuestionManualMerge = q"
                    >
                      <div class="z-fx z-fx-auto text" [class.alreadyUsed]="checkIfMapped(q)" [ngSwitch]="option">
                        <div class="z-fx z-fx-none z-fx-center-center {{ 'question-icon ' + iconColors[q.type] }}">
                          <mat-icon>{{ icons[q.type] || 'question_unknown' }}</mat-icon>
                        </div>
                        <div class="z-fx-auto question-title" [class.alreadyUsed]="checkIfMapped(q)">
                          {{ q.title | shorten: 80 : '...' }}
                        </div>
                      </div>
                      <div
                        *ngIf="checkIfMapped(q)"
                        class="z-fx-none used-checkmark-icon"
                        matTooltip="Already matched"
                        matTooltipPosition="above"
                        i18n-matTooltip
                      >
                        <mat-icon>bullet_check</mat-icon>
                      </div>
                    </button>
                  </mat-menu>
                </button>
                <div
                  *ngIf="
                    selectedQuestionManualMerge &&
                    (question.type.indexOf('choice') >= 0 || question.type.indexOf('dropdown'))
                  "
                  class="z-fx-col z-fx-gap-8 choice-mapping"
                >
                  <div *ngFor="let choice of question.choiceList" class="z-fx z-fx-start-center choice-item">
                    <div class="text">{{ choice.content | shorten: 35 : '...' }}</div>
                    <button
                      mat-raised-button
                      color="secondary"
                      class="z-fx z-fx-between-center dropdown"
                      [mat-menu-trigger-for]="dropMenuChoice"
                    >
                      <div
                        class="z-fx z-fx-auto z-fx-start-center text"
                        [ngSwitch]="selectedQuestionManualMergeChoice[choice['$key']]"
                      >
                        <mat-icon>
                          {{
                            selectedQuestionManualMergeChoice[choice['$key']]
                              ? !selectedQuestionManualMergeChoice[choice['$key']]['manualMerge']
                                ? 'check'
                                : 'plus'
                              : 'question_unknown'
                          }}
                        </mat-icon>
                        <div class="z-fx-auto question-title">
                          <span *ngIf="selectedQuestionManualMergeChoice[choice['$key']]">
                            {{ selectedQuestionManualMergeChoice[choice['$key']].content | shorten: 35 : '...' }}
                          </span>
                          <span *ngIf="!selectedQuestionManualMergeChoice[choice['$key']]" i18n>Select choice...</span>
                        </div>
                      </div>
                      <div class="z-arrow-container">
                        <mat-icon class="z-arrow-icon">arrow_down</mat-icon>
                      </div>
                      <mat-menu #dropMenuChoice class="merge-drop-menu-half" x-position="before" y-position="below">
                        <button
                          *ngFor="let c of selectedQuestionManualMerge.choiceList"
                          mat-menu-item
                          class="z-fx menu-option"
                          [class.z-selected]="
                            selectedQuestionManualMergeChoice[choice['$key']] &&
                            c['$key'] === selectedQuestionManualMergeChoice[choice['$key']]
                          "
                          [style.width]="menuWidth"
                          (click)="selectedQuestionManualMergeChoice[choice['$key']] = c"
                        >
                          <div class="z-fx z-fx-auto z-fx-start-center text" [ngSwitch]="option">
                            <mat-icon>check</mat-icon>
                            <div class="z-fx-auto question-title">{{ c.content | shorten: 80 : '...' }}</div>
                          </div>
                        </button>
                        <button
                          mat-menu-item
                          class="z-fx menu-option"
                          [class.z-selected]="
                            selectedQuestionManualMergeChoice[choice['$key']] &&
                            selectedQuestionManualMergeNewChoice['$key'] ===
                              selectedQuestionManualMergeChoice[choice['$key']]
                          "
                          [style.width]="menuWidth"
                          (click)="
                            selectedQuestionManualMergeChoice[choice['$key']] = selectedQuestionManualMergeNewChoice
                          "
                        >
                          <div class="z-fx z-fx-auto z-fx-start-center text" [ngSwitch]="option">
                            <mat-icon>plus</mat-icon>
                            <div class="z-fx-auto question-title" i18n>Add as new item</div>
                          </div>
                        </button>
                      </mat-menu>
                    </button>
                  </div>
                </div>
                <div class="z-fx z-fx-end-center z-fx-gap-8 action-buttons">
                  <button
                    mat-raised-button
                    color="secondary"
                    class="cancel-button"
                    i18n
                    (click)="mapQuestion(question, true)"
                  >
                    Cancel
                  </button>
                  <button mat-raised-button color="primary" class="ok-button" i18n (click)="mapQuestion(question)">
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="z-fx-col z-fx-center-start new-questions">
          <div class="z-fx z-fx-start-center settings-item">
            <div class="status-icon">
              <mat-icon class="check-icon" [class.disabled]="!newQuestions || !newQuestions.length">
                {{ newQuestions?.length ? 'bullet_check' : 'bullet_no' }}
              </mat-icon>
            </div>
            <div class="z-fx-auto texts">
              <div class="header" i18n>{{ newQuestions?.length }} new questions</div>
              <div class="description" i18n>
                Questions with dissimilar Title, Type or Labels will be imported as new charts.
              </div>
            </div>
            <div *ngIf="newQuestions && newQuestions.length" class="toggle">
              <mat-icon class="icon clickable" (click)="showNewQuestions = !showNewQuestions">
                {{ !showNewQuestions ? 'pointer_down' : 'pointer_up' }}
              </mat-icon>
            </div>
          </div>
          <div *ngIf="newQuestions && newQuestions.length && showNewQuestions" class="z-fx-col question-list">
            <div class="z-fx z-fx-start-center select-all-container">
              <mat-checkbox
                class="z-fx-none selection-checkbox"
                i18n="checkbox|select all questions to be merged"
                color="primary"
                [ngModel]="selectedAllNewQuestions"
                (ngModelChange)="selectAllQuestions('new', $event)"
              ></mat-checkbox>
              <div class="select-all-text" i18n>Select all</div>
            </div>
            <div *ngFor="let question of newQuestions; let i = index" class="z-fx-col question-item-container">
              <div class="z-fx z-fx-start-center question-item">
                <mat-checkbox
                  class="z-fx-none selection-checkbox"
                  i18n="checkbox|select question to be merged"
                  color="primary"
                  [ngModel]="selectedQuestions[question['$key']]"
                  (ngModelChange)="selectQuestion(question['$key'], $event)"
                ></mat-checkbox>
                <div class="z-fx z-fx-none z-fx-center-center {{ 'question-icon ' + iconColors[question.type] }}">
                  <mat-icon>{{ icons[question.type] || 'question_unknown' }}</mat-icon>
                </div>
                <div class="z-fx-auto question-title">{{ question.title | shorten: 80 : '...' }}</div>
                <mat-icon class="z-fx-none more-icon clickable" (click)="openManualMergeItem = question['$key']">
                  more
                </mat-icon>
              </div>
              <div *ngIf="openManualMergeItem === question['$key']" class="z-fx-col map-question">
                <div class="header" i18n>Merge into...</div>
                <button
                  mat-raised-button
                  color="secondary"
                  class="z-fx z-fx-between-center dropdown"
                  [mat-menu-trigger-for]="dropMenu"
                >
                  <div class="z-fx z-fx-auto text" [ngSwitch]="selectedQuestionManualMerge">
                    <div
                      *ngIf="selectedQuestionManualMerge"
                      class="z-fx z-fx-none z-fx-center-center {{
                        'question-icon ' + iconColors[selectedQuestionManualMerge.type]
                      }}"
                    >
                      <mat-icon>{{ icons[selectedQuestionManualMerge.type] || 'question_unknown' }}</mat-icon>
                    </div>
                    <div class="z-fx-sc z-fx-auto question-title">
                      <span *ngIf="selectedQuestionManualMerge">
                        {{ selectedQuestionManualMerge.title | shorten: 80 : '...' }}
                      </span>
                      <span *ngIf="!selectedQuestionManualMerge" i18n>Select question...</span>
                    </div>
                  </div>
                  <div class="z-arrow-container">
                    <mat-icon class="z-arrow-icon">arrow_down</mat-icon>
                  </div>
                  <mat-menu #dropMenu class="merge-drop-menu" x-position="before" y-position="below">
                    <ng-container *ngIf="filterQuestions(survey.questions, question) | async; let filteredQuestions">
                      <ng-container *ngIf="filteredQuestions.length > 0; else noQuestions">
                        <button
                          *ngFor="let q of filteredQuestions"
                          mat-menu-item
                          class="z-fx z-fx-between-center menu-option"
                          [class.z-selected]="
                            selectedQuestionManualMerge && q['$key'] === selectedQuestionManualMerge['$key']
                          "
                          [disabled]="checkIfMapped(q)"
                          [style.width]="menuWidth"
                          (click)="selectedQuestionManualMerge = q"
                        >
                          <div class="z-fx z-fx-auto text" [ngSwitch]="option">
                            <div
                              class="z-fx z-fx-none z-fx-center-center {{ 'question-icon ' + iconColors[q.type] }}"
                              [class.alreadyUsed]="checkIfMapped(q)"
                            >
                              <mat-icon>{{ icons[q.type] || 'question_unknown' }}</mat-icon>
                            </div>
                            <div class="z-fx-auto question-title" [class.alreadyUsed]="checkIfMapped(q)">
                              {{ q.title | shorten: 80 : '...' }}
                            </div>
                          </div>
                          <div
                            *ngIf="checkIfMapped(q)"
                            class="z-fx-none used-checkmark-icon"
                            matTooltip="Already matched"
                            matTooltipPosition="above"
                            i18n-matTooltip
                          >
                            <mat-icon>bullet_check</mat-icon>
                          </div>
                        </button>
                      </ng-container>
                      <ng-template #noQuestions>
                        <button mat-menu-item class="z-fx z-fx-between-center menu-option" [style.width]="menuWidth">
                          <div class="z-fx z-fx-auto text" [ngSwitch]="option">
                            <div class="z-fx-auto question-title" i18n>No matchable questions found...</div>
                          </div>
                        </button>
                      </ng-template>
                    </ng-container>
                  </mat-menu>
                </button>
                <div
                  *ngIf="
                    selectedQuestionManualMerge &&
                    (question.type.indexOf('choice') >= 0 || question.type.indexOf('dropdown'))
                  "
                  class="z-fx-col z-fx-gap-8 choice-mapping"
                >
                  <div *ngFor="let choice of question.choiceList" class="z-fx z-fx-start-center choice-item">
                    <div class="text">{{ choice.content | shorten: 35 : '...' }}</div>
                    <button
                      mat-raised-button
                      color="secondary"
                      class="z-fx z-fx-between-center dropdown"
                      [mat-menu-trigger-for]="dropMenuChoice"
                    >
                      <div
                        class="z-fx z-fx-auto z-fx-start-center text"
                        [ngSwitch]="selectedQuestionManualMergeChoice[choice['$key']]"
                      >
                        <mat-icon>
                          {{
                            selectedQuestionManualMergeChoice[choice['$key']]
                              ? !selectedQuestionManualMergeChoice[choice['$key']]['manualMerge']
                                ? 'check'
                                : 'plus'
                              : 'question_unknown'
                          }}
                        </mat-icon>
                        <div class="z-fx-auto question-title">
                          <span *ngIf="selectedQuestionManualMergeChoice[choice['$key']]">
                            {{ selectedQuestionManualMergeChoice[choice['$key']].content | shorten: 35 : '...' }}
                          </span>
                          <span *ngIf="!selectedQuestionManualMergeChoice[choice['$key']]" i18n>Select choice...</span>
                        </div>
                      </div>
                      <div class="z-arrow-container">
                        <mat-icon class="z-arrow-icon">arrow_down</mat-icon>
                      </div>
                      <mat-menu #dropMenuChoice class="merge-drop-menu-half" x-position="before" y-position="below">
                        <button
                          *ngFor="let c of selectedQuestionManualMerge.choiceList"
                          mat-menu-item
                          class="z-fx menu-option"
                          [class.z-selected]="
                            selectedQuestionManualMergeChoice[choice['$key']] &&
                            c['$key'] === selectedQuestionManualMergeChoice[choice['$key']]
                          "
                          [style.width]="menuWidth"
                          (click)="selectedQuestionManualMergeChoice[choice['$key']] = c"
                        >
                          <div class="z-fx z-fx-auto z-fx-start-center text" [ngSwitch]="option">
                            <mat-icon>check</mat-icon>
                            <div class="z-fx-auto question-title">{{ c.content | shorten: 80 : '...' }}</div>
                          </div>
                        </button>
                        <button
                          mat-menu-item
                          class="z-fx menu-option"
                          [class.z-selected]="
                            selectedQuestionManualMergeChoice[choice['$key']] &&
                            selectedQuestionManualMergeNewChoice['$key'] ===
                              selectedQuestionManualMergeChoice[choice['$key']]
                          "
                          [style.width]="menuWidth"
                          (click)="
                            selectedQuestionManualMergeChoice[choice['$key']] = selectedQuestionManualMergeNewChoice
                          "
                        >
                          <div class="z-fx z-fx-auto z-fx-start-center text" [ngSwitch]="option">
                            <mat-icon>plus</mat-icon>
                            <div class="z-fx-auto question-title" i18n>Add as new item</div>
                          </div>
                        </button>
                      </mat-menu>
                    </button>
                  </div>
                </div>
                <div class="z-fx z-fx-end-center z-fx-gap-8 action-buttons">
                  <button
                    mat-raised-button
                    color="secondary"
                    class="cancel-button"
                    i18n
                    (click)="mapQuestion(question, true)"
                  >
                    Cancel
                  </button>
                  <button
                    mat-raised-button
                    color="primary"
                    class="ok-button"
                    i18n
                    [disabled]="!selectedQuestionManualMerge"
                    (click)="mapQuestion(question)"
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="
            mappings &&
            mappings['outcomes'] &&
            mappings['outcomes']['outcomes'] &&
            mappings['outcomes']['outcomes'].length
          "
          class="z-fx-col z-fx-center-start outcomes"
        >
          <div class="z-fx z-fx-start-center settings-item">
            <div class="status-icon">
              <mat-icon class="check-icon">bullet_check</mat-icon>
            </div>
            <div class="z-fx-auto texts">
              <div class="header" i18n>Outcomes</div>
              <div class="description" i18n>
                Outcomes with identical Title will be merged into existing Outcome chart.
              </div>
            </div>
            <div class="toggle">
              <mat-icon class="icon clickable" (click)="showOutcomes = !showOutcomes">
                {{ !showOutcomes ? 'pointer_down' : 'pointer_up' }}
              </mat-icon>
            </div>
          </div>
          <div *ngIf="showOutcomes" class="question-list">
            <div class="z-fx-col z-fx-gap-8 outcome-mapping">
              <div *ngFor="let outcome of mappings['outcomes']['outcomes']" class="z-fx z-fx-start-center outcome-item">
                <div class="text">{{ outcome.orig.title | shorten: 35 : '...' }}</div>
                <button
                  mat-raised-button
                  color="secondary"
                  class="z-fx z-fx-between-center dropdown"
                  [mat-menu-trigger-for]="dropMenuOutcome"
                >
                  <div class="z-fx z-fx-auto z-fx-start-center text" [ngSwitch]="outcome.mappedTo['$key']">
                    <mat-icon>
                      {{
                        outcome && outcome.mappedTo
                          ? !outcome.mappedTo.manualMerge
                            ? 'check'
                            : 'plus'
                          : 'question_unknown'
                      }}
                    </mat-icon>
                    <div class="z-fx-auto question-title">
                      <span *ngIf="outcome && outcome.mappedTo">
                        {{ outcome.mappedTo.title | shorten: 35 : '...' }}
                      </span>
                      <span *ngIf="!(outcome && outcome.mappedTo)" i18n>Select outcome...</span>
                    </div>
                  </div>
                  <div class="z-arrow-container">
                    <mat-icon class="z-arrow-icon">arrow_down</mat-icon>
                  </div>
                  <mat-menu #dropMenuOutcome class="merge-drop-menu-outcomes" x-position="before" y-position="below">
                    <button
                      *ngFor="let c of activeSurveyOutcomes"
                      mat-menu-item
                      class="z-fx menu-option"
                      [class.z-selected]="
                        outcome.mappedTo && outcome.mappedTo['$key'] && c['$key'] === outcome.mappedTo['$key']
                      "
                      [style.width]="menuWidth"
                      (click)="outcome.mappedTo = c"
                    >
                      <div class="z-fx z-fx-auto z-fx-start-center text" [ngSwitch]="option">
                        <mat-icon>check</mat-icon>
                        <div class="z-fx-auto question-title">{{ c.title | shorten: 80 : '...' }}</div>
                      </div>
                    </button>
                    <button
                      mat-menu-item
                      class="z-fx menu-option"
                      [class.z-selected]="
                        outcome &&
                        outcome.mappedTo &&
                        outcome.mappedTo['$key'] &&
                        outcomesManualMergeNewOutcome['$key'] === outcome.mappedTo['$key']
                      "
                      [style.width]="menuWidth"
                      (click)="outcome.mappedTo = outcomesManualMergeNewOutcome"
                    >
                      <div class="z-fx z-fx-auto z-fx-start-center text" [ngSwitch]="option">
                        <mat-icon>plus</mat-icon>
                        <div class="z-fx-auto question-title" i18n>Add as new item</div>
                      </div>
                    </button>
                  </mat-menu>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-scrollbar>
  </div>
</div>

<div *ngIf="mergeDone" class="merge-done">
  <zef-success-box [darkMode]="true">
    <div class="merge-success">
      <div class="merge-success-title">
        <mat-icon class="zef-icon-large">bullet_check</mat-icon>

        <div class="zef-text-d3" i18n>Survey successfully merged</div>
      </div>

      <div class="merge-success-sub-title zef-text-p1" i18n>
        {{ (selectedSurvey.survey | async)?.name }} was merged into {{ (survey.survey | async)?.name }}.
      </div>

      <div class="merge-success-buttons">
        <button mat-raised-button color="secondary" (click)="closePanel.emit(true)" i18n>Done</button>
      </div>
    </div>
  </zef-success-box>
</div>
