import {
  Component,
  OnChanges,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  ElementRef,
} from '@angular/core';

import { Charts } from '@shared/enums/charts.enum';

import { Crossfilter } from '@report/shared/services/crossfilter.service';

import { AnonymityStatus, ChartContent, ChartSettings, ChartSizeInput } from '@shared/models/report.model';
import { HelpSubject } from '@shared/modules/help-center/help-subject.enum';

/**
 * This is a chart component which draws chart.
 */
/* eslint-disable @angular-eslint/component-selector */
@Component({
  selector: 'chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Chart implements OnChanges {
  @Input() content: ChartContent | null = null;
  @Input() settings: ChartSettings = {} as ChartSettings;
  @Input() transition: number = 0;
  @Input() filtering: boolean = true;
  @Input() update: Date = new Date();
  @Input() size: ChartSizeInput = { width: 0, height: 0 };
  @Input() crossfilter: Crossfilter | null = null;
  @Input() touchDevice: boolean = false;
  @Input() filtersDemo: boolean = false;
  @Input() trendHoverInput: string = '';
  @Input() isSharedReport: boolean = false;
  @Input() anonymityTreshold: number = null;
  @Input() anonymityStatus: AnonymityStatus = { atRisk: false, hide: false, trend: false } as AnonymityStatus;
  @Input() extraAnonymityProtection: boolean = false;
  @Output() trendHoverOutput: EventEmitter<string> = new EventEmitter<string>();
  @Output() settingsChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeTimePeriod: EventEmitter<string> = new EventEmitter<string>();

  readonly chartTypes = Charts;
  readonly subjects = HelpSubject;

  public chartHeight: string = '';
  public chartItemHeight: string = '';
  public updateChart: Date | null = null;

  public zChecked: boolean = false;

  public showAverageHelper: boolean = false;

  public filterIcon: string = '';
  public filterText: string = '';

  public selectionExists: boolean = false;

  public isData: boolean = false;
  public exportChart: boolean = false;

  get Math() {
    return Math;
  }

  constructor(
    private _element: ElementRef,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnChanges() {
    this.exportChart = this.hasParentClass(this._element.nativeElement, 'export-chart');
    if (this.size && this.size.height) {
      this.onViewResize({ dimensions: this.size });
    }
    if (this.content) {
      this.selectionExists =
        this.content && this.content.filtersAll ? Object.keys(this.content.filtersAll).length > 0 : false;
      const nonHelperData: number[] =
        this.content.totalAnswers?.filter(
          (totalAnswers, index) =>
            (!this.content.comparison || this.content.comparison.index !== index) &&
            (!this.content.trend || this.content.trend.index !== index),
        ) || [];
      this.isData =
        !nonHelperData.length || nonHelperData.some((val) => !!val) || this.settings?.type === Charts.RESPONSERATES;
    }
    if (
      (this.settings.type === Charts.SUMMARY2D ||
        this.settings.type === Charts.SUMMARY1D ||
        this.settings.type === Charts.TRENDLINEAR2DCHART ||
        this.settings.type === Charts.TRENDLINEAR1DCHART) &&
      this.settings.zScoredValues &&
      !this.zChecked
    ) {
      this.zChecked = true;
    }
    if (
      this.chartHeight !== this.chartItemHeight &&
      this.content &&
      this.content.distributions &&
      this.content.distributions.length === 1
    ) {
      this.chartItemHeight = this.chartHeight;
    }
  }

  onViewResize($event) {
    this.chartHeight = $event.dimensions.height + 'px';
    this.chartItemHeight =
      $event.dimensions.height / (this.content && this.content.distributions ? this.content.distributions.length : 1) +
      'px';
    this.updateChart = new Date();
  }

  public summaryChartHeight(sizeText) {
    return sizeText ? Number(sizeText.slice(0, -2)) - 24 + 'px' : 'auto';
  }

  public zScoringSwitch(action) {
    this.transition = 750;
    this.zChecked = action.checked;
    this.settings.zScoredValues = action.checked;
    this.cdRef.detectChanges();
  }

  public zScoreCheck() {
    let zAverages = 0;
    let globalZAverages = 0;
    let group = '';
    let global = false;

    if (this.content) {
      for (const i in this.content.domain) {
        if (this.content.domain[i].scale === 'linear') {
          if (this.content.stats[i].zAverage != null) {
            zAverages += 1;
          }
          if (this.content.stats[i].globalZAverage != null) {
            globalZAverages += 1;
          }
          if (group && this.content.details[i] && this.content.details[i].group !== group) {
            global = true;
          } else if (!group && this.content.details[i] && this.content.details[i].group) {
            group = this.content.details[i].group;
          }
        }
      }
    }

    return (
      ((this.settings.type === Charts.SUMMARY2D || this.settings.type === Charts.TRENDLINEAR2DCHART) &&
        !((!global && zAverages > 5) || (global && globalZAverages > 5))) ||
      ((this.settings.type === Charts.SUMMARY1D || this.settings.type === Charts.TRENDLINEAR1DCHART) &&
        !((!global && zAverages > 2) || (global && globalZAverages > 2)))
    );
  }

  public toggleAverageHelper($event: boolean) {
    this.showAverageHelper = $event;
    this.cdRef.detectChanges();
  }

  public chartHeightDivider(value: string, percentage: number): string {
    return parseFloat(value) * (percentage / 100) + 'px';
  }

  public hasParentClass(element, classname): boolean {
    if (element.className && element.className.split(' ').indexOf(classname) >= 0) {
      return true;
    }

    return !!element.parentNode && this.hasParentClass(element.parentNode, classname);
  }
}
