import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { DimensionData } from '@shared/models/report.model';

import { Rights } from '@shared/enums/rights.enum';

/**
 * This is stats view component which handles showing of statistics.
 */
@Component({
  selector: 'filter-view',
  templateUrl: './filter-view.component.html',
  styleUrls: ['./filter-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterView {
  @Input() filters: any;
  @Input() dimensions: DimensionData;
  @Input() surveyRights: number = 0;
  @Input() userRights: number = 0;
  @Input() isSharedReport: boolean = false;
  @Output() removeCropFilters = new EventEmitter<any>();

  readonly Rights = Rights;

  public showFilters: boolean = false;

  constructor() {}

  public toggleView() {
    this.showFilters = !this.showFilters;
  }

  public removeFilter(filter) {
    this.removeCropFilters.emit(filter.id ? filter.id : filter.property);
  }

  public filterName(filter) {
    let useId: string = '';

    if (!filter.id && filter.property) {
      useId += 'contacts-' + filter.property;
    } else if (filter.part != null) {
      useId += filter.id + ':' + filter.part;
    } else if (filter.filterType === 'sentiment') {
      useId += filter.id + ':sentiment';
    } else if (filter.filterType === 'word') {
      useId += filter.id + (!filter.id?.includes(':rootCause') ? ':words' : 'Words');
    } else {
      useId += filter.id;
    }

    return this.dimensions[useId]
      ? this.dimensions[useId].title || ''
      : filter.survey && this.dimensions[filter.survey + '/' + useId]
        ? this.dimensions[filter.survey + '/' + useId].title || ''
        : '';
  }
}
