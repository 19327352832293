import * as d3 from 'd3';

import { Directive, ElementRef, HostListener, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';

import { Colors } from '@report/shared/enums/colors.enum';

/**
 * This is a horizontal bar chart.
 */
@Directive({
  selector: '[scaleX]',
})
export class ScaleX implements OnChanges {
  @Input() scale: [number, number] = [0, 100];
  @Input() previousScale: [number, number] = [0, 100];
  @Input() percentageValues: boolean = false;
  @Input() previousPercentageValues: boolean = false;
  @Input() transitionDuration: number = 0;
  @Input() hover: boolean = false;
  @Input() update: Date = new Date();

  private base: any;

  private canvas: any;

  private scaleX: any;

  private width: any;
  private height: any;
  private margin: any;
  private fontSize: number = 0;

  @HostListener('window:resize') resize() {
    this.updateChart(null);
  }

  constructor(private _element: ElementRef) {
    this.constructBody();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.scale || changes.previousScale || changes.percentageValues || changes.hover || changes.update) {
      this.updateChart(changes.scale);
    }
  }

  updateChart(dataChanges: SimpleChange | null) {
    this.setEnvironment();
    this.setScales();
    this.setCanvas(dataChanges);
  }

  constructBody() {
    this.base = d3.select(this._element.nativeElement).append('div').attr('class', 'scale-x');
  }

  setEnvironment() {
    this.fontSize = parseFloat(window.getComputedStyle(this._element.nativeElement).fontSize);

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = (this.hover ? 'bold ' : 'normal ') + (12 / 14) * this.fontSize + 'px Inter';
    const max = context.measureText('99999').width + 4;

    const sideMargin: number = Math.max(this.fontSize, max);

    this.margin = {
      top: 8,
      right: sideMargin,
      bottom: 8,
      left: this.scale[0] === 0 ? this.fontSize : sideMargin,
    };

    const width = this._element.nativeElement.clientWidth - this.margin.left - this.margin.right;
    const height = this._element.nativeElement.clientHeight - this.margin.top - this.margin.bottom;

    this.width = width > 0 ? width : 0;
    this.height = height > 0 ? height : 0;
  }

  setScales() {
    this.scaleX = d3.scaleLinear().rangeRound([0, this.width]).domain(this.scale);
  }

  setCanvas(dataChanges: SimpleChange | null) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const __this = this;
    const drawContent = function () {
      const context = d3.select(this).node().getContext('2d');

      if (
        dataChanges &&
        __this.previousScale[1] != null &&
        __this.previousScale[1] !== __this.scale[1] &&
        __this.previousPercentageValues === __this.percentageValues &&
        __this.transitionDuration > 0
      ) {
        const interpolateMin = d3.interpolateNumber(__this.previousScale[0], __this.scale[0]);
        const interpolateMax = d3.interpolateNumber(__this.previousScale[1], __this.scale[1]);
        const ease = d3.easeCubic;

        const timer = d3.timer((elapsed) => {
          const step = elapsed / __this.transitionDuration;
          let scale;

          if (step >= 1) {
            scale = __this.scaleX;

            timer.stop();
          } else {
            scale = d3
              .scaleLinear()
              .rangeRound([0, __this.width])
              .domain([interpolateMin(ease(step)), interpolateMax(ease(step))]);
          }

          __this.setXAxis(context, scale);
        });
      } else {
        __this.setXAxis(context, __this.scaleX);
      }
    };

    this.canvas = this.base.selectAll('.scale-x-canvas').data([this.scale]);

    this.canvas.exit().remove();

    this.canvas
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .each(drawContent);

    this.canvas
      .enter()
      .append('canvas')
      .attr('class', 'scale-x-canvas')
      .style('position', 'relative')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .each(drawContent);
  }

  setXAxis(context, scaleX) {
    context.clearRect(
      0,
      0,
      this.width + this.margin.left + this.margin.right,
      this.height + this.margin.top + this.margin.bottom,
    );

    const tickCount = this.percentageValues ? 4 : this.scale[1] > 3 ? 4 : this.scale[1] > 2 ? 2 : 1;
    const tickPadding = 3;
    const ticks = scaleX.ticks(tickCount);
    const tickFormat = scaleX.tickFormat(tickCount, this.percentageValues ? ',.1%' : 'd');

    context.font = 12 / 14 + 'em Inter';
    context.fillStyle = Colors.TEXT;
    context.strokeStyle = Colors.HELPERLINE;
    context.lineWidth = 1;
    context.textAlign = 'center';
    context.textBaseline = 'top';
    ticks.forEach((d) => {
      if (!(this.percentageValues && d > 1)) {
        const x = this.margin.left + scaleX(d);
        const y = this.margin.top + tickPadding;
        context.fillText(tickFormat(d), x, y);
      }
    });
  }
}
