import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { Select, Store } from '@ngxs/store';
import { ChartsManager } from '@report/shared/services/charts-manager.service';
import { Crossfilter } from '@report/shared/services/crossfilter.service';
import { ReportCommon } from '@report/shared/services/report-common.service';
import { UserInfo } from '@shared/models/auth.model';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';
import { PrefsManager } from '@shared/services/prefs-manager.service';
import { AccountState } from '@shared/states/account.state';
import { SignOutWithoutRedirect } from '@shared/states/auth.actions';
import { AuthState } from '@shared/states/auth.state';
import { ReportState } from '@shared/states/report.state';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

/**
 * This is a navigation bar for the report application.
 */
@Component({
  selector: 'nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavBar implements OnInit {
  readonly window = window;
  readonly environment = environment;

  public selectedTab: number = 4;
  public email: string = '';

  @Input() info: any = null;

  @Select(AuthState.info)
  readonly user$: Observable<UserInfo>;

  @Select(AuthState.isAnonymous)
  readonly isAnonymous$: Observable<boolean>;

  readonly isPrivateReport$: Observable<boolean> = this.rc.data$.pipe(map((data) => Boolean(data?.isSharedReport)));

  constructor(
    private store: Store,
    private cdRef: ChangeDetectorRef,
    private pm: PrefsManager,
    private hooks: LifecycleHooks,
    private ls: LocalStorageService,
    private rc: ReportCommon,
    private cf: Crossfilter,
    private cm: ChartsManager,
  ) {}

  ngOnInit() {
    const userKey = this.store.selectSnapshot(AccountState.userKey);
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    if (userKey) {
      this.pm
        .loadIdentity(userKey, teamKey)
        .pipe(takeUntil(this.hooks.destroy))
        .subscribe((user) => {
          this.email = user.email;
          this.cdRef.detectChanges();
        });
    }
  }

  openExportDialog() {
    const reportKey = this.rc.data$.value.reportKey;
    const reportData = this.store.selectSnapshot(ReportState.reports).find((report) => report.$key === reportKey);

    this.rc.openExportDialog({
      cm: this.cm,
      cf: this.cf,
      sampleData: false,
      reportData,
    });
  }

  public logout(): void {
    this.ls.clear('remember');
    this.ls.clear('report-team');

    this.store.dispatch(new SignOutWithoutRedirect()).subscribe(() => {
      window?.location.reload();
    });
  }
}
