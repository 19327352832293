import * as matrix from './matrix.js';

export const AbstractMatrix = matrix.AbstractMatrix;
export const CHO = matrix.CHO;
export const CholeskyDecomposition = matrix.CholeskyDecomposition;
export const DistanceMatrix = matrix.DistanceMatrix;
export const EVD = matrix.EVD;
export const EigenvalueDecomposition = matrix.EigenvalueDecomposition;
export const LU = matrix.LU;
export const LuDecomposition = matrix.LuDecomposition;
export const Matrix = matrix.Matrix;
export const MatrixColumnSelectionView = matrix.MatrixColumnSelectionView;
export const MatrixColumnView = matrix.MatrixColumnView;
export const MatrixFlipColumnView = matrix.MatrixFlipColumnView;
export const MatrixFlipRowView = matrix.MatrixFlipRowView;
export const MatrixRowSelectionView = matrix.MatrixRowSelectionView;
export const MatrixRowView = matrix.MatrixRowView;
export const MatrixSelectionView = matrix.MatrixSelectionView;
export const MatrixSubView = matrix.MatrixSubView;
export const MatrixTransposeView = matrix.MatrixTransposeView;
export const NIPALS = matrix.NIPALS;
export const Nipals = matrix.Nipals;
export const QR = matrix.QR;
export const QrDecomposition = matrix.QrDecomposition;
export const SVD = matrix.SVD;
export const SingularValueDecomposition = matrix.SingularValueDecomposition;
export const SymmetricMatrix = matrix.SymmetricMatrix;
export const WrapperMatrix1D = matrix.WrapperMatrix1D;
export const WrapperMatrix2D = matrix.WrapperMatrix2D;
export const correlation = matrix.correlation;
export const covariance = matrix.covariance;
export default matrix.default.Matrix ? matrix.default.Matrix : matrix.Matrix;
export const determinant = matrix.determinant;
export const inverse = matrix.inverse;
export const linearDependencies = matrix.linearDependencies;
export const pseudoInverse = matrix.pseudoInverse;
export const solve = matrix.solve;
export const wrap = matrix.wrap;
