/**
 * Resolves reports data from the Firebase.
 *
 * @unstable
 */

import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Store } from '@ngxs/store';

import { ReportData } from '@shared/models/survey.model';

import { GetReports } from '@shared/states/report.state';

import { AppState } from '@report/shared/models/state.model';

// TODO: When everything is using store change to returning Observable<void>

@Injectable({
  providedIn: 'root',
})
export class SavedReports {
  constructor(readonly store: Store) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<Observable<ReportData[]>> {
    const surveyKey = route.params['survey'] || route.queryParams['survey'];

    return this.store
      .dispatch(new GetReports(surveyKey))
      .pipe(mapTo(this.store.select((state: AppState) => state.report.reports)));
  }
}
