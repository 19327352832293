<div class="z-fx-col z-fx-fill content-area">
  <ng-container *ngIf="dataService?.whyFinderStatuses?.[whyFinderKey] | async as status">
    <ng-container *ngIf="status !== 'ready'">
      <ng-container *ngTemplateOutlet="loading; context: { status: status }"></ng-container>
    </ng-container>
  </ng-container>
  <div *ngIf="(loadingTextSummary$ | async) || insight" class="z-fx text-summary">
    <div class="z-fx-col z-fx-gap-16 text-summary-area">
      <div *ngIf="loadingTextSummary$ | async" class="z-fx z-fx-start-center z-fx-gap-8 insight">
        <mat-icon>refresh_ai</mat-icon>
        <div class="zef-text-p1 summary-loading" i18n>Generating AI insights...</div>
      </div>
      <div *ngIf="insight?.length" class="z-fx z-fx-start-start z-fx-gap-8 insight">
        <div
          class="z-fx z-fx-start-start z-fx-gap-32 insight-section"
          (mouseenter)="insightHover(insight)"
          (mouseleave)="insightHover(null)"
        >
          <div class="z-fx-col z-fx-start-center z-fx-gap-8 side-stuff">
            <mat-icon>highlight_ai</mat-icon>
            <div class="reload-insights">
              <mat-icon class="retry-icon zef-icon-micro" (click)="updateTextSummary(true)">retry</mat-icon>
            </div>
          </div>
          <div class="zef-text-p1 summary-text" [class.shortened]="!showOversizedInsight">
            <ng-container *ngFor="let part of insight">
              <span class="{{ part[1] }}">{{ part[0] }}</span>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    #tooltip
    *ngIf="hoveredThemes?.length"
    class="z-fx-col z-fx-center-center z-fx-gap-16 item-tooltip"
    [style.transform]="'translate(' + tooltipPosition[0] + 'px,' + tooltipPosition[1] + 'px)'"
  >
    <div
      *ngFor="let theme of hoveredThemes; trackBy: trackByTitle; let last = last"
      class="z-fx-col z-fx-center-center z-fx-gap-8"
    >
      <div class="label">{{ theme?.title }}</div>
      <div class="z-fx z-fx-center-center z-fx-gap-4 content">
        <span class="number">{{ theme?.description }}</span>
      </div>
      <div class="z-fx z-fx-center-center z-fx-gap-16">
        <div class="z-fx z-fx-center-center z-fx-gap-4 content">
          <span class="icon">player_thumbs_down</span>
          <span class="number">{{ theme?.stats?.weaknesses }}</span>
        </div>
        <div class="z-fx z-fx-center-center z-fx-gap-4 content">
          <span class="icon">player_thumbs_up</span>
          <span class="number">{{ theme?.stats?.strengths }}</span>
        </div>
      </div>
    </div>
  </div>
  <ng-container>
    <div *ngIf="!isNotFreezed() || !(trend || comparison?.values?.length)" class="z-fx z-fx-around-center stats">
      <div class="z-fx z-fx-around-center z-fx-gap-8">
        <div class="z-fx z-fx-around-center">
          <mat-icon class="icon">contact</mat-icon>
          <span numberTransition class="number" [data]="responses" [duration]="transitionDuration"></span>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="z-fx-col z-fx-auto chart-area" (elementResize)="chartAreaResize($event)">
    <ng-container *ngIf="!isDataAvailable">
      <ng-container *ngTemplateOutlet="nothing"></ng-container>
    </ng-container>
    <div
      class="topic-bubble"
      topicBubble
      [chartData]="themes"
      [selectedItems]="selectedThemes"
      [highlight]="hoveredInsight"
      [scale]="[0, 100]"
      [axisLabels]="axisLabels"
      [transitionDuration]="transitionDuration"
      [update]="Math.max(update?.valueOf(), sizeUpdate?.valueOf())"
      (hover)="themeChartHover($event)"
      (click)="themeChartClick()"
    ></div>
  </div>
</div>

<ng-container *ngIf="!anonymityLock; else anonymityLockTemplate"></ng-container>

<ng-template #nothing>
  <div class="z-fx-col nothing">
    <div class="z-fx-col z-fx-auto z-fx-start-center">
      <div class="z-fx-col z-fx-end-center image">
        <img src="assets/decos/art-nothing.png" height="48px" width="48px" class="art-nothing" alt="nothing" />
      </div>
      <div class="description" i18n>Looks like there is no data to show.</div>
    </div>
  </div>
</ng-template>
<ng-template #anonymous>
  <div class="z-fx-col z-fx-auto anonymous">
    <div class="z-fx z-fx-center-center answerers">
      <mat-icon class="respondent-icon">contact</mat-icon>
      <span class="number" i18n>under {{ anonymityTreshold }}</span>
    </div>
    <div class="z-fx-col z-fx-auto z-fx-start-center z-fx-gap-16">
      <div class="z-fx-col z-fx-end-center image">
        <mat-icon class="zef-icon-larger">anonymous_on</mat-icon>
      </div>
      <div class="description zef-text-center">
        <ng-container i18n>
          Charts are hidden if there are less than {{ anonymityTreshold }} respondents in Anonymous survey.
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #anonymityLockTemplate>
  <div
    class="anonymity-lock"
    matTooltip="Chart is locked to protect anonymity of the respondent"
    matTooltipClass="zef-tooltip-ink-lighter"
    i18n-matTooltip
    matTooltipPosition="above"
  >
    <mat-icon class="zef-icon-small lock-icon">lock</mat-icon>
  </div>
</ng-template>

<ng-template #noItemsAvailable>
  <div class="z-fx-col z-fx-auto z-fx-center-center">
    <img src="assets/decos/art-nothing.png" height="48px" width="48px" class="art-nothing" alt="nothing" />
  </div>
</ng-template>

<ng-template let-status="status" #loading>
  <div class="z-fx-col z-fx-center-center loading-status">
    <zef-spinner size="huge"></zef-spinner>
    <div class="zef-text-p1">
      <ng-container [ngSwitch]="status">
        <ng-container *ngSwitchCase="'started'">
          <ng-container i18n>Started process...</ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'loading'">
          <ng-container i18n>Loading themes... (1/3)</ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'finalizing'">
          <ng-container i18n>Finalizing themes... (2/3)</ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'assigning'">
          <ng-container i18n>Assigning responses into themes... (3/3)</ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>
