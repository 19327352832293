export function isField(key?: string): boolean {
  return key?.substring(0, 7) === 'fields-';
}

export function sortByValue(a, b): number {
  return a < b ? -1 : 1;
}

export function isContactEntry(key?: string): boolean {
  return [
    'id',
    'firstName',
    'lastName',
    'name',
    'email',
    'phone',
    'photo',
    'thumb',
    'created',
    'modified',
    'source',
    'creator',
    'collectionMethod',
  ].includes(!isField(key) ? key : key?.substring(7));
}

export function generatePollHeader(pollData: any) {
  const { displayName, image } = pollData;

  return (
    `<div class="z-fx-col z-fx-none z-fx-center-center z-fx-gap-32">` +
    (image ? `<img alt="logo" class="poll-image" src="${image}" style="max-width: 80px">` : ``) +
    `<div class="z-fx-col z-fx-center-center" style="padding-bottom: 32px">` +
    `<div class="poll-name zef-text-d3 zef-text-align-center">${displayName || ''}</div></div></div>`
  );
}
