<mat-divider *ngIf="filters && filters.length > 0"></mat-divider>
<div *ngIf="filters && filters.length > 0" class="content">
  <div (click)="toggleView()" class="toggle-link" i18n>{{ filters.length }} crop filters in action</div>
  <div *ngIf="showFilters" class="z-fx-col z-fx-gap-8 filter-container">
    <div *ngFor="let filter of filters" class="z-fx z-fx-start-center z-fx-gap-8 filter-item">
      <mat-icon fontSet="md" class="filter-icon">filter_list</mat-icon>
      <div
        class="z-fx-auto filter-name"
        [matTooltip]="filterName(filter).length > 40 ? filterName(filter) : ''"
        matTooltipPosition="above"
      >
        {{ filterName(filter) }}
      </div>
      <mat-icon
        *ngIf="
          (Rights.hasRights(Rights.EDIT, surveyRights || 0) || Rights.hasRights(Rights.ADMIN, userRights || 0)) &&
          !isSharedReport
        "
        class="delete-icon"
        (click)="removeFilter(filter)"
        matTooltip="Remove filter"
      >
        action_delete
      </mat-icon>
    </div>
  </div>
</div>
