/**
 * Colors used for analysis.
 *
 * @unstable
 */

export class Colors {
  static getComparisonColor(i: number): string {
    return this.COMPARISON[Math.round(i) % this.COMPARISON.length];
  }

  static readonly COMPARISON: string[] = [
    '#4988D4',
    '#FF7D1B',
    '#00934F',
    '#CA0404',
    '#FFA2BE',
    '#520466',
    '#006893',
    '#CC9256',
    '#660529',
    '#E3D472',
    '#4EE67D',
    '#4865d4',
    '#004B39',
    '#ffb61b',
    '#2C92A7',
    '#ca6703',
    '#a3cc55',
    '#f2a2ff',
  ];
  static readonly COMPARISONLINEAR: string[] = [
    '#CA0404',
    '#D33535',
    '#DC6666',
    '#E69797',
    '#EFC8C8',
    '#F9F9F9',
    '#D5E2F1',
    '#B2CBEA',
    '#8FB5E2',
    '#6C9EDB',
    '#4988D4',
  ];

  static readonly DEFAULT: string = '#71b2ff';
  static readonly FILTER: string = '#cc9256';
  static readonly UNSELECTED: string = '#ddd';
  static readonly SELECTED: string = '#dbb389';
  static readonly TEXT: string = '#112539';
  static readonly HIGHLIGHT: string = '#35485a';
  static readonly HELPERLINE: string = '#e1e8ea';
  static readonly BACKGROUNDSUMMARY: string = '#dae2e5';
  static readonly AVERAGEHELPER: string = '#f0406a';
  static readonly BACKGROUND: string = '#fff';
  static readonly PRIMARY: string = '#0083ff';

  static readonly NPS: string[] = ['#ff7171', '#ffe971', '#78ff71'];
  static readonly SENTIMENT: string[] = ['#e20046', '#fee119', '#18b53a'];
  static readonly WHYFINDERBUBBLE: string[] = ['#18b56c', '#e5c800', '#495c6c'];
  static readonly WHYFINDERTEXT: string[] = ['#117f4c', '#a08c00', '#495c6c'];
  static readonly WHYFINDERBACKGROUND: string = '#f9fafb';
  static readonly WHYFINDERHELPERTEXT: string = '#93a0ab';
}
