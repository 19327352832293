import { Observable } from 'rxjs';

import { timeout, first } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { DataPreparation } from '@report/shared/services/data-preparation.service';
import { Crossfilter } from '@report/shared/services/crossfilter.service';
import { FilterConverter } from '@report/shared/services/filter-converter.service';
import { WordCounter } from '@report/shared/services/word-counter.service';
import { ReportAssistant } from '@report/shared/services/report-assistant.service';
import { ReportSave } from '@report/shared/services/report-save.service';

import { QuestionsManager } from '@shared/services/questions-manager.service';
import { ReportManager } from '@shared/services/report-manager.service';
import { SurveysManager } from '@shared/services/surveys-manager.service';

import { ChartsManager } from '@report/shared/services/charts-manager.service';
import { DataConverter } from '@report/shared/services/data-converter.service';
import { LocalesManager } from '@shared/services/locales-manager.service';

import { ReportData } from '@shared/models/survey.model';

import { GridItem } from '@shared/models/report.model';
import { Store } from '@ngxs/store';

/**
 * This service will update stored report data with new responses.
 */
@Injectable()
export class ReportUpdate {
  constructor(
    private qm: QuestionsManager,
    private rm: ReportManager,
    private sm: SurveysManager,
    private ra: ReportAssistant,
    private rs: ReportSave,
    private fc: FilterConverter,
    private lm: LocalesManager,
    private store: Store,
  ) {}

  connectChart(surveyKey, reportKey, chartKey): Observable<any> {
    return new Observable<any>((observer) => {
      const dp: DataPreparation = new DataPreparation(
        this.qm as QuestionsManager,
        new WordCounter(this.lm as LocalesManager),
        this.lm as LocalesManager,
      );
      const cf: Crossfilter = new Crossfilter(null, dp, this.fc, this.ra, this.rm, this.sm, this.store);
      const cm: ChartsManager = new ChartsManager(cf, new DataConverter(), this.store);

      this.rm.getStoredReportData(surveyKey, reportKey).subscribe((report) => {
        cf.connect(surveyKey, reportKey, report);
        cm.connect(report.charts || []);

        cf.dataChange.subscribe((log): any => {
          if (log) {
            const chartData: GridItem = cm.grid.find((item) => item.key === chartKey) || null;
            observer.next({ chart: chartData, crossfilter: cf });

            this.rm
              .listReports(surveyKey)
              .pipe(first(), timeout(300000))
              .subscribe((reports) => {
                const selectedReport: ReportData = reports.find((item) => item.$key === reportKey);

                if (
                  selectedReport &&
                  (selectedReport.latestAnswerTime !== cf.getLatestAnswerTime() || cf.updateNeed())
                ) {
                  this.rs.save(cf, cm, surveyKey, reportKey, selectedReport.title).pipe(first()).subscribe();
                }
              });
          }
        });
      });
    });
  }
}
