/**
 * Resolves survey data from the Firebase.
 *
 * @unstable
 */

import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Store } from '@ngxs/store';

import { AppState } from '@shared/models/state.model';
import { SurveyData } from '@shared/models/survey.model';

import { GetSurvey } from '@shared/states/survey.actions';
import { shareRef } from '@shared/operators/share-ref.operator';

// TODO: When everything is using store change to returning Observable<void>

@Injectable({
  providedIn: 'root',
})
export class SurveyBasics {
  constructor(readonly store: Store) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<Observable<SurveyData>> {
    const surveyKey = route.params.survey || route.queryParams.survey;

    return this.store
      .dispatch(new GetSurvey(surveyKey))
      .pipe(mapTo(this.store.select((state: AppState) => state.survey[surveyKey]?.survey)), shareRef());
  }
}
