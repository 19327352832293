<div class="container">
  <div *ngIf="responseRates$ | async as responseRates; else nothing" class="chart">
    <div
      funnelChartH
      [style.height]="size"
      [data]="responseRates"
      [steps]="steps"
      [stepLabels]="stepLabels"
      [update]="update"
    ></div>
  </div>
</div>

<ng-template #nothing>
  <div class="z-fx-col nothing">
    <div class="z-fx-col z-fx-auto z-fx-start-center">
      <div class="z-fx-col z-fx-end-center image">
        <img src="assets/decos/art-nothing.png" height="48px" width="48px" class="art-nothing" alt="nothing" />
      </div>
      <div class="description" i18n>Looks like there is no data to show.</div>
    </div>
  </div>
</ng-template>
