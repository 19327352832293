<div class="z-fx-col z-fx-auto z-fx-start-start container">
  <ng-container *ngIf="(loading$ | async) === false; else loading">
    <ng-container *ngIf="gridItems?.length > 0; else noCharts">
      <div
        class="z-fx z-fx-start-center z-fx-gap-24 chart-list"
        [style.overflow]="gridItems.length > 1 ? 'auto' : 'hidden'"
      >
        <div *ngFor="let item of gridItems; trackBy: trackByKey" class="z-fx-col z-fx-gap-16 chart-container">
          <chart-card
            class="z-fx-col z-fx-auto grid-item"
            [settings]="item"
            [data]="item.data"
            [height]="item.gridSettings.sizey"
            [width]="item.gridSettings.sizex"
            [row]="item.gridSettings.row"
            [update]="item.data.timestamp"
            [visibleArea]="[0, 1000, 210]"
            [comparison]="item.data.comparison"
            [explorable]="false"
            [isSharedReport]="isSharedReport"
            [discoverChart]="true"
            (hidedChart)="hideChart($event)"
            (addDiscoverChart)="addDiscoverChart($event)"
          >
            <span class="drag-handle" cdkDragHandle>{{ item.title }}</span>
          </chart-card>
          <div *ngIf="aiInsights[item.key] as insight" class="z-fx z-fx-start-start z-fx-gap-8 z-fx-none chart-insight">
            <mat-icon>highlight_ai</mat-icon>
            <div class="zef-text-p2">
              {{ insight }}
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #loading>
  <div class="z-fx z-fx-center-center z-fx-gap-16 loading">
    <zef-spinner></zef-spinner>
    <div class="zef-text-p2" i18n>Loading charts...</div>
  </div>
</ng-template>

<ng-template #noCharts>
  <div class="z-fx-col z-fx-auto z-fx-start-center nothing">
    <div class="z-fx-col z-fx-end-center image">
      <img src="assets/decos/art-nothing.png" height="64px" width="64px" class="art-nothing" alt="nothing" />
    </div>
    <div class="description" i18n>Looks like there is no data to show.</div>
  </div>
</ng-template>
