export function setChartImageSizes(type, chartTypes, comparison, exportSize, chartContent, settings) {
  if (type === chartTypes.WORDANALYSIS) {
    let comparisonCount: number = 1;
    const groupCount: number = settings.chartSettings.wordChartGrouped ? 4 : 1;

    if (comparison && comparison.values.length > 0) {
      comparisonCount = comparison.values.length;
    }

    exportSize.height = comparisonCount * groupCount * 700;
  } else if (chartTypes.DISTRIBUTIONCHARTS.indexOf(type) >= 0) {
    exportSize.height = chartContent ? chartContent.distributions.length * 700 : 0;
  } else if (
    type === chartTypes.HEATMAP ||
    chartTypes.SUMMARYCHARTS.indexOf(type) >= 0 ||
    chartTypes.TRENDCHARTS.indexOf(type) >= 0
  ) {
    let chartCount = 1;

    if (comparison && comparison.values.length > 0) {
      if (!settings.chartSettings || settings.chartSettings.summaryChartComparisonMode !== 'joined') {
        chartCount = comparison.values.length;
      }
    }

    if (
      [
        chartTypes.SUMMARY2D,
        chartTypes.SUMMARY1D,
        chartTypes.TRENDLINEAR2DCHART,
        chartTypes.TRENDLINEAR1DCHART,
      ].indexOf(type) >= 0
    ) {
      const groups = new Set();
      const slider2ds = new Set();

      for (let d = 0, lend = ((chartContent && chartContent.details) || []).length; d < lend; d++) {
        if (chartContent.details[d] && chartContent.details[d].scale === 'linear') {
          const groupId = `${chartContent.details[d].labelsLinear ? chartContent.details[d].labelsLinear.axis : ''}: ${
            chartContent.details[d].labelsLinear ? chartContent.details[d].labelsLinear.min : ''
          } - ${chartContent.details[d].labelsLinear ? chartContent.details[d].labelsLinear.max : ''} (${
            chartContent.details[d].valueScaleLinear ? chartContent.details[d].valueScaleLinear.min : ''
          }-${chartContent.details[d].valueScaleLinear ? chartContent.details[d].valueScaleLinear.max : ''})`;

          if (chartContent.details[d].originalType === 'slider-2d') {
            slider2ds.add(groupId);
          }

          groups.add(groupId);
        }
      }

      if ((type === chartTypes.SUMMARY2D || type === chartTypes.TRENDLINEAR2DCHART) && slider2ds.size > 0) {
        if (Math.floor(slider2ds.size / 2) > 1) {
          chartCount = Math.floor(slider2ds.size / 2) * chartCount;
        }
      } else if (groups.size > 0) {
        chartCount = chartCount * groups.size;
      }
    }

    exportSize.height = chartCount * 700;
  } else {
    exportSize.height = 700;
  }
}

export function generateChartImageContent(
  exportType,
  type,
  chartTypes,
  _element,
  chartContent,
  settings,
  Colors,
  Questions,
  drawChartLegends,
  drawIntelligenceIcon,
) {
  const arr: string[] = [];
  const widthArr: number[] = [];
  const heightArr: number[] = [];

  const exportChartElement = _element.nativeElement.querySelector('.export-chart');
  let charts = exportChartElement != null ? exportChartElement.querySelectorAll('canvas') : [];

  if (type === chartTypes.NPS && chartContent.details.find((item) => item.scale === 'categorical')) {
    const mixedTable = {
      headers: [
        {
          content:
            exportChartElement && exportChartElement.querySelector('.title-header-text')
              ? exportChartElement.querySelector('.title-header-text').textContent
              : 'Name',
          styles: {
            fontWeight: 'bold',
            textAlign: 'right',
          },
        },
        {
          content: 'n',
          styles: {
            fontWeight: 'bold',
            textAlign: 'center',
          },
        },
        {
          content:
            exportChartElement && exportChartElement.querySelector('.nps-score-header-text')
              ? exportChartElement.querySelector('.nps-score-header-text').textContent
              : 'NPS',
          styles: {
            fontWeight: 'bold',
            textAlign: 'center',
          },
        },
        {
          content:
            exportChartElement && exportChartElement.querySelector('.detractor-header-text')
              ? exportChartElement.querySelector('.detractor-header-text').textContent
              : 'Detractor',
          styles: {
            fontWeight: 'bold',
            textAlign: 'center',
          },
        },
        {
          content:
            exportChartElement && exportChartElement.querySelector('.passive-header-text')
              ? exportChartElement.querySelector('.passive-header-text').textContent
              : 'Passive',
          styles: {
            fontWeight: 'bold',
            textAlign: 'center',
          },
        },
        {
          content:
            exportChartElement && exportChartElement.querySelector('.promoter-header-text')
              ? exportChartElement.querySelector('.promoter-header-text').textContent
              : 'Promoter',
          styles: {
            fontWeight: 'bold',
            textAlign: 'center',
          },
        },
      ],
      columnWidths: [0.25, 0.1, 0.35, 0.1, 0.1, 0.1],
      types: ['text', 'text', 'image', 'text', 'text', 'text'],
      values: [],
    };
    const titles = exportChartElement ? exportChartElement.querySelectorAll('.title-cell') : [];
    const counts = exportChartElement ? exportChartElement.querySelectorAll('.count-cell') : [];
    const detractors = exportChartElement ? exportChartElement.querySelectorAll('.detractor-cell') : [];
    const passives = exportChartElement ? exportChartElement.querySelectorAll('.passive-cell') : [];
    const promoters = exportChartElement ? exportChartElement.querySelectorAll('.promoter-cell') : [];

    for (let i = 0, len = titles.length; i < len; i++) {
      mixedTable.values.push([
        {
          content: (titles[i] && titles[i].textContent) || '',
          styles: {
            textAlign: 'right',
          },
        },
        {
          content: (counts[i] && counts[i].textContent) || '',
          styles: {
            textAlign: 'center',
          },
        },
        {
          dataUrl: charts[i]?.toDataURL('image/png'),
          width: charts[i]?.width,
          height: charts[i]?.height,
          styles: {
            background: settings.chartSettings.npsChartMode === 'gauge' ? 'f9fafb' : '',
          },
        },
        {
          content: (detractors[i] && detractors[i].textContent) || '',
          styles: {
            textAlign: 'center',
          },
        },
        {
          content: (passives[i] && passives[i].textContent) || '',
          styles: {
            textAlign: 'center',
          },
        },
        {
          content: (promoters[i] && promoters[i].textContent) || '',
          styles: {
            textAlign: 'center',
          },
        },
      ]);
    }

    settings.exports.mixedTable = [mixedTable];
  } else if (
    type === chartTypes.BARCHARTH ||
    type === chartTypes.MULTIBARCHARTH ||
    type === chartTypes.STACKEDBARCHARTH ||
    (type === chartTypes.WORDANALYSIS && settings.chartSettings.wordChartMode === 'bar') ||
    type === chartTypes.SUMMARYCHOICE
  ) {
    const wordCloudComparison =
      type === chartTypes.WORDANALYSIS &&
      chartContent &&
      chartContent.comparison &&
      chartContent.comparison.values &&
      chartContent.comparison.values.length;
    const imageQuestion = chartContent.details.find(
      (item) => item.originalType === Questions.CHOICE_PICTURE && type !== chartTypes.SUMMARYCHOICE,
    );
    const elName = type !== chartTypes.SUMMARYCHOICE ? 'bar-chart-table' : '.bar-chart-table';
    const chartTables = exportChartElement ? exportChartElement.querySelectorAll(elName) : [];
    const canvasName =
      type === chartTypes.BARCHARTH || (type === chartTypes.WORDANALYSIS && !wordCloudComparison)
        ? 'canvas.single-bar-chart-h-canvas'
        : type === chartTypes.MULTIBARCHARTH || wordCloudComparison
          ? 'canvas.multi-bar-chart-h-canvas'
          : type === chartTypes.STACKEDBARCHARTH || type === chartTypes.SUMMARYCHOICE
            ? 'canvas.stacked-bar-chart-h-canvas'
            : '';

    for (let c = 0, lenc = chartTables.length; c < lenc; c++) {
      const chartEl = chartTables[c];
      const headerText = chartEl?.querySelector('.group-title')?.textContent || '';
      const mixedTable = imageQuestion
        ? {
            headers: [{ content: '' }, { content: '' }, { content: '' }],
            columnWidths: [0.25, 0.15, 0.6],
            types: ['text', 'image', 'image'],
            values: [],
            footers: [],
          }
        : {
            headers: [{ content: headerText }, { content: '' }],
            columnWidths: [0.25, 0.75],
            types: ['text', 'image'],
            values: [],
            footers: [],
          };
      const rows = chartEl ? chartEl.querySelectorAll('.bar-chart-table-row') : [];
      const footerBar = chartEl ? chartEl.querySelector('.scale-x-canvas') : null;
      const addFooterBar: (drawImage: boolean) => void = (drawImage: boolean) => {
        mixedTable.footers = [
          { content: '' },
          ...(imageQuestion ? [{ url: null, width: 0, height: 0 }] : []),
          {
            dataUrl: (drawImage && footerBar?.toDataURL('image/png')) || null,
            width: (drawImage && footerBar?.width) || 0,
            height: (drawImage && footerBar?.height) || 0,
          },
        ];
      };

      if (rows.length > 0) {
        if (!settings.exports.mixedTable) {
          settings.exports.mixedTable = [];
        }

        if (
          type === chartTypes.MULTIBARCHARTH ||
          type === chartTypes.STACKEDBARCHARTH ||
          wordCloudComparison ||
          type === chartTypes.SUMMARYCHOICE
        ) {
          const widthFromBarEl = chartEl ? chartEl.querySelector(canvasName) : null;
          const width = (widthFromBarEl && widthFromBarEl.width) || 0;
          const legends = chartEl ? chartEl.querySelectorAll('.legend') : [];
          const height = 20;

          for (let round = 0, roundLen = Math.ceil(legends.length / 2); round < roundLen; round++) {
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext('2d');

            for (let i = round * 2, len = round + 1 < roundLen ? i + 2 : i + (legends.length % 2 || 2); i < len; i++) {
              const w = width / 2;
              const h = 20;
              const x = i % 2 === 0 ? 0 : w;
              const y = 0;
              const fontSize = parseFloat(window.getComputedStyle(exportChartElement).fontSize);
              const textColor = Colors.TEXT;
              const fillColor = window.getComputedStyle(legends[i].querySelector('span.circle'))['background-color'];
              const label =
                legends[i].querySelector('span.text-label') && legends[i].querySelector('span.text-label').textContent;
              const respondents =
                legends[i].querySelector('span.respondents') &&
                legends[i].querySelector('span.respondents').textContent;

              drawChartLegends(ctx, x, y, w, h, fontSize, fillColor, textColor, label, respondents);
            }

            mixedTable.values.push([
              { content: '' },
              ...(imageQuestion ? [{ url: null, width: 0, height: 0 }] : []),
              {
                dataUrl: width ? canvas.toDataURL('image/png') : null,
                width: canvas.width,
                height: canvas.height,
              },
            ]);
          }
        }

        for (let i = 0, len = rows.length; i < len; i++) {
          const title = rows[i] ? rows[i].querySelector('.choice-title > span') : null;
          const bar = rows[i] ? rows[i].querySelector(canvasName) : null;

          if (imageQuestion) {
            const img = rows[i] ? rows[i].querySelector('.image') : null;

            mixedTable.values.push([
              {
                content: (title && title.textContent) || '',
                styles: {
                  textAlign: 'right',
                },
              },
              {
                url: img?.src,
                width: img?.width || 0,
                height: img?.height || 0,
              },
              {
                dataUrl: bar?.toDataURL('image/png'),
                width: bar?.width,
                height: bar?.height,
              },
            ]);
          } else {
            mixedTable.values.push([
              {
                content: (title && title.textContent) || '',
                styles: {
                  textAlign: 'right',
                  fontSize: len > 7 ? 8 : 10,
                },
              },
              {
                dataUrl: bar.toDataURL('image/png'),
                width: bar.width,
                height: bar.height,
              },
            ]);
          }
        }

        if (type !== chartTypes.SUMMARYCHOICE) {
          addFooterBar(true);
        }

        settings.exports.mixedTable.push(mixedTable);
      }
    }
  } else if (
    type === chartTypes.TEXTSENTIMENTANALYSIS &&
    chartContent.details.find((item) => item.scale === 'categorical') &&
    settings.chartSettings.sentimentChartMode !== 'bar'
  ) {
    const mixedTable = {
      headers: [
        {
          content:
            exportChartElement && exportChartElement.querySelector('.label-header')
              ? exportChartElement.querySelector('.label-header').textContent
              : 'Name',
          styles: {
            fontWeight: 'bold',
            textAlign: 'right',
          },
        },
        {
          content: 'n',
          styles: {
            fontWeight: 'bold',
            textAlign: 'center',
          },
        },
        {
          content:
            exportChartElement && exportChartElement.querySelector('.bar-chart-header')
              ? exportChartElement.querySelector('.bar-chart-header').textContent
              : 'Average',
          styles: {
            fontWeight: 'bold',
            textAlign: 'center',
          },
        },
      ],
      columnWidths: [0.25, 0.1, 0.65],
      types: ['text', 'text', 'image'],
      values: [],
      footers: [],
    };
    const labels = exportChartElement ? exportChartElement.querySelectorAll('.label-cell') : [];
    const counts = exportChartElement ? exportChartElement.querySelectorAll('.count-cell') : [];

    for (let i = 0, len = labels.length; i < len; i++) {
      mixedTable.values.push([
        {
          content: (labels[i] && labels[i].textContent) || '',
          styles: {
            textAlign: 'right',
          },
        },
        {
          content: (counts[i] && counts[i].textContent) || '',
          styles: {
            textAlign: 'center',
          },
        },
        {
          dataUrl: charts[i].toDataURL('image/png'),
          width: charts[i].width,
          height: charts[i].height,
          styles: {
            background: settings.chartSettings.sentimentChartMode === 'gauge' ? 'f9fafb' : '',
          },
        },
      ]);
    }

    const footerBar = exportChartElement ? exportChartElement.querySelector('.scale-x-canvas') : null;

    if (footerBar) {
      mixedTable.footers = [
        { content: '' },
        { content: '' },
        {
          dataUrl: footerBar && footerBar.toDataURL('image/png'),
          width: (footerBar && footerBar.width) || 0,
          height: (footerBar && footerBar.height) || 0,
        },
      ];
    }

    settings.exports.mixedTable = [mixedTable];
  } else if (type === chartTypes.SUMMARY1D) {
    const chartTables = exportChartElement ? exportChartElement.querySelectorAll('.summary1d-chart-container') : [];
    const canvasName = 'canvas.summary-1d-canvas';

    for (let c = 0, lenc = chartTables.length; c < lenc; c++) {
      const chartEl = chartTables[c];
      const isIntglZmd = !!chartEl?.closest('.intelligently-zoomed');
      const generateIntelligentZoomImage: () => HTMLCanvasElement = () => {
        const canvas = document.createElement('canvas');
        const widthFromBarEl = chartTables[0] ? chartTables[0].querySelector(canvasName) : null;
        const width = (widthFromBarEl && widthFromBarEl.width) || 0;
        canvas.width = width;
        canvas.height = 20;

        if (isIntglZmd) {
          const ctx = canvas.getContext('2d');

          const x = width / 2;
          const label = $localize`Intelligent Zoom™`;

          ctx.font = '12px Inter';
          ctx.textBaseline = 'middle';
          ctx.fillStyle = '#ef5d65';

          const wLabel = ctx.measureText(label).width;

          drawIntelligenceIcon(ctx, 20, x - wLabel / 2 - 8 - 4, 0);

          ctx.fillText(label, x - wLabel / 2 + 8 + 4, 10);
        }

        return canvas;
      };
      const mixedTable = {
        headers: [{ content: '' }, { content: '' }],
        columnWidths: [0.7, 0.3],
        types: ['image', 'text'],
        values: [],
        footers: [],
      };
      const rows = chartEl ? chartEl.querySelectorAll('.row') : [];
      const footerBar = chartEl ? chartEl.querySelector('canvas.summary-1d-scale-canvas') : null;
      const addFooterBar: () => void = () => {
        mixedTable.footers = [
          {
            dataUrl: footerBar && footerBar.toDataURL('image/png'),
            width: (footerBar && footerBar.width) || 0,
            height: (footerBar && footerBar.height) || 0,
          },
          { content: '' },
        ];
      };

      if (rows.length > 0) {
        if (!settings.exports.mixedTable) {
          settings.exports.mixedTable = [];
        }

        const intglZmd: HTMLCanvasElement = generateIntelligentZoomImage();
        mixedTable.values.push([
          {
            dataUrl: intglZmd ? intglZmd.toDataURL('image/png') : null,
            width: intglZmd.width,
            height: intglZmd.height,
          },
          { content: '' },
        ]);

        if (
          chartContent &&
          chartContent.comparison &&
          chartContent.comparison.values &&
          chartContent.comparison.values.length
        ) {
          const legendElName: string =
            settings?.chartSettings?.summaryChartComparisonMode === 'joined' ? '.legend' : '.comparison-group-title';
          const widthFromBarEl = chartEl ? chartEl.querySelector(canvasName) : null;
          const width = (widthFromBarEl && widthFromBarEl.width) || 0;
          const legends = chartEl ? chartEl.querySelectorAll(legendElName) : [];
          const height = 20;

          for (let round = 0, roundLen = Math.ceil(legends.length / 2); round < roundLen; round++) {
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext('2d');

            for (let i = round * 2, len = round + 1 < roundLen ? i + 2 : i + (legends.length % 2 || 2); i < len; i++) {
              const centered = legends.length === 1 || round * 2 + 1 === len;
              const w = centered ? width : width / 2;
              const h = 20;
              const x = centered ? width / 2 : i % 2 === 0 ? (1 / 4) * width : (3 / 4) * width;
              const y = 0;
              const fontSize = parseFloat(window.getComputedStyle(exportChartElement).fontSize);
              const textColor = Colors.TEXT;
              const fillColor = window.getComputedStyle(legends[i].querySelector('span.circle'))['background-color'];
              const label =
                legends[i].querySelector('span.text-label') && legends[i].querySelector('span.text-label').textContent;

              drawChartLegends(ctx, x, y, w, h, fontSize, fillColor, textColor, label, null, true);
            }

            mixedTable.values.push([
              {
                dataUrl: width ? canvas.toDataURL('image/png') : null,
                width: canvas.width,
                height: canvas.height,
              },
              { content: '' },
            ]);
          }
        }

        for (let i = 0, len = rows.length; i < len; i++) {
          const title = rows[i] ? rows[i].querySelector('.summary1d-question-export-text') : null;
          const chartImg = rows[i] ? rows[i].querySelector(canvasName) : null;
          const isSelected: boolean = title?.textContent?.indexOf('***') === 0;

          mixedTable.values.push([
            {
              dataUrl: chartImg && chartImg.toDataURL('image/png'),
              width: (chartImg && chartImg.width) || 0,
              height: (chartImg && chartImg.height) || 0,
            },
            {
              content: (isSelected ? title?.textContent?.replace('***', '') : title?.textContent) || '',
              styles: {
                textAlign: 'left',
                fontSize: len > 7 ? 8 : 10,
                fontWeight: isSelected ? 'bold' : 'normal',
              },
            },
          ]);
        }

        addFooterBar();

        settings.exports.mixedTable.push(mixedTable);
      }
    }
  } else {
    for (const item of charts) {
      arr.push(item.toDataURL('image/png'));
      widthArr.push(item.width);
      heightArr.push(item.height);
    }
  }

  settings.exports.imageUrl = arr;
  settings.exports['width'] = widthArr;
  settings.exports['height'] = heightArr;
  charts = [];
}
