<div class="z-fx-col z-fx-auto z-fx-center-center builder-base">
  <div class="z-fx-col z-fx-auto builder-container">
    <div class="builder-header">
      <div class="z-fx z-fx-start-start">
        <p class="z-fx-auto builder-header-text" i18n>Custom chart creator</p>
        <mat-icon class="z-fx-right-center builder-header-icon" (click)="close()">close</mat-icon>
      </div>
    </div>
    <div class="z-fx z-fx-auto builder-panels">
      <div class="z-fx-col z-fx-none dimension-panel">
        <ng-scrollbar class="z-fx-col z-fx-auto">
          <div class="z-fx-col z-fx-auto content">
            <div *ngIf="dimensionInput" class="z-fx z-fx-start-center z-fx-gap-8 dimension imported">
              <mat-icon>link1</mat-icon>
              <div class="text">
                {{ dimensionInput.title }}
              </div>
            </div>
            <div
              *ngFor="let dimension of selectedDimensions; trackBy: trackByKey; let i = index"
              class="z-fx z-fx-start-center dimension"
            >
              <zef-select
                class="dimension-selector"
                [class.deletable]="selectedDimensions.length > 1 || dimensionInput"
                type="combo"
                [placeholder]="placeholderText"
                [canSearch]="true"
                [value]="selectedDimensions[i]"
                (change)="select($event, dimension, i)"
                (search)="searchUpdate.next($event)"
                (close)="closeSelect()"
              >
                <mat-icon *selectPrefix>plus</mat-icon>
                <zef-select-category [category]="categories.groups">
                  <ng-container *ngFor="let group of filteredGroups; trackBy: trackByKey">
                    <zef-select-option [value]="group">
                      <mat-icon *selectPrefix [ngClass]="group?.iconClass">
                        {{ group.icon }}
                      </mat-icon>
                      <ng-container *selectOptionLabel>
                        <span>
                          {{ group.title }}
                        </span>
                      </ng-container>
                    </zef-select-option>
                  </ng-container>
                </zef-select-category>
                <zef-select-category [category]="categories.questions">
                  <ng-container *ngFor="let question of filteredQuestions; trackBy: trackByKey">
                    <zef-select-option [value]="question">
                      <mat-icon *selectPrefix [ngClass]="question?.iconClass">
                        {{ question.icon }}
                      </mat-icon>
                      <ng-container *selectOptionLabel>
                        {{ question.title }}
                      </ng-container>
                    </zef-select-option>
                  </ng-container>
                </zef-select-category>
                <zef-select-category [category]="categories.dimensions">
                  <ng-container *ngFor="let dimension of filteredDimensions; trackBy: trackByKey">
                    <zef-select-option [value]="dimension">
                      <mat-icon *selectPrefix [ngClass]="dimension?.iconClass">
                        {{ dimension.icon }}
                      </mat-icon>
                      <ng-container *selectOptionLabel>
                        {{ dimTitleFn(dimension) }}
                      </ng-container>
                    </zef-select-option>
                  </ng-container>
                </zef-select-category>
              </zef-select>
              <button
                *ngIf="selectedDimensions.length > 1 || dimensionInput"
                class="delete"
                mat-icon-button
                matTooltip="Remove"
                matTooltipPosition="above"
                i18n-matTooltip
                (click)="deleteDimension(i)"
              >
                <mat-icon color="ink">action_delete</mat-icon>
              </button>
            </div>
            <div class="z-fx z-fx-auto z-fx-start-center">
              <button
                mat-raised-button
                class="zef-placeholder-button small add-new-dimension inline"
                color="primary"
                (click)="newDimension()"
              >
                <mat-icon>plus</mat-icon>
                <span i18n>Add new dimension...</span>
              </button>
            </div>
          </div>
        </ng-scrollbar>
      </div>
      <div class="z-fx-col z-fx-auto chart-panel">
        <ng-scrollbar class="z-fx-col z-fx-auto" [autoHeightDisabled]="false" #sb>
          <div class="z-fx-col z-fx-auto content">
            <div *ngIf="gridItem?.data?.details?.length" class="z-fx z-fx-auto grid-item">
              <chart-card
                class="z-fx-col z-fx-auto grid-item"
                [style.height]="
                  gridItem.gridSettings.sizey * calculations.gridHeightUnit(gridItem.gridSettings.sizey) + 'px'
                "
                [settings]="gridItem"
                [data]="gridItem.data"
                [update]="gridItem.data.timestamp"
                [height]="gridItem.gridSettings.sizey"
                [width]="gridItem.gridSettings.sizex"
                [visibleArea]="[0, 1000, 210]"
              >
                <span>{{ gridItem.title }}</span>
              </chart-card>
            </div>
          </div>
        </ng-scrollbar>
      </div>
    </div>
    <div class="z-fx z-fx-end-center z-fx-gap-4 footer">
      <button mat-raised-button color="secondary" i18n (click)="close()">Cancel</button>
      <button mat-raised-button color="primary" i18n (click)="save()">Save</button>
    </div>
  </div>
</div>
