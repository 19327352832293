<div class="z-fx-col z-fx-auto list-mode-container" [class.search]="showSearchBar">
  <div *ngIf="title" class="title">{{ title }}</div>
  <div *ngIf="showSearchBar && !hideToolbar" class="toolbar search-box">
    <zef-searchbox (input)="searchUpdate$.next($event)">
      <span i18n>Find...</span>
    </zef-searchbox>
  </div>
  <div *ngIf="!hideToolbar" class="z-fx z-fx-end-center toolbar">
    <div class="toolbar-item"></div>
    <div class="z-fx z-fx-around-center toolbar-item stats">
      <div *ngIf="chartType === chartTypes.BARCHARTH" class="z-fx z-fx-around-center z-fx-gap-8">
        <div class="z-fx z-fx-around-center">
          <mat-icon class="icon">contact</mat-icon>
          <span numberTransition class="number" [data]="responses" [duration]="750"></span>
        </div>
      </div>
    </div>
    <div class="toolbar-item">
      <ng-container *ngIf="sortKey$ | async as sortkey">
        <ng-container *ngIf="sortDirection$ | async as sortdir">
          <div class="z-fx z-fx-start-center">
            <button mat-icon-button [@rotate]="sortdir !== 'asc'" (click)="onSortDir()">
              <mat-icon class="zef-color-primary">sort_up</mat-icon>
            </button>

            <zef-select [value]="sortkey" type="inline-p2" (change)="onSortColumn($event)">
              <zef-select-option value="survey" i18n>Original</zef-select-option>
              <zef-select-option value="title" i18n>Alphabetical</zef-select-option>
              <zef-select-option value="value" i18n>Count</zef-select-option>
            </zef-select>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div *ngIf="legends && legends.length" class="z-fx z-fx-wrap z-fx-center-center legends">
    <div
      *ngFor="let legend of legends; trackBy: trackBy; let i = index"
      class="legend"
      (mouseover)="onLegendHover(i)"
      (mouseleave)="onLegendHover(null)"
      (click)="onFilterLegend(i)"
    >
      <div class="label">
        <span class="circle" [style.background-color]="legend.color"></span>
        <span class="text-label">{{ legend.label }}</span>
      </div>
      <div class="z-fx z-fx-center-center z-fx-gap-8 stats">
        <div class="z-fx z-fx-around-center">
          <mat-icon class="icon">contact</mat-icon>
          <span class="number respondents">{{ legend.responses }}</span>
        </div>
      </div>
    </div>
  </div>
  <div
    #tooltip
    *ngIf="hoveredElement != null || hoveredLegend != null"
    class="item-tooltip"
    [innerHtml]="hoveredContent"
    [style.transform]="'translate(' + tooltipPosition[0] + 'px,' + tooltipPosition[1] + 'px)'"
  ></div>

  <div class="z-fx-flex z-fx-col table-content" (elementResize)="contentAreaResize($event)">
    <div
      *ngIf="anonymityLock"
      class="bar-anonymity-lock"
      matTooltip="Chart is locked to protect anonymity of the respondent"
      matTooltipClass="zef-tooltip-ink-lighter"
      i18n-matTooltip
      matTooltipPosition="above"
    >
      <mat-icon class="zef-icon-small">lock</mat-icon>
    </div>
    <ng-container *ngIf="data[dataIndex || 0]?.length < vsEnableAmount; else vs">
      <ng-scrollbar *ngIf="chartBarHeight" [style.height.px]="chartsAreaHeight">
        <div class="z-fx-col z-fx-auto">
          <div class="z-fx-col z-fx-auto table-container">
            <!-- <ng-container *ngTemplateOutlet="table"></ng-container> -->
            <div
              *ngFor="let element of chartTable$ | async; trackBy: trackBy"
              class="z-fx z-fx-start-start bar-chart-table-row"
              [style.height.px]="chartBarHeight"
            >
              <ng-container *ngTemplateOutlet="basicTableRow; context: { element: element }"></ng-container>
            </div>
          </div>
        </div>
      </ng-scrollbar>
    </ng-container>
    <ng-template #vs>
      <ng-container *ngIf="chartBarHeight">
        <cdk-virtual-scroll-viewport
          *ngIf="!vsHide"
          [itemSize]="chartBarHeight"
          class="ps"
          [style.height.px]="chartsAreaHeight"
        >
          <div
            *cdkVirtualFor="let element of chartTable$ | async; trackBy: trackBy"
            class="z-fx z-fx-start-start bar-chart-table-virtual-row"
            [style.height.px]="chartBarHeight"
          >
            <ng-container *ngTemplateOutlet="basicTableRow; context: { element: element }"></ng-container>
          </div>
        </cdk-virtual-scroll-viewport>
      </ng-container>
    </ng-template>
    <ng-container *ngIf="showFooter(); then footerRow"></ng-container>
  </div>
</div>

<ng-template let-element="element" #basicTableRow>
  <div
    *ngFor="let column of displayedColumns"
    class="cell"
    [style.width]="getColumnWidth(column)"
    [class.locked]="!filtering || anonymityLock"
    (mouseover)="onHover(element)"
    (mouseleave)="onHover(null)"
    (click)="onFilter(element)"
  >
    <div
      *ngIf="column === 'title'"
      #titleContainer
      class="z-fx z-fx-end-center choice-title"
      [class.hovered]="hoveredElement === element.index"
      [class.hasImages]="showImages"
      [style.align-items]="!showMoreButton(titleContainer, titleSpan) ? 'center' : 'flex-start'"
      [style.padding-right]="hideToolbar ? '0' : null"
    >
      <span #titleSpan class="title-container">
        {{ element.title }}
      </span>
      <mat-icon
        *ngIf="showMoreButton(titleContainer, titleSpan)"
        class="show-more-texts"
        (click)="expandLabels(); $event.stopPropagation()"
      >
        more
      </mat-icon>
      <mat-icon
        *ngIf="expandedTitles && showLessButton(titleContainer, titleSpan)"
        fontSet="md"
        class="show-less-texts"
        (click)="shrinkLabels(); $event.stopPropagation()"
      >
        expand_less
      </mat-icon>
    </div>
    <div
      *ngIf="column === 'image'"
      class="z-fx z-fx-center-center image-cell"
      [class.hovered]="hoveredElement === element.index"
    >
      <div *ngIf="element.imageUrl as imageUrl" class="image-container">
        <img class="image" src="{{ imageUrl }}" [style.object-fit]="element.imageMode" alt="bar-image" />
      </div>
    </div>
    <div
      *ngIf="column === 'bar'"
      class="bar"
      [class.hovered]="hoveredElement === element.index"
      [class.hasImages]="showImages"
    >
      <ng-container *ngIf="chartBarHeight">
        <ng-container *ngTemplateOutlet="chart; context: { element: element }"></ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #footerRow>
  <div class="z-fx bar-chart-table-footer-row" [style.top.px]="chartsAreaHeight">
    <div class="choice-title" [style.padding-right]="hideToolbar ? '0' : null"></div>
    <div *ngIf="showImages" class="image"></div>
    <div class="bar" [class.hasImages]="showImages">
      <div
        class="bar-chart-scale"
        scaleX
        [scale]="[0, max]"
        [previousScale]="[0, previousMax]"
        [percentageValues]="percentageValues"
        [previousPercentageValues]="previousPercentageValues"
        [update]="Math.max(update?.valueOf(), sizeUpdate?.valueOf())"
        [hover]="hoveredElement != null"
        [transitionDuration]="transitionDuration"
      ></div>
    </div>
  </div>
</ng-template>

<ng-template let-element="element" #chart>
  <ng-container [ngSwitch]="chartType">
    <div
      *ngSwitchCase="chartTypes.BARCHARTH"
      class="bar-chart-container"
      [class.hovered]="hoveredElement === element.index"
      singleBarChartH
      [value]="element.value"
      [previousValue]="element.previousValue"
      [colors]="element.colorScale"
      [scale]="[0, max]"
      [previousScale]="[0, previousMax]"
      [showNumbers]="showNumbers"
      [percentageValues]="percentageValues"
      [previousPercentageValues]="previousPercentageValues"
      [drawHelperLines]="true"
      [update]="Math.max(update?.valueOf(), sizeUpdate?.valueOf())"
      [hover]="hoveredElement === element.index"
      [transitionDuration]="transitionDuration"
    ></div>
    <div
      *ngSwitchCase="chartTypes.MULTIBARCHARTH"
      class="bar-chart-container"
      [class.hovered]="hoveredElement === element.index"
      multiBarChartH
      [values]="element.childValues"
      [previousValues]="element.previousChildValues"
      [colors]="element.colors"
      [scale]="[0, max]"
      [previousScale]="[0, previousMax]"
      [showNumbers]="showNumbers"
      [percentageValues]="percentageValues"
      [previousPercentageValues]="previousPercentageValues"
      [drawHelperLines]="true"
      [update]="Math.max(update?.valueOf(), sizeUpdate?.valueOf())"
      [hover]="hoveredElement === element.index"
      [currentHoveredBar]="hoveredBar"
      [transitionDuration]="transitionDuration"
      [selections]="element.childSelections"
      (hoveredBar)="newHoveredBar($event)"
    ></div>
    <div
      *ngSwitchCase="chartTypes.STACKEDBARCHARTH"
      class="bar-chart-container"
      [class.hovered]="hoveredElement === element.index"
      stackedBarChartH
      [values]="element.childValues"
      [previousValues]="element.previousChildValues"
      [colors]="element.colors"
      [scale]="[0, max]"
      [previousScale]="[0, previousMax]"
      [showNumbers]="showNumbers"
      [percentageValues]="percentageValues"
      [previousPercentageValues]="previousPercentageValues"
      [drawHelperLines]="true"
      [update]="Math.max(update?.valueOf(), sizeUpdate?.valueOf())"
      [hover]="hoveredElement === element.index"
      [currentHoveredBar]="hoveredBar"
      [transitionDuration]="transitionDuration"
      [selections]="element.childSelections"
      (hoveredBar)="newHoveredBar($event)"
    ></div>
  </ng-container>
</ng-template>
