import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { Charts } from '@shared/enums/charts.enum';
import { LicenseFeature } from '@shared/enums/license-feature.enum';
import { Questions } from '@shared/enums/questions.enum';

import { TeamFeature } from '@shared/models/features.model';

import { AccountState } from '@shared/states/account.state';
import { BillingState } from '@shared/states/billing.state';

import {
  ChartContent,
  ChartSettings,
  CrossfilterData,
  DimensionData,
  DimensionDataItem,
  Exports,
  GridItem,
  GridItemData,
  GridItemDataItem,
} from '@shared/models/report.model';

import { Crossfilter } from '@report/shared/services/crossfilter.service';
import { DataConverter } from '@report/shared/services/data-converter.service';

import { generatePollHeader, isField } from '@report/shared/utilities/report.utilities';

/**
 * This is a charts manager service which generates and updates charting grid array used in charts view.
 */
@Injectable({
  providedIn: 'root',
})
export class ChartsManager {
  private originalCharts: { [name: string]: string } = {};
  private originalComparisonCharts: { [name: string]: string } = {};

  private cfSub: any;
  private cfNewCardSub: any;
  private cfRemoveCardSub: any;
  private pinnedSub: any;

  private timePeriod: string;

  public activeLocale: string = '';

  public grid: GridItem[] = [];
  public visibleGridItems: boolean[] = [];

  public blankForReason: boolean = false;

  public imageSubj = new BehaviorSubject<string>('');

  public gridSubj = new BehaviorSubject<GridItem[]>(this.grid);

  public updateSubj = new BehaviorSubject<boolean>(false);

  public gridChange = new BehaviorSubject<string | null>(null);

  public closeMenusSubj = new BehaviorSubject<boolean>(false);

  constructor(
    private cf: Crossfilter,
    private dc: DataConverter,
    private store: Store,
  ) {}

  connect(grid: GridItem[]) {
    this.grid = grid || [];
    this.gridSubj.next([]);
    this.cfSub = this.cf.crossfilter.subscribe((crossfilter: CrossfilterData | null) => {
      this.activeLocale = this.cf.getActiveLocale();

      if (crossfilter) {
        if (this.timePeriod && this.timePeriod !== this.cf.getTimePeriod()) {
          this.closeMenusSubj.next(true);
        }

        this.timePeriod = this.cf.getTimePeriod();
      }
      if (crossfilter && this.grid.length < 1) {
        this.generateGrid(crossfilter);
      } else if (crossfilter && this.grid.length > 1) {
        this.updateGrid(crossfilter);
      }
    });
    this.cfNewCardSub = this.cf.newChartCard.subscribe((dataItems: GridItemData | null) => {
      if (dataItems != null) {
        this.createNewGridItem(dataItems);
      }
    });
    this.cfRemoveCardSub = this.cf.removeChartCard.subscribe((dataItems: GridItemData | null) => {
      if (dataItems != null) {
        for (const item in dataItems) {
          const gridIndex: number = this.grid.findIndex((gItem) => gItem.key === item);
          if (gridIndex >= 0) {
            this.removeGridItem(gridIndex);
          }
        }
      }
    });
  }

  updateItemVisibility(gridId: number, status: boolean) {
    this.visibleGridItems[gridId] = status;
  }

  getVisibleItems() {
    return this.grid.filter((item) => this.visibleGridItems[item.gridId]);
  }

  disconnectGrid() {
    if (this.cfSub) {
      this.cfSub.unsubscribe();
    }
    if (this.pinnedSub) {
      this.pinnedSub.unsubscribe();
    }
    if (this.cfNewCardSub) {
      this.cfNewCardSub.unsubscribe();
    }
    if (this.cfRemoveCardSub) {
      this.cfRemoveCardSub.unsubscribe();
    }
  }

  reset() {
    this.grid = [];
    this.originalCharts = {};
    this.originalComparisonCharts = {};

    this.disconnectGrid();

    this.gridSubj.next(this.grid);
    this.gridChange.next(null);
  }

  generateGrid(data: CrossfilterData) {
    this.generateCards(data.gridItems, data.dimensions, data.stats);
    this.gridSubj.next(this.grid);
  }

  changeOrder(fromIndex: number, toIndex: number): void {
    const clamp = (value: number, max: number) => Math.max(0, Math.min(max, value));

    const from = clamp(fromIndex, this.grid.length - 1);
    const to = clamp(toIndex, this.grid.length - 1);

    if (from !== to) {
      const target = this.grid[from];
      const delta = to < from ? -1 : 1;

      for (let i = from; i !== to; i += delta) {
        this.grid[i] = this.grid[i + delta];
        this.grid[i]['gridId'] = i;
      }

      this.grid[to] = target;
      this.grid[to]['gridId'] = to;

      this.gridSubj.next(this.grid);
    }
  }

  updateGrid(data: CrossfilterData) {
    for (const item of this.grid) {
      const key: string = item['key'];
      let difference: number = 0;
      if (data.gridItems[key]) {
        item.hided = data.gridItems[key].disabled;
      }

      if (
        !item.isNotesCard &&
        !item.isSubheader &&
        data.gridItems[key] &&
        !item.hided &&
        (item.chartSettings.type || this.originalCharts[key] || this.originalComparisonCharts[key])
      ) {
        const convertedData: ChartContent = this.dc.BasicConverter(data.gridItems[key]);

        if (item['key'] !== 'time' && (item['data']['trend'] || convertedData['trend'])) {
          // Saving original chart type to be used after comparison
          if (!item['data']['trend'] && Charts.TRENDCHARTS.indexOf(item.chartSettings.type) < 0) {
            if (item['data']['comparison'] && !this.originalComparisonCharts[key]) {
              this.originalComparisonCharts[key] = item.chartSettings.type;
            } else if (!this.originalCharts[key]) {
              this.originalCharts[key] = item.chartSettings.type;
            }
          }
          // Saving guess of original chart when saved report is used and thus original chart is not available
          if (convertedData['trend'] && !this.originalCharts[key]) {
            this.originalCharts[key] = this.chartTypeRecommendation(
              convertedData['details']
                .filter((detail) => !convertedData['comparison'] || convertedData['comparison']['key'] !== detail.key)
                .filter((detail) => detail.key !== 'time')
                .map((detail) => detail.scale),
              convertedData['details']
                .filter((detail) => !convertedData['comparison'] || convertedData['comparison']['key'] !== detail.key)
                .filter((detail) => detail.key !== 'time'),
            );
          }
          // Saving guess of original comparison chart when saved report is used and thus chart is not available
          if (convertedData['comparison'] && convertedData['trend'] && !this.originalComparisonCharts[key]) {
            this.originalComparisonCharts[key] =
              this.comparisonChartRecommendation(
                convertedData['scales'].filter((scale) => scale !== 'time'),
                this.originalCharts[key],
              ) ||
              this.originalCharts[key] ||
              this.chartTypeRecommendation(
                convertedData['scales'].filter((scale) => scale !== 'time'),
                convertedData['details'].filter((detail) => detail.key !== 'time'),
              );
          }

          if (
            (!item['data']['trend'] && convertedData['trend']) ||
            (item['data']['trend'] && !convertedData['trend'])
          ) {
            if (convertedData['trend']) {
              if (Charts.TRENDCHARTS.indexOf(item.chartSettings.type) < 0) {
                item.chartSettings.type = this.trendChartRecommendation(
                  convertedData['details'],
                  this.originalCharts[key],
                );
              }
            } else if (!convertedData['trend']) {
              if (convertedData['comparison']) {
                if (
                  this.originalComparisonCharts[key] &&
                  item.chartSettings.type !== this.originalComparisonCharts[key]
                ) {
                  item.chartSettings.type = this.originalComparisonCharts[key];
                  delete this.originalComparisonCharts[key];
                }
              } else {
                if (this.originalCharts[key] && item.chartSettings.type !== this.originalCharts[key]) {
                  item.chartSettings.type = this.originalCharts[key];
                  delete this.originalCharts[key];
                }
              }
            }
            this.closeMenusSubj.next(true);

            difference =
              this.chartHeightRecommendation(
                convertedData,
                item.chartSettings.type,
                convertedData['comparison'],
                item.chartSettings,
              ) - item.gridSettings.sizey;

            item.gridSettings.sizey += difference;
          }
          if (
            (!item['data']['comparison'] && convertedData['comparison']) ||
            (item['data']['comparison'] && !convertedData['comparison']) ||
            (item['data']['comparison'] &&
              convertedData['comparison'] &&
              item['data']['comparison'].values &&
              convertedData['comparison'].values &&
              item['data']['comparison'].values.length !== convertedData['comparison'].values.length)
          ) {
            this.closeMenusSubj.next(true);

            difference =
              this.chartHeightRecommendation(
                convertedData,
                item.chartSettings.type,
                convertedData['comparison'],
                item.chartSettings,
              ) - item.gridSettings.sizey;

            item.gridSettings.sizey += difference;
          }
        } else if (item['data']['comparison'] || convertedData['comparison']) {
          // Saving original chart type to be used after comparison
          if (item['data']['distributions'] && !item['data']['comparison'] && !this.originalCharts[key]) {
            this.originalCharts[key] = item.chartSettings.type;
          }
          // Saving guess of original chart when saved report is used and thus original chart is not available
          if (
            convertedData['comparison'] &&
            item['data']['scales'] &&
            item['data']['scales'].length === 2 &&
            !this.originalCharts[key] &&
            Charts.SUMMARYCHARTS.indexOf(item.chartSettings.type) < 0 &&
            Charts.CUSTOMCHARTS.indexOf(item.chartSettings.type) < 0
          ) {
            this.originalCharts[key] = this.chartTypeRecommendation(
              [item['data']['scales'][0]],
              [item['data']['details'][0]],
            );
          }

          if (
            (!item['data']['comparison'] && convertedData['comparison']) ||
            (item['data']['comparison'] && !convertedData['comparison']) ||
            (item['data']['comparison'].values &&
              convertedData['comparison'].values &&
              item['data']['comparison'].values.length !== convertedData['comparison'].values.length)
          ) {
            if (
              convertedData['comparison'] &&
              convertedData['scales'].length === 2 &&
              Charts.COMPARISONCHARTS.indexOf(item.chartSettings.type) < 0
            ) {
              item.chartSettings.type = this.comparisonChartRecommendation(
                convertedData['scales'],
                this.originalCharts[key],
              );
            } else if (!convertedData['comparison']) {
              if (this.originalCharts[key] && item.chartSettings.type !== this.originalCharts[key]) {
                item.chartSettings.type = this.originalCharts[key];
                delete this.originalCharts[key];
              }
            }
            this.closeMenusSubj.next(true);

            difference =
              this.chartHeightRecommendation(
                convertedData,
                item.chartSettings.type,
                convertedData['comparison'],
                item.chartSettings,
              ) - item.gridSettings.sizey;

            item.gridSettings.sizey += difference;
          }
        }

        item['data']['distributions'] = convertedData['distributions'];
        item['data']['stats'] = convertedData['stats'];
        item['data']['filters'] = convertedData['filters'];
        item['data']['filtersAll'] = convertedData['filtersAll'];
        item['data']['timestamp'] = convertedData['timestamp'];
        item['data']['comparison'] = convertedData['comparison'];
        item['data']['trend'] = convertedData['trend'];
        item['data']['totalAnswers'] = convertedData['totalAnswers'];
        item['data']['totalCrosstabAnswers'] = convertedData['totalCrosstabAnswers'];
        item['title'] = item['customTitle']?.[this.activeLocale || 'default']
          ? item['customTitle'][this.activeLocale || 'default']
          : item['chartSettings']?.['type'] === Charts.INTERVIEWERSUMMARY ||
              item['chartSettings']?.['type'] === Charts.WHYFINDERSUMMARY
            ? convertedData['domain']?.[1]?.['title'] && !convertedData['trend']
              ? convertedData['domain'][1]['title'].split(
                  item['chartSettings']?.['type'] === Charts.WHYFINDERSUMMARY
                    ? ` - ${$localize`themes`}`
                    : ` - ${$localize`:@@zef-i18n-00542-ma:root cause categories`}`,
                )?.[0]
              : item['title']
            : item['isGroup'] && item['key'] !== 'outcome'
              ? convertedData['domain']?.[0]?.['groupLabel']
                ? convertedData['domain'][0]['groupLabel']
                : item['title']
              : convertedData['domain']?.[0]?.['title'] && !convertedData['trend']
                ? convertedData['domain'][0]['title']
                : item['title'];

        /* Looping through domain items without changing reference of the item */
        if (
          (item['data']['domain'] && item['data']['domain'].length !== convertedData['domain'].length) ||
          JSON.stringify(item['data']['details']) !== JSON.stringify(convertedData['details'])
        ) {
          item['data']['domain'] = convertedData['domain'];
          item['data']['details'] = convertedData['details'];
          item['data']['scales'] = convertedData['scales'];
        } else if (item['data']['domain']) {
          for (const dim in item['data']['domain']) {
            for (const ditem in convertedData['domain'][dim]) {
              item['data']['domain'][dim][ditem] = convertedData['domain'][dim][ditem];
            }
          }
        } else {
          item['data']['domain'] = convertedData['domain'];
        }
      }
    }

    this.gridSubj.next(this.grid);
  }

  createNewGridItem(dataItems: GridItemData) {
    const key: string = Object.keys(dataItems)[0];
    const data = dataItems[key];
    const isContactCard: boolean = isField(key);
    const answersHeaderIndex: number = this.grid.findIndex((item) => item.key === 'Answers_header');
    const contactsHeaderIndex: number = this.grid.findIndex((item) => item.key === 'Contacts_header');
    const isFirstContactCard: boolean = contactsHeaderIndex < 0 && isContactCard;
    const isGroup = data.details.filter((item) => item.group === key).length === data.details.length;
    // let lastRow: number = 0;
    // for (let i = 0, len = this.grid.length; i < len; i++) {
    //   if (this.grid[i].gridSettings) {
    //     const itemRow: number = this.grid[i].gridSettings.row + this.grid[i].gridSettings.sizey;

    //     if (itemRow > lastRow) {
    //       lastRow = itemRow;
    //     }
    //   }
    // }

    if (isFirstContactCard) {
      const headerItem: GridItem = {
        hided: false,
        gridId: this.grid.length,
        isSubheader: true,
        key: 'Contacts_header',
        title: 'Contacts',
        showDataTable: false,
        chartSettings: {
          type: null,
        },
        isGroup: false,
        data: {} as ChartContent,
        gridSettings: {
          sizex: 2,
          sizey: 2,
          // 'col': 1,
          // 'row': lastRow,
          draggable: false,
          resizable: false,
        },
        exports: {} as Exports,
      };
      this.grid.push(headerItem);
    }

    const newItem: GridItem = {
      hided: false,
      gridId: this.grid.length,
      key,
      title: data.details[0].title, // what if 0 dimensions?
      showDataTable: false,
      chartSettings: {
        type: this.chartTypeRecommendation(data.scales, data.details),
        textSummary: this.textSummaryAvailable(data.details),
      },
      isGroup,
      isCustomCard:
        ['survey', 'lang', 'shareLink', 'hashtags', 'zefSurveyUserRating', 'responseRates'].indexOf(key) < 0,
      data: this.dc.BasicConverter(data),
      gridSettings: {
        sizex: key === 'fields-entrySummary' || isGroup ? 2 : 1,
        sizey: this.chartHeightRecommendation(data, this.chartTypeRecommendation(data.scales, data.details)),
        // 'col': this.grid[this.grid.length - 1].gridSettings.sizex === 8
        //       ? 1
        //       : this.grid[this.grid.length - 1].gridSettings.col === 1
        //         ? 5
        //         : 1,
        // 'row': lastRow + 1,
        dragHandle: '.header',
      },
      exports: {} as Exports,
    };

    if (
      key === 'survey' ||
      key === 'lang' ||
      key === 'shareLink' ||
      key === 'hashtags' ||
      key === 'zefSurveyUserRating' ||
      key === 'responseRates'
    ) {
      this.grid.push(newItem);
      this.changeOrder(newItem.gridId, answersHeaderIndex);
    } else if (!isContactCard && contactsHeaderIndex >= 0) {
      this.grid.push(newItem);
      this.changeOrder(newItem.gridId, contactsHeaderIndex);
    } else {
      this.grid.push(newItem);
    }
  }

  createGridItem(gridItem: GridItem) {
    this.grid.push(gridItem);
    this.blankForReason = false;
    this.gridSubj.next(this.grid);
  }

  duplicateGridItem(gridItem: GridItem, oldItemKey: string) {
    const oldItemIndex = this.grid.findIndex((item) => item.key === oldItemKey);
    this.createGridItem(gridItem);

    if (this.originalCharts[oldItemKey]) {
      this.originalCharts[gridItem.key] = this.originalCharts[oldItemKey];
    }

    if (this.originalComparisonCharts[oldItemKey]) {
      this.originalComparisonCharts[gridItem.key] = this.originalComparisonCharts[oldItemKey];
    }

    if (oldItemIndex >= 0) {
      this.changeOrder(this.grid.length - 1, oldItemIndex + 1);
    }

    this.gridChange.next('Added new custom chart');
  }

  removeGridItem(id: number) {
    const key = this.grid[id].key;
    this.grid.splice(id, 1);

    for (let i = 0, len = this.grid.length; i < len; i++) {
      this.grid[i]['gridId'] = i;
    }

    if (this.grid.length === 0) {
      this.blankForReason = true;
    }

    if (key.indexOf('/')) {
      const survey: string = key.split('/')[0];
      const question: string = key.split('/')[1];
      const mergeSettings = this.cf.getMergeSettings()?.mergeSettings || {};

      if (mergeSettings[survey] && mergeSettings[survey][question]) {
        this.cf.updateMergeSettings(survey, question, { hide: true });
      }
    }

    if (this.cf.gridItems[key]) {
      delete this.cf.gridItems[key];
    }

    this.gridSubj.next(this.grid);
    this.gridChange.next('Removed chart');
  }

  generateCards(gridItems: GridItemData, dimensions: DimensionData, stats: any) {
    // Here we generate index to questions based on order
    const groupedItems = { ungrouped: { order: 0, items: [] as any[] } };
    const groupArr: any[] = [];
    let isContactItems: boolean = false;
    let lastQuestion = 0;

    const canAddInsights =
      this.store.selectSnapshot(AccountState.isFeatureEnabled(TeamFeature.INSIGHTS)) &&
      this.store.selectSnapshot(BillingState.featureActive(LicenseFeature.EskoWhy)) &&
      stats?.respondents > 4 &&
      this.cf.getAnonymityTreshold() <= 1 &&
      Object.values(dimensions).filter(
        (dimension) =>
          dimension?.scale === 'categorical' &&
          dimension?.originalType !== 'free-text' &&
          dimension?.originalTypeSpecifier !== 'text-words' &&
          dimension?.originalType !== 'response-rates' &&
          dimension?.key !== 'userSegments' &&
          dimension?.values?.length > 1,
      )?.length > 0;
    let hasInsightsConnected: boolean = false;

    for (const candidate of Object.keys(gridItems)) {
      const group = gridItems[candidate].details[0].group;
      const infoText =
        gridItems[candidate].details.length === 1 &&
        gridItems[candidate].details[0].originalType === Questions.INFO_TEXT;
      const aiInterviewer: boolean =
        gridItems[candidate].details.length > 3 &&
        gridItems[candidate]?.details?.[1]?.originalType === Questions.AI_INTERVIEWER &&
        gridItems[candidate]?.details?.[2]?.originalType === Questions.AI_INTERVIEWER;
      const whyFinder: boolean =
        gridItems[candidate].details.length > 2 &&
        gridItems[candidate]?.details?.[1]?.originalType === Questions.ESKO_WHY_FINDER &&
        gridItems[candidate]?.details?.[2]?.originalType === Questions.ESKO_WHY_FINDER;

      if (
        candidate === 'time' ||
        candidate === 'outcome' ||
        candidate === 'lang' ||
        candidate === 'hashtags' ||
        candidate === 'shareLink' ||
        candidate === 'survey' ||
        candidate === 'zefSurveyUserRating' ||
        candidate === 'responseRates'
      ) {
        groupedItems[candidate] = { order: 0, key: candidate };
      } else if (
        gridItems[candidate].details[0].key.split(':')[0] !== candidate.split(':')[0] &&
        candidate !== 'fields-entrySummary' &&
        !aiInterviewer &&
        !whyFinder
      ) {
        if (groupedItems[candidate]) {
          groupedItems[candidate] = { order: 0, key: candidate, items: [] as any[] };
        }
      } else if (candidate === 'userSegments' || infoText) {
      } else if (group) {
        if (!groupedItems[group]) {
          groupedItems[group] = { order: 0, key: group, items: [] as any[] };
        }
        const item = { key: candidate, order: gridItems[candidate].details[0].order };
        if (Questions.isNps({ type: gridItems[candidate].details[0].originalType as Questions })) {
          item['nps'] = true;
          hasInsightsConnected = canAddInsights;
        }

        groupedItems[group]['items'].push(item);
      } else {
        const item = { key: candidate, order: gridItems[candidate].details[0].order };
        if (Questions.isNps({ type: gridItems[candidate].details[0].originalType as Questions })) {
          item['nps'] = true;
          hasInsightsConnected = canAddInsights;
        }

        groupedItems['ungrouped']['items'].push(item);
      }

      if (candidate !== 'time' && candidate !== 'outcome' && candidate !== 'fields-entrySummary') {
        if (gridItems[candidate].details[0].order > lastQuestion) {
          lastQuestion = gridItems[candidate].details[0].order;
        }
      }
    }

    for (const group in groupedItems) {
      if (group === 'time') {
        groupArr.push(groupedItems[group]);
      } else if (
        group === 'lang' ||
        group === 'hashtags' ||
        group === 'shareLink' ||
        group === 'survey' ||
        group === 'zefSurveyUserRating' ||
        group === 'responseRates'
      ) {
        groupedItems[group]['order'] += 0.000000000001;
        groupArr.push(groupedItems[group]);
      } else if (group === 'outcome') {
        groupedItems[group]['order'] = lastQuestion + 1;
        groupArr.push(groupedItems[group]);
      } else if (group === 'ungrouped') {
        for (const item of groupedItems['ungrouped']['items']) {
          if (isField(item['key'])) {
            item['order'] = lastQuestion + 2 - (item['key'] === 'fields-entrySummary' ? 0.1 : 0);
            isContactItems = true;
          }
          groupArr.push(item);
        }
      } else {
        groupedItems[group]['items'].sort((a, b) => a.order - b.order);
        groupedItems[group]['order'] = groupedItems[group]['items'][0].order;
        groupArr.push(groupedItems[group]);
      }
    }

    if (hasInsightsConnected) {
      groupArr.push({ order: 0.000000000001, key: 'Insights_header' });
    }

    groupArr.push({ order: 0.000000000002, key: 'Answers_header' });

    if (isContactItems) {
      groupArr.push({ order: lastQuestion + 1.5, key: 'Contacts_header' });
    }

    const pollData = this.cf.getPollData();

    if (pollData) {
      groupArr.push({ order: -0.02, key: 'Poll_header' });

      if (pollData.notes) {
        groupArr.push({ order: pollData.notesPosition === 'below' ? 999 : -0.01, key: 'Poll_notes' });
      }
    }

    groupArr.sort((a, b) => a.order - b.order);

    let gridId: number = 0;
    let colIndex: number = 1;
    let rowIndex: number = 1;

    for (const index in groupArr) {
      const group = groupArr[index];
      const aiInterviewer: boolean =
        gridItems[group.key]?.details?.filter((item) => item?.originalType === Questions.AI_INTERVIEWER).length > 3;
      const whyFinder: boolean =
        gridItems[group.key]?.details?.filter(
          (item) =>
            item?.originalType === Questions.ESKO_WHY_FINDER && item?.originalTypeSpecifier !== 'why-finder-raw',
        ).length > 1;
      if (group.key === 'Answers_header' || group.key === 'Contacts_header') {
        const headerItem: GridItem = {
          hided: false,
          gridId,
          isSubheader: true,
          key: group.key,
          title: group.key === 'Answers_header' ? 'Responses' : 'Contacts',
          showDataTable: false,
          chartSettings: {
            type: null,
          },
          isGroup: false,
          data: {} as ChartContent,
          gridSettings: {
            sizex: 2,
            sizey: 2,
            col: 1,
            row: rowIndex,
            draggable: false,
            resizable: false,
          },
          exports: {} as Exports,
        };
        this.grid[gridId] = headerItem;
        gridId += 1;
        rowIndex += headerItem['gridSettings']['sizey'];
      } else if (group.key === 'Insights_header') {
        const headerItem: GridItem = {
          hided: false,
          gridId,
          isInsightsCard: true,
          key: group.key,
          title: 'Insights',
          showDataTable: false,
          chartSettings: {
            type: null,
          },
          isGroup: false,
          data: {} as ChartContent,
          gridSettings: {
            sizex: 2,
            sizey: 9,
            col: 1,
            row: rowIndex,
            draggable: false,
            resizable: false,
          },
          exports: {} as Exports,
        };
        this.grid[gridId] = headerItem;
        gridId += 1;
        rowIndex += headerItem['gridSettings']['sizey'];
      } else if (group.key === 'Poll_header' || group.key === 'Poll_notes') {
        const pollHeaderItem: GridItem = {
          hided: false,
          gridId,
          isNotesCard: true,
          notesCardData: {
            content: group.key === 'Poll_header' ? generatePollHeader(this.cf.getPollData()) : pollData.notes,
          },
          key: group.key,
          title: '',
          showDataTable: false,
          chartSettings: {
            type: null,
          },
          isGroup: false,
          data: {} as ChartContent,
          gridSettings: {
            sizex: 2,
            sizey: 2,
            col: 1,
            row: rowIndex,
            draggable: false,
            resizable: false,
          },
          exports: {} as Exports,
        };
        this.grid[gridId] = pollHeaderItem;
        gridId += 1;
        rowIndex += pollHeaderItem['gridSettings']['sizey'];
      } else if (
        !group.items &&
        group.key !== 'time' &&
        group.key !== 'outcome' &&
        group.key !== 'fields-entrySummary' &&
        !aiInterviewer &&
        !whyFinder
      ) {
        const convertedData = this.dc.BasicConverter(gridItems[group.key]);
        const chartSettings = {
          type: this.chartTypeRecommendation(gridItems[group.key].scales, gridItems[group.key].details),
          textSummary: this.textSummaryAvailable(convertedData.details),
          ...gridItems[group.key].defaultChartSettings,
        };
        const questionItem: GridItem = {
          hided: gridItems[group.key].disabled,
          gridId,
          key: group.key,
          title: convertedData.domain[0].title, // what if 0 dimensions?
          showDataTable: false,
          chartSettings,
          isGroup: false,
          data: convertedData,
          gridSettings: {
            sizex: 1,
            sizey: this.chartHeightRecommendation(
              gridItems[group.key],
              this.chartTypeRecommendation(gridItems[group.key].scales, gridItems[group.key].details),
              null,
              chartSettings,
            ),
            col: colIndex,
            row: rowIndex,
            dragHandle: '.header',
            textSummary: this.textSummaryAvailable(convertedData.details),
          },
          exports: {} as Exports,
        };
        this.grid[gridId] = questionItem;
        gridId += 1;
        colIndex = colIndex === 1 ? 5 : 1;
        rowIndex += colIndex === 5 ? questionItem['gridSettings']['sizey'] : 0;
      } else if (group.key === 'time') {
        const convertedData = this.dc.BasicConverter(gridItems[group.key]);
        const timeItem: GridItem = {
          hided: gridItems[group.key].disabled,
          gridId,
          key: group.key,
          title: convertedData.domain[0].title,
          showDataTable: false,
          chartSettings: {
            type: this.chartTypeRecommendation(gridItems[group.key].scales, gridItems[group.key].details),
            ...gridItems[group.key].defaultChartSettings,
          },
          isGroup: false,
          data: convertedData,
          gridSettings: {
            sizex: 2,
            sizey: this.chartHeightRecommendation(
              gridItems[group.key],
              this.chartTypeRecommendation(gridItems[group.key].scales, gridItems[group.key].details),
            ),
            col: 1,
            row: rowIndex,
            dragHandle: '.header',
          },
          exports: {} as Exports,
        };
        this.grid[gridId] = timeItem;
        gridId += 1;
        rowIndex += timeItem['gridSettings']['sizey'];
      } else if (group.key === 'fields-entrySummary') {
        const convertedData = this.dc.BasicConverter(gridItems[group.key]);
        const summaryItem: GridItem = {
          hided: gridItems[group.key].disabled,
          gridId,
          key: group.key,
          title: $localize`Email / Phone`,
          showDataTable: false,
          chartSettings: {
            type: this.chartTypeRecommendation(gridItems[group.key].scales, gridItems[group.key].details),
            ...gridItems[group.key].defaultChartSettings,
          },
          isGroup: false,
          data: convertedData,
          gridSettings: {
            sizex: 2,
            sizey: this.chartHeightRecommendation(
              gridItems[group.key],
              this.chartTypeRecommendation(gridItems[group.key].scales, gridItems[group.key].details),
            ),
            col: 1,
            row: rowIndex,
            dragHandle: '.header',
          },
          exports: {} as Exports,
        };
        this.grid[gridId] = summaryItem;
        gridId += 1;
        rowIndex += summaryItem['gridSettings']['sizey'];
      } else if (group.key === 'outcome' || aiInterviewer || whyFinder) {
        const convertedData = this.dc.BasicConverter(gridItems[group.key]);
        const questionItem: GridItem = {
          hided: gridItems[group.key].disabled,
          gridId,
          key: group.key,
          title:
            !aiInterviewer && !whyFinder
              ? convertedData.domain[0].title
              : whyFinder
                ? convertedData.domain[1].title?.split(` - ${$localize`themes`}`)?.[0]
                : convertedData.domain[1].title?.split(
                    ` - ${$localize`:@@zef-i18n-00542-ma:root cause categories`}`,
                  )?.[0],
          showDataTable: false,
          chartSettings: {
            type: this.chartTypeRecommendation(gridItems[group.key].scales, gridItems[group.key].details),
            ...gridItems[group.key].defaultChartSettings,
          },
          isGroup: true,
          data: convertedData,
          gridSettings: {
            sizex: 2,
            sizey: this.chartHeightRecommendation(
              gridItems[group.key],
              this.chartTypeRecommendation(gridItems[group.key].scales, gridItems[group.key].details),
            ),
            col: 1,
            row: rowIndex,
            dragHandle: '.header',
          },
          exports: {} as Exports,
        };
        this.grid[gridId] = questionItem;
        gridId += 1;
        rowIndex += questionItem['gridSettings']['sizey'];
      } else {
        const convertedData = this.dc.BasicConverter(gridItems[group.key]);
        colIndex = 1;
        // create group card
        const linear = gridItems[group.key].scales.filter((item) => item === 'linear').length > 0 ? true : false;
        const twods =
          gridItems[group.key].details.filter((item) => item.originalType === Questions.SLIDER_2D).length > 1;
        const choices =
          gridItems[group.key].scales.filter((item) => item === 'categorical').length /
            gridItems[group.key].scales.length >
          0.7;
        const chartType =
          linear && twods ? Charts.SUMMARY2D : linear ? Charts.SUMMARY1D : choices ? Charts.SUMMARYCHOICE : '';
        const chartSettings = {
          type: chartType,
          textSummary: this.textSummaryAvailable(convertedData.details),
          ...(chartType === Charts.SUMMARYCHOICE ? { scale: 'percentage' } : {}),
          ...gridItems[group.key].defaultChartSettings,
        };
        const sizey =
          linear || choices ? this.chartHeightRecommendation(gridItems[group.key], chartType, null, chartSettings) : 2;
        const groupItem: GridItem = {
          hided: gridItems[group.key].disabled,
          gridId,
          key: group.key,
          title: convertedData.domain[0].groupLabel, // what if 0 dimensions?
          showDataTable: false,
          chartSettings,
          isGroup: true,
          data: convertedData,
          gridSettings: {
            sizex: 2,
            sizey,
            col: 1,
            row: rowIndex,
            dragHandle: '.header',
          },
          exports: {} as Exports,
        };
        this.grid[gridId] = groupItem;
        gridId += 1;

        rowIndex += groupItem['gridSettings']['sizey'];
        let column = 1;
        // loop questions after group card
        for (let q = 0; q < group.items.length; q++) {
          if (group.items.length === 1) {
            column = 3;
          }
          const question = group.items[q];
          const convertedQuestionData = this.dc.BasicConverter(gridItems[question.key]);
          const isAIInterviewer: boolean =
            gridItems[question.key]?.details?.filter((item) => item?.originalType === Questions.AI_INTERVIEWER).length >
            3;
          const isWhyFinder: boolean =
            gridItems[question.key]?.details?.filter((item) => item?.originalType === Questions.ESKO_WHY_FINDER)
              .length > 1;
          const chartSettingsQuestion = {
            type: this.chartTypeRecommendation(gridItems[question.key].scales, gridItems[question.key].details),
            textSummary: this.textSummaryAvailable(convertedQuestionData.details),
            ...gridItems[question.key].defaultChartSettings,
          };
          const questionItem: GridItem = {
            hided: gridItems[question.key].disabled,
            gridId,
            key: question.key,
            title: !isWhyFinder
              ? convertedQuestionData.domain[0].title
              : convertedQuestionData.domain[1]?.title?.split(` - ${$localize`themes`}`)?.[0] ||
                convertedQuestionData.domain[0].title,
            showDataTable: false,
            chartSettings: chartSettingsQuestion,
            isGroup: false,
            data: convertedQuestionData,
            gridSettings: {
              sizex: !isAIInterviewer && !isWhyFinder ? 1 : 2,
              sizey: this.chartHeightRecommendation(
                gridItems[question.key],
                this.chartTypeRecommendation(gridItems[question.key].scales, gridItems[question.key].details),
                null,
                chartSettingsQuestion,
              ),
              col: column,
              row: rowIndex,
              dragHandle: '.header',
            },
            exports: {} as Exports,
          };
          this.grid[gridId] = questionItem;
          gridId += 1;
          column = column === 5 && q === group.items.length - 2 ? 3 : column === 1 ? 5 : 1;
          rowIndex += column === 5 ? 0 : questionItem['gridSettings']['sizey'];
        }
      }
    }
  }

  chartTypesRecommendation(scales: string[], details: DimensionDataItem[], comparison: any) {
    const suitableTypes: string[] = [];

    const linearNumber = (scales || []).filter((item) => item === 'linear').length;
    const categoricalNumber = (scales || []).filter((item) => item === 'categorical').length;
    const time = (scales || []).filter((item) => item === 'time').length;
    const text = (scales || []).filter((item) => item === 'text' || item === 'contact-text').length;
    const sentiment = (details || []).filter((item) => item.originalTypeSpecifier === 'text-sentiment').length;
    const words = (details || []).filter((item) => item.originalTypeSpecifier === 'text-words').length;
    const emotions = (details || []).filter((item) => item.originalTypeSpecifier === 'text-emotions').length;
    const aiInterviewer = (details || []).filter((item) => item.originalType === Questions.AI_INTERVIEWER).length;
    const whyFinder = (details || []).filter((item) => item.originalType === Questions.ESKO_WHY_FINDER).length;
    const responseRate = (details || []).filter((item) => item.originalType === 'response-rates').length;

    if (text > 0) {
      suitableTypes.push(Charts.TEXTANSWERTABLE);
    } else if (linearNumber > 0 && words === 0) {
      suitableTypes.push(Charts.BARCHARTV);
    } else if (categoricalNumber > 0 && words === 0 && responseRate === 0) {
      suitableTypes.push(Charts.PIECHART, Charts.DONUTCHART, Charts.MULTIDONUTCHART);
    }

    if (responseRate > 0) {
      suitableTypes.push(Charts.RESPONSERATES);
    }

    if (aiInterviewer > 3) {
      suitableTypes.push(Charts.INTERVIEWERSUMMARY);
    }

    if (whyFinder > 1) {
      suitableTypes.push(Charts.WHYFINDERSUMMARY);
    }

    if (linearNumber > 0) {
      suitableTypes.push(Charts.SUMMARY1D);
    }

    if (linearNumber === 1 && text === 0 && words === 0) {
      suitableTypes.push(Charts.NPS);
    }

    if (linearNumber > 1) {
      suitableTypes.push(Charts.SUMMARY2D);
    }

    if (categoricalNumber > 0 && text === 0 && words === 0 && responseRate === 0) {
      suitableTypes.push(Charts.BARCHARTH);
    }

    if (
      emotions === 1 &&
      text === 1 &&
      (categoricalNumber === 1 ||
        (categoricalNumber === 2 && comparison && comparison.values && comparison.values.length)) &&
      words === 0
    ) {
      suitableTypes.push(Charts.TEXTEMOTIONS);
    }

    // if (
    //   (categoricalNumber === 1 ||
    //     (categoricalNumber === 2 && comparison && comparison.values && comparison.values.length)) &&
    //   text === 1 &&
    //   linearNumber === 0
    // ) {
    //   suitableTypes.push(Charts.BARCHARTHPLUSTEXT);
    // }

    // We hide word cloud from recommendations and we recommend word analysis instead
    // if (text > 0 || categoricalNumber > 0) {
    //   suitableTypes.push(Charts.WORDCLOUD);
    // }

    if (
      sentiment === 1 &&
      text === 1 &&
      (categoricalNumber === 0 ||
        (categoricalNumber === 1 && comparison && comparison.values && comparison.values.length)) &&
      linearNumber === 1 &&
      words === 0
    ) {
      suitableTypes.push(Charts.TEXTSENTIMENTANALYSIS);
    }

    if (
      words > 0 &&
      sentiment === 0 &&
      (categoricalNumber < 2 ||
        (categoricalNumber === 2 && comparison && comparison.values && comparison.values.length)) &&
      linearNumber === 0 &&
      text === 0 &&
      time === 0
    ) {
      suitableTypes.push(Charts.WORDANALYSIS);
    } else if (words > 0) {
      suitableTypes.push(Charts.TEXTANSWERTABLE);
    }

    if (
      !(categoricalNumber > 0 && linearNumber < 3 && linearNumber > 0) &&
      !(categoricalNumber === 1 && linearNumber === 0 && text > 0) &&
      (categoricalNumber > 0 || linearNumber > 2) &&
      !(categoricalNumber > 0 && text > 0) &&
      words === 0 &&
      responseRate === 0
    ) {
      suitableTypes.push(Charts.RADARCHART);
    }

    if (time > 0 && words === 0 && emotions === 0) {
      if (text === 0) {
        suitableTypes.push(Charts.BARCHARTV, Charts.LINECHART);
      }

      if ((scales || []).length <= 2 && scales[0] === 'time' && text === 0) {
        suitableTypes.push(Charts.TIMELINECHART);
      }

      if (categoricalNumber > 0) {
        suitableTypes.push(Charts.MULTILINECHART);
        suitableTypes.push(Charts.TRENDCATEGORICALCHART);
      }
      if (linearNumber > 0) {
        suitableTypes.push(Charts.TRENDLINEAR1DCHART);

        if (linearNumber === 1) {
          suitableTypes.push(Charts.TRENDNPSCHART);
        }

        if (linearNumber > 1) {
          suitableTypes.push(Charts.TRENDLINEAR2DCHART);
        }
      }
    }

    if ((scales || []).length === 2 && words === 0) {
      if (categoricalNumber === 2) {
        suitableTypes.push(
          Charts.STACKEDBARCHARTH,
          Charts.STACKEDBARCHARTV,
          Charts.MULTIBARCHARTH,
          Charts.MULTIBARCHARTV,
        );
      } else if (linearNumber === 2) {
        suitableTypes.push(Charts.HEATMAP);
      } else if (linearNumber === 1 && categoricalNumber === 1) {
        suitableTypes.push(
          Charts.STACKEDBARCHARTH,
          Charts.STACKEDBARCHARTV,
          Charts.MULTIBARCHARTH,
          Charts.MULTIBARCHARTV,
        );
      }
    }

    if ((scales || []).length === 3) {
      if (categoricalNumber === 1 && linearNumber === 2) {
        suitableTypes.push(Charts.HEATMAP);
      }
    }

    if (((categoricalNumber > 1 && !comparison) || categoricalNumber > 2) && words === 0) {
      suitableTypes.push(Charts.SUMMARYCHOICE);
    }

    return suitableTypes;
  }

  chartTypeRecommendation(scales: string[], details: DimensionDataItem[]) {
    const linearNumber = (scales || []).filter((item) => item === 'linear').length;
    const categoricalNumber = (scales || []).filter((item) => item === 'categorical').length;
    const time = (scales || []).filter((item) => item === 'time').length;
    const textNumber = (scales || []).filter((item) => item === 'text' || item === 'contact-text').length;
    const nps = (details || []).filter((item) => Questions.isNps({ type: item.originalType as Questions })).length;
    const sentiment = (details || []).filter((item) => item.originalTypeSpecifier === 'text-sentiment').length;
    const words = (details || []).filter((item) => item.originalTypeSpecifier === 'text-words').length;
    const emotions = (details || []).filter((item) => item.originalTypeSpecifier === 'text-emotions').length;
    const userSegments = (details || []).filter((item) => item.originalType === 'userSegments').length;
    const aiInterviewer = (details || []).filter((item) => item.originalType === Questions.AI_INTERVIEWER).length;
    const whyFinder = (details || []).filter((item) => item.originalType === Questions.ESKO_WHY_FINDER).length;
    const responseRates = (details || []).filter((item) => item.originalType === 'response-rates').length;

    if ((scales || []).length === 1) {
      if (nps > 0) {
        return Charts.NPS;
      } else if (words > 0) {
        return Charts.WORDANALYSIS;
      } else if (responseRates > 0) {
        return Charts.RESPONSERATES;
      } else if (categoricalNumber > 0) {
        return Charts.BARCHARTH;
      } else if (linearNumber > 0) {
        return Charts.BARCHARTV;
      } else if (time > 0) {
        return Charts.TIMELINECHART;
      } else if (textNumber > 0) {
        return Charts.TEXTANSWERTABLE;
      } else {
        return '';
      }
    } else if ((scales || []).length === 2) {
      if (categoricalNumber === 2 && words === 0) {
        return Charts.STACKEDBARCHARTH;
      } else if (linearNumber === 2) {
        return Charts.HEATMAP;
      } else if (nps === 1) {
        return Charts.NPS;
      } else if (time > 0) {
        return Charts.BARCHARTH;
      } else if (emotions === 1 && textNumber === 1) {
        return Charts.TEXTEMOTIONS;
      } else if (textNumber > 0 || sentiment > 0 || words > 0) {
        return sentiment > 0 && textNumber > 0 ? Charts.TEXTSENTIMENTANALYSIS : Charts.TEXTANSWERTABLE;
      } else {
        return Charts.SUMMARY1D;
      }
    } else if ((scales || []).length > 2) {
      if (aiInterviewer > 3) {
        return Charts.INTERVIEWERSUMMARY;
      } else if (whyFinder > 1) {
        return Charts.WHYFINDERSUMMARY;
      } else if (
        linearNumber > 3 &&
        details.length > 3 &&
        details.filter((item) => item.originalType === Questions.SLIDER_2D).length > 3
      ) {
        return Charts.SUMMARY2D;
      } else if (linearNumber > 0) {
        return Charts.SUMMARY1D;
      } else if (categoricalNumber / scales.length > 0.7 && words === 0) {
        return Charts.SUMMARYCHOICE;
      } else if (
        emotions === 1 &&
        textNumber === 1 &&
        (categoricalNumber === 1 || (categoricalNumber === 2 && userSegments === 1)) &&
        words === 0
      ) {
        return Charts.TEXTEMOTIONS;
      } else if (textNumber > 0) {
        return Charts.TEXTANSWERTABLE;
      } else {
        return Charts.BARCHARTH;
      }
    } else {
      return '';
    }
  }

  comparisonChartRecommendation(scales, originalType) {
    if ((scales || []).length === 2) {
      if (originalType === Charts.NPS) {
        return Charts.NPS;
      } else if (scales[0] === 'categorical') {
        return Charts.MULTIBARCHARTH;
      } else if (scales[0] === 'linear') {
        return Charts.MULTIBARCHARTV;
      } else if (scales[0] === 'text' || scales[0] === 'contact-text') {
        return Charts.TEXTANSWERTABLE;
      } else if (scales[0] === 'time') {
        return Charts.TIMELINECHART;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  trendChartRecommendation(details, originalType) {
    if (originalType === Charts.NPS) {
      return Charts.TRENDNPSCHART;
    } else if (originalType === Charts.BARCHARTHPLUSTEXT) {
      return Charts.BARCHARTHPLUSTEXT;
    } else if (originalType === Charts.TEXTEMOTIONS) {
      return Charts.TEXTEMOTIONS;
    } else if (originalType === Charts.INTERVIEWERSUMMARY) {
      return Charts.INTERVIEWERSUMMARY;
    } else if (originalType === Charts.WHYFINDERSUMMARY) {
      return Charts.WHYFINDERSUMMARY;
    } else if (originalType === Charts.RESPONSERATES) {
      return Charts.RESPONSERATES;
    } else if ((details || []).length === 2) {
      if (details[1]['originalTypeSpecifier'] === 'text-words') {
        return Charts.TEXTANSWERTABLE;
      } else if (details[1]['scale'] === 'categorical') {
        return Charts.TRENDCATEGORICALCHART;
      } else if (details[1]['scale'] === 'linear') {
        return Charts.TRENDLINEAR1DCHART;
      } else if (details[1]['scale'] === 'text' || details[1]['scale'] === 'contact-text') {
        return Charts.TEXTANSWERTABLE;
      } else {
        return '';
      }
    } else if ((details || []).length === 3) {
      if (details.filter((item) => item['originalTypeSpecifier'] === 'text-words').length > 0) {
        return Charts.TEXTANSWERTABLE;
      } else if (originalType === Charts.HEATMAP) {
        return Charts.TRENDLINEAR2DCHART;
      } else if (details.filter((item) => item && item.scale === 'categorical').length === 2) {
        return Charts.TRENDCATEGORICALCHART;
      } else if (
        details.filter((item) => item && item.scale === 'categorical').length === 1 &&
        details.filter((item) => item && item.scale === 'linear').length === 1
      ) {
        return Charts.TRENDLINEAR1DCHART;
      } else if (originalType === Charts.SUMMARY1D) {
        return Charts.TRENDLINEAR1DCHART;
      } else if (details.find((item) => item && item.originalTypeSpecifier === 'text-sentiment')) {
        return Charts.TEXTSENTIMENTANALYSIS;
      } else {
        return '';
      }
    } else if ((details || []).length > 3) {
      if (originalType === Charts.SUMMARY1D) {
        return Charts.TRENDLINEAR1DCHART;
      } else if (originalType === Charts.SUMMARY2D || originalType === Charts.HEATMAP) {
        return Charts.TRENDLINEAR2DCHART;
      } else if (details.find((item) => item && item.originalTypeSpecifier === 'text-sentiment')) {
        return Charts.TEXTSENTIMENTANALYSIS;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  chartHeightRecommendation(
    data: GridItemDataItem | ChartContent,
    type: string,
    comparison: any = null,
    chartSettings: ChartSettings = {} as ChartSettings,
  ) {
    let chartCount = 0;
    let newSize = 0;

    if (type === Charts.SUMMARY2D) {
      chartCount += 1;

      if (comparison && comparison.values && comparison.values.length > 0) {
        if (!chartSettings || chartSettings.summaryChartComparisonMode !== 'joined') {
          chartCount = comparison.values.length;
        }
      }

      const groups = new Set();

      for (let d = 0, lend = ((data && data.details) || []).length; d < lend; d++) {
        if (data.details[d]?.originalType === 'slider-2d') {
          const groupId = `${data.details[d].labelsLinear?.axis || ''}: ${data.details[d].labelsLinear?.min || ''} - ${
            data.details[d].labelsLinear?.max || ''
          } (${data.details[d].valueScaleLinear?.min}-${data.details[d].valueScaleLinear?.max})`;
          groups.add(groupId);
        }
      }

      if (Math.floor(groups.size / 2) > 1) {
        chartCount = Math.floor(groups.size / 2) * chartCount;
      }

      newSize = 8 + (chartCount > 1 ? (chartCount - 1) * 4 : 0);
    } else if (type === Charts.SUMMARY1D) {
      chartCount += 1;

      if (comparison && comparison.values && comparison.values.length > 0) {
        if (!chartSettings || chartSettings.summaryChartComparisonMode !== 'joined') {
          chartCount = comparison.values.length;
        }
      }
      const groupSizes: { [id: string]: number } = {};
      const questionGroups: { [id: string]: any } = {};

      for (let i = 0, len = (data.details || []).length; i < len; i++) {
        const detail = data.details[i];
        if (detail?.scale === 'linear') {
          const groupId = `${detail?.labelsLinear?.axis || ''}: ${detail?.labelsLinear?.min || ''} - ${
            detail?.labelsLinear?.max || ''
          } (${detail?.valueScaleLinear?.min}-${detail?.valueScaleLinear?.max})`;

          if (!groupSizes[groupId]) {
            groupSizes[groupId] = 0;
            questionGroups[groupId] = new Set();
          }
          groupSizes[groupId] += 1;
          if (detail.group) {
            questionGroups[groupId].add(detail.group);
          }
        }
      }

      for (const qg in questionGroups) {
        if (questionGroups[qg].size) {
          groupSizes[qg] += questionGroups[qg].size;
        }
      }

      newSize = Object.values(groupSizes).reduce(
        (a: number, b: number) =>
          a +
          1 +
          chartCount * Math.round(b / 2) +
          (b > 2 ? 1 + Math.round(b / 8) : 0) +
          (window.innerWidth > 1400 && b > 2 ? Math.ceil(b / 4) : 0),
        2 + (window.innerWidth > 1400 && Object.keys(groupSizes).length > 1 ? 1 : 0),
      );
      newSize = newSize < 5 ? 5 : newSize;
    } else if (type === Charts.BARCHARTH || type === Charts.STACKEDBARCHARTH) {
      chartCount += (data.details || []).length;
      const legendSpace =
        (comparison && comparison.values && comparison.values.length && Math.ceil(comparison.values.length / 3)) || 0;
      let barCount = 0;
      let choicePicture = false;

      for (let i = 0, len = data.scales.length; i < len; i++) {
        if (type !== Charts.STACKEDBARCHARTH || i === 0) {
          if (data.scales[i] === 'linear') {
            barCount += 11; // proper scaling needed here!
          } else {
            barCount += (data.details && data.details[i] && data.details[i]['values'].length) || 0;
          }
          if (data.details[i] && data.details[i]['originalType'] === Questions.CHOICE_PICTURE) {
            choicePicture = true;
          }
        }
      }
      const barCountAddition: number = Math.round(
        choicePicture
          ? barCount
          : barCount > 1
            ? ((barCount - 1) * ((barCount % 2 === 0 ? barCount - 2 : barCount - 1) / 2)) / (barCount - 1)
            : 0,
      );
      newSize = chartCount * 3 + (barCountAddition < 5 ? barCountAddition : 5) + (choicePicture ? 1 : 2) + legendSpace;
    } else if (type === Charts.MULTIBARCHARTH) {
      const legendSpace =
        (comparison && comparison.values && comparison.values.length && Math.ceil(comparison.values.length / 3)) || 0;
      const barCount: number =
        data.scales[0] === 'linear'
          ? 11
          : data.distributions && data.distributions[0]
            ? Object.keys(data.distributions[0]).length
            : 0;
      const barMultiplier: number =
        data.scales[1] === 'linear'
          ? 11
          : data.comparison != null && data.comparison.index === 1 && data.comparison.values
            ? data.comparison.values.length || 1
            : data.distributions && data.distributions[1]
              ? Object.keys(data.distributions[1]).length
              : 1;

      newSize =
        Math.min(Math.round(barCount / 6 + Math.round(Math.ceil(barMultiplier + barCount / 6) / 2 || 1)) + 2 + 3, 8) +
        legendSpace;
    } else if (type === Charts.BARCHARTV || type === Charts.LINECHART || type === Charts.AREACHART) {
      chartCount += (data.details || []).length;
      newSize = Math.round(chartCount * 2.5) + 2;
    } else if (type === Charts.PIECHART || type === Charts.DONUTCHART) {
      chartCount += (data.details || []).length;
      newSize = chartCount * 3 + 3;
    } else if (type === Charts.MULTIDONUTCHART) {
      chartCount += (data.details || []).length;
      let donutCount = 0;
      for (const i in data.scales) {
        if (data.scales[i] === 'linear') {
          donutCount += 11; // proper scaling needed here!
        } else {
          donutCount += Object.keys(data.distributions && data.distributions[i]).length;
        }
      }
      newSize = chartCount * 3 + Math.round(donutCount / 2) + 3;
    } else if (type === Charts.TEXTANSWERTABLE) {
      newSize = 6 + (chartSettings?.textSummary ? 3 : 0);
    } else if (type === Charts.TEXTSENTIMENTANALYSIS) {
      newSize = 11;
    } else if (type === Charts.TEXTEMOTIONS) {
      newSize =
        11 +
        (comparison && comparison.values && comparison.values.length > 0
          ? (comparison.values.length - 1) * (data.trend ? 6 : 3)
          : 0);
    } else if (type === Charts.INTERVIEWERSUMMARY) {
      newSize =
        11 +
        (comparison && comparison.values && comparison.values.length > 0
          ? (comparison.values.length - 1) * (data.trend ? 6 : 3)
          : 0);
    } else if (type === Charts.WHYFINDERSUMMARY) {
      newSize = 9;
    } else if (type === Charts.BARCHARTHPLUSTEXT) {
      newSize =
        11 +
        (comparison && comparison.values && comparison.values.length > 0
          ? (comparison.values.length - 1) * (data.trend ? 7 : 1)
          : 0);
    } else if (type === Charts.WORDANALYSIS) {
      newSize =
        11 +
        (comparison && comparison.values && comparison.values.length > 0
          ? Math.floor((comparison.values.length - 1) / 2) * 7
          : 0);
    } else if (type === Charts.NPS) {
      chartCount += 1;
      if (comparison && comparison.values && comparison.values.length > 0) {
        chartCount = comparison.values.length;
      } else {
        for (const i in data.scales) {
          if (data.scales[i] === 'categorical' || (data.scales[i] === 'time' && data.stats[i])) {
            chartCount += data.details?.[i]?.values?.length;
          }
        }
      }
      newSize = Math.floor(chartCount / 3) + 5 + (chartCount > 4 ? 1 : 0);
    } else if (type === Charts.HEATMAP) {
      if (data.distributions && data.distributions.length > 2) {
        const catIndex = data.details.findIndex((d) => d.scale === 'categorical');
        chartCount += catIndex >= 0 && data.distributions[catIndex] ? data.distributions[catIndex].length : 0;
      }
      newSize = chartCount === 0 ? 6 : chartCount * 4 + 2;
    } else if (type === Charts.RADARCHART) {
      const parseGroupId = (details) =>
        `${details.labelsLinear?.axis || ''}: ${details.labelsLinear?.min || ''} - ${
          details.labelsLinear?.max || ''
        } ` + `(${details.valueScaleLinear.min}-${details.valueScaleLinear.max})`;

      const parseGroupNumber = (details) => {
        const groups = {};
        for (const item in details) {
          if (details[item].scale === 'linear') {
            const groupId = parseGroupId(details[item]);
            groups[groupId] = 'exists';
          }
        }

        return Object.keys(groups).length;
      };

      let linearNumber = 0;
      // let categoricalNumber = 0;

      for (const i in data.scales) {
        if (data.scales[i] === 'linear') {
          linearNumber += 1;
        } else if (data.scales[i] === 'categorical') {
          // categoricalNumber += 1;
        }
      }

      if (linearNumber > 2) {
        chartCount = parseGroupNumber(data.details);
      } else {
        const len = data.distributions && data.distributions.length;
        chartCount = comparison ? len - 1 : len;
      }

      newSize = chartCount * 4 + 2;
    } else if (type === Charts.TRENDLINEAR2DCHART) {
      chartCount += 1;

      if (comparison && comparison.values && comparison.values.length > 0) {
        if (!chartSettings || chartSettings.summaryChartComparisonMode !== 'joined') {
          chartCount = comparison.values.length;
        }
      }

      const groups = new Set();

      for (let d = 0, lend = ((data && data.details) || []).length; d < lend; d++) {
        if (data.details[d] && data.details[d].originalType === 'slider-2d') {
          const groupId = `${data.details[d].labelsLinear?.axis || ''}: ${data.details[d].labelsLinear?.min || ''} - ${
            data.details[d].labelsLinear?.max || ''
          } (${data.details[d].valueScaleLinear?.min}-${data.details[d].valueScaleLinear?.max})`;
          groups.add(groupId);
        }
      }

      if (Math.floor(groups.size / 2) > 1) {
        chartCount = Math.floor(groups.size / 2) * chartCount;
      }

      newSize = 8 + (chartCount > 1 ? (chartCount - 1) * 5 : 0);
    } else if (type === Charts.TRENDLINEAR1DCHART) {
      chartCount += 1;
      if (comparison && comparison.values && comparison.values.length > 0) {
        if (!chartSettings || chartSettings.summaryChartComparisonMode !== 'joined') {
          chartCount = comparison.values.length;
        }
      }
      const groups = new Set();
      let linearCount = 0;

      for (let i = 0, len = (data.details || []).length; i < len; i++) {
        const detail = data.details[i];
        if (detail.scale === 'linear') {
          linearCount += 1;
          groups.add(
            `${detail.labelsLinear ? detail.labelsLinear.axis : ''}: ${
              detail.labelsLinear ? detail.labelsLinear.min : ''
            } - ${detail.labelsLinear ? detail.labelsLinear.max : ''} (${detail.valueScaleLinear.min}-${
              detail.valueScaleLinear.max
            })`,
          );
        }
      }

      newSize = groups.size * chartCount * (4 + Math.round(linearCount / 10)) + 2;
    } else if (type === Charts.TRENDCATEGORICALCHART || type === Charts.TRENDNPSCHART) {
      chartCount += 1;
      if (comparison && comparison.values && comparison.values.length > 0) {
        if (!chartSettings || chartSettings.summaryChartComparisonMode !== 'joined') {
          chartCount = comparison.values.length;
        }
      }

      newSize = chartCount * 4 + 2;
    } else if (type === Charts.TIMELINECHART) {
      newSize = 5;
    } else if (type === Charts.SUMMARYCHOICE) {
      newSize = Math.max(data?.details?.length * (comparison?.values?.length || 1), 6);
    } else if (type === Charts.RESPONSERATES) {
      const index = data?.details?.findIndex((item) => item?.originalType === 'response-rates');
      const shares = data?.details?.[index]?.values?.length || 0;

      newSize = 6 + Math.round(shares / 20);
    } else if (!type) {
      newSize = 1;
    } else {
      newSize = 6;
    }

    return newSize;
  }

  textSummaryAvailable(details): boolean {
    return (
      details?.filter(
        (det) => det?.originalType === Questions.FREE_TEXT || det?.originalType === Questions.INPUT_STRING,
      )?.length > 0 && this.store?.selectSnapshot(AccountState.extensions)?.indexOf('textSummary') >= 0
    );
  }
}
