import * as d3 from 'd3';

import { Directive, ElementRef, HostListener, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';

import { CanvasContext, ChartDistribution, ChartDomain, NPSData, NPSDistribution } from '@shared/models/report.model';

import { Crossfilter } from '@report/shared/services/crossfilter.service';

import { Colors } from '@report/shared/enums/colors.enum';

import { drawContactIcon, drawRoundRect, shortenText } from '@shared/utilities/canvas.utilities';

/**
 * This is a NPS bar chart.
 */
@Directive({
  selector: '[npsBarChart]',
})
export class NPSBarChart implements OnChanges {
  @Input() NPSdata: NPSData = {} as NPSData;
  @Input() data: ChartDistribution[] = [];
  @Input() domain: ChartDomain = {} as ChartDomain;
  @Input() stats: any;
  @Input() scale: any;
  @Input() filterInput: any;
  @Input() transitionDuration: number = 0;
  @Input() showNumbers: boolean = false;
  @Input() showLabels: boolean = true;
  @Input() showCount: boolean = true;
  @Input() update: Date = new Date();
  @Input() filtering: boolean = false;
  @Input() anonymityLock: boolean = false;
  @Input() title: string = '';
  @Input() totalAnswers: number = 0;
  @Input() selectionExists: boolean = false;
  @Input() filtersDemo: boolean = false;
  @Input() touchDevice: boolean = false;

  private base: any;

  private context: CanvasContext = {} as CanvasContext;
  private canvas: any;

  private max: number = 0;
  private previousMax: number = 0;

  private npsScore: number = null;
  private previousNpsScore: number = null;
  private responses: number = 0;
  private previousResponses: number = 0;

  private scaleX: any;
  private scaleY: any;

  private brush: any;
  private brushArea: any;
  private brushing: boolean = false;

  private tooltip: any;
  private tooltipData: any;

  private width: number;
  private height: number;
  private margin: { [s: string]: number };
  private fontSize: number = 0;
  private unit: number = 0;

  private filter: any;

  private legendItems: any[];

  private selections: any = [];

  @HostListener('window:resize') resize() {
    this.updateChart(null);
  }

  constructor(
    private _element: ElementRef,
    private cf: Crossfilter,
  ) {
    this.constructBody();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.NPSdata ||
      changes.data ||
      changes.domain ||
      changes.stats ||
      changes.scale ||
      changes.filterInput ||
      changes.showNumbers ||
      changes.showLabels ||
      changes.showCount ||
      changes.update ||
      changes.filtering ||
      changes.anonymityLock ||
      changes.title ||
      changes.totalAnswers ||
      changes.selectionExists ||
      changes.filtersDemo ||
      changes.touchDevice
    ) {
      this.updateChart(changes.data);
    }
  }

  updateChart(dataChanges: SimpleChange | null): void {
    this.setEnvironment();
    this.setScales();
    this.setCanvas(dataChanges);
    this.setBrush();
  }

  constructBody(): void {
    this.base = d3
      .select(this._element.nativeElement)
      .append('div')
      .attr('class', 'nps-bar-chart')
      .style('position', 'relative');
    this.tooltip = d3
      .select(this._element.nativeElement)
      .append('div')
      .attr('class', 'item-tooltip')
      .style('display', 'none');
  }

  setEnvironment(): void {
    const h: number = this._element.nativeElement.clientHeight;
    const fontSize = parseFloat(window.getComputedStyle(this._element.nativeElement).fontSize);
    this.fontSize = h - (10 / 14) * fontSize * 12 >= h / 4 ? fontSize : (h - h / 4) / ((10 / 14) * 12);
    this.unit = (10 / 14) * this.fontSize;

    this.margin = {
      top: 3 * this.unit,
      right: 3 * this.unit,
      bottom: 9 * this.unit,
      left: 3 * this.unit,
    };

    const width = this._element.nativeElement.clientWidth - this.margin.left - this.margin.right;
    const height = h - this.margin.top - this.margin.bottom;

    this.width = width > 0 ? width : 0;
    this.height = height > 0 ? height : 0;
  }

  setScales(): void {
    const overScale = 1.2;
    this.scaleX = d3
      .scaleBand()
      .rangeRound([0, this.width])
      .domain(this.domain.keys)
      .paddingInner(0.2)
      .paddingOuter(0.1);

    this.previousMax = this.max;
    this.max = (d3.max(this.data, (d) => (this.scale === 'percentage' ? d.percentage : d.value)) || 1) * overScale;

    this.scaleY = d3.scaleLinear().rangeRound([this.height, 0]).domain([0, this.max]);

    this.previousNpsScore = this.npsScore;
    this.npsScore = this.NPSdata && this.NPSdata['npsScore'] != null ? this.NPSdata['npsScore'] : null;
    this.previousResponses = this.responses;
    this.responses =
      this.NPSdata && this.NPSdata['distribution'] && this.NPSdata['distribution'].length > 0
        ? this.NPSdata['distribution'].reduce((acc: number, obj: NPSDistribution) => acc + obj.value, 0)
        : this.totalAnswers;
  }

  setCanvas(dataChanges: SimpleChange | null): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const __this = this;
    const drawContent = function (d) {
      const context = d3.select(this).node().getContext('2d');

      if (dataChanges && !dataChanges.firstChange && __this.transitionDuration > 0) {
        const dataObj = __this.context && __this.context.data ? __this.context.data : [];
        const interpolateArray: any = (a, b) => {
          const nb: number = b ? b.length : 0;
          const na: number = a ? Math.min(nb, a.length) : 0;
          const x: any[] = new Array(na);
          const c: any[] = new Array(...b);

          for (let i = 0; i < na; ++i) {
            x[i] = {
              value: d3.interpolateNumber(a[i]['value'], b[i]['value']),
              percentage: d3.interpolateNumber(a[i]['percentage'], b[i]['percentage']),
              percentage_all: d3.interpolateNumber(a[i]['percentage_all'], b[i]['percentage_all']),
            };
          }

          return function (t) {
            for (let i = 0; i < na; ++i) {
              c[i]['value'] = x[i]['value'](t);
              c[i]['percentage'] = x[i]['percentage'](t);
              c[i]['percentage_all'] = x[i]['percentage_all'](t);
            }
            return c;
          };
        };
        const interpolator = interpolateArray(dataObj, d);
        const interpolateMax = d3.interpolateNumber(__this.previousMax, __this.max);
        const interpolateNpsScore = d3.interpolateNumber(__this.previousNpsScore, __this.npsScore);
        const interpolateResponses = d3.interpolateNumber(__this.previousResponses, __this.responses);
        const ease = d3.easeCubic;

        const timer = d3.timer((elapsed) => {
          const step = elapsed / __this.transitionDuration;
          let data;
          let scaleY;
          let npsScore;
          let responses;

          if (step >= 1) {
            data = interpolator(ease(1));
            npsScore = interpolateNpsScore(ease(1));
            responses = interpolateResponses(ease(1));
            scaleY = d3
              .scaleLinear()
              .rangeRound([__this.height, 0])
              .domain([0, interpolateMax(ease(1))]);
            timer.stop();
          } else {
            data = interpolator(ease(step));
            npsScore = Math.round(interpolateNpsScore(ease(step)));
            responses = Math.round(interpolateResponses(ease(step)));

            scaleY = d3
              .scaleLinear()
              .rangeRound([__this.height, 0])
              .domain([0, interpolateMax(ease(step))]);
          }

          __this.setRects(context, data, scaleY);
          __this.setTexts(context, npsScore, responses);
        });
      } else {
        __this.setRects(context, d, __this.scaleY);
        __this.setTexts(context, __this.npsScore, __this.responses);
      }

      __this.context = { context, data: d };
    };

    this.canvas = this.base.selectAll('.nps-bar-chart-canvas').data([this.data]);

    this.canvas.exit().remove();

    this.canvas
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .each(drawContent);

    this.canvas
      .enter()
      .append('canvas')
      .attr('class', 'nps-bar-chart-canvas')
      .style('position', 'absolute')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .each(drawContent);
  }

  setRects(context, data: any[] = [], scaleY, filter: any[] | null = [], highlight: any[] | null = []): void {
    context.clearRect(this.margin.left - 1, this.margin.top - 4, this.width + this.margin.right + 1, this.height + 7);
    this.setYAxis(context, scaleY);
    this.selections = new Set();

    for (let x = 0, lenx = data.length; x < lenx; x++) {
      const key = data[x]['key'];
      const value = data[x]['value'];
      const percentage = data[x]['percentage'];

      const xPos = this.scaleX(key) + this.margin.left;
      const yPos = scaleY(this.scale === 'percentage' ? percentage : value) + this.margin.top;
      const width = this.scaleX.bandwidth();
      const height = this.height - scaleY(this.scale === 'percentage' ? percentage : value);

      const color: string =
        this.NPSdata.distribution &&
        this.NPSdata.distribution[0] &&
        this.NPSdata.distribution[0]['originalValues'] &&
        this.NPSdata.distribution[0]['originalValues'].indexOf(key) >= 0
          ? Colors.NPS[0]
          : this.NPSdata.distribution &&
              this.NPSdata.distribution[1] &&
              this.NPSdata.distribution[1]['originalValues'] &&
              this.NPSdata.distribution[1]['originalValues'].indexOf(key) >= 0
            ? Colors.NPS[1]
            : this.NPSdata.distribution &&
                this.NPSdata.distribution[2] &&
                this.NPSdata.distribution[2]['originalValues'] &&
                this.NPSdata.distribution[2]['originalValues'].indexOf(key) >= 0
              ? Colors.NPS[2]
              : this.selectionExists
                ? Colors.SELECTED
                : this.filtersDemo
                  ? Colors.UNSELECTED
                  : Colors.DEFAULT;
      context.fillStyle = color;

      if (filter != null && filter.length === 2) {
        const xFi =
          filter &&
          filter[0] <= xPos - this.margin.left + width / 2 &&
          filter[1] >= xPos - this.margin.left + width / 2;

        if (!xFi) {
          context.fillStyle = Colors.UNSELECTED;
        } else if (xFi) {
          this.selections.add(key);
          context.fillStyle = color;
        }
      } else if (this.filterInput && this.filterInput.length > 0) {
        const xFi = this.filterInput.indexOf(key) > -1;

        if (!xFi) {
          context.fillStyle = Colors.UNSELECTED;
        } else {
          context.fillStyle = color;
        }
      }

      context.strokeStyle = 'transparent';
      context.lineWidth = 1;
      if (highlight && highlight.length > 0) {
        const xHi = highlight && highlight.indexOf(key) > -1;

        if (xHi) {
          context.lineWidth = 2;
          context.strokeStyle = Colors.HIGHLIGHT;
        }
      }

      drawRoundRect(context, xPos, yPos, width, height, { tl: 5, tr: 5, bl: 0, br: 0 }, context.fillStyle, true);

      if (this.showNumbers || (highlight && highlight.length > 0 && highlight.indexOf(key) > -1)) {
        context.font =
          (highlight && highlight.length > 0 && highlight.indexOf(key) > -1 ? 'bold ' : 'normal ') +
          10 / 14 +
          'em Inter';
        context.textBaseline = 'bottom';
        context.textAlign = 'center';
        context.fillStyle = Colors.TEXT;
        context.fillText(
          this.scale === 'percentage' ? (percentage * 100).toFixed(1) + '%' : Math.round(value),
          xPos + width / 2,
          yPos - 5,
        );
      }
    }
  }

  setTexts(context, npsScore, responses): void {
    this.legendItems = [];

    context.clearRect(0, this.height + this.margin.top, this.width + this.margin.right, this.margin.bottom);
    context.clearRect(0, 0, this.width + this.margin.right + this.margin.left, this.margin.top);

    context.fillStyle = this.filterInput ? Colors.FILTER : Colors.TEXT;
    context.textAlign = 'left';
    context.textBaseline = 'middle';

    const h = this.margin.top / 2;

    const wIcon = this.showCount ? this.fontSize + 4 : 0;

    context.font = 10 / 14 + 'em Inter';
    const wNumber = this.showCount ? context.measureText(responses).width + 12 : 0;

    const wNpsScoreIcon = this.npsScore != null ? context.measureText('NPS').width + 4 : 0;

    context.font = 10 / 14 + 'em Inter';
    const wNpsScoreNumber = this.npsScore != null ? context.measureText(npsScore).width + 8 : 0;

    context.font = 12 / 14 + 'em Inter';
    const title = this.title ? shortenText(context, this.title, this.width, 8 + (wIcon + wNumber) / 2) : '';

    const wTitle = title ? context.measureText(title).width + 8 : 0;

    const startPoint =
      this.margin.left + this.width / 2 - (wIcon + wNumber + wNpsScoreIcon + wNpsScoreNumber + wTitle) / 2;

    if (title) {
      context.fillText(title, startPoint, h);
    }

    if (this.showCount) {
      context.font = 10 / 14 + 'em Inter';
      drawContactIcon(context, this.fontSize, startPoint + wTitle, h, context.fillStyle);
      context.fillText(responses, startPoint + wTitle + wIcon, h);
    }

    if (this.npsScore != null) {
      context.font = 10 / 14 + 'em Inter';
      context.fillText('NPS', startPoint + wTitle + wIcon + wNumber, h);
      context.fillText(npsScore, startPoint + wTitle + wIcon + wNumber + wNpsScoreIcon, h);
    }

    context.beginPath();
    context.moveTo(this.margin.left + 8, this.height + this.margin.top + this.unit);
    context.lineTo(this.margin.left + this.width - 8, this.height + this.margin.top + this.unit);
    context.closePath();
    context.strokeStyle = Colors.HELPERLINE;
    context.stroke();

    context.beginPath();
    context.arc(this.margin.left + this.width / 2, this.height + this.margin.top + this.unit, 5, 0, 2 * Math.PI);
    context.closePath();
    context.fillStyle = Colors.BACKGROUND;
    context.stroke();
    context.fill();

    context.font = 12 / 14 + 'em Inter';
    context.textBaseline = 'top';
    context.fillStyle = Colors.TEXT;

    context.textAlign = 'start';
    const xMinLabel = shortenText(context, this.domain.labelsLinear.min, this.width / 2, 10);
    context.fillText(xMinLabel, this.margin.left, this.height + this.margin.top + this.margin.bottom / 6);

    context.textAlign = 'end';
    const xMaxLabel = shortenText(context, this.domain.labelsLinear.max, this.width / 2, 10);
    context.fillText(xMaxLabel, this.margin.left + this.width, this.height + this.margin.top + this.margin.bottom / 6);

    context.font = 'normal bold ' + 12 / 14 + 'em Inter';
    context.textAlign = 'center';
    const xAxisLabel = shortenText(context, this.domain.labelsLinear.axis, this.width, 10);
    context.fillText(
      xAxisLabel,
      this.margin.left + this.width / 2,
      this.height + this.margin.top + (2.2 * this.margin.bottom) / 6,
    );

    if (this.showLabels && this.NPSdata && this.NPSdata.distribution) {
      context.font = 10 / 14 + 'em Inter';
      context.textBaseline = 'top';
      context.textAlign = 'center';

      const labelWidths: number[] = [];

      for (let i = 0, len = this.NPSdata.distribution.length; i < len; i++) {
        labelWidths.push(context.measureText(this.NPSdata.distribution[i].label).width);
      }
      const totalW: number = labelWidths.reduce((a, b) => a + b, 0) + 3 * 2 * this.unit;

      for (let i = 0, len = this.NPSdata.distribution.length; i < len; i++) {
        const item: NPSDistribution = this.NPSdata.distribution[i];
        const w = labelWidths[i];
        const xPosText =
          this.width / 2 +
          this.margin.left -
          totalW / 2 +
          w / 2 +
          (i === 1 ? labelWidths[0] + 3 * this.unit : i === 2 ? labelWidths[1] + labelWidths[0] + 6 * this.unit : 0);
        const xPosDot = xPosText - w / 2 - this.unit;
        const yPos = this.height + this.margin.top + ((this.domain.labelsLinear.axis ? 4 : 3) * this.margin.bottom) / 6;
        this.legendItems.push({
          area: [xPosDot, xPosText + w / 2],
          items: this.NPSdata.distribution[i]['originalValues'],
        });
        context.fillStyle = Colors.NPS[i];
        context.beginPath();
        context.arc(xPosDot, yPos + 0.5 * this.unit, 0.5 * this.unit, 0, 2 * Math.PI);
        context.closePath();
        context.fill();

        context.fillStyle = Colors.TEXT;
        context.fillText(item.label, xPosText, yPos);
        context.fillText(
          this.scale === 'percentage' ? (item.percentage * 100).toFixed(1) + '%' : item.value,
          xPosText,
          yPos + this.unit,
        );
      }
    }
  }

  setYAxis(context, scaleY): void {
    context.clearRect(0, 0, this.margin.left, this.height + this.margin.top + 10);
    const tickCount = this.scale === 'percentage' ? 4 : this.max > 3 ? 4 : this.max > 2 ? 2 : 1;
    const tickPadding = 3;
    const ticks = scaleY.ticks(tickCount);
    const tickFormat = scaleY.tickFormat(tickCount, this.scale === 'percentage' ? ',.1%' : 'd');

    context.font = 10 / 14 + 'em Inter';
    context.fillStyle = Colors.TEXT;
    context.strokeStyle = Colors.HELPERLINE;
    context.lineWidth = 1;
    context.textAlign = 'right';
    context.textBaseline = 'middle';
    ticks.forEach((d) => {
      const x = this.margin.left - tickPadding;
      const y = this.margin.top + scaleY(d);
      context.fillText(tickFormat(d), x, y);
      if (d > 0) {
        context.beginPath();
        context.moveTo(x + tickPadding + 8, y);
        context.lineTo(this.margin.left + this.width, y);
        context.stroke();
      }
    });
  }

  setBrush(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const __this = this;
    const hoverFunction = function (event) {
      if (!__this.touchDevice || !__this.filtering || __this.anonymityLock) {
        const area = d3.pointer(event);
        __this.selectForHover(event, area);
      }
    };

    if (this.filtering && !this.anonymityLock && this.height > 0) {
      this.brush = d3
        .brushX()
        .on('brush', function (event) {
          if (event.sourceEvent) {
            __this.brushing = true;
            const area = this.parentElement ? d3.pointer(event, this.parentElement) : [];
            const sel =
              this && d3.select(this) && d3.select(this).node() ? d3.brushSelection(d3.select(this).node()!) : null;

            __this.base.select('.nps-bar-chart-canvas').each(function (da) {
              __this.setRects(__this.context.context, da, __this.scaleY, sel);
            });
            if (!__this.touchDevice || !__this.filtering || __this.anonymityLock) {
              __this.selectForHover(event, area);
            }
          }
        })
        .on('end', function (event) {
          if (event.sourceEvent) {
            __this.brushing = false;
            const sel =
              this && d3.select(this) && d3.select(this).node() ? d3.brushSelection(d3.select(this).node()!) : null;

            __this.base.select('.nps-bar-chart-canvas').each(function (da) {
              __this.setRects(__this.context.context, da, __this.scaleY, sel);
            });

            __this.callFilter();
          }
        });

      const callBrush = function () {
        if (__this.filterInput && __this.filterInput.length > 0) {
          const barW = __this.scaleX.bandwidth();
          const padding = (__this.scaleX.paddingInner() * __this.scaleX.step()) / 2;

          const minX = __this.scaleX(__this.filterInput[0]) - padding;
          const maxX = __this.scaleX(__this.filterInput[__this.filterInput.length - 1]) + barW + padding;

          const brushArea = [minX > 0 ? minX : 0, maxX];

          d3.select(this).call(__this.brush).call(__this.brush.move, brushArea);
        } else {
          const brushOn = d3.brushSelection(d3.select(this).node()) != null;

          if (!__this.filterInput && brushOn) {
            d3.select(this).call(__this.brush.move, null);
          } else {
            d3.select(this).call(__this.brush);
          }
        }
      };

      this.brushArea = this.base.selectAll('.svg-brush').data([this.data]);

      this.brushArea.exit().remove();

      this.brushArea
        .attr('width', this.width + this.margin.left)
        .attr('height', this.height + this.margin.top + this.margin.bottom)
        .select('.brush')
        .attr('transform', `translate(${this.margin.left},${this.margin.top})`)
        .each(callBrush);

      this.brushArea
        .enter()
        .append('svg')
        .attr('class', 'svg-brush')
        .attr('width', this.width + this.margin.left)
        .attr('height', this.height + this.margin.top + this.margin.bottom)
        .style('position', 'absolute')
        .style('top', 0)
        .style('left', 0)
        .on('mousemove', hoverFunction)
        .on('mouseout', function (event) {
          __this.setTooltip(d3.pointer(event, __this._element.nativeElement));
        })
        .append('g')
        .attr('class', 'brush')
        .attr('transform', `translate(${this.margin.left},${this.margin.top})`)
        .each(callBrush);
    } else {
      this.brushArea = this.base.selectAll('.svg-brush').data([]);

      this.brushArea.exit().remove();

      this.base
        .selectAll('.nps-bar-chart-canvas')
        .on('mousemove', hoverFunction)
        .on('mouseout', function (event) {
          __this.setTooltip(d3.pointer(event, __this._element.nativeElement));
        });
    }
  }

  setTooltip(position, data: any[] = [], legendItem = false): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const __this = this;

    if (!legendItem && data.length > 0 && data[0]) {
      if (JSON.stringify([data[0], legendItem]) !== JSON.stringify(this.tooltipData)) {
        this.tooltip.html(
          `
            <div class="question">${this.domain.labels[data[0].key]}</div>
            <div class="stats">
              <span class="icon">contact</span> ${data[0].value} (${(data[0].percentage * 100).toFixed(1)}%)
            </div>
          `,
        );
      }
      this.tooltip.style('display', 'block').style('transform', function () {
        return `
              translate(${position[0] - this.offsetWidth / 2}px,${position[1] - this.offsetHeight - 24}px)`;
      });
    } else {
      this.tooltip.html('').style('display', 'none');
    }

    this.tooltipData = [data[0], legendItem];

    // adding hovering effect
    this.base.selectAll('.nps-bar-chart-canvas').each(function (d) {
      const highlight = data.map((item) => item.key);
      if (!__this.brushing) {
        __this.setRects(__this.context.context, d, __this.scaleY, [], highlight);
      }
    });
  }

  callFilter(): void {
    if (this.filtering && !this.anonymityLock) {
      this.filter = [];
      const filter = { key: this.domain.key, values: this.domain.keys, filter: Array.from(this.selections) };

      this.filter.push(filter);
      if (JSON.stringify(this.filter[0].filter) !== JSON.stringify(this.filterInput)) {
        this.cf.filter(this.filter);
      }
    }
  }

  // Helpers
  selectForHover(event, area): void {
    const items = this.itemsBelow(area);
    const yPos = this.height + this.margin.top + ((this.domain.labelsLinear.axis ? 4 : 3) * this.margin.bottom) / 6;

    const legendItems = this.data.filter(
      (item) =>
        area[1] >= yPos &&
        this.legendItems.find(
          (legendItem) =>
            legendItem['area'][0] < area[0] &&
            legendItem['area'][1] >= area[0] &&
            legendItem['items'].indexOf(item.key) >= 0,
        ),
    );

    if (items.length > 0) {
      this.setTooltip(d3.pointer(event, this._element.nativeElement), items, legendItems.length > 0);
    } else {
      this.setTooltip(d3.pointer(event, this._element.nativeElement));
    }
  }

  itemsBelow(area): ChartDistribution[] {
    const yPos = this.height + this.margin.top + ((this.domain.labelsLinear.axis ? 4 : 3) * this.margin.bottom) / 6;

    return this.data.filter(
      (item) =>
        (area[0] < this.scaleX(item.key) + this.scaleX.bandwidth() + this.margin.left &&
          area[0] > this.scaleX(item.key) + this.margin.left &&
          area[1] < yPos) ||
        (area[1] >= yPos &&
          this.legendItems.find(
            (legendItem) =>
              legendItem['area'][0] < area[0] &&
              legendItem['area'][1] >= area[0] &&
              legendItem['items'].indexOf(item.key) >= 0,
          )),
    );
  }
}
