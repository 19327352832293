import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { QueryList } from '@angular/core';

export function fromQuery<T>(source: QueryList<T>): Observable<T[]> {
  if (!source) {
    return of([]);
  }

  return source.changes.pipe(
    startWith([]),
    map(() => source.toArray()),
  );
}
