<div *ngIf="!showSearchBar && (!anonymityTreshold || anonymityTreshold === 1)" class="mode-selector">
  <div class="zef-tabs-icon">
    <button mat-icon-button [class.active]="chartMode === 'gauge'" (click)="changeChartMode('gauge')">
      <mat-icon>chart_nps</mat-icon>
    </button>

    <button mat-icon-button [class.active]="chartMode === 'bar'" (click)="changeChartMode('bar')">
      <mat-icon>chart_bar_vertical</mat-icon>
    </button>
  </div>
</div>

<div
  *ngIf="!listModeOn && NPSdata$ | async as npsdata"
  [ngSwitch]="chartMode"
  class="z-fx-col"
  [style.height]="chartHeight"
>
  <ng-container *ngIf="!anonymityTreshold || (anonymityTreshold && hasEnoughAnswers(npsdata[0])); else anonymous">
    <div
      *ngSwitchCase="'gauge'"
      class="nps-gauge"
      [style.flex]="'1 1 ' + chartHeight"
      [style.min-height]="chartHeight"
      [style.max-height]="chartHeight"
      npsGauge
      [NPSdata]="npsdata[0]"
      [scale]="scale"
      [filterInput]="filterInput && filterInput[0]"
      [domain]="npsDomain"
      [transitionDuration]="transition"
      [showNumbers]="showNumbers"
      [update]="update"
      [filtering]="filtering"
      [anonymityLock]="anonymityLock"
      [hideDistribution]="hideDistribution"
      [touchDevice]="touchDevice"
      [selectionExists]="selectionExists"
    ></div>
    <div
      *ngSwitchCase="'bar'"
      class="nps-bar-chart"
      [style.flex]="'1 1 ' + chartHeight"
      [style.min-height]="chartHeight"
      [style.max-height]="chartHeight"
      npsBarChart
      [NPSdata]="npsdata && npsdata[0]"
      [data]="npsdata && npsdata[0] && npsdata[0]['originalDistribution']"
      [stats]="stats"
      [scale]="scale"
      [filterInput]="filterInput && filterInput[npsDomIndex]"
      [domain]="domain && domain[npsDomIndex]"
      [transitionDuration]="transition"
      [showNumbers]="showNumbers"
      [update]="update"
      [filtering]="filtering"
      [anonymityLock]="anonymityLock"
      [title]="''"
      [totalAnswers]="totalAnswers"
      [selectionExists]="selectionExists"
      [filtersDemo]="filtersDemo"
      [touchDevice]="touchDevice"
    ></div>
    <ng-container *ngIf="!anonymityLock; else anonymityLockTemplate"></ng-container>
  </ng-container>
</div>

<div *ngIf="listModeOn" class="z-fx-col z-fx-auto list-mode-container" [class.search]="showSearchBar">
  <div *ngIf="showSearchBar" class="z-fx toolbar">
    <div class="z-fx-auto search-box">
      <zef-searchbox (input)="searchUpdate$.next($event)">
        <span i18n>Find...</span>
      </zef-searchbox>
    </div>
    <div *ngIf="!anonymityTreshold" class="mode-selector-search">
      <div class="zef-tabs-icon">
        <button mat-icon-button [class.active]="chartMode === 'gauge'" (click)="changeChartMode('gauge')">
          <mat-icon>chart_nps</mat-icon>
        </button>

        <button mat-icon-button [class.active]="chartMode === 'bar'" (click)="changeChartMode('bar')">
          <mat-icon>chart_bar_vertical</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div
    #tooltip
    *ngIf="chartMode === 'gauge' && hoveredElement != null"
    class="item-tooltip"
    [innerHtml]="hoveredContent"
    [style.transform]="'translate(' + tooltipPosition[0] + 'px,' + tooltipPosition[1] + 'px)'"
  ></div>
  <ng-scrollbar class="z-fx-flex z-fx-col ps">
    <div class="z-fx-col z-fx-auto">
      <div class="z-fx-col z-fx-auto table-container">
        <div class="z-fx-col z-fx-auto table-content mat-elevation-z0">
          <table cdk-table [dataSource]="NPStable$" cellspacing="0" cellpadding="0" class="nps-table">
            <!-- Name Column -->
            <ng-container cdkColumnDef="title">
              <th cdk-header-cell *cdkHeaderCellDef class="title-header-cell clickable" (click)="onSortColumn('title')">
                <span>
                  <span class="title-header-text" i18n>Name</span>
                  <ng-container *ngIf="sortKey$ | async as sortkey">
                    <ng-container *ngIf="sortDirection$ | async as sortdir">
                      <mat-icon
                        class="sort-icon zef-icon-small zef-color-primary"
                        [class.opacity-0]="sortkey !== 'title' || !sortdir"
                      >
                        {{ sortdir === 'asc' ? 'sort_up' : 'sort_down' }}
                      </mat-icon>
                    </ng-container>
                  </ng-container>
                </span>
              </th>
              <td
                cdk-cell
                *cdkCellDef="let element"
                class="title-cell"
                [class.hovered]="hoveredElement === element.index"
                (mouseenter)="onHover(element)"
                (mouseout)="onHover(null)"
              >
                {{ element.title }}
              </td>
              <td cdk-footer-cell *cdkFooterCellDef class="title-footer-cell z-fx-between-center"></td>
            </ng-container>

            <!-- Respondent Count Column -->
            <ng-container cdkColumnDef="count">
              <th cdk-header-cell *cdkHeaderCellDef class="count-header-cell clickable" (click)="onSortColumn('count')">
                <span>
                  <mat-icon class="zef-icon-small">contact</mat-icon>
                  <ng-container *ngIf="sortKey$ | async as sortkey">
                    <ng-container *ngIf="sortDirection$ | async as sortdir">
                      <mat-icon
                        class="sort-icon zef-icon-small zef-color-primary"
                        [class.invisible]="sortkey !== 'count' || !sortdir"
                      >
                        {{ sortdir === 'asc' ? 'sort_up' : 'sort_down' }}
                      </mat-icon>
                    </ng-container>
                  </ng-container>
                </span>
              </th>
              <td
                cdk-cell
                *cdkCellDef="let element"
                class="count-cell"
                [class.hovered]="hoveredElement === element.index"
                (mouseenter)="onHover(element)"
                (mouseout)="onHover(null)"
              >
                <ng-container
                  *ngIf="!anonymityTreshold || element.count >= anonymityTreshold; else anonymityLockedIcon"
                >
                  {{ element.count }}
                </ng-container>
              </td>
              <td cdk-footer-cell *cdkFooterCellDef class="count-footer-cell z-fx-between-center"></td>
            </ng-container>

            <!-- NPS Score Column -->
            <ng-container cdkColumnDef="npsScore">
              <th
                cdk-header-cell
                *cdkHeaderCellDef
                class="nps-score-header-cell clickable"
                [style.flex]="'1 1 ' + (wideModeOn ? '35%' : '65%')"
                (click)="onSortColumn('npsScore')"
              >
                <span>
                  <span class="nps-score-header-text" i18n>NPS Score</span>
                  <ng-container *ngIf="sortKey$ | async as sortkey">
                    <ng-container *ngIf="sortDirection$ | async as sortdir">
                      <mat-icon
                        class="sort-icon zef-icon-small zef-color-primary"
                        [class.invisible]="sortkey !== 'npsScore' || !sortdir"
                      >
                        {{ sortdir === 'asc' ? 'sort_up' : 'sort_down' }}
                      </mat-icon>
                    </ng-container>
                  </ng-container>
                </span>
              </th>
              <td
                cdk-cell
                *cdkCellDef="let element"
                class="nps-score-cell"
                [class.hovered]="hoveredElement === element.index"
                [style.max-width]="wideModeOn ? '35%' : '65%'"
                [ngSwitch]="chartMode"
                (mouseover)="onHover(element)"
                (mouseout)="onHover(null)"
              >
                <ng-container *ngIf="!anonymityTreshold || element.count >= anonymityTreshold">
                  <div
                    *ngSwitchCase="'gauge'"
                    class="single-bar-chart-container"
                    [class.hovered]="hoveredElement === element.index"
                    singleBarChartH
                    [value]="element.npsScore"
                    [colors]="element.colorScale"
                    [scale]="[-100, 100]"
                    [update]="update"
                    [hover]="hoveredElement === element.index"
                    [transitionDuration]="transition"
                  ></div>
                  <div
                    *ngSwitchCase="'bar'"
                    class="nps-bar-chart comparison"
                    [class.hovered]="hoveredElement === element.index"
                    npsBarChart
                    [NPSdata]="element"
                    [data]="element && element['originalDistribution']"
                    [stats]="stats"
                    [scale]="scale"
                    [filterInput]="filterInput && filterInput[npsDomIndex]"
                    [domain]="domain && domain[npsDomIndex]"
                    [transitionDuration]="transition"
                    [showNumbers]="showNumbers"
                    [showCount]="false"
                    [update]="update"
                    [filtering]="filtering"
                    [anonymityLock]="anonymityLock"
                    [title]="''"
                    [totalAnswers]="totalAnswers"
                    [selectionExists]="selectionExists"
                    [filtersDemo]="filtersDemo"
                    [touchDevice]="touchDevice"
                  ></div>
                  <ng-container
                    *ngIf="chartMode === 'gauge' || !anonymityLock; else anonymityLockTemplate"
                  ></ng-container>
                </ng-container>
              </td>
              <td
                cdk-footer-cell
                *cdkFooterCellDef
                class="nps-score-footer-cell z-fx"
                [style.flex]="'1 1 ' + (wideModeOn ? '35%' : '65%')"
              >
                <div class="min">-100</div>
                <div class="center">0</div>
                <div class="max">100</div>
              </td>
            </ng-container>

            <!-- Detractor Count Column -->
            <ng-container cdkColumnDef="detractor">
              <th
                cdk-header-cell
                *cdkHeaderCellDef
                class="detractor-header-cell clickable"
                (click)="onSortColumn('detractor')"
              >
                <span>
                  <span class="detractor-header-text" i18n>Detractor</span>
                  <ng-container *ngIf="sortKey$ | async as sortkey">
                    <ng-container *ngIf="sortDirection$ | async as sortdir">
                      <mat-icon
                        class="sort-icon zef-icon-small zef-color-primary"
                        [class.invisible]="sortkey !== 'detractor' || !sortdir"
                      >
                        {{ sortdir === 'asc' ? 'sort_up' : 'sort_down' }}
                      </mat-icon>
                    </ng-container>
                  </ng-container>
                </span>
              </th>
              <td
                cdk-cell
                *cdkCellDef="let element"
                class="detractor-cell"
                [class.hovered]="hoveredElement === element.index"
                (mouseenter)="onHover(element)"
                (mouseout)="onHover(null)"
              >
                <ng-container
                  *ngIf="
                    !anonymityTreshold ||
                      (element.count >= anonymityTreshold && isAnonymitySafe(element?.distribution));
                    else anonymityLockedIcon
                  "
                >
                  {{ element?.distribution?.[0]?.value || '-' }}
                </ng-container>
              </td>
              <td cdk-footer-cell *cdkFooterCellDef class="detractor-footer-cell z-fx-between-center"></td>
            </ng-container>

            <!-- Passive Count Column -->
            <ng-container cdkColumnDef="passive">
              <th
                cdk-header-cell
                *cdkHeaderCellDef
                class="passive-header-cell clickable"
                (click)="onSortColumn('passive')"
              >
                <span>
                  <span class="passive-header-text" i18n>Passive</span>
                  <ng-container *ngIf="sortKey$ | async as sortkey">
                    <ng-container *ngIf="sortDirection$ | async as sortdir">
                      <mat-icon
                        class="sort-icon zef-icon-small zef-color-primary"
                        [class.invisible]="sortkey !== 'passive' || !sortdir"
                      >
                        {{ sortdir === 'asc' ? 'sort_up' : 'sort_down' }}
                      </mat-icon>
                    </ng-container>
                  </ng-container>
                </span>
              </th>
              <td
                cdk-cell
                *cdkCellDef="let element"
                class="passive-cell"
                [class.hovered]="hoveredElement === element.index"
                (mouseenter)="onHover(element)"
                (mouseout)="onHover(null)"
              >
                <ng-container
                  *ngIf="
                    !anonymityTreshold ||
                      (element.count >= anonymityTreshold && isAnonymitySafe(element?.distribution));
                    else anonymityLockedIcon
                  "
                >
                  {{ element?.distribution?.[1]?.value || '-' }}
                </ng-container>
              </td>
              <td cdk-footer-cell *cdkFooterCellDef class="passive-footer-cell z-fx-between-center"></td>
            </ng-container>

            <!-- Promoter Count Column -->
            <ng-container cdkColumnDef="promoter">
              <th
                cdk-header-cell
                *cdkHeaderCellDef
                class="promoter-header-cell clickable"
                (click)="onSortColumn('promoter')"
              >
                <span>
                  <span class="promoter-header-text" i18n>Promoter</span>
                  <ng-container *ngIf="sortKey$ | async as sortkey">
                    <ng-container *ngIf="sortDirection$ | async as sortdir">
                      <mat-icon
                        class="sort-icon zef-icon-small zef-color-primary"
                        [class.invisible]="sortkey !== 'promoter' || !sortdir"
                      >
                        {{ sortdir === 'asc' ? 'sort_up' : 'sort_down' }}
                      </mat-icon>
                    </ng-container>
                  </ng-container>
                </span>
              </th>
              <td
                cdk-cell
                *cdkCellDef="let element"
                class="promoter-cell"
                [class.hovered]="hoveredElement === element.index"
                (mouseenter)="onHover(element)"
                (mouseout)="onHover(null)"
              >
                <ng-container
                  *ngIf="
                    !anonymityTreshold ||
                      (element.count >= anonymityTreshold && isAnonymitySafe(element?.distribution));
                    else anonymityLockedIcon
                  "
                >
                  {{ element?.distribution?.[2]?.value || '-' }}
                </ng-container>
              </td>
              <td cdk-footer-cell *cdkFooterCellDef class="promoter-footer-cell z-fx-between-center"></td>
            </ng-container>

            <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
            <tr cdk-row *cdkRowDef="let row; columns: displayedColumns"></tr>
            <tr cdk-footer-row *cdkFooterRowDef="displayedColumns"></tr>
          </table>
        </div>
      </div>
    </div>
  </ng-scrollbar>
</div>

<ng-template #anonymityLockTemplate>
  <div
    class="anonymity-lock"
    matTooltip="Chart is locked to protect anonymity of the respondent"
    matTooltipClass="zef-tooltip-ink-lighter"
    i18n-matTooltip
    matTooltipPosition="above"
  >
    <mat-icon class="zef-icon-small lock-icon">lock</mat-icon>
  </div>
</ng-template>

<ng-template #anonymityLockedIcon>
  <mat-icon *ngIf="!exportChart" class="zef-icon-small zef-color-ink anonymity-lock-icon">anonymous_on</mat-icon>
</ng-template>

<ng-template #anonymous>
  <div class="z-fx-col z-fx-auto anonymous">
    <div class="z-fx z-fx-center-center answerers">
      <mat-icon class="respondent-icon">contact</mat-icon>
      <span class="number" i18n>under {{ anonymityTreshold }}</span>
    </div>
    <div class="z-fx-col z-fx-auto z-fx-start-center z-fx-gap-16">
      <div class="z-fx-col z-fx-end-center image">
        <mat-icon class="zef-icon-larger">anonymous_on</mat-icon>
      </div>
      <div class="description zef-text-center">
        <ng-container i18n>
          Charts are hidden if there are less than {{ anonymityTreshold }} respondents in Anonymous survey.
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
