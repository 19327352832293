import * as d3 from 'd3';

import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';

import {
  CanvasContext,
  ChartDistribution,
  ChartDomain,
  ChartLegend,
  DimensionDataItem,
} from '@shared/models/report.model';

import { Crossfilter } from '@report/shared/services/crossfilter.service';

import { Colors } from '@report/shared/enums/colors.enum';
import { drawContactIcon, shortenText } from '@shared/utilities/canvas.utilities';

/**
 * This is a horizontal bar chart.
 */
@Directive({
  selector: '[timelineChart]',
})
export class TimelineChart implements OnChanges {
  @Input() data: ChartDistribution[][] = [];
  @Input() domain: ChartDomain = {} as ChartDomain;
  @Input() details: DimensionDataItem = {} as DimensionDataItem;
  @Input() stats: any;
  @Input() scale: any;
  @Input() comparison: any;
  @Input() filterInput: any;
  @Input() transitionDuration: number = 0;
  @Input() showNumbers: boolean = false;
  @Input() update: Date = new Date();
  @Input() filtering: boolean = false;
  @Input() anonymityLock: boolean = false;
  @Input() totalAnswers: number = 0;
  @Input() touchDevice: boolean = false;
  @Input() selectionExists: boolean = false;
  @Input() filtersDemo: boolean = false;
  @Input() trendHoverInput: string = '';
  @Output() hover: EventEmitter<string> = new EventEmitter<string>();

  private base: any;

  private context: CanvasContext = {} as CanvasContext;
  private canvas: any;

  private max: number = 0;
  private previousMax: number = 0;

  private responses: number[] = [];
  private previousResponses: number[] = [];

  private scaleY: any;

  private brush: any;
  private brushArea: any;
  private brushing: boolean = false;

  private legends: ChartLegend[] = [];

  private tooltip: any;
  private tooltipTexts: any;

  private width: number = 0;
  private height: number = 0;
  private margin: any;
  private fontSize: number = 0;
  private unit: number = 0;
  private marginBottomUnits: number = 0;

  private selectedTimePeriod: string = 'day';
  private timePeriods: any[] = [];

  private filter: any;

  private timePos: number | null = null;
  private hoveredLines: string = '';
  private timelineFormat: any;
  private locale: string = 'en';

  private selections: any = [new Set(), new Set()];

  @HostListener('window:resize') resize() {
    this.updateChart(null);
  }

  constructor(
    private _element: ElementRef,
    private cf: Crossfilter,
  ) {
    this.constructBody();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.data ||
      changes.domain ||
      changes.scale ||
      changes.filterInput ||
      changes.showNumbers ||
      changes.update ||
      changes.filtering ||
      changes.anonymityLock ||
      changes.title ||
      changes.stats ||
      changes.comparison ||
      (changes.trendHoverInput && !this.hoveredLines) ||
      changes.filtersDemo ||
      changes.selectionExists
    ) {
      this.updateChart(changes.data);
    }
  }

  updateChart(dataChanges: SimpleChange | null) {
    this.setEnvironment();
    this.setScales();
    this.setNumbers();
    this.setCanvas(dataChanges);
    this.setBrush();
  }

  constructBody() {
    this.margin = { top: 50, right: 40, bottom: 90, left: 40 };
    this.marginBottomUnits = 7;
    this.base = d3.select(this._element.nativeElement).append('div').attr('class', 'timeline-chart');
  }

  setEnvironment() {
    this.fontSize = parseFloat(window.getComputedStyle(this._element.nativeElement).fontSize);
    this.unit = (10 / 14) * this.fontSize;

    this.margin = {
      top: 3.4 * this.unit,
      right: 3 * this.unit,
      bottom: this.marginBottomUnits * this.unit,
      left: 3 * this.unit,
    };

    const width = this._element.nativeElement.clientWidth - this.margin.left - this.margin.right;
    const height = this._element.nativeElement.clientHeight - this.margin.top - this.margin.bottom;

    this.width = width > 0 ? width : 0;
    this.height = height > 0 ? height : 0;
  }

  setScales() {
    const overScale = 1.2;
    let max: number = 0;

    this.data[0].forEach((d) => {
      if (this.domain.keysY.length > 0) {
        d.children.forEach((c) => {
          const val = this.scale === 'percentage' ? c.percentage : c.value;
          if (val > max) {
            max = val;
          }
        });
      } else {
        const val = this.scale === 'percentage' ? d.percentage : d.value;
        if (val > max) {
          max = val;
        }
      }
    });

    this.previousMax = this.max;
    this.max = (max || 1) * overScale;

    this.scaleY = d3.scaleLinear().rangeRound([this.height, 0]).domain([0, this.max]);

    this.selectedTimePeriod = this.cf.getTimePeriod();
  }

  setNumbers() {
    this.previousResponses = this.responses;
    this.responses = [];

    if (this.domain.keysY.length > 0) {
      (this.data[1] || []).forEach((d, i) => {
        const statItem = ((this.stats && this.stats[1] && this.stats[1]['children']) || []).find(
          (item) => item && item.key === d.key,
        );
        this.responses[i] =
          statItem &&
          statItem['children'] &&
          statItem['children'][this.domain.index] &&
          statItem['children'][this.domain.index]['responses'] != null
            ? statItem['children'][this.domain.index]['responses']
            : null;
      });
    } else {
      this.responses[0] =
        this.stats && this.stats[0] && this.stats[0]['responses'] != null ? this.stats[0]['responses'] : null;
    }
  }

  colorScale(index) {
    if (this.domain.scaleY.length > 0) {
      return Colors.getComparisonColor(index);
    } else {
      return this.selectionExists ? Colors.SELECTED : this.filtersDemo ? Colors.UNSELECTED : Colors.DEFAULT;
    }
  }

  setCanvas(dataChanges: SimpleChange | null) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const __this = this;
    const drawContent = function (d) {
      const context = d3.select(this).node().getContext('2d');

      __this.setLegends(context);

      if (dataChanges && !dataChanges.firstChange && __this.transitionDuration > 0) {
        const dataObj = __this.context && __this.context.data ? __this.context.data : [];
        const interpolateArray: any = (a, b) => {
          const nb: number = b ? b.length : 0;
          const na: number = a ? Math.min(nb, a.length) : 0;
          const x: any[] = new Array(na);
          const c: any[] = new Array(...b);

          for (let i = 0; i < na; ++i) {
            x[i] = {
              value: d3.interpolateNumber(a[i]['value'], b[i]['value']),
              percentage: d3.interpolateNumber(a[i]['percentage'], b[i]['percentage']),
              percentage_all: d3.interpolateNumber(a[i]['percentage_all'], b[i]['percentage_all']),
              children: [],
            };
            for (let child = 0, lenc = c[i]['children'].length; child < lenc; child++) {
              const aVal: number =
                a[i] && a[i]['children'] && a[i]['children'][child] ? a[i]['children'][child]['value'] : 0;
              const bVal: number =
                b[i] && b[i]['children'] && b[i]['children'][child] ? b[i]['children'][child]['value'] : 0;
              const aPer: number =
                a[i] && a[i]['children'] && a[i]['children'][child] ? a[i]['children'][child]['percentage'] : 0;
              const bPer: number =
                b[i] && b[i]['children'] && b[i]['children'][child] ? b[i]['children'][child]['percentage'] : 0;
              const aPerAll: number =
                a[i] && a[i]['children'] && a[i]['children'][child] ? a[i]['children'][child]['percentage_all'] : 0;
              const bPerAll: number =
                b[i] && b[i]['children'] && b[i]['children'][child] ? b[i]['children'][child]['percentage_all'] : 0;
              x[i]['children'][child] = {
                value: d3.interpolateNumber(aVal, bVal),
                percentage: d3.interpolateNumber(aPer, bPer),
                percentage_all: d3.interpolateNumber(aPerAll, bPerAll),
              };
            }
          }

          return function (t) {
            for (let i = 0; i < na; ++i) {
              c[i]['value'] = x[i]['value'](t);
              c[i]['percentage'] = x[i]['percentage'](t);
              c[i]['percentage_all'] = x[i]['percentage_all'](t);

              for (let child = 0, lenc = c[i]['children'].length; child < lenc; child++) {
                c[i]['children'][child]['value'] = x[i]['children'][child]['value'](t);
                c[i]['children'][child]['percentage'] = x[i]['children'][child]['percentage'](t);
                c[i]['children'][child]['percentage_all'] = x[i]['children'][child]['percentage_all'](t);
              }
            }
            return c;
          };
        };
        const interpolator = interpolateArray(dataObj, d[0]);
        const interpolateMax = d3.interpolateNumber(__this.previousMax, __this.max);
        const interpolateResponses = d3.interpolateArray(__this.previousResponses, __this.responses);
        const ease = d3.easeCubic;

        const timer = d3.timer((elapsed) => {
          const step = elapsed / __this.transitionDuration;
          let data;
          let scaleY;
          let responses;

          if (step >= 1) {
            data = interpolator(ease(1));
            responses = interpolateResponses(ease(1));
            scaleY = d3
              .scaleLinear()
              .rangeRound([__this.height, 0])
              .domain([0, interpolateMax(ease(1))]);
            timer.stop();
          } else {
            data = interpolator(ease(step));
            responses = interpolateResponses(ease(step));
            scaleY = d3
              .scaleLinear()
              .rangeRound([__this.height, 0])
              .domain([0, interpolateMax(ease(step))]);
          }

          __this.setLines(context, data, scaleY, [], null, [], responses);
        });
      } else {
        __this.setLines(context, d[0], __this.scaleY, [], null, [], __this.responses);
      }

      __this.context = { context, data: d[0] };
    };

    this.canvas = this.base.selectAll('.timeline-chart-canvas').data([this.data]);

    this.canvas.exit().remove();

    this.canvas
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .each(drawContent);

    this.canvas
      .enter()
      .append('canvas')
      .attr('class', 'timeline-chart-canvas')
      .style('position', 'relative')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .each(drawContent);
  }

  setLegends(context) {
    this.legends = [];
    const y = this.margin.top + this.height + 5 * this.unit;
    const width = this.width + this.margin.left + this.margin.right;
    const height = 2 * this.unit;
    let usedSpace = 0;
    let arr: any[] = [];
    let currentX = 0;
    let currentY = 0;
    let margin;

    context.clearRect(0, y - 1, width, this.margin.bottom);

    context.textBaseline = 'top';
    context.textAlign = 'left';
    context.font = 10 / 14 + 'em Inter';

    for (let i = 0, length = this.domain.keysY.length; i < length; i++) {
      const key = this.domain.keysY[i];
      const text = shortenText(context, this.domain.labelsY[key], width, 3 * this.unit);
      const elW = context.measureText(text).width;

      if (usedSpace + elW + 2 * this.unit <= width) {
        usedSpace += elW + 2 * this.unit;
        arr.push([key, this.domain.colorsY && this.domain.colorsY[key] != null ? this.domain.colorsY[key] : i, text]);
      } else {
        margin = (width - usedSpace) / 2;
        currentX = margin;

        for (let a = 0, len = arr.length; a < len; a++) {
          const itemText = arr[a][2];
          const itemColor = arr[a][1];
          const itemKey = arr[a][0];
          currentX += this.unit;

          context.fillStyle = Colors.TEXT;
          context.fillText(itemText, currentX, currentY + y);

          context.beginPath();
          context.arc(currentX - 0.8 * this.unit, currentY + y + 0.6 * this.unit, 0.5 * this.unit, 0, 2 * Math.PI);
          context.closePath();
          context.fillStyle = this.colorScale(itemColor);
          context.fill();
          this.legends.push({
            key: itemKey,
            x: currentX,
            y: currentY + y,
            width: context.measureText(itemText).width,
            height,
          });
          currentX += context.measureText(itemText).width + this.unit;
        }

        currentY += height;
        usedSpace = elW + 2 * this.unit;

        arr = [[key, this.domain.colorsY && this.domain.colorsY[key] != null ? this.domain.colorsY[key] : i, text]];
      }
    }

    margin = (width - usedSpace) / 2;
    currentX = margin;

    for (let a = 0, len = arr.length; a < len; a++) {
      const itemText = arr[a][2];
      const itemColor = arr[a][1];
      const itemKey = arr[a][0];
      currentX += this.unit;

      context.fillStyle = Colors.TEXT;
      context.fillText(itemText, currentX, currentY + y);

      context.beginPath();
      context.arc(currentX - 0.8 * this.unit, currentY + y + 0.6 * this.unit, 0.5 * this.unit, 0, 2 * Math.PI);
      context.closePath();
      context.fillStyle = this.colorScale(itemColor);
      context.fill();
      this.legends.push({
        key: itemKey,
        x: currentX,
        y: currentY + y,
        width: context.measureText(itemText).width,
        height,
      });
      currentX += context.measureText(itemText).width + this.unit;
    }

    const spaceNeeded = currentY / this.unit + 7;

    if (!isNaN(spaceNeeded) && this.marginBottomUnits !== spaceNeeded) {
      this.marginBottomUnits += spaceNeeded - this.marginBottomUnits;
      this.setEnvironment();
      this.setScales();
      this.setLegends(context);
    }
  }

  setLines(
    context,
    data: any[] = [],
    scaleY,
    filter: any[] | null = [],
    highlight: any[] | null = [],
    legends: any[] = [],
    responses: number[] = [],
  ) {
    context.clearRect(this.margin.left, this.margin.top, this.width + this.margin.right, this.height + 5);
    this.setTexts(context, responses);
    this.setYAxis(context, scaleY);
    this.selections = [new Set(), new Set()];
    const lines: any[] = [];
    const legendIndex = legends.length > 0 ? this.domain.keysY.indexOf(legends[0].key) : -1;

    for (let x = 0, lenx = data.length; x < lenx; x++) {
      const keyX = data[x]['key'];
      const posParent = this.scaleXPos(x, lenx);

      if (this.domain.keysY.length > 0) {
        for (let y = 0, leny = data[x].children.length; y < leny; y++) {
          const keyY = data[x]['children'][y]['key'];
          const value = data[x]['children'][y]['value'];
          const percentage = data[x]['children'][y]['percentage_all'];

          const yPos = scaleY(this.scale === 'percentage' ? percentage : value) + this.margin.top;
          const xPos = posParent;

          if (!lines[y]) {
            lines[y] = [];
          }

          lines[y].push({
            keyX,
            xPos,
            keyY,
            yPos,
            value,
            percentage,
          });
        }
      } else {
        const keyY = data[x]['key'];
        const value = data[x]['value'];
        const percentage = data[x]['percentage_all'];

        const yPos = scaleY(this.scale === 'percentage' ? percentage : value) + this.margin.top;
        const xPos = posParent;

        if (!lines[0]) {
          lines[0] = [];
        }

        lines[0].push({
          keyX,
          xPos,
          keyY,
          yPos,
          value,
          percentage,
        });
      }
    }

    const filterCheck = (d) =>
      filter != null && filter.length === 2
        ? filter && filter[0] <= d['xPos'] - this.margin.left && filter[1] >= d['xPos'] - this.margin.left
        : this.filterInput && this.filterInput[0] && this.filterInput[0].length > 0
          ? this.filterInput[0].indexOf(d['keyX']) > -1
          : true;

    const line = d3
      .line()
      .defined(function (d: any) {
        return filterCheck(d);
      })
      .x(function (d) {
        return d['xPos'];
      })
      .y(function (d) {
        return d['yPos'];
      })
      .curve(d3.curveLinear)
      .context(context);

    const filteredLine = d3
      .line()
      .defined(function (d: any) {
        return !filterCheck(d);
      })
      .x(function (d) {
        return d['xPos'];
      })
      .y(function (d) {
        return d['yPos'];
      })
      .curve(d3.curveLinear)
      .context(context);

    for (let l = 0, len = lines.length; l < len; l++) {
      const color = this.colorScale(
        this.domain.colorsY && lines[l][0] && this.domain.colorsY[lines[l][0]['keyY']] != null
          ? this.domain.colorsY[lines[l][0]['keyY']]
          : l,
      );
      const legendDim = legendIndex === -1 ? false : legendIndex !== l;

      context.lineWidth = 1.5;
      context.fillStyle = color;
      context.strokeStyle = color;
      context.globalAlpha = 1;

      context.beginPath();
      line(lines[l]);
      context.globalAlpha = !legendDim ? 1 : 0.05;
      context.strokeStyle = color;
      context.stroke();

      context.beginPath();
      filteredLine(lines[l]);
      context.globalAlpha = 0.05;
      context.strokeStyle = color;
      context.stroke();
    }

    if (highlight && highlight[0] && highlight[0].length > 0) {
      for (let h = 0, lenh = highlight[0].length; h < lenh; h++) {
        const d = (lines && lines[0] && lines[0].length > 0 ? lines[0] : []).findIndex(
          (item) => item.keyX === highlight[0][h]['key'],
        );
        const lenhh = lines && lines[0] && lines[0].length > 0 ? lines[0].length : 1;
        const xPos = this.scaleXPos(d, lenhh);

        if (!isNaN(xPos)) {
          context.strokeStyle = Colors.TEXT;
          context.lineWidth = 1;
          context.beginPath();
          context.moveTo(xPos, this.margin.top);
          context.lineTo(xPos, this.margin.top + this.height);
          context.stroke();
        }
      }
    } else if (this.trendHoverInput) {
      const d = (lines && lines[0] && lines[0].length > 0 ? lines[0] : []).findIndex(
        (item) => item.keyX === this.trendHoverInput,
      );
      const lenhh = lines && lines[0] && lines[0].length > 0 ? lines[0].length : 1;
      const xPos = this.scaleXPos(d, lenhh);

      if (!isNaN(xPos)) {
        context.strokeStyle = Colors.TEXT;
        context.lineWidth = 1;
        context.beginPath();
        context.moveTo(xPos, this.margin.top);
        context.lineTo(xPos, this.margin.top + this.height);
        context.stroke();
      }
    }

    for (let l = 0, len = lines.length; l < len; l++) {
      const color = this.colorScale(
        this.domain.colorsY && lines[l][0] && this.domain.colorsY[lines[l][0]['keyY']] != null
          ? this.domain.colorsY[lines[l][0]['keyY']]
          : l,
      );
      const legendDim = legendIndex === -1 ? false : legendIndex !== l;

      if (!legendDim) {
        for (let d = 0, lend = lines[l].length; d < lend; d++) {
          context.lineWidth = 1.5;
          context.fillStyle = color;
          context.strokeStyle = color;
          context.globalAlpha = 1;

          const keyX = lines[l][d]['keyX'];
          const keyY = lines[l][d]['keyY'];
          const xPos = lines[l][d]['xPos'];
          const yPos = lines[l][d]['yPos'];
          const value = lines[l][d]['value'];
          const percentage = lines[l][d]['percentage'];

          if (filter != null && filter.length === 2) {
            const parent =
              filter &&
              filter[0] <= this.scaleXPos(d, lend) - this.margin.left &&
              filter[1] >= this.scaleXPos(d, lend) - this.margin.left;

            if (!parent) {
              context.globalAlpha = 0.1;
            } else if (parent) {
              this.selections[0].add(keyX);
            }
          } else if (this.filterInput && this.filterInput[0] && this.filterInput[0].length > 0) {
            const parent = this.filterInput[0] && this.filterInput[0].indexOf(keyX) > -1;

            if (!parent) {
              context.globalAlpha = 0.1;
            } else {
              if (filter != null && parent) {
                this.selections[0].add(keyX);
              }
            }
          }

          context.strokeStyle = 'transparent';

          let isHighlighted = false;
          if (
            highlight &&
            highlight.length > 0 &&
            ((highlight[0] && highlight[0].length > 0) || (highlight[1] && highlight[1].length > 0))
          ) {
            const xHi = highlight[0] && highlight[0].map((item) => item.key).indexOf(keyX) > -1;
            const child =
              (highlight[1] && highlight[1].map((item) => item.key).indexOf(keyY) > -1) ||
              (highlight[1] && highlight[1][0] && highlight[1][0]['key'] === 'aggregate');

            if (xHi && child) {
              isHighlighted = true;
              context.strokeStyle = Colors.HIGHLIGHT;
            }
          } else if (this.trendHoverInput && this.trendHoverInput === keyX) {
            isHighlighted = true;
            context.strokeStyle = Colors.HIGHLIGHT;
          }

          if (isHighlighted || this.width / lend > 16) {
            context.beginPath();
            context.arc(xPos, yPos, !isHighlighted ? 4 : value > 0 ? 8 : 4, 0, 2 * Math.PI);
            context.closePath();

            context.fill();
            context.stroke();
          }

          if (this.showNumbers) {
            context.font = 10 / 14 + 'em Inter';
            context.textBaseline = 'bottom';
            context.textAlign = 'center';
            context.fillStyle = Colors.TEXT;
            context.fillText(
              this.scale === 'percentage' ? (percentage * 100).toFixed(1) + '%' : Math.round(value),
              xPos,
              yPos - 5,
            );
          }
        }
      }
    }
    context.globalAlpha = 1;
    context.clearRect(this.margin.left, this.margin.top + this.height, this.width + this.margin.right, 8);
  }

  setTexts(context, responses) {
    const height = 4 * this.unit;
    context.clearRect(0, this.height + this.margin.top, this.width + this.margin.right + this.margin.left, height + 2);
    context.clearRect(0, 0, this.width + this.margin.right + this.margin.left, this.margin.top);

    const h = (1 / 3 + 1.2 / 2) * this.unit;

    // setting time period selector
    this.timePeriods = [];
    const periods: string[] = ['day', 'week', 'month', 'year'];
    const names = {
      year: $localize`:@@zef-i18n-00339:Yearly`,
      month: $localize`:@@zef-i18n-00340:Monthly`,
      week: $localize`:@@zef-i18n-00341:Weekly`,
      day: $localize`:@@zef-i18n-00342:Daily`,
    };
    const ws: number[] = [];
    const w: number[] = [];
    context.fillStyle = Colors.TEXT;
    context.textAlign = 'left';
    context.textBaseline = 'middle';
    context.font = 'normal 600 ' + 12 / 14 + 'em Inter';

    for (let p = 0, lenp = periods.length; p < lenp; p++) {
      const item = periods[p];
      const width = context.measureText(names[item]).width;

      ws[p] = width + (p > 0 ? ws[p - 1] : 0);
      w[p] = width;
    }

    for (let p = 0, lenp = periods.length; p < lenp; p++) {
      const item = periods[p];
      const x = this.margin.left + this.width - ws[p] - p * 16;
      context.fillStyle = item !== this.selectedTimePeriod ? Colors.TEXT : Colors.PRIMARY;
      context.fillText(names[item], x, h);
      this.timePeriods.push({ key: item, x, y: h, w: w[p], h: (12 / 14) * this.fontSize });
    }

    // Setting stats
    const wIcon: number[] = [];
    const wNumber: number[] = [];

    context.textAlign = 'left';
    context.textBaseline = 'middle';

    for (let i = 0, len = responses.length; i < len; i++) {
      wIcon.push(this.fontSize + 4);

      context.font = 10 / 14 + 'em Inter';
      wNumber.push(context.measureText(Math.round(responses[i])).width + 8);
    }
    const yPos =
      this.timePeriods[this.timePeriods.length - 1].x <
      (this.margin.left + this.margin.right + this.width) / 2 +
        (wIcon.reduce((t, v) => t + v) + wNumber.reduce((t, v) => t + v)) / 2
        ? h + 1.8 * this.unit
        : h;

    for (let i = 0, len = responses.length; i < len; i++) {
      const key = this.data && this.data[1] && this.data[1][i] ? this.data[1][i]['key'] : '';

      const startPointResponses =
        (this.margin.left + this.margin.right + this.width) / 2 +
        (wIcon.reduce((t, v) => t + v) + wNumber.reduce((t, v) => t + v)) * (1 / len) * (i - len / 2);

      context.fillStyle = Colors.TEXT;
      context.fillStyle =
        key && this.domain.colorsY && this.domain.colorsY[key] != null
          ? this.colorScale(this.domain.colorsY[key])
          : this.colorScale(i);
      drawContactIcon(context, this.fontSize, startPointResponses, yPos, context.fillStyle);

      context.font = 10 / 14 + 'em Inter';
      context.fillStyle =
        key && this.domain.colorsY && this.domain.colorsY[key] != null
          ? this.colorScale(this.domain.colorsY[key])
          : this.colorScale(i);
      context.fillStyle = Colors.TEXT;
      context.fillText(Math.round(responses[i]), startPointResponses + wIcon[i], yPos);
    }

    // setting timeline
    context.beginPath();
    context.moveTo(this.margin.left, this.height + this.margin.top + 1 * this.unit);
    context.lineTo(this.margin.left + this.width, this.height + this.margin.top + 1 * this.unit);
    context.closePath();
    context.strokeStyle = Colors.HELPERLINE;
    context.stroke();

    const keys = this.domain.keys;
    this.setTimelineTicks(context, keys, this.width, this.margin.top + this.height);
  }

  getTimelineFormat(times) {
    this.locale =
      this.cf.getActiveLocale().length > 5 || !this.cf.getActiveLocale()
        ? navigator.language
        : this.cf.getActiveLocale();
    const years =
      new Date(Number(times[0])).getFullYear() !== new Date(Number(times[times.length - 1])).getFullYear() ||
      new Date(Number(times[0])).getFullYear() !== new Date().getFullYear();

    if (years) {
      if (this.selectedTimePeriod === 'year') {
        return new Intl.DateTimeFormat(this.locale, { year: 'numeric' });
      } else if (this.selectedTimePeriod === 'month') {
        return new Intl.DateTimeFormat(this.locale, { month: 'short', year: 'numeric' });
      } else {
        return new Intl.DateTimeFormat(this.locale, { day: 'numeric', month: 'numeric', year: 'numeric' });
      }
    } else if (this.selectedTimePeriod === 'year') {
      return new Intl.DateTimeFormat(this.locale, { year: 'numeric' });
    } else if (this.selectedTimePeriod === 'month') {
      return new Intl.DateTimeFormat(this.locale, { month: 'long' });
    } else {
      return new Intl.DateTimeFormat(this.locale, { day: 'numeric', month: 'short' });
    }
  }

  setTimelineTicks(context, times, width, y) {
    this.timelineFormat = this.getTimelineFormat(times);
    context.font = 10 / 14 + 'em Inter';
    let lastItem = times.length - 1;
    const tickW =
      context.measureText(this.timelineFormat.format(new Date(2019, this.locale !== 'fi' ? 11 : 10, 24))).width + 16;
    const allTicks = Number(times.length);
    const tickPossible = Math.floor((width + tickW) / tickW);
    const possibleTicksBetween =
      tickPossible < allTicks
        ? tickPossible > 2
          ? tickPossible / (allTicks - 2) > 0.5
            ? Math.floor((allTicks - 2) / 2)
            : tickPossible - 2
          : 0
        : allTicks - 2;
    const availableExcessSpace = (width + tickW) / tickW - tickPossible;

    const calcTickReducer = (all, possible) => {
      const rounder = (number) => Math.round(number * Math.pow(10, 5)) / Math.pow(10, 5);

      return possible > 0 && (all - 1) / (possible + 1) >= 1 ? rounder((all - 1) / (possible + 1)) : all;
    };

    const arr = [
      allTicks,
      allTicks,
      allTicks,
      Number.isInteger(calcTickReducer(allTicks, possibleTicksBetween + availableExcessSpace > 0.7 ? 1 : 0))
        ? calcTickReducer(allTicks, possibleTicksBetween + availableExcessSpace > 0.7 ? 1 : 0)
        : allTicks,
    ];
    for (let i = possibleTicksBetween; i > 0; i--) {
      const reducer = calcTickReducer(allTicks, i);
      const reducer1 = calcTickReducer(allTicks - 1, i);
      const reducer2 = calcTickReducer(allTicks - 2, i);

      if (Number.isInteger(reducer) && reducer < arr[0]) {
        arr[0] = reducer;
      }
      if (Number.isInteger(reducer1) && reducer1 < arr[1]) {
        arr[1] = reducer1;
      }
      if (Number.isInteger(reducer2) && reducer2 < arr[2] && allTicks - 2 > possibleTicksBetween + 2) {
        arr[2] = reducer2;
      }
    }
    const reducerIndex = arr.indexOf(Math.min(...arr));

    const tickReducer = arr[reducerIndex];
    lastItem -= reducerIndex < 3 ? reducerIndex : 0;

    const w = width / times.length;
    times.forEach((tick, i) => {
      if (Number(i) === 0 || Number(i) % tickReducer === 0 || Number(i) === lastItem) {
        const posX = Number(i) * w + this.margin.left + w / 2;
        context.beginPath();
        context.moveTo(posX, y + 1 * this.unit);
        context.lineTo(posX, y + 1 * this.unit + 5);
        context.lineWidth = 1;
        context.strokeStyle = Colors.HELPERLINE;
        context.stroke();

        context.textBaseline = 'middle';
        context.textAlign = 'center';

        context.fillStyle = Colors.TEXT;
        context.fillText(this.timelineFormat.format(new Date(Number(tick) || 0)), posX, y + 2.5 * this.unit);
      }
    });
  }

  setYAxis(context, scaleY) {
    context.clearRect(0, this.margin.top, this.margin.left, this.height + this.unit);
    context.globalAlpha = 1;

    const tickCount = this.scale === 'percentage' ? 4 : this.max > 3 ? 4 : this.max > 2 ? 2 : 1;
    const tickPadding = 3;
    const ticks = scaleY.ticks(tickCount);
    const tickFormat = scaleY.tickFormat(tickCount, this.scale === 'percentage' ? ',.1%' : 'd');

    context.font = 10 / 14 + 'em Inter';
    context.fillStyle = Colors.TEXT;
    context.strokeStyle = Colors.HELPERLINE;
    context.lineWidth = 1;
    context.textAlign = 'right';
    context.textBaseline = 'middle';
    ticks.forEach((d) => {
      const x = this.margin.left - tickPadding;
      const y = this.margin.top + scaleY(d);
      context.fillText(tickFormat(d), x, y);
      if (d > 0) {
        context.beginPath();
        context.moveTo(x + tickPadding + 8, y);
        context.lineTo(this.margin.left + this.width, y);
        context.stroke();
      }
    });
  }

  setBrush() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const __this = this;
    const hoverFunction = function (event) {
      const area = d3.pointer(event);
      let timePeriodItemsBelow = false;
      const selector = __this.filtering && !__this.anonymityLock ? '.svg-brush' : '.timeline-chart-canvas';

      if (area[1] < __this.margin.top) {
        for (let t = 0, lent = __this.timePeriods.length; t < lent; t++) {
          const item = __this.timePeriods[t];
          if (
            area[0] > item.x &&
            area[0] < item.x + item.w &&
            area[1] < item.y + item.h / 2 &&
            area[1] > item.y - item.h / 2
          ) {
            timePeriodItemsBelow = true;
            break;
          }
        }
        if (timePeriodItemsBelow) {
          __this.base.selectAll(selector).style('cursor', 'pointer');
        } else {
          __this.base.selectAll(selector).style('cursor', null);
        }
      }

      if (!timePeriodItemsBelow && (!__this.touchDevice || !__this.filtering || __this.anonymityLock)) {
        __this.selectForHover(area);
      }
    };
    const clickFunction = function (event) {
      const area = d3.pointer(event);

      if (area[1] < __this.margin.top) {
        for (let t = 0, lent = __this.timePeriods.length; t < lent; t++) {
          const item = __this.timePeriods[t];
          if (
            area[0] > item.x &&
            area[0] < item.x + item.w &&
            area[1] < item.y + item.h / 2 &&
            area[1] > item.y - item.h / 2
          ) {
            __this.cf.changeTimePeriod(item.key);
            break;
          }
        }
      }
    };

    if (this.filtering && !this.anonymityLock) {
      this.brush = d3
        .brushX()
        .on('brush', function (event) {
          if (event.sourceEvent) {
            // learn more from https://github.com/d3/d3-selection/issues/122
            __this.brushing = true;

            const sel =
              this && d3.select(this) && d3.select(this).node() ? d3.brushSelection(d3.select(this).node()!) : null;

            __this.base.select('.timeline-chart-canvas').each(function () {
              __this.setLines(
                __this.context.context,
                __this.context.data,
                __this.scaleY,
                sel,
                null,
                [],
                __this.responses,
              );
            });

            __this.setTooltip(null);
          }
        })
        .on('end', function (event) {
          if (event.sourceEvent) {
            // learn more from https://github.com/d3/d3-selection/issues/122
            __this.brushing = false;
            const sel =
              this && d3.select(this) && d3.select(this).node() ? d3.brushSelection(d3.select(this).node()!) : null;

            __this.base.select('.timeline-chart-canvas').each(function () {
              __this.setLines(
                __this.context.context,
                __this.context.data,
                __this.scaleY,
                sel,
                null,
                [],
                __this.responses,
              );
            });

            __this.callFilter();
          }
        });

      const callBrush = function () {
        const currentBrush = d3.brushSelection(d3.select(this).node());
        if (__this.filterInput && __this.filterInput[0] && __this.filterInput[0].length > 0) {
          const checkIndex = (key) => __this.domain.keys.indexOf(key);
          const len = __this.domain.keys.length;
          let minX =
            d3.min(
              __this.filterInput[0].map((fi) => fi),
              (mn) => __this.scaleXPos(checkIndex(mn), len) - __this.margin.left,
            ) || 0;
          let maxX = d3.max(
            __this.filterInput[0].map((fi) => fi),
            (mx) => __this.scaleXPos(checkIndex(mx), len) - __this.margin.left,
          );

          minX -= __this.width / len / 4;
          maxX += __this.width / len / 4;

          const brushArea = [minX > 0 ? minX : 0, maxX > 0 ? maxX : 0];

          if (
            !currentBrush ||
            currentBrush.length !== 2 ||
            currentBrush[0] !== brushArea[0] ||
            currentBrush[1] !== brushArea[1]
          ) {
            d3.select(this).call(__this.brush).call(__this.brush.move, brushArea);
          }
        } else {
          const brushOn = d3.brushSelection(d3.select(this).node()) != null;

          if (__this.filterInput && !__this.filterInput[0] && brushOn) {
            d3.select(this).call(__this.brush.move, null);
          } else {
            d3.select(this).call(__this.brush);
          }
        }
      };

      this.brushArea = this.base.selectAll('.svg-brush').data([this.data]);

      this.brushArea.exit().remove();

      this.brushArea
        .attr('width', this.width + this.margin.left)
        .attr('height', this.height + this.margin.top + 5 * this.unit)
        .select('.brush')
        .attr('transform', `translate(${this.margin.left},${this.margin.top})`)
        .each(callBrush);

      this.brushArea
        .enter()
        .append('svg')
        .attr('class', 'svg-brush')
        .attr('width', this.width + this.margin.left)
        .attr('height', this.height + this.margin.top + 5 * this.unit)
        .style('position', 'absolute')
        .style('top', 0)
        .style('left', 0)
        .on('mousemove', hoverFunction)
        .on('mouseout', function () {
          __this.timePos = null;
          __this.hoveredLines = '';
          __this.setTooltip(null);
        })
        .on('click', clickFunction)
        .append('g')
        .attr('class', 'brush')
        .attr('stroke-width', 0)
        .attr('transform', `translate(${this.margin.left},${this.margin.top})`)
        .each(callBrush);
    } else {
      this.brushArea = this.base.selectAll('.svg-brush').data([]);

      this.brushArea.exit().remove();
    }

    this.base
      .selectAll('.timeline-chart-canvas')
      .on('mousemove', hoverFunction)
      .on('mouseout', function () {
        __this.timePos = null;
        __this.hoveredLines = '';
        __this.setTooltip(null);
      })
      .on('click', clickFunction);
  }

  setTooltip(position: number | null, data: any[] = [], parentData: any = [], legends: any = []) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const __this = this;
    // const parents = parentData.children;
    const sortedData = new Array(...data).sort((a, b) => Number(b.value) - Number(a.value));

    // adding hovering effect
    this.base.selectAll('.timeline-chart-canvas').each(function (d) {
      if (this.parentElement) {
        // const i = this.parentElement.attributes['data-index'].value;
        const hoveredLegends = legends.filter((item) => item.groupId === d.id);

        __this.setLines(
          __this.context.context,
          __this.context.data,
          __this.scaleY,
          [],
          [parentData, data],
          hoveredLegends,
          __this.responses,
        );
      }
    });

    this.hover.emit(parentData && parentData[0] ? parentData[0]['key'] : '');

    const options = {
      backgroundColor: (d) =>
        this.colorScale(this.domain.keysY.length > 0 && d.colorIndex != null ? d.colorIndex : null),
      html: (d) => `<div class="question">${data.length > 1 ? '<span class="circle">●</span>' : ''}
        <span class="text">${this.domain.labelsY[d.key] || ''}</span> <span class="stats">
        <span class="icon">contact</span> <span class="number">${d.value != null ? d.value : '-'} (${
          d.percentage != null ? (d.percentage * 100).toFixed(1) : '-'
        }%)</span></span>`,
      time: () =>
        parentData && parentData[0] && parentData[0]['key']
          ? `<span class="date">${
              this.timelineFormat && Number(parentData[0]['key'])
                ? this.timelineFormat.format(Number(parentData[0]['key']))
                : ''
            }</span>`
          : '',
    };

    this.base.selectAll('.trend-chart-tooltip').remove();

    this.tooltip = this.base.selectAll('.trend-chart-tooltip').data(sortedData.length > 0 ? [sortedData] : []);

    this.tooltip.exit().remove();

    this.tooltip.html(options.time);

    this.tooltip.enter().append('div').attr('class', 'trend-chart-tooltip').html(options.time);

    this.tooltipTexts = this.base
      .selectAll('.trend-chart-tooltip')
      .selectAll('.trend-chart-tooltip-text')
      .data((d) => d);

    this.tooltipTexts.exit().remove();

    this.tooltipTexts.style('color', options.backgroundColor).html(options.html);

    this.tooltipTexts
      .enter()
      .append('div')
      .attr('class', 'trend-chart-tooltip-text')
      .style('color', options.backgroundColor)
      .html(options.html);

    this.base.selectAll('.trend-chart-tooltip').style('transform', function (d, i, g) {
      const w = __this.margin.left + __this.width + __this.margin.right;
      const tipW = this.getBoundingClientRect().width;
      const pos = position; // + (__this.scaleX.bandwidth() / 2);
      let sumHeight = 15;
      for (let e = 0, len = g.length; e < len; e++) {
        if (e <= i) {
          sumHeight += g[e].getBoundingClientRect().height;
        }
      }

      return `translate(${
        tipW < w ? (pos + tipW / 2 > w ? w - tipW : pos - tipW / 2 < 0 ? 0 : pos - tipW / 2) : 0
      }px,${__this.margin.top - sumHeight}px)`;
    });
  }

  callFilter() {
    if (this.filtering && !this.anonymityLock) {
      this.filter = [];

      const filterX = {
        key: this.domain.key,
        values: this.details.values,
        filter: Array.from(this.selections[0]),
      };

      this.filter.push(filterX);

      const filterInput = JSON.stringify(this.filterInput.slice(0, 1).map((item) => (item == null ? [] : item)));
      const filter = JSON.stringify(this.filter.map((item) => item.filter));

      if (filter !== filterInput) {
        this.cf.filter(this.filter);
      } else {
        const brushOn =
          this.brushArea.select('.brush').node() != null &&
          d3.brushSelection(this.brushArea.select('.brush').node()) != null;

        if (!this.filterInput[0] && brushOn) {
          this.brushArea.select('.brush').call(this.brush.move, null);
        }
      }
    }
  }

  // Helpers
  selectForHover(area) {
    const itemsBelow = this.itemsBelow(area);

    const parents = itemsBelow.parents;
    const childs = itemsBelow.childs.length > 0 ? itemsBelow.childs : [];
    const legends = itemsBelow.legends;
    const timePos =
      parents && parents.length > 0
        ? this.scaleXPos(this.domain.keys.indexOf(parents[0]['key']), this.domain.keys.length)
        : null;
    let hoveredLines = '';

    for (let c = 0, lenc = childs.length; c < lenc; c++) {
      hoveredLines += childs[c]['key'];
    }

    if (this.timePos !== timePos || this.hoveredLines !== hoveredLines) {
      if (parents.length > 0) {
        this.setTooltip(timePos, childs, parents);
      } else {
        this.setTooltip(timePos);
      }
      this.timePos = timePos;
      this.hoveredLines = hoveredLines;
    } else if (legends.length > 0) {
      this.setTooltip(timePos, [], parents, legends);
    }
  }

  itemsBelow(area) {
    const allChilds = [];
    const exactChilds = [];
    const parents = [];

    for (let p = 0, lenp = this.data[0].length; p < lenp; p++) {
      const width = this.width / lenp;
      const parentItem = this.data[0][p];
      const xPosParent1 = this.scaleXPos(p, lenp) - width / 2;
      const xPosParent2 = this.scaleXPos(p, lenp) + width / 2;

      if (
        area[0] < xPosParent2 &&
        area[0] >= xPosParent1 &&
        area[1] < this.margin.top + this.height + 5 * this.unit &&
        area[1] >= this.margin.top
      ) {
        parents.push(parentItem);

        if (this.domain.keysY.length > 0) {
          for (let l = 0, len = parentItem['children'].length; l < len; l++) {
            const item = Object.assign({}, parentItem['children'][l]);
            const yPos = this.scaleY(this.scale === 'percentage' ? item.percentage : item.value);

            item['colorIndex'] = this.domain.colorsY[item.key] || l;

            allChilds.push(item);

            if (area[1] > yPos + this.margin.top - 5 && area[1] < yPos + this.margin.top + 5) {
              exactChilds.push(item);
            }
          }
        } else {
          const item = Object.assign({}, parentItem);
          item['key'] = 'aggregate';

          allChilds.push(item);
        }
      }
    }

    const legends = this.legends.filter(
      (item) =>
        area[0] > item.x - 15 && area[0] < item.x + item.width && area[1] > item.y && area[1] < item.y + item.height,
    );

    return { parents, childs: exactChilds.length > 0 ? exactChilds : allChilds, legends };
  }

  scaleXPos(index: number, arrLen: number) {
    return index * (this.width / arrLen) + this.margin.left + this.width / arrLen / 2;
  }
}
