import * as d3 from 'd3';

import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';

import {
  CanvasContext,
  ChartDomain,
  ChartSizeInput,
  SummaryChartData,
  SummaryChartDataChild,
} from '@shared/models/report.model';

import { Colors } from '@report/shared/enums/colors.enum';
import { shortenText } from '@shared/utilities/canvas.utilities';

/**
 * This is a Summary 2d chart directive.
 */
@Directive({
  selector: '[summary1d]',
})
export class Summary1DChart implements OnChanges {
  @Input() chartData: SummaryChartData = {} as SummaryChartData;
  @Input() singleItem: string = '';
  @Input() showOnlyScale: boolean = false;
  @Input() size: ChartSizeInput = {} as ChartSizeInput;
  @Input() highlight: any[] = [];
  @Input() groupHighlight: string = '';
  @Input() selectedItems: any[] = [];
  @Input() domain: ChartDomain[] = [];
  @Input() zvalues: boolean = false;
  @Input() showAverages: boolean = false;
  @Input() showTooltip: boolean = false;
  @Input() transitionDuration: number = 0;
  @Input() update: Date = new Date();
  @Input() comparison: any;
  @Input() comparisonMode: string = 'grouped';
  @Input() selectionExists: boolean = false;
  @Input() filtersDemo: boolean = false;
  @Input() tooltipContainer: any;

  @Output() hover: EventEmitter<string[]> = new EventEmitter<string[]>();

  // Please notice height related margins from summary-1d.component
  private margin: any = { top: 50, right: 50, bottom: 50, left: 50 };
  private width: number = 0;
  private height: number = 0;
  private ballSize: number = 0;
  private itemHeight: number = 0;
  private fontSize: number = 0;

  private context: CanvasContext = {} as CanvasContext;
  private singleItemIndex: number = null;
  private childIndexes: number[] = [];
  private previousHover: string[] = [];

  // D3 elements
  private base: any;
  private tooltip: any;
  private tooltipTexts: any;
  private canvas: any;

  @HostListener('window:resize') resize() {
    this.updateChart(null, null);
  }

  constructor(private _element: ElementRef) {
    this.constructBody();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.chartData ||
      changes.size ||
      changes.highlight ||
      changes.groupHighlight ||
      changes.domain ||
      changes.zvalues ||
      changes.showAverages ||
      changes.update ||
      changes.comparison ||
      changes.comparisonMode ||
      changes.filtersDemo ||
      changes.singleItem ||
      changes.showOnlyScale ||
      changes.selectedItems
    ) {
      this.updateChart(changes.update, changes.zvalues);
    }
  }

  constructBody() {
    this.margin = { top: 50, right: 50, bottom: 50, left: 50 };
    this.base = d3.select(this._element.nativeElement);
  }

  updateChart(dataChanges: SimpleChange | null, zChanges: SimpleChange | null) {
    this.setSizes();
    this.setCanvas(dataChanges, zChanges);
  }

  /**
   * Setting sizes for 1Ds.
   */
  setSizes() {
    this.fontSize = parseFloat(window.getComputedStyle(this._element.nativeElement).fontSize);
    this.margin = {
      top: !this.singleItem && !this.showOnlyScale ? this.fontSize * 3 : 0,
      right: this.fontSize * 3,
      bottom: !this.singleItem ? this.fontSize * 3 : 0,
      left: this.fontSize * 3,
    };
    this.width =
      this.size.width - this.margin.left - this.margin.right > 0
        ? this.size.width - this.margin.left - this.margin.right
        : 0;

    const height = this._element.nativeElement.clientHeight - this.margin.top - this.margin.bottom;

    this.height = height > 0 ? height : 0;
    this.itemHeight =
      height > 0
        ? this.singleItem || this.showOnlyScale
          ? height
          : height / (this.chartData?.visibleQuestionList?.length || 1)
        : 0;
    this.ballSize = !this.showOnlyScale
      ? Math.min(this.width / 24, this.height > 16 ? this.height / 2 - 8 : Infinity)
      : 4;
    this.singleItemIndex = this.chartData?.visibleQuestionList?.findIndex((item) => item?.[0]?.key === this.singleItem);
    this.childIndexes =
      this.chartData?.children.map((item) =>
        this.chartData?.visibleQuestionList.findIndex(
          (vItem) => this.unGrouppedKey(vItem?.[0]?.key) === this.unGrouppedKey(item.key),
        ),
      ) || [];
  }

  /**
   * Selecting colors.
   */
  colorScale(index) {
    if (this.comparison) {
      return Colors.getComparisonColor(index);
    } else {
      return this.selectionExists ? Colors.SELECTED : this.filtersDemo ? Colors.UNSELECTED : Colors.DEFAULT;
    }
  }

  setCanvas(dataChanges: SimpleChange | null, zChanges: SimpleChange | null) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const __this = this;
    const drawContent = function (d) {
      const context = d3.select(this).node().getContext('2d');
      let averages: number | null = null;
      const dataItems = d.children;

      if (__this.showAverages) {
        averages = __this.zvalues
          ? __this.margin.left + __this.width / 2
          : d3.mean(
              dataItems.filter((f: any) => !f.isGroupAverage).filter((f: any) => f.n > 0),
              (c: any) => c.cx + __this.margin.left,
            );
      }

      if (
        ((dataChanges && !dataChanges.firstChange) || (zChanges && !zChanges.firstChange)) &&
        __this.transitionDuration > 0
      ) {
        let dataObj = __this.context && __this.context.data ? __this.context.data : [];
        const averageObj: number | null =
          averages != null && __this.context && __this.context.averages
            ? __this.context.averages != null
              ? __this.context.averages
              : averages
            : null;

        if (JSON.stringify(dataObj.map((item) => item.key)) !== JSON.stringify(dataItems.map((item) => item.key))) {
          dataObj = dataItems.map((item) => dataObj.find((dItem) => dItem.key === item.key) || item);
        }

        const interpolator = d3.interpolateArray(dataObj, dataItems);
        const averageInterpolator = d3.interpolateNumber(averageObj ? averageObj : 0, averages ? averages : 0);
        const ease = d3.easeCubic;

        const t = d3.timer((elapsed) => {
          const step = elapsed / __this.transitionDuration;
          let data;
          let average;

          if (step >= 1) {
            data = dataItems;
            if (__this.showAverages && averages === null) {
              averages = __this.zvalues
                ? __this.margin.left + __this.width / 2
                : d3.mean(dataItems, (c: any) => c.cx + __this.margin.left);
            }
            average = __this.showAverages ? averages : null;

            t.stop();
          } else {
            data = interpolator(ease(step));
            average = __this.showAverages && averages != null ? averageInterpolator(ease(step)) : null;
          }

          __this.setChart(context, data, d.sliderDetails, __this.highlight, average);
        });
      } else {
        __this.setChart(context, dataItems, d.sliderDetails, __this.highlight, averages);
      }

      __this.context = { context, data: dataItems, averages };
    };

    const hoverFunction = function (event) {
      const area = d3.pointer(event);
      __this.selectForHover(event, area);
    };
    const canvasName: string = !this.showOnlyScale ? 'summary-1d-canvas' : 'summary-1d-scale-canvas';

    this.canvas = this.base.selectAll('.' + canvasName).data([this.chartData]);

    this.canvas.exit().remove();

    this.canvas
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .each(drawContent);

    this.canvas
      .enter()
      .append('canvas')
      .attr('class', canvasName)
      .on('mousemove', hoverFunction)
      .on('mouseout', function (event) {
        __this.setTooltip(event, d3.pointer(event, __this._element.nativeElement));
      })
      .style('position', 'relative')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .each(drawContent);
  }

  setChart(context, data, details, highlighted: any[] = [], average: number | null = null) {
    context.clearRect(
      0,
      0,
      this.width + this.margin.left + this.margin.right,
      this.height + this.margin.top + this.margin.bottom,
    );
    context.globalAlpha = 1;

    this.setArea(context);
    this.setTexts(context, details);

    const highlightsOn = (highlighted && highlighted.length > 0) || this.groupHighlight;
    const highlightArea: any[] = [];

    let isFirstHelper: boolean = true;

    for (let i = 0, len = data.length; i < len; i++) {
      context.globalAlpha = 1;
      const newIndex: number = this.childIndexes[i];
      const drawHelpers: boolean = !(this.singleItemIndex >= 0) || (newIndex >= 0 && newIndex <= this.singleItemIndex);

      if (data[i].n > 0 && newIndex >= 0 && drawHelpers) {
        this.setHelperLines(
          context,
          data[i],
          newIndex,
          this.singleItemIndex >= 0 && newIndex === this.singleItemIndex,
          !!highlighted.find((item) => data[i].key === item) ||
            this.groupHighlight === data[i].group ||
            !!(this.selectedItems || []).find((item) => data[i].key === item),
          isFirstHelper,
        );
        isFirstHelper = false;
      }

      if (highlightsOn) {
        const highlight = highlighted.find((item) => data[i].key === item) || this.groupHighlight === data[i].group;
        if (highlight) {
          context.globalAlpha = 0.1;
        } else {
          context.globalAlpha = 0.03;
        }
      } else {
        context.globalAlpha = 0.1;
      }

      if (
        data[i].n > 0 &&
        !this.showOnlyScale &&
        newIndex >= 0 &&
        (!this.singleItem || this.unGrouppedKey(data[i].key) === this.unGrouppedKey(this.singleItem))
      ) {
        this.setEllipses(context, data[i], newIndex);
      }
    }

    for (let i = 0, len = data.length; i < len; i++) {
      const newIndex: number = this.childIndexes[i];
      if (
        data[i].n > 0 &&
        !this.showOnlyScale &&
        newIndex >= 0 &&
        (!this.singleItem || this.unGrouppedKey(data[i].key) === this.unGrouppedKey(this.singleItem))
      ) {
        let highlight;
        if (highlightsOn) {
          highlight = highlighted.find((item) => data[i].key === item) || this.groupHighlight === data[i].group;
          if (highlight) {
            context.globalAlpha = 0.9;
            highlightArea.push([data[i].cx, data[i].number || data[i].numberAlphabet]);
          } else {
            const onTop = highlightArea.filter(
              (area) =>
                area[0] < data[i].cx + this.ballSize &&
                area[0] > data[i].cx - this.ballSize &&
                area[1] === (data[i].number || data[i].numberAlphabet),
              //     area[1] < data[i].cy + this.ballSize &&
              //     area[1] > data[i].cy - this.ballSize,
            );
            if (onTop.length > 0) {
              context.globalAlpha = 0.03;
            } else {
              context.globalAlpha = 0.5;
            }
          }
        } else {
          context.globalAlpha = 0.9;
        }

        this.setBalls(
          context,
          data[i],
          newIndex,
          highlight || !!(this.selectedItems || []).find((item) => data[i].key === item),
        );
      }
    }

    context.globalAlpha = 1;

    if (average != null) {
      context.fillStyle = Colors.AVERAGEHELPER;
      context.strokeStyle = Colors.AVERAGEHELPER;
      context.beginPath();
      if (!this.singleItem) {
        context.arc(average, this.margin.top + this.height, 5, 0, 2 * Math.PI, false);
        context.moveTo(average, this.margin.top + this.height - 5);
      } else {
        context.moveTo(average, this.margin.top + this.height);
      }

      context.lineTo(average, this.margin.top);
      context.fill();
      context.stroke();
    }
  }

  setArea(context) {
    context.strokeStyle = Colors.BACKGROUNDSUMMARY;
    context.fillStyle = Colors.BACKGROUND;

    context.beginPath();
    context.moveTo(this.margin.left + this.width / 2, this.margin.top);
    context.lineTo(this.margin.left + this.width / 2, this.margin.top + this.height - (!this.singleItem ? 5 : 0));

    if (!this.singleItem) {
      context.moveTo(this.margin.left, this.margin.top + this.height);
      context.lineTo(this.margin.left + this.width, this.margin.top + this.height);
      context.arc(this.margin.left + this.width / 2, this.margin.top + this.height, 5, 0, 2 * Math.PI);
    }

    context.stroke();
    context.fill();
  }

  setTexts(context, sliderDetails) {
    context.font = 12 / 14 + 'em Inter';
    context.fillStyle = Colors.TEXT;
    context.textBaseline = 'top';
    context.textAlign = 'start';

    const xMinLabel = shortenText(context, sliderDetails.labels.min, this.width / 2, (10 / 14) * this.fontSize);
    context.fillText(xMinLabel, this.margin.left, this.height + this.margin.top + this.margin.bottom / 12);

    context.textAlign = 'end';
    const xMaxLabel = shortenText(context, sliderDetails.labels.max, this.width / 2, (10 / 14) * this.fontSize);
    context.fillText(xMaxLabel, this.margin.left + this.width, this.height + this.margin.top + this.margin.bottom / 12);

    context.font = 'normal bold ' + 12 / 14 + 'em Inter';
    context.textAlign = 'center';
    const xAxisLabel = shortenText(context, sliderDetails.labels.axis, this.width, (10 / 14) * this.fontSize);
    context.fillText(
      xAxisLabel,
      this.margin.left + this.width / 2,
      this.height + this.margin.top + (1.5 * this.margin.bottom) / 3,
    );

    if (this.comparison && this.comparisonMode !== 'joined' && this.margin.top) {
      context.font = this.margin.top / 3.5 + 'px Inter';
      context.textBaseline = 'middle';

      const title = this.chartData.group.title ? shortenText(context, this.chartData.group.title, this.width, 10) : '';

      context.fillText(title, this.margin.left + this.width / 2, this.margin.top / 2);
    }
  }

  setHelperLines(context, data, i, isSingleItem, highlighted, isFirstHelper) {
    const cx = data.cx + this.margin.left;
    const cy =
      this.singleItem || this.showOnlyScale
        ? this.margin.top + this.height / 2
        : i * this.itemHeight + this.margin.top + this.itemHeight / 2;

    context.save();

    context.strokeStyle = Colors.HELPERLINE;
    context.fillStyle = Colors.BACKGROUND;
    context.lineWidth = 1;

    if ((!this.showOnlyScale && isFirstHelper) || (!this.singleItem && !this.showOnlyScale)) {
      context.beginPath();
      context.moveTo(this.margin.left, cy);
      context.lineTo(this.margin.left + this.width, cy);
      context.stroke();
    }

    if (highlighted) {
      context.lineWidth = 2;
      context.strokeStyle = Colors.HIGHLIGHT;
      context.setLineDash([]);
    } else {
      context.lineWidth = 1;
      context.strokeStyle = Colors.HELPERLINE;
      context.setLineDash([5, 5]);
    }

    context.beginPath();
    context.moveTo(cx, !this.singleItem || isSingleItem ? cy + this.ballSize : this.margin.top);
    context.lineTo(cx, this.margin.top + this.height + (!this.singleItem ? -5 : this.margin.bottom));
    context.stroke();

    context.setLineDash([]);

    if (!this.singleItem) {
      context.beginPath();
      context.arc(cx, this.margin.top + this.height, 3, 0, 2 * Math.PI, false);
      context.fill();
      context.stroke();
    }

    context.restore();
  }

  setEllipses(context, data, i) {
    context.fillStyle = this.colorScale(data.index);

    const cx = data.cx + this.margin.left;
    const cy = this.singleItem
      ? this.margin.top + this.height / 2
      : i * this.itemHeight + this.margin.top + this.itemHeight / 2;
    const w = data.rx;
    const h = this.ballSize;
    const radius = h;
    const scaleX = w / h;
    const scaleY = 1;

    context.save();
    context.beginPath();
    context.scale(scaleX, scaleY);
    context.arc(cx / scaleX, cy / scaleY, radius, 0, 2 * Math.PI, false);
    context.closePath();
    context.restore();
    context.fill();
  }

  setBalls(context, data, i, highlight: any) {
    context.fillStyle = this.colorScale(data.index);
    context.strokeStyle = Colors.HIGHLIGHT;
    context.lineWidth = 2;

    const cx = data.cx + this.margin.left;
    const cy = this.singleItem
      ? this.margin.top + this.height / 2
      : i * this.itemHeight + this.margin.top + this.itemHeight / 2;
    const radius = this.ballSize;

    if (highlight && !this.singleItem) {
      context.beginPath();
      context.moveTo(cx, cy);
      context.lineTo(cx, this.margin.top + this.height - 5);
      context.arc(cx, this.margin.top + this.height, 3, 0, 2 * Math.PI, false);
      context.stroke();
    }

    context.beginPath();
    context.arc(cx, cy, radius, 0, 2 * Math.PI, false);
    context.closePath();

    context.fill();
    if (highlight) {
      context.stroke();
    }

    context.fillStyle = Colors.BACKGROUND;
    context.font = this.ballSize + 'px Inter';
    context.textAlign = 'center';
    context.textBaseline = 'middle';

    if (data.number != null) {
      context.fillText(Math.round(Number(data.number)), cx, cy);
    } else if (data.numberAlphabet) {
      context.fillText(data.numberAlphabet, cx, cy);
    }
  }

  setTooltip(event, position, data: any[] = [], containerPosition = null) {
    if (this.showTooltip) {
      const options = {
        backgroundColor: (d) => (this.comparison && this.comparisonMode === 'joined' ? this.colorScale(d.index) : null),
        html: (d) =>
          `<div class="question">${d.number || d.numberAlphabet}. ${d.title}${
            d.groupLabel ? ` (${d.groupLabel})` : ''
          }</div> <div class="stats"><span class="icon">contact</span> ${d.n} <span class="icon">axis_x</span> ${
            this.zvalues
              ? d.zAverage != null
                ? d.zAverage.toFixed(1)
                : '-'
              : d.average != null
                ? d.average.toFixed(1)
                : '-'
          }</div>`,
      };
      const tooltipBase: any = d3.select(this.tooltipContainer || this._element.nativeElement);

      this.tooltip = tooltipBase.selectAll('.summary1d-tooltip').data(data.length > 0 ? data : []);

      this.tooltip.exit().remove();

      this.tooltip
        .style('background-color', options.backgroundColor)
        .style('border-top-color', options.backgroundColor);

      this.tooltip
        .enter()
        .append('div')
        .attr('class', 'summary1d-tooltip')
        .style('background-color', options.backgroundColor)
        .style('border-top-color', options.backgroundColor);

      this.tooltipTexts = tooltipBase
        .selectAll('.summary1d-tooltip')
        .selectAll('.summary1d-tooltip-text')
        .data((d) => [d]);

      this.tooltipTexts.exit().remove();

      this.tooltipTexts.html(options.html);

      this.tooltipTexts.enter().append('div').attr('class', 'summary1d-tooltip-text').html(options.html);

      tooltipBase.selectAll('.summary1d-tooltip').style('transform', function (d, i, g) {
        const pos = containerPosition != null ? containerPosition : position;

        let sumHeight = 15;
        for (let e = 0, len = g.length; e < len; e++) {
          if (e <= i) {
            sumHeight += g[e].getBoundingClientRect().height + 10;
          }
        }
        return `translate(${pos[0] - 125}px,${pos[1] - sumHeight}px)`;
      });
    }

    const hoverData: string[] = data.map((item) => item.key);

    if (JSON.stringify(hoverData) !== JSON.stringify(this.previousHover)) {
      this.hover.emit(hoverData);
      this.previousHover = hoverData;

      // set Hover effects to chart
      this.setChart(
        this.context.context,
        this.context.data,
        this.chartData.sliderDetails,
        data.map((item) => item.key),
        null,
      );
    }
  }

  // Helpers
  selectForHover(event, area): void {
    const itemsBelow: SummaryChartDataChild[] = this.itemsBelow(area);

    if (itemsBelow.length > 0) {
      this.setTooltip(
        event,
        d3.pointer(event, this._element.nativeElement.firstElementChild),
        itemsBelow,
        this.tooltipContainer ? d3.pointer(event, this.tooltipContainer) : null,
      );
    } else {
      this.setTooltip(event, d3.pointer(event, this._element.nativeElement.firstElementChild));
    }
  }

  itemsBelow(area): SummaryChartDataChild[] {
    return this.chartData.children.filter(
      (item, i) =>
        !item.isUnderAnonymityTreshold &&
        ((this.singleItem && this.unGrouppedKey(item.key) === this.unGrouppedKey(this.singleItem)) ||
          !this.singleItem) &&
        area[0] < item.cx + this.ballSize + this.margin.left &&
        area[0] > item.cx - this.ballSize + this.margin.left &&
        (this.singleItem ||
          this.showOnlyScale ||
          (this.childIndexes[i] >= 0 &&
            area[1] < this.childIndexes[i] * this.itemHeight + this.itemHeight / 2 + this.ballSize + this.margin.top &&
            area[1] > this.childIndexes[i] * this.itemHeight + this.itemHeight / 2 - this.ballSize + this.margin.top)),
    );
  }

  unGrouppedKey(key: string): string {
    return key.indexOf('\u001D') >= 0 ? key.split('\u001D')[1] : key;
  }
}
