/**
 * Common module for the report charts.
 *
 * @unstable
 */

import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

import { ChartsView } from '@report/+charts/charts.component';

import { ChartModule } from '@report/shared/modules/chart.module';

import { NotesCard } from '@report/+charts/notes-card/notes-card.component';
import { ChartCard } from '@report/+charts/chart-card/chart-card.component';
import { ChartDiscover } from '@report/+charts/chart-discover/chart-discover.component';
import { ChartBuilder } from '@report/+charts/chart-builder/chart-builder.component';

import { ChartMenu } from '@report/+charts/chart-card/chart-menu/chart-menu.component';

import { FullscreenChart } from '@report/+charts/fullscreen-chart/fullscreen-chart.dialog';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
  bootstrap: [],
  declarations: [ChartsView, NotesCard, ChartCard, ChartDiscover, ChartBuilder, ChartMenu, FullscreenChart],
  exports: [SharedModule, ChartModule, ChartsView],
  imports: [SharedModule, ChartModule, PortalModule, OverlayModule],
  providers: [],
})
export class ChartsModule {}
